import JWTDecode from "jwt-decode";

import { logger } from "../../../shared/core/logging/logger";
import type { TokenResult } from "../../../shared/domains/authentication/authentication-service";
import type {
  AccessToken,
  ConnectedSession,
  JWTToken,
  NotConnectedSession,
  Session,
} from "../../../shared/domains/authentication/session";
import type { SecuredCookiesService } from "../../core/net/secured-cookies-service";
import type { Scope, ServiceDomainScope } from "./scope";

export class SessionBuilder {
  private securedCookies: boolean;

  public constructor(securedCookiesService?: SecuredCookiesService) {
    this.securedCookies = securedCookiesService?.getSecuredCookies() ?? false;
  }

  public build(tokens?: TokenResult): Session {
    try {
      if (!tokens) {
        return this.buildNotConnectedSession();
      } else {
        if (tokens.refreshToken) {
          return this.buildConnectedSession(tokens, tokens.refreshToken);
        } else {
          return this.buildNotConnectedSession(tokens);
        }
      }
    } catch (e) {
      logger.debug("AuthenticationManager", "Build session failed", e);
      return this.buildNotConnectedSession();
    }
  }

  private buildScopes(tokens?: TokenResult): (Scope | ServiceDomainScope)[] {
    const decodedToken: JWTToken | null = tokens ? JWTDecode(tokens.accessToken) : null;
    return decodedToken?.scopes ?? [];
  }

  private buildAccessToken(tokens: TokenResult, expirationDate: Date): AccessToken {
    return this.securedCookies
      ? {
          value: "",
          type: "",
          expirationDate,
        }
      : {
          value: tokens.accessToken,
          type: tokens.tokenType,
          expirationDate,
        };
  }

  private buildClientId(tokens?: TokenResult): string | null {
    const decodedToken: JWTToken | null = tokens ? JWTDecode(tokens.accessToken) : null;
    return decodedToken?.user?.id ?? null;
  }

  private buildConnectedSession(tokens: TokenResult, refreshToken: string): ConnectedSession {
    const clientId = this.buildClientId(tokens);
    const scopes = this.buildScopes(tokens);
    const accessToken = this.buildAccessToken(tokens, tokens.expirationDate);
    return this.securedCookies
      ? {
          accessToken,
          refreshToken: "",
          scopes,
          isConnected: true,
          clientId,
        }
      : {
          accessToken,
          refreshToken,
          scopes,
          isConnected: true,
          clientId,
        };
  }

  private buildNotConnectedSession(tokens?: TokenResult): NotConnectedSession {
    const scopes = this.buildScopes(tokens);
    const accessToken = tokens ? this.buildAccessToken(tokens, tokens.expirationDate) : null;
    return {
      accessToken,
      scopes: scopes,
      isConnected: false,
    };
  }
}
