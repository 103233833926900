import type { CurrencyIdentifier } from "../../../core/currency/currency";
import type { TimeRangeSelection } from "../search/transaction-search-manager";

export enum TransactionDirection {
  ALL = "All",
  INCOME = "Income",
  OUTCOME = "Outcome",
}

export interface TransactionFilters {
  timerange: TimeRangeSelection;
  amountRange: [number | null, number | null];
  currencyId: CurrencyIdentifier | undefined;
  isQualified: number | undefined;
  isJustified: number | undefined;
  isLocked: number | undefined;
  direction?: "debit" | "credit";
}
