import type { Amount } from "../../../core/amount/amount";

export interface CashTransfer {
  id: string;
  type: CashTransferType;
  date: string;
  amount: Amount;
  label: string;
  code: string;
  recipient?: string;
  sender?: string;
}

export enum CashTransferType {
  Received = 0,
  Sent = 1,
}

export const defaultTransferTitles = {
  [CashTransferType.Received]: "cashTransferScreen.receivedTransferDefaultTitle",
  [CashTransferType.Sent]: "cashTransferScreen.sentTransferDefaultTitle",
};
