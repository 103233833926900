import React, { useState } from "react";
import styled from "styled-components";

import { useIntl } from "../../../../../shared/core/i18n/use-intl";
import { identityManager } from "../../../../../shared/core/service/services";
import type { KycDocument } from "../../../../../shared/domains/identity/identity";
import { RoundedSquareButton } from "../../../common/shape/rounded-square";
import { Spinner } from "../../../common/spinner";
import { DownloadIcon } from "../../../common/svg/download-icon";
import { shadows, theme } from "../../../styles/theme";

interface DownloadDocumentProps {
  document: KycDocument;
}

export const DownloadDocument = ({ document }: DownloadDocumentProps) => {
  const { formatMessage } = useIntl();

  const [isDownloading, setIsDownloading] = useState(false);

  const handleDocumentDownload = async () => {
    setIsDownloading(true);

    try {
      await identityManager.downloadKycDocument(document.id);
    } finally {
      setIsDownloading(false);
    }
  };

  return (
    <DownloadContainer>
      {formatMessage(`documents.${[document.type]}`)}
      {isDownloading ? <Spinner /> : null}
      <DownloadIconContainer size={34} onClick={handleDocumentDownload}>
        <DownloadIcon />
      </DownloadIconContainer>
    </DownloadContainer>
  );
};

const DownloadContainer = styled.div`
  ${shadows.medium};
  ${theme.boldText};
  width: 100%;
  font-size: 0.9375rem;
  margin: 14px 0;
  padding: 10px 22px;
  border-radius: 13px;
  border: 0 none;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
`;

const DownloadIconContainer = styled(RoundedSquareButton)`
  background-color: #f4f4f4;
`;
