import type { AxiosInstance } from "axios";
import axios from "axios";

import { Config } from "../config/config";
import type { DebugInterface } from "../debug/debug-interface";
import { addBaseUrlInterceptor } from "./interceptors/add-base-url-interceptor";
import { addSecuredCookieInterceptor } from "./interceptors/add-secured-cookie-interceptor";
import { addUserAgentInterceptor } from "./interceptors/add-user-agent-interceptor";
import { debugResponseInterceptor } from "./interceptors/debug-response-interceptor";
import { addRequestInterceptor, addResponseInterceptor } from "./interceptors/interceptor";
import { addRequestLogInterceptor } from "./interceptors/log-request-interceptor";
import { logResponseInterceptor } from "./interceptors/log-response-interceptor";
import type { SecuredCookiesService } from "./secured-cookies-service";
import type { UserAgentService } from "./user-agent-service";

export class ServiceDomainApiService {
  public instance: AxiosInstance;

  public constructor(
    private customUserAgentService: UserAgentService | null,
    private securedCookiesService?: SecuredCookiesService,
    private debugIntercept?: DebugInterface | undefined,
  ) {
    this.instance = axios.create();
    addRequestInterceptor(this.instance, addBaseUrlInterceptor(Config.SERVICE_DOMAIN_API_URL));
    //addRequestInterceptor(this.instance, addAuthorizationInterceptor(this.serviceDomainManager));
    if (this.securedCookiesService) {
      addRequestInterceptor(this.instance, addSecuredCookieInterceptor(this.securedCookiesService));
    }
    if (this.customUserAgentService) {
      addRequestInterceptor(this.instance, addUserAgentInterceptor(this.customUserAgentService));
    }
    addRequestInterceptor(this.instance, addRequestLogInterceptor());
    if (this.debugIntercept) {
      addResponseInterceptor(this.instance, debugResponseInterceptor(debugIntercept));
    }
    addResponseInterceptor(this.instance, logResponseInterceptor);
  }
}
