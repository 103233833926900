import React from "react";

import AddButton from "./buttons/add-button";

type AddButtonInputFileProps = {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  accept?: string;
  disabled: boolean;
  children: React.ReactNode;
};

export default function AddButtonInputFile(props: AddButtonInputFileProps) {
  const { accept = "application/pdf,image/jpg,image/jpeg,image/png", onChange, disabled, children } = props;
  const uniqueId = Math.random().toString(36).substring(7);
  return (
    <label
      htmlFor={uniqueId}
      style={{
        display: "block",
        position: "relative",
        width: "100%",
      }}
    >
      <input
        id={uniqueId}
        type="file"
        onChange={onChange}
        accept={accept}
        style={{
          opacity: 0,
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          cursor: "pointer",
        }}
      />
      <AddButton disabled={disabled}>{children}</AddButton>
    </label>
  );
}
