import type { PhoneNumber } from "libphonenumber-js";
import React, { useState } from "react";
import { Redirect, useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";

import { useIntl } from "../../../../../shared/core/i18n/use-intl";
import { authExecutor, registerManager } from "../../../../../shared/core/service/services";
import type { PendingEnrollment } from "../../../../../shared/domains/enrollment/register-manager";
import { useAsyncFunction } from "../../../../../shared/utils/utils";
import { Path } from "../../../../core/routing/path";
import { RegisterState, useRegisterMachine } from "../../../../machine/register-machine";
import { DefaultButton } from "../../../common/buttons/default-button";
import { useQuitBlocking } from "../../../common/nav/use-quit-blocking";
import { StaticAssets } from "../../../styles/custom-assets";
import { theme } from "../../../styles/theme";
import { UIConstants } from "../../../styles/uiConstants";
import EnrollmentLayout from "../shared/components/enrollment-layout";
import EnrollmentProgress from "../shared/components/enrollment-progress";
import EnrollmentStepForm from "../shared/components/enrollment-step-form";
import { RegisterOtpStep } from "./components/register-otp-step";
import PhoneNumberRegisterStep from "./components/register-phone-numbert-step-form";
import {
  RegisterConfirmPincodeStep,
  RegisterNewPincodeStep,
  RegisterPincodeStep,
} from "./components/register-pincode-step";
import { RegisterTermOfServiceStep } from "./components/register-term-of-service";

const usePendingEnrollment = () => {
  const [pendingEnrollment, setPendingEnrollment] = useState<PendingEnrollment | null>(null);
  const phoneNumber = registerManager.parsedPhoneNumber.get();
  useAsyncFunction(async () => {
    const pendingEnrollment = await registerManager.loadPendingEnrollment();
    setPendingEnrollment(pendingEnrollment);
  }, []);

  return { phoneNumber: phoneNumber || pendingEnrollment?.phoneNumber };
};

const centeredStates = [
  RegisterState.ValidateForm,
  RegisterState.ConfirmRegister,
  RegisterState.ConfirmRegisterAfterUbble,
  RegisterState.OTPSubmission,
  RegisterState.OTPConfirming,
  RegisterState.PromptForPincodeChoice,
  RegisterState.FetchingPincodeKeyboard,
  RegisterState.PromptingPincodeKeyboard,
  RegisterState.PincodePromptNotMatch,
  RegisterState.PromptingConfirmPincodeKeyboard,
];

export const RegisterScreen = () => {
  const { formatMessage } = useIntl();
  const { pathname } = useLocation();
  const history = useHistory();

  const { phoneNumber } = usePendingEnrollment();
  const [formPhoneNumber, setFormPhoneNumber] = useState<PhoneNumber | undefined>(undefined);
  const {
    state,
    context,
    keyboard,
    start,
    moveForward,
    moveBackward,
    eulaUri,
    step,
    stepIndex,
    stepCount,
    fillResponse,
    fillMultiImages,
    error,
    complete,
    submitPincode,
    confirmPincode,
    completeTermOfService,
    confirmOtp,
  } = useRegisterMachine(
    pathname.includes(Path.UbbleRedirect)
      ? RegisterState.ConfirmRegisterAfterUbble
      : RegisterState.WaitingForPhoneNumber,
  );

  const { redirectIfAllowed } = useQuitBlocking(
    state !== RegisterState.WaitingForPhoneNumber &&
      state !== RegisterState.OTPConfirming &&
      state !== RegisterState.RegistrationCompleted &&
      state !== RegisterState.ValidateForm,
    formatMessage("registerScreen.quitConfirmMessage"),
  );

  const renderProgressBar = (label?: string) => (
    <ProgressBarWrapper>
      <EnrollmentProgress step={stepIndex} stepCount={stepCount || undefined} label={label} />
    </ProgressBarWrapper>
  );

  const connect = () => {
    if (authExecutor.withPhoneNumberAndPinCode()) {
      const path = Path.Connect;
      history.push(path);
    } else {
      authExecutor.connect();
    }
  };

  const renderRegisterMachine = () => {
    switch (state) {
      case RegisterState.StartRegistration:
      case RegisterState.WaitingForPhoneNumber:
        return (
          <>
            <PhoneNumberRegisterStep
              onSubmit={(phoneNumber) => {
                setFormPhoneNumber(phoneNumber);
                start(phoneNumber);
              }}
              loading={state === RegisterState.StartRegistration}
              errorMessage={context.error}
              initialPhoneNumber={formPhoneNumber}
            />
            <BottomContainer>
              <ConnectQuestion>{formatMessage("registerScreen.connectQuestion")}</ConnectQuestion>
              <ConnectButton onClick={connect}>{formatMessage("registerScreen.connectButton")}</ConnectButton>
            </BottomContainer>
          </>
        );
      case RegisterState.Completing:
        return (
          <>
            {renderProgressBar(step ? step.label : formatMessage("registerScreen.pincodeStepTitle"))}
            {step && (
              <EnrollmentStepForm
                onInputValueChange={fillResponse}
                onInputMultiImagesChange={fillMultiImages}
                step={step}
                onSubmit={moveForward}
                onBackward={moveBackward}
                errorMessage={error}
              />
            )}
          </>
        );
      case RegisterState.PromptForPincodeChoice:
        return <RegisterPincodeStep onSubmit={complete} onBackward={moveBackward} errorMessage={error} />;
      case RegisterState.FetchingPincodeKeyboard:
      case RegisterState.PromptingPincodeKeyboard:
      case RegisterState.PincodePromptNotMatch:
        return (
          <>
            {renderProgressBar(formatMessage("registerScreen.pincodeStepTitle"))}
            <RegisterNewPincodeStep
              keyboard={keyboard}
              onSubmit={submitPincode}
              onBackward={moveBackward}
              errorMessage={
                state === RegisterState.PincodePromptNotMatch
                  ? formatMessage("registerScreen.newCodesDontMatchError")
                  : undefined
              }
            />
          </>
        );
      case RegisterState.PromptingConfirmPincodeKeyboard:
        return (
          <>
            {renderProgressBar(formatMessage("registerScreen.pincodeStepTitle"))}
            <RegisterConfirmPincodeStep keyboard={keyboard} onSubmit={confirmPincode} onBackward={moveBackward} />
          </>
        );
      case RegisterState.ValidateForm:
      case RegisterState.ConfirmRegister:
      case RegisterState.ReadingTermOfService:
        return (
          <>
            {renderProgressBar(formatMessage(eulaUri ? "registerScreen.eulaTitle" : "registerScreen.noEulaTitle"))}
            <RegisterTermOfServiceStep
              onSubmit={completeTermOfService}
              onBackward={moveBackward}
              url={eulaUri ?? "https://tagpay.fr/conditionsgeneralesdutilisation"}
              loading={state === RegisterState.ConfirmRegister || state === RegisterState.ValidateForm}
              errorMessage={error}
              hasEula={!!eulaUri}
            />
          </>
        );
      case RegisterState.ConfirmRegisterAfterUbble:
      case RegisterState.OTPSubmission:
      case RegisterState.OTPConfirming:
        return (
          <RegisterOtpStep
            onSubmit={confirmOtp}
            phoneNumber={phoneNumber}
            loading={state === RegisterState.ConfirmRegisterAfterUbble}
            errorMessage={error}
          />
        );

      case RegisterState.RegistrationCompleted:
        console.log(
          `RegisterState.RegistrationCompleted then redirectTo${Path.Root}?phone=${phoneNumber?.nationalNumber}`,
        );
        return <Redirect to={`${Path.Root}?phone=${phoneNumber?.nationalNumber}`} />;
    }
  };

  return (
    <EnrollmentLayout centered={centeredStates.includes(state)}>
      <Logo src={StaticAssets.logo} onClick={() => redirectIfAllowed(Path.Root)} />
      {renderRegisterMachine()}
    </EnrollmentLayout>
  );
};

const Logo = styled.img`
  width: 112px;
  height: 112px;
  object-fit: contain;
  margin-top: 0;
  margin-bottom: 8px;

  :hover {
    cursor: pointer;
  }

  @media (max-width: ${UIConstants.TABLET_BREAKPOINT}px) {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

const ProgressBarWrapper = styled.div`
  margin: 30px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media (max-width: ${UIConstants.TABLET_BREAKPOINT}px) {
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: stretch;
    margin: 0;
    margin-top: 30px;
    margin-bottom: 30px;
  }
`;

const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 48px;
  white-space: pre;
  align-items: baseline;
`;

const ConnectQuestion = styled.span`
  ${theme.mediumText}
  font-size: 0.9375rem;
  color: black;
`;

const ConnectButton = styled(DefaultButton)`
  ${theme.boldText}
  font-size: 0.9375rem;
  color: ${theme.mainColor};
`;
