import type { HTMLAttributes } from "react";
import React from "react";
import styled from "styled-components";

import type { Amount } from "../../../../../shared/core/amount/amount";
import type { Account } from "../../../../../shared/domains/account/account";
import { AccountingTransactionType } from "../../../../../shared/domains/accounting-transaction/accounting-transaction-type";
import type { AccountingTransaction } from "../../../../../shared/domains/accounting-transaction/transaction/transaction";
import type { AuthorizationHold } from "../../../../../shared/domains/authorization-hold/authorization-hold";
import { ACCOUNT_TRANSACTION_DETAILS_MODAL_ID } from "../../../../core/modal/modal-id";
import { Modal } from "../../../common/modal/modal";
import { theme } from "../../../styles/theme";
import { AccountTransactionCard } from "./account-transaction-card";
import { AccountTransactionDetailsModal } from "./accounting-transaction-details-modal";

interface TransactionsSection {
  data: (AuthorizationHold | AccountingTransaction)[];
  label: string;
  date: Date | string;
  amount?: Amount;
}

interface TransactionsSectionViewProps extends HTMLAttributes<HTMLDivElement> {
  section: TransactionsSection;
  account: Account | null;
}

export function TransactionsSectionView(props: TransactionsSectionViewProps) {
  const { section, account, ...rest } = props;

  return (
    <Section {...rest}>
      <Title>{section.label}</Title>
      {section.data.map((trans) => (
        <TransactionCard
          key={trans.id}
          transaction={trans}
          showUnjustifiedTag={trans.objectType === AccountingTransactionType.Transaction}
          onClick={() => {
            trans.objectType === AccountingTransactionType.Transaction &&
              account &&
              Modal.present(
                ACCOUNT_TRANSACTION_DETAILS_MODAL_ID,
                () => <AccountTransactionDetailsModal account={account} transaction={trans} />,
                {
                  canBeDismissed: false,
                },
              );
          }}
        />
      ))}
    </Section>
  );
}

const Section = styled.section`
  align-self: stretch;
  display: flex;
  flex-direction: column;
`;

const Title = styled.span`
  ${theme.mediumText};
  font-size: 0.875rem;
  color: #b1b1b1;
  margin-bottom: 25px;
`;

const TransactionCard = styled(AccountTransactionCard)`
  cursor: ${(props) => (props.onClick ? "pointer" : null)};
  align-self: stretch;
  &:not(:last-of-type) {
    margin-bottom: 25px;
  }
`;
