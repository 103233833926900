import type { AxiosRequestConfig } from "axios";

import type { UserAgentService } from "../user-agent-service";
import type { Interceptor } from "./interceptor";

export const addUserAgentInterceptor: (userAgentService: UserAgentService) => Interceptor<AxiosRequestConfig> = (
  userAgentService,
) => ({
  onFulfilled: async (config) => {
    const userAgent = await userAgentService.getUserAgent();
    return {
      ...config,
      headers: { "User-Agent": userAgent, ...config.headers },
    };
  },
});
