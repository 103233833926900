import React from "react";
import styled from "styled-components";

import { useIntl } from "../../../../../shared/core/i18n/use-intl";
import { getAvailableRechargeMethod } from "../../../../../shared/domains/recharge/get-available-recharge-methods";
import { useExternalAccounts } from "../../../../../shared/domains/recipients/use-external-accounts";
import { RechargeMethod } from "../../../../machine/recharge-machine";
import { FlashIcon } from "../../../common/svg/flash-icon";
import { RechargeBankToWalletIcon } from "../../../common/svg/recharge-bank-to-wallet-icon";
import { RechargeCardIcon } from "../../../common/svg/recharge-card-icon";
import { SmallChevronIcon } from "../../../common/svg/small-chevron-icon";
import { shadows, theme } from "../../../styles/theme";

interface RechargeMethodSelectionProps {
  selectMethod: (method: RechargeMethod) => void;
}
export const RechargeMethodSelection = (props: RechargeMethodSelectionProps) => {
  const { selectMethod } = props;
  const { data } = useExternalAccounts();
  const externalAccount = data?.[0];
  const externalAccountIBAN = externalAccount?.iban;
  const { hasRechargeByCard, hasBankToWallet } = getAvailableRechargeMethod(externalAccountIBAN);

  const { formatMessage } = useIntl();

  const externalBank = externalAccount?.bankName?.toUpperCase();

  return (
    <ContentContainer>
      <Header>
        <Title>{formatMessage("rechargeMethod.title")}</Title>
        <Subtitle>{formatMessage("rechargeMethod.subtitle")}</Subtitle>
      </Header>
      {hasRechargeByCard && (
        <Card onClick={() => selectMethod(RechargeMethod.WITH_CARD)}>
          <LeftColumn>
            <CardTitle>{formatMessage("rechargeMethod.withCard.title")}</CardTitle>
            <CardDescription>{formatMessage("rechargeMethod.withCard.description")}</CardDescription>
            <TagContainer>
              <FlashIcon fill={theme.mainColor()} />
              <Tag>{formatMessage("rechargeMethod.withCard.instantTag")}</Tag>
            </TagContainer>
          </LeftColumn>
          <RightColumn>
            <IconContainer>
              <RechargeCardIcon fill={theme.mainColor()} />
            </IconContainer>
            <SmallChevronIcon />
          </RightColumn>
        </Card>
      )}
      {hasBankToWallet && (
        <Card onClick={() => selectMethod(RechargeMethod.BANK_TO_WALLET)}>
          <LeftColumn>
            <CardTitle>{formatMessage("rechargeMethod.bankDebit.title")}</CardTitle>
            <CardDescription>
              {externalBank
                ? formatMessage("rechargeMethod.bankDebit.description", { bankName: externalBank })
                : formatMessage("rechargeMethod.bankDebit.noBankDescription")}
            </CardDescription>
          </LeftColumn>
          <RightColumn>
            <IconContainer>
              <RechargeBankToWalletIcon
                style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                fill={theme.mainColor()}
              />
            </IconContainer>
            <SmallChevronIcon />
          </RightColumn>
        </Card>
      )}
    </ContentContainer>
  );
};

const Header = styled.div`
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
`;
const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const Title = styled.div`
  font-size: 1.375rem;
  ${theme.boldText}
`;

const Subtitle = styled.div`
  font-size: 0.9375rem;
  ${theme.text};
  color: #b1b1b1;
  margin-bottom: 40px;
`;

const Card = styled.div`
  display: flex;
  flex-direction: row;
  padding: 18px;
  background-color: #ffffff;
  border-radius: 13px;
  ${shadows.medium};
  margin-bottom: 16px;
  :hover {
    cursor: pointer;
  }
`;

const CardTitle = styled.div`
  ${theme.boldText};
  font-size: 0.9375rem;
  margin-bottom: 5px;
`;

const CardDescription = styled.div`
  font-size: 0.75rem;
  ${theme.text};
  color: #b1b1b1;
  margin-bottom: 15px;
`;

const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 50px;
`;

const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 8px;
  background-color: ${() => theme.mainColorLightBackground()};
`;

const TagContainer = styled.div`
  display: flex;
  flex-direction: row;
  background-color: ${() => theme.mainColorLightBackground()};
  border-radius: 13px;
  height: 20px;
  align-items: center;
  align-self: flex-start;
  padding-left: 5px;
  padding-right: 10px;
`;
const Tag = styled.div`
  font-size: 0.625rem;
  ${theme.boldText}
  color: ${theme.mainColor}
`;
