import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import type { Card } from "../../../../../shared/domains/cards/card";
import { isAnNotEmptyArray } from "../../../../../shared/utils/utils";
import { useQueryParam } from "../../../../domain/authentication/use-query-param";

export const CARD_QUERY_PARAM = "card";
export const useSelectedCard = (cards: Card[]) => {
  const cardId = useQueryParam(CARD_QUERY_PARAM);
  const history = useHistory();
  const cardState = useState<Card | null>(cards.find((card) => card.id === cardId) ?? null);
  const [selectedCard, setSelectedCard] = cardState;

  if (selectedCard === null && cards && isAnNotEmptyArray(cards)) {
    setSelectedCard(cards.find((card) => card.id === cardId) ?? cards[0]);
  }
  if ((cards === null || !isAnNotEmptyArray(cards)) && selectedCard) {
    setSelectedCard(null);
  }

  useEffect(() => {
    setSelectedCard(cards.find((card) => card.id === cardId) ?? null);
  }, [cardId, cards, setSelectedCard]);

  useEffect(() => {
    selectedCard &&
      history.push({
        search: `?${CARD_QUERY_PARAM}=${selectedCard.id}`,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCard]);

  return cardState;
};
