import type { PhoneNumber } from "libphonenumber-js";
import React from "react";
import styled from "styled-components";

import { useIntl } from "../../../../../../shared/core/i18n/use-intl";
import { MarkdownText } from "../../../../common/forms/markdown-text";
import { theme } from "../../../../styles/theme";
import { OtpConfirm } from "../../../recipient/otp-confirm";
import { FormWithButtons } from "../../shared/components/form-with-buttons";

export const RegisterOtpStep: React.FC<{
  phoneNumber?: PhoneNumber;
  onSubmit: (otp: string) => void;
  errorMessage?: string;
  loading?: boolean;
}> = ({ onSubmit, errorMessage, phoneNumber, loading }) => {
  const { formatMessage } = useIntl();

  return (
    <FormWithButtons>
      <Container>
        <Title>{formatMessage("registerScreen.otpTitle")}</Title>
        <OtpConfirm
          submitOtp={onSubmit}
          additionalText={formatMessage("registerScreen.otpLabel")}
          phoneNumber={phoneNumber?.formatInternational() ?? undefined}
          loading={loading}
          errorMessage={errorMessage}
        />
      </Container>
    </FormWithButtons>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: 30px;
`;

const Title = styled(MarkdownText)`
  ${theme.boldText};
  text-align: center;
  font-size: 1.875rem;
  padding-bottom: 20px;
`;
