import React from "react";

import type { FormAutocompleteInput } from "../../../../shared/core/data-forms/form-input-types";
import { AutocompleteInput } from "../forms/autocomplete-input";
import { DisableValueText } from "./disable-value-text";

export interface AutocompleteFieldProps {
  input: FormAutocompleteInput;
  onChange?: (value: string) => void;
  disabled?: boolean;
}

export const AutocompleteField: React.FC<AutocompleteFieldProps> = ({ input, disabled, onChange }) => {
  const handleOnChange = (value?: string) => {
    if (onChange) {
      onChange(value ? value : "");
    }
  };

  return input.disabled || disabled ? (
    <DisableValueText>{input.value}</DisableValueText>
  ) : (
    <AutocompleteInput inputId={input.id} title={input.label} onChange={handleOnChange} />
  );
};
