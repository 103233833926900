import * as React from "react";
import styled from "styled-components";

import { theme } from "../../styles/theme";

interface SwitchButtonProps {
  value: boolean;
  id?: string;
  className?: string;
  onSwitch: (value: boolean) => void;
}

export function SwitchButton(props: SwitchButtonProps) {
  const { value, onSwitch, ...rest } = props;
  return (
    <Switch role="switch" aria-checked={value} onClick={() => onSwitch(!value)} {...rest}>
      <Round />
    </Switch>
  );
}

interface SwitchContainerProps extends SwitchButtonProps {
  labelPosition?: "left" | "right";
  getSwitchLabel?: (value: boolean) => string;
}

export function SwitchContainer(props: SwitchContainerProps) {
  const { value, labelPosition = "rigth", className, onSwitch, getSwitchLabel, ...rest } = props;

  return (
    <Container type="button" className={className} onClick={() => onSwitch(!value)} reversed={labelPosition === "left"}>
      {getSwitchLabel && <Label>{getSwitchLabel(props.value)}</Label>}
      <SwitchButton {...rest} value={value} onSwitch={onSwitch} />
    </Container>
  );
}

const Container = styled.button<{ reversed: boolean }>`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  border-radius: 15px;
  padding: 2px;
  border: none;
  background-color: transparent;
  ${({ reversed }) => (reversed ? "flex-direction: row-reverse;" : "")}
`;

const Switch = styled.div`
  width: 48px;
  height: 25px;
  padding: 3px 3px 3px 26px;
  border-radius: 12.5px;
  background-color: ${theme.mainColor};
  opacity: 1;
  transition: opacity 0.2s;
  will-change: opacity;

  &[aria-checked="false"] {
    background-color: ${theme.colors.gray[300]};
  }

  :disabled {
    opacity: 0.5;
  }
`;

const Round = styled.div`
  width: 19px;
  height: 19px;
  border-radius: 10px;
  background-color: white;
  transition: transform 0.2s;
  will-change: transform;
  transform: translateX(-23px);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);

  [aria-checked="true"] & {
    background-color: #ffffff;
    transform: translateX(0);
  }
`;

const Label = styled.div`
  ${theme.mediumText}
  flex: 1;
  color: black;
  font-size: 0.85rem;
  user-select: none;
`;
