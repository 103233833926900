import React from "react";
import styled from "styled-components";

import { useIntl } from "../../../../../shared/core/i18n/use-intl.ts";
import type { UpcomingTransfer } from "../../../../../shared/domains/transactions/upcoming-transfers/upcoming-transfers";
import { formatFrequency } from "../../../../../shared/utils/formatFrequency.ts";
import { DETAIL_UPCOMING_TRANSFER_MODAL_ID } from "../../../../core/modal/modal-id.ts";
import { AmountText } from "../../../common/amount-text.tsx";
import { RoundedModalContainer } from "../../../common/modal/rounded-modal-container.tsx";
import { UpcomingTransferStatusPill } from "./upcoming-transfer-status-pill.tsx";

export interface DetailUpcomingTransferModalProps {
  transfer: UpcomingTransfer;
}

export const DetailUpcomingTransferModal = ({ transfer }: DetailUpcomingTransferModalProps) => {
  const { formatDate, formatMessage } = useIntl();
  const { paymentTransaction, frequency, nextExecutionDate, customerInstructionOrderingParties, endDate } = transfer;
  const frequencyLabel = frequency ? formatFrequency(frequency.interval, frequency.unit, formatMessage) : "-";

  return (
    <RoundedModalContainer closeButton={true} id={DETAIL_UPCOMING_TRANSFER_MODAL_ID}>
      <Container>
        <Header>
          <Title>
            {formatMessage("recurringTransfer.recurringTransferTitleDetails", {
              destinationAccount: paymentTransaction?.paymentTransactionParties?.creditor?.name,
            })}
          </Title>
          <Status>
            <UpcomingTransferStatusPill status={transfer.status} />
          </Status>
        </Header>

        {paymentTransaction?.paymentTransactionAmountInformation?.instructedAmount && (
          <AmountField label={formatMessage("recurringTransfer.scheduledAmount")} className="amount-field">
            <AmountText amount={paymentTransaction.paymentTransactionAmountInformation.instructedAmount} />
          </AmountField>
        )}

        <Section>
          <SectionTitle>{formatMessage("recurringTransfer.beneficiaryDetails")}</SectionTitle>
          {paymentTransaction?.paymentTransactionParties?.creditor?.name && (
            <Field label={formatMessage("recurringTransfer.beneficiaryAccount")}>
              {paymentTransaction.paymentTransactionParties.creditor.name}
            </Field>
          )}
          {paymentTransaction?.paymentTransactionParties?.creditor?.bankId && (
            <Field label={formatMessage("recurringTransfer.beneficiaryBIC")}>
              {paymentTransaction.paymentTransactionParties.creditor.bankId}
            </Field>
          )}
        </Section>

        <Section>
          <SectionTitle>{formatMessage("recurringTransfer.recurringTransferDetails")}</SectionTitle>
          {customerInstructionOrderingParties?.debtor?.accountId?.value && (
            <Field label={formatMessage("recurringTransfer.sourceAccount")}>
              {customerInstructionOrderingParties.debtor.accountId.value}
            </Field>
          )}
          {frequency?.interval && frequency?.unit && (
            <Field label={formatMessage("recurringTransfer.frequency")}>{frequencyLabel}</Field>
          )}
          {nextExecutionDate && (
            <Field label={formatMessage("recurringTransfer.nextExecutionDate")}>
              {formatDate(nextExecutionDate, { year: "numeric", month: "long", day: "numeric" })}
            </Field>
          )}
          {endDate && (
            <Field label={formatMessage("recurringTransfer.endDate")}>
              {formatDate(endDate, { year: "numeric", month: "long", day: "numeric" })}
            </Field>
          )}
          {paymentTransaction?.paymentTransactionDedicatedInformations?.remittanceInformation?.value && (
            <Field label={formatMessage("recurringTransfer.description")}>
              {paymentTransaction.paymentTransactionDedicatedInformations.remittanceInformation.value}
            </Field>
          )}
        </Section>
      </Container>
    </RoundedModalContainer>
  );
};
const Field = ({ label, children, className }: { label: string; children: React.ReactNode; className?: string }) => (
  <SectionContent className={className}>
    <Label>{label}</Label>
    <Result>{children}</Result>
  </SectionContent>
);

const Title = styled.h2`
  margin-bottom: 20px;
`;

const Header = styled.section`
  display: flex;
  align-items: center;
`;

const Status = styled.div`
  height: 25px;
  margin: 5px 0 0 10px;
`;

const Section = styled.div`
  margin-top: 30px;
`;

const SectionTitle = styled.h3`
  margin-bottom: 10px;
  font-size: 17px;
`;

const SectionContent = styled.div`
  margin: 0 0 5px;
  line-height: 22px;
`;

const Label = styled.span`
  display: block;
  font-size: 14px;
  color: #adadad;
  font-weight: 500;
`;

const Result = styled.span`
  font-size: 13px;
  display: block;
`;

const AmountField = styled(Field)`
  text-align: center;
  font-weight: 700;

  &.amount-field {
    ${Label} {
      font-size: 15px;
      margin-bottom: 5px;
    }

    ${Result} {
      font-size: 32px;
      line-height: 32px;
    }
  }
`;

const Container = styled.section`
  padding: 10px 20px;
`;
