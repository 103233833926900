import { logger } from "../../../core/logging/logger";
import type { ConnectedServiceDomainApiService } from "../../../core/net/connected-service-domain-api-service";
import type { Paginated } from "../../../utils/pagination";
import type { UpcomingTransfer, UpcomingTransferStatus } from "./upcoming-transfers";

export class UpcomingTransfersService {
  public constructor(private serviceDomainApiService: ConnectedServiceDomainApiService) {}

  public async getUpcomingTransfers(offset?: number): Promise<Paginated<UpcomingTransfer>> {
    try {
      const response = await this.serviceDomainApiService.instance.get("/standing-orders/credit-transfers", {
        params: {
          offset,
        },
      });
      return response.data;
    } catch (error) {
      logger.debug("UpcomingTransfersService", "Failed to get upcoming transfers", error);
      throw new Error(error);
    }
  }

  public async editUpcomingTransfer(id: string, modifiedTransfer: UpcomingTransfer): Promise<UpcomingTransfer> {
    try {
      const response = await this.serviceDomainApiService.instance.patch(
        `/standing-orders/credit-transfers/${id}`,
        modifiedTransfer,
      );
      return response.data;
    } catch (error) {
      logger.debug("UpcomingTransfersService", "Failed to edit upcoming transfer", error);
      throw new Error(error);
    }
  }

  public async updateStatusUpcomingTransfer(id: string, status: UpcomingTransferStatus): Promise<UpcomingTransfer> {
    try {
      const response = await this.serviceDomainApiService.instance.patch(`/standing-orders/credit-transfers/${id}`, {
        status,
      });
      return response.data;
    } catch (error) {
      logger.debug("UpcomingTransfersService", "Failed to update upcoming transfer status", error);
      throw new Error(error);
    }
  }

  public async deleteUpcomingTransfer(id: string): Promise<UpcomingTransfer> {
    try {
      const response = await this.serviceDomainApiService.instance.delete(`/standing-orders/credit-transfers/${id}`);
      return response.data;
    } catch (error) {
      logger.debug("UpcomingTransfersService", "Failed to delete upcoming transfer", error);
      throw new Error(error);
    }
  }
}
