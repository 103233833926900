"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var tag_map_1 = require("./tag-map");
var ExtendedSignal = /** @class */ (function () {
    function ExtendedSignal(_baseSignal) {
        this._baseSignal = _baseSignal;
        this._tagMap = new tag_map_1.TagMap();
    }
    ExtendedSignal.merge = function () {
        var signals = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            signals[_i] = arguments[_i];
        }
        var listeners = new Map();
        return new ExtendedSignal({
            add: function (listener) {
                var newListener = function (payload) { return listener(payload); };
                listeners.set(listener, newListener);
                signals.forEach(function (signal) { return signal.add(newListener); });
            },
            remove: function (listener) {
                var newListener = listeners.get(listener);
                listeners.delete(listener);
                signals.forEach(function (signal) { return signal.remove(newListener); });
            },
        });
    };
    ExtendedSignal.promisify = function (resolveSignal, rejectSignal) {
        return new Promise(function (resolve, reject) {
            function clearListeners() {
                resolveSignal.remove(completeResolution);
                if (rejectSignal) {
                    rejectSignal.remove(completeRejection);
                }
            }
            function completeRejection(payload) {
                clearListeners();
                reject(payload);
            }
            function completeResolution(payload) {
                clearListeners();
                resolve(payload);
            }
            resolveSignal.add(completeResolution);
            if (rejectSignal) {
                rejectSignal.add(completeRejection);
            }
        });
    };
    ExtendedSignal.prototype.add = function (listener) {
        var tags = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            tags[_i - 1] = arguments[_i];
        }
        var _a;
        (_a = this._tagMap).setListeners.apply(_a, [listener].concat(tags));
        this._baseSignal.add(listener);
    };
    ExtendedSignal.prototype.remove = function (listenerOrTag) {
        var _this = this;
        this._tagMap.getListeners(listenerOrTag)
            .forEach(function (taggedListener) {
            _this._baseSignal.remove(taggedListener);
            _this._tagMap.clearListener(taggedListener);
        });
        this._baseSignal.remove(listenerOrTag);
        this._tagMap.clearListener(listenerOrTag);
    };
    ExtendedSignal.prototype.addOnce = function (listener) {
        var _this = this;
        var tags = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            tags[_i - 1] = arguments[_i];
        }
        var _a;
        // to match the set behavior of add, only add the listener if the listener is not already
        // registered, don't add the same listener twice
        if (this._tagMap.getListeners(listener).size > 0) {
            return;
        }
        var oneTimeListener = function (payload) {
            _this._baseSignal.remove(oneTimeListener);
            listener(payload);
        };
        (_a = this._tagMap).setListeners.apply(_a, [oneTimeListener, listener].concat(tags));
        this._baseSignal.add(oneTimeListener);
    };
    ExtendedSignal.prototype.filter = function (filter) {
        return convertedListenerSignal(this._baseSignal, function (listener) { return function (payload) {
            if (filter(payload)) {
                listener(payload);
            }
        }; });
    };
    ExtendedSignal.prototype.map = function (transform) {
        return convertedListenerSignal(this._baseSignal, function (listener) { return function (payload) { return listener(transform(payload)); }; });
    };
    ExtendedSignal.prototype.merge = function () {
        var signals = [];
        for (var _i = 0; _i < arguments.length; _i++) {
            signals[_i] = arguments[_i];
        }
        return ExtendedSignal.merge.apply(ExtendedSignal, [this._baseSignal].concat(signals));
    };
    ExtendedSignal.prototype.promisify = function (rejectSignal) {
        return ExtendedSignal.promisify(this._baseSignal, rejectSignal);
    };
    ExtendedSignal.prototype.readOnly = function () {
        return convertedListenerSignal(this._baseSignal, function (listener) { return function (payload) { return listener(payload); }; });
    };
    ExtendedSignal.prototype.reduce = function (accumulator, initialValue) {
        return convertedListenerSignal(this._baseSignal, function (listener) { return (function () {
            var accum = initialValue;
            return function (payload) {
                accum = accumulator(accum, payload);
                listener(accum);
            };
        })(); });
    };
    ExtendedSignal.prototype.peek = function (peekaboo) {
        return convertedListenerSignal(this._baseSignal, function (listener) { return function (payload) {
            peekaboo(payload);
            listener(payload);
        }; });
    };
    ExtendedSignal.prototype.cache = function (cache) {
        this._baseSignal.add(function (payload) { return cache.add(payload); });
        return convertedListenerSignal(this._baseSignal, function (listener) { return function (payload) { return listener(payload); }; }, function (listener, listenerActive) {
            cache.forEach(function (payload) {
                if (listenerActive()) {
                    listener(payload);
                }
            });
        });
    };
    return ExtendedSignal;
}());
exports.ExtendedSignal = ExtendedSignal;
/**
 * Provides a new signal, with its own set of listeners, and the ability to transform listeners that
 * are added to the new signal.
 */
function convertedListenerSignal(baseSignal, convertListener, postAddHook) {
    var listenerMap = new Map();
    return new ExtendedSignal({
        add: function (listener) {
            var newListener = convertListener(listener);
            listenerMap.set(listener, newListener);
            baseSignal.add(newListener);
            if (postAddHook) {
                postAddHook(listener, function () { return listenerMap.has(listener); });
            }
        },
        remove: function (listener) {
            var newListener = listenerMap.get(listener);
            listenerMap.delete(listener);
            // TODO undefined ok in other case
            if (newListener !== undefined) {
                baseSignal.remove(newListener);
            }
        },
    });
}
