import { logger } from "../logging/logger";
import type { ApiService } from "../net/api-service";
import type { Translations } from "./translations";

export class I18NService {
  public constructor(private apiService: ApiService) {}

  public async fetchTranslations(locale: string) {
    try {
      const response = await this.apiService.instance.get<Translations>("/translations", {
        params: { applicationId: "mwallet", langs: [locale] },
      });
      return response.data;
    } catch (e) {
      logger.warn("I18NService", "Failed to fetch translations", e);
      throw e;
    }
  }
}
