import moment from "moment";

import type { ConnectedServiceDomainApiService } from "../../../shared/core/net/connected-service-domain-api-service";
import { makeUtcDate } from "../../../shared/utils/date-to-local";
import { downloadFile } from "../../utils/download";

export class WebGenerateAccountStatementService {
  constructor(private connectedServiceDomainApiService: ConnectedServiceDomainApiService) {}
  public async downloadGenerateAccountStatement(accountId: string, startDate: Date, endDate: Date) {
    try {
      const result = await this.connectedServiceDomainApiService.instance.get(
        `/accounts/${accountId}/statements/generate`,
        {
          params: {
            startDate: makeUtcDate(new Date(startDate)),
            endDate: makeUtcDate(new Date(endDate)),
          },
          responseType: "arraybuffer",
        },
      );
      const formattedStartDate = moment(startDate).format("YYYYMMDD");
      const formattedEndDate = moment(endDate).format("YYYYMMDD");

      const filename = `statement_${accountId}_${formattedStartDate}_${formattedEndDate}.pdf`;
      await downloadFile(filename, result.data, "application/pdf");
      return filename;
    } catch (error) {
      console.error("Failed to generate statement:", {
        accountId,
        startDate,
        endDate,
        error: error.response?.data || error.message || error,
      });
      throw new Error("Failed to generate statement.");
    }
  }
}
