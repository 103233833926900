import React from "react";
import styled from "styled-components";

import { useIntl } from "../../../../../shared/core/i18n/use-intl";
import type { UpcomingTransfer } from "../../../../../shared/domains/transactions/upcoming-transfers/upcoming-transfers";
import { DELETE_UPCOMING_TRANSFER_MODAL_ID } from "../../../../core/modal/modal-id";
import { PrimaryButton } from "../../../common/buttons/primary-button.ts";
import { Modal } from "../../../common/modal/modal";
import { RoundedModalContainer } from "../../../common/modal/rounded-modal-container";
import { fonts, theme } from "../../../styles/theme";

export interface DeleteUpcomingTransferModalProps {
  transfer: UpcomingTransfer;
  onConfirm: () => void;
}

export const DeleteUpcomingTransferModal = (props: DeleteUpcomingTransferModalProps) => {
  const { transfer, onConfirm } = props;

  const { formatMessage } = useIntl();

  const closeModal = () => {
    Modal.dismiss(DELETE_UPCOMING_TRANSFER_MODAL_ID);
  };

  const handleConfirm = () => {
    onConfirm();
    closeModal();
  };

  return (
    <ModalContainer closeButton id={DELETE_UPCOMING_TRANSFER_MODAL_ID}>
      <Container>
        <Title>{formatMessage("upcomingTransfers.deletePrompt.title")}</Title>
        <Message>{formatMessage("upcomingTransfers.deletePrompt.message", { id: transfer.id })}</Message>
        <ConfirmButton size="M" onClick={handleConfirm}>
          {formatMessage("upcomingTransfers.confirmButton")}
        </ConfirmButton>
      </Container>
    </ModalContainer>
  );
};

const ModalContainer = styled(RoundedModalContainer)``;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 56px;
`;

const Title = styled.span`
  ${fonts.bold}
  font-size: 1.5rem;
  color: ${theme.mainColor};
`;

const Message = styled.div`
  ${theme.text};
  color: black;
  font-size: 0.9375rem;
  line-height: 1.25rem;
  text-align: center;
  margin: 48px 10px 15px;
`;

const ConfirmButton = styled(PrimaryButton)`
  margin-top: 30px;
  margin-bottom: 30px;
`;
