import type { CountryCode } from "libphonenumber-js";

import type { Country } from "../assets/countries";
import { countriesArray } from "../assets/countries";
import type { AccountOrRecipient } from "../domains/transactions/customer-instruction.ts";
import { getCountryCodeFromIban } from "./iban.ts";

export const getCountryByNumeric = (numeric) => {
  const res = countriesArray.filter((c) => c["iso-numeric"] === numeric);
  return res.length > 0 ? (res[0]["alpha-2"] as CountryCode) : null;
};

export const getCountryCodeByAlpha2 = (alpha2: string) =>
  countriesArray.find((country) => country["alpha-2"] === alpha2) || null;

export const getCountryCodeFromBic = (bic: string): string | null => {
  if (bic.length < 8) return null;
  return bic.substring(4, 6).toUpperCase();
};

export const setCountryFromAccount = (
  account: AccountOrRecipient | null,
  handleSetSelectedCountry: (country: Country) => void,
) => {
  if (account?.iban) {
    const countryCodeFromIban = getCountryCodeFromIban(account.iban);
    if (countryCodeFromIban) {
      const country = getCountryCodeByAlpha2(countryCodeFromIban);
      if (country) {
        handleSetSelectedCountry(country);
      }
    }
  } else if (account?.bic) {
    const countryCodeFromBic = getCountryCodeFromBic(account.bic);
    if (countryCodeFromBic) {
      const country = getCountryCodeByAlpha2(countryCodeFromBic);
      if (country) {
        handleSetSelectedCountry(country);
      }
    }
  }
};
