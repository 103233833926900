"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var ArrayCache = /** @class */ (function () {
    function ArrayCache() {
        this._payloads = [];
    }
    ArrayCache.prototype.forEach = function (callback) {
        this._payloads.forEach(function (value) { return callback(value); });
    };
    return ArrayCache;
}());
exports.ArrayCache = ArrayCache;
var ValueCache = /** @class */ (function (_super) {
    __extends(ValueCache, _super);
    function ValueCache() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ValueCache.prototype.add = function (value) {
        this._payloads = [value];
    };
    return ValueCache;
}(ArrayCache));
exports.ValueCache = ValueCache;
var CollectionCache = /** @class */ (function (_super) {
    __extends(CollectionCache, _super);
    function CollectionCache() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CollectionCache.prototype.add = function (value) {
        this._payloads.push(value);
    };
    return CollectionCache;
}(ArrayCache));
exports.CollectionCache = CollectionCache;
