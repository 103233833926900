import type { HTMLAttributes } from "react";
import React from "react";
import styled from "styled-components";

import { Circle } from "./shape/circle";
import { CrossIcon } from "./svg/cross-icon";

interface ErrorCircleProps extends HTMLAttributes<HTMLDivElement> {
  theme?: "plain" | "shallow";
}
export const ErrorCircle = (props: ErrorCircleProps) => (
  <RedCircle {...props} size={116}>
    <CrossIcon fill={props.theme === "shallow" ? "#ff4242" : "#ffffff"} />
  </RedCircle>
);

const RedCircle = styled(Circle)<{ theme?: "plain" | "shallow" }>`
  align-self: center;
  background-color: ${(props) => (props.theme === "shallow" ? "#ffffff" : "#ff4242")};
  box-shadow: 0 6px 44px 0 rgba(255, 66, 66, 0.5);
`;
