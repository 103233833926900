import React, { useState } from "react";
import styled from "styled-components";

import { useIntl } from "../../../../shared/core/i18n/use-intl";
import { savingsManager, savingsService } from "../../../../shared/core/service/services";
import type { SavingContract } from "../../../../shared/domains/savings/savings";
import { useAsyncEffect } from "../../../../shared/utils/utils";
import { ErrorMessage } from "../../common/error-message";
import { MainColorSpinner } from "../../common/spinner";
import { theme } from "../../styles/theme";
import { CreateSavingModal } from "./components/create-savings-modal";

export function CreateSavingScreen() {
  const { formatMessage } = useIntl();

  const [fetchingData, setFetchingData] = useState(false);
  const [apiSuccess, setApiSuccess] = useState(false);
  const [apiError, setApiError] = useState("");

  const handleSendRequest = async (contractReq: SavingContract) => {
    setFetchingData(true);
    setApiSuccess(false);
    setApiError("");

    try {
      await savingsManager.createSavingsContract(contractReq);
      setApiSuccess(true);
    } catch (e) {
      setApiError(e.response?.data?.message);
    } finally {
      setFetchingData(false);
    }
  };

  useAsyncEffect(async () => {
    setFetchingData(true);

    /** Two APIs will be called sequentially to fetch list of all savings products
     *  Will be optimized in future, as of now proceeding for demo
     */
    const res = await savingsService.getAllSavingsProduct("0");
    await savingsManager.getAllSavingsProduct(`${res.count}`);
    setFetchingData(false);
  }, []);

  return (
    <Container>
      <Title>{formatMessage("createSavingsAccount.title")}</Title>
      {apiError && <ApiErrorMessage>{apiError}</ApiErrorMessage>}
      {apiSuccess && (
        <ApiSuccessMessage>{formatMessage("createSavingsAccount.creationSuccessMessage")}</ApiSuccessMessage>
      )}
      {fetchingData ? (
        <LoadingContainer>
          <MainColorSpinner size="30px" />
        </LoadingContainer>
      ) : (
        <CreateSavingModal handleSendRequest={handleSendRequest} />
      )}
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
`;

const Title = styled.span`
  font-size: 1.5625rem;
  margin-bottom: 12px;
  ${theme.boldText};
  color: ${theme.mainColor};
  white-space: pre;
`;

const LoadingContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 30vh;
`;

const ApiErrorMessage = styled(ErrorMessage)`
  margin-top: 10px;
  font-size: 1rem;
`;

const ApiSuccessMessage = styled(ApiErrorMessage)`
  color: #4bb543;
`;
