import type { Account } from "../domains/account/account";
import type { CurrencyCouple } from "../domains/transactions/customer-instruction";

/**
 * Returns currency codes based on payment network configuration.
 * @param sourceAccount Source account (from)
 * @param currencyCouples Array of currency pairs
 * @returns Array of currency code strings
 */
export const fetchTargetCurrencies = (sourceAccount: Account, currencyCouples: CurrencyCouple[]) => {
  const targetCurrencies = currencyCouples?.reduce(
    (acc, cc) => {
      if (cc.origin === sourceAccount.currency) {
        acc.push(cc.target);
      }
      return acc;
    },
    [sourceAccount.currency],
  );

  return targetCurrencies;
};
