import type { SVGProps } from "react";
import React from "react";

export function LoanIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <path d="M20.5 7.898c-.004.004-.01.008-.011.012-.138.43-.448.603-.897.602-5.061-.004-10.124-.003-15.185-.002-.25 0-.483-.036-.671-.223a.766.766 0 0 1 .154-1.219c.192-.107.398-.19.6-.28C6.848 5.726 9.208 4.667 11.565 3.6a.987.987 0 0 1 .873.003c2.508 1.136 5.02 2.264 7.53 3.39.254.114.434.279.51.55.002.009.014.015.021.023v.332zM4.102 20.48c-.022-.014-.042-.03-.066-.04-.375-.143-.583-.48-.527-.858.053-.359.36-.628.748-.657.044-.003.089-.002.133-.002l15.212-.001c.299 0 .56.075.74.326.066.092.106.201.158.302v.299c-.014.028-.03.056-.04.086a.78.78 0 0 1-.519.51c-.025.008-.048.023-.072.034H4.102zM11.988 18.476h-6.57c-.094 0-.189-.007-.282-.02a.761.761 0 0 1-.667-.794.77.77 0 0 1 .733-.738c.229-.003.229-.01.229-.247v-6.47c0-.045-.004-.09 0-.134.006-.074-.03-.093-.098-.094-.06 0-.12-.01-.18-.023a.365.365 0 0 1-.292-.353.366.366 0 0 1 .26-.377c.058-.018.12-.023.179-.03.033-.004.066 0 .1 0h13.207c.093 0 .19.008.278.033a.369.369 0 0 1 .253.385.375.375 0 0 1-.289.342 1.036 1.036 0 0 1-.113.022c-.165.021-.166.02-.166.177v6.587c0 .028.002.056 0 .083-.005.07.025.096.096.098.104.004.212.007.31.036a.767.767 0 0 1-.12 1.498 2.198 2.198 0 0 1-.281.018h-6.587zM10.84 13.44v3.4c-.002.053.018.084.076.08h2.073c.165 0 .166 0 .166-.16v-6.616c0-.163 0-.164-.162-.164h-1.99c-.18 0-.162-.006-.163.16V13.441zm6.175.019v-3.318c0-.167.017-.161-.162-.161h-1.99c-.156 0-.158.001-.158.152v6.635c0 .152.002.153.157.153h1.974c.194 0 .18.02.18-.177v-3.285zm-10.029-.001v3.25c0 .045.002.09 0 .134-.001.054.023.082.08.079.027-.002.054 0 .082 0h1.974c.163 0 .163-.001.164-.162v-6.585c0-.038-.002-.077 0-.116.002-.056-.025-.08-.08-.078H7.149c-.162 0-.162 0-.163.16v3.317z" />
    </svg>
  );
}
