import { Observable } from "../../utils/observable";
import type { FeaturesManager } from "../features/features-manager";
import type { ExchangeRate } from "./currency";
import type { CurrencyService } from "./currency-service";

export class CurrencyManager {
  constructor(
    private currencyService: CurrencyService,
    private featuresManager: FeaturesManager,
  ) {}
  exchangeRates = new Observable<ExchangeRate[] | null>(null);

  async getExchangeRates() {
    if (!this.featuresManager.features.get().sdaExchangeRateView) {
      return;
    }
    try {
      const exchangeRatesResponse = await this.currencyService.getExchangeRates();
      this.exchangeRates.set(exchangeRatesResponse);
    } catch (e) {
      this.exchangeRates.set(null);
      throw e;
    }
  }
}
