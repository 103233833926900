import { logger } from "../../core/logging/logger";
import type { ConnectedApiService } from "../../core/net/connected-api-service";
import type { Paginated, PaginationOptions } from "../../utils/pagination";
import type { Product, ProductType } from "./product";

export class ProductService {
  public constructor(private apiService: ConnectedApiService) {}

  public async fetchProducts(
    productTypeFilter?: ProductType,
    pagination?: PaginationOptions,
  ): Promise<Paginated<Product>> {
    try {
      const response = await this.apiService.instance.get<Paginated<Product>>("/products", {
        params: { type: productTypeFilter, ...pagination },
      });
      return response.data;
    } catch (e) {
      logger.debug("ProductService", "Fetch products failed", e);
      throw e;
    }
  }
}
