import React from "react";
import styled from "styled-components";

import type { FormNumberInput } from "../../../../shared/core/data-forms/form-input-types";
import { useIntl } from "../../../../shared/core/i18n/use-intl";
import { TextInput } from "../forms/text-input";
import { DisableValueText } from "./disable-value-text";

interface NumberFieldProps {
  input: FormNumberInput;
  onChange?: (value: number) => void;
  disabled?: boolean;
}

export const NumberField: React.FC<NumberFieldProps> = ({ input, disabled, onChange }) => {
  const { formatMessage } = useIntl();

  return input.disabled || disabled ? (
    <DisableValueText>{input.value}</DisableValueText>
  ) : (
    <StyledTextInput
      required={input.required}
      value={input.value?.toString() ?? ""}
      type="text"
      onChange={(e) => {
        if (onChange) {
          const parsedFloat = parseFloat(e.target.value);
          onChange(isNaN(parsedFloat) ? 0 : parsedFloat);
        }
      }}
      placeholder={formatMessage("billFormScreen.textInputPlaceholder")}
    />
  );
};

const StyledTextInput = styled(TextInput)`
  input::placeholder {
    color: #b1b1b1;
  }
`;
