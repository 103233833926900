import type { SVGProps } from "react";
import React from "react";

export function CardIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.73 4.511c-1.21 0-2.192.982-2.192 2.192v1.462h16.077V6.703a2.192 2.192 0 0 0-2.192-2.192H4.731zm13.885 5.115H2.538v5.846a2.192 2.192 0 0 0 2.193 2.193h11.692a2.192 2.192 0 0 0 2.192-2.193V9.626zm-12.524 5.94h2.51a1.11 1.11 0 0 0 1.107-1.105 1.108 1.108 0 0 0-1.106-1.106h-2.51a1.108 1.108 0 0 0-1.106 1.106 1.11 1.11 0 0 0 1.105 1.105z"
      />
    </svg>
  );
}
