import "reflect-metadata";

import React from "react";
import ReactDOM from "react-dom";

import { setConfiguration } from "../shared/core/config/config";

const root = document.getElementById("main");
fetch("/static/config.json")
  .then((response) =>
    response.json().then((externalConfiguration) => {
      setConfiguration(externalConfiguration);
      document.title = externalConfiguration.WEB_CLIENT_TITLE ?? "M-Wallet";
      ReactDOM.render(React.createElement(require("./app").App), root);
    }),
  )
  .catch((e) => {
    console.error("Could not load config.json. App can't start", e);
  });
