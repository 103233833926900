import React from "react";
import styled from "styled-components";

import { ContactType } from "../../../../shared/core/contact/contact";
import { useIntl } from "../../../../shared/core/i18n/use-intl";
import { contactService } from "../../../../shared/core/service/services";
import { CONTACT_SUPPORT_MODAL_ID } from "../../../core/modal/modal-id";
import { MarkdownText } from "../../common/forms/markdown-text";
import { RoundedModalContainer } from "../../common/modal/rounded-modal-container";
import { theme } from "../../styles/theme";

interface ContactSupportModalProps {
  title: string;
  subtitle: string;
}
export const ContactSupportModal = (props: ContactSupportModalProps) => {
  const { title, subtitle } = props;
  const { formatMessage } = useIntl();
  const contacts = contactService.getContacts();
  return (
    <RoundedModalContainer closeButton id={CONTACT_SUPPORT_MODAL_ID}>
      <Header>
        <Title>{title}</Title>
        <DetailsTitle>{subtitle}</DetailsTitle>
      </Header>
      <ContentContainer>
        <ContactText>
          {formatMessage("contactSupport.contactByPhone", { phoneNumber: contacts.get(ContactType.Phone) })}
        </ContactText>
        <ContactText>
          {formatMessage("contactSupport.contactByEmail", { email: contacts.get(ContactType.Email) })}
        </ContactText>
      </ContentContainer>
    </RoundedModalContainer>
  );
};

const Header = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-right: 10px;
`;

const Title = styled(MarkdownText)`
  ${theme.boldText};
  font-size: 1.125rem;
  margin-top: 30px;
`;

const DetailsTitle = styled.div`
  font-size: 0.9375rem;
  margin: 20px 0px;
  padding-right: 60px;
  ${theme.text}
  color: #b1b1b1;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 10px;
`;

const ContactText = styled(MarkdownText)`
  font-size: 0.9375rem;
  ${theme.text}
  &:not(:last-of-type) {
    margin-bottom: 10px;
  }
`;
