import type { SyntheticEvent } from "react";
import React, { useMemo, useState } from "react";
import styled from "styled-components";

interface ImageWithFallbackProps
  extends React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
  renderPlaceholder?: (className?: string) => React.ReactElement;
  renderErrorImage?: (className?: string) => React.ReactElement;
  onError?: (e: SyntheticEvent<HTMLImageElement, Event>) => void;
  onLoad?: (e: SyntheticEvent<HTMLImageElement, Event>) => void;
}

export const ImageWithFallback: React.FC<ImageWithFallbackProps> = (props: ImageWithFallbackProps) => {
  const { renderPlaceholder, renderErrorImage, onError, onLoad, style, ...otherProps } = props;
  const [isLoading, setLoading] = useState(true);
  const [isErrored, setIsErrored] = useState(false);

  const ImageMemoized = useMemo(
    () => (
      <img
        {...otherProps}
        style={{ ...{ position: "absolute", opacity: isLoading || isErrored ? 0 : 1, left: 0 } }}
        onError={(e) => {
          setLoading(false);
          setIsErrored(true);
          onError && onError(e);
        }}
        onLoad={(e) => {
          setLoading(false);
          setIsErrored(false);
          onLoad && onLoad(e);
        }}
      />
    ),
    [onError, onLoad, otherProps, isLoading, isErrored],
  );

  return (
    <Container
      style={style}
      height={typeof props.height === "number" ? `${props.height}px` : props.height}
      width={typeof props.width === "number" ? `${props.width}px` : props.width}
    >
      {ImageMemoized}
      {isLoading && renderPlaceholder?.(props.className)}
      {isErrored && renderErrorImage?.(props.className)}
    </Container>
  );
};

const Container = styled.div<{ height?: string; width?: string }>`
  position: relative;
  height: ${(props) => props.height};
  width: ${(props) => props.width};
`;
