import { useMachine } from "@xstate/react";
import type { PhoneNumber } from "libphonenumber-js";
import { useCallback, useMemo, useState } from "react";
import { assign, Machine, send } from "xstate";

import type { FormFieldValueType } from "../../mobile/core/data-forms/form-field";
import type { FileProps, FormInputWithHierarchy } from "../../shared/core/data-forms/form-input-types";
import type { ExtraStepFormInput } from "../../shared/core/data-forms/form-parser";
import {
  authExecutor,
  configurationManager,
  pincodeKeyboardService,
  registerManager,
} from "../../shared/core/service/services";
import type { RegisterPreAuthDto, RegisterPreAuthErrorDto } from "../../shared/domains/enrollment/register-request";
import { RegisterTypeConfirmation } from "../../shared/domains/enrollment/register-request";
import type { Keyboard } from "../../shared/domains/pincode/keyboard";
import type { PincodeSubmission } from "../../shared/domains/pincode/pincode";
import { isDefined } from "../../shared/utils/assert";
import type { UploadedImage } from "../../shared/utils/images-converter";
import { useObservable } from "../../shared/utils/observable";
import { FONT_SIZE, TOUCH_SIZE } from "./keyboard-machine-type";

export const useRegisterMachine = (
  initialState:
    | RegisterState.WaitingForPhoneNumber
    | RegisterState.ConfirmRegisterAfterUbble = RegisterState.WaitingForPhoneNumber,
) => {
  const registerMachine = useMemo(() => registerMachineBuilder(initialState), [initialState]);
  const [machine, sendEvent] = useMachine(registerMachine);
  const { value: state, context } = machine as { value: RegisterState; context: RegisterMachineContext };
  const extraFormInput = useObservable(registerManager.extraFormInput);
  const formStepsAsArray = useObservable(registerManager.formStepsAsArray);
  const confirmError = useObservable(registerManager.confirmError);
  const [stepIndex, setStepIndex] = useState(-1);
  const configuration = useObservable(configurationManager.configuration);
  const stepCount =
    formStepsAsArray.length +
    (extraFormInput.pincodeFormInput !== null ? 1 : 0) +
    (configuration.termsAndConditionsUrl !== null ? 1 : 0);
  const step = formStepsAsArray.length > stepIndex ? formStepsAsArray[stepIndex] : null;

  const complete = () => {
    sendEvent("COMPLETE", { extraFormInput });
  };

  const moveForward = useCallback(() => setStepIndex((index) => index + 1), []);

  const moveBackward = () => {
    if (!(state === RegisterState.Completing && stepIndex !== 0)) {
      sendEvent({ type: "GO_BACK" });
    }

    setStepIndex((index) => index - 1);
  };
  const start = (newPhoneNumber: PhoneNumber) => {
    sendEvent("CHECK_PHONE_NUMBER", { phoneNumber: newPhoneNumber });
    setStepIndex(0);
  };
  const completeTermOfService = () => {
    sendEvent("COMPLETE_TERM_OF_SERVICE");
  };
  const submitPincode = (pincode: PincodeSubmission) => {
    sendEvent({ type: "SUBMIT_PINCODE", pincode });
  };
  const confirmPincode = (pincode: PincodeSubmission) => {
    sendEvent({ type: "CONFIRM_PINCODE", pincode });
  };
  const confirmOtp = (otp: string) => {
    sendEvent("OTP_CONFIRM", { otp });
  };

  if (state === RegisterState.Completing && !step) {
    complete();
  }

  return {
    state,
    context,
    eulaUri: context.termOfServiceUri,
    start,
    keyboard: context.keyboard,
    moveForward,
    moveBackward,
    step,
    stepIndex,
    stepCount,
    fillResponse: (input: FormInputWithHierarchy, value: FormFieldValueType, fileProps?: FileProps) =>
      registerManager.fillResponse(input, value, fileProps),
    fillMultiImages: (input: FormInputWithHierarchy, values: UploadedImage[]) =>
      registerManager.fillMultiImagesResponse(input, values),
    error: confirmError || context.error,
    complete,
    submitPincode,
    confirmPincode,
    completeTermOfService,
    confirmOtp,
  };
};

interface RegisterMachineContext {
  extraFormInput?: ExtraStepFormInput;
  phoneNumber?: PhoneNumber;
  error?: string;
  keyboard?: Keyboard;
  code?: PincodeSubmission;
  pincodeRequired?: boolean;
  termOfServiceUri?: string | null;
  otp?: string;
}

export enum RegisterState {
  WaitingForPhoneNumber = "WAITING_FOR_PHONE_NUMBER",
  StartRegistration = "START_REGISTRATION",
  ValidateForm = "VALIDATE_FORM",
  Completing = "COMPLETING",
  PromptForPincodeChoice = "PROMPT_PINCODE_CHOICE_DISCLAIMER",
  FetchingPincodeKeyboard = "FETCHING_PINCODE_KEYBOARD",
  PromptingPincodeKeyboard = "PROMPTING_PINCODE_KEYBOARD",
  PromptingConfirmPincodeKeyboard = "PROMPTING_CONFIRM_PINECODE_KEYBOARD",
  PincodePromptNotMatch = "PINCODE_PROMPT_NOT_MATCH",
  ReadingTermOfService = "READING_TERM_OF_SERVICE",
  ConfirmRegister = "CONFIRM_REGISTER",
  ConfirmRegisterAfterUbble = "CONFIRM_REGISTER_AFTER_UBBLE",
  OTPSubmission = "OTP_SUBMISSION",
  OTPConfirming = "OTP_CONFIRMING",
  RegistrationCompleted = "REGISTRATION_COMPLETED",
  UbbleConfirmationRedirection = "UBBLE_CONFIRMATION_REDIRECTION",
}

type RegisterEvent =
  | { type: "CHECK_PHONE_NUMBER"; phoneNumber: PhoneNumber }
  | { type: "START_COMPLETING" }
  | { type: "PINCODE_REQUIRED" }
  | { type: "PINCODE_NOT_REQUIRED" }
  | { type: "TERM_OF_SERVICE_REQUIRED" }
  | { type: "TERM_OF_SERVICE_NOT_REQUIRED" }
  | { type: "SUBMIT_PINCODE"; pincode: PincodeSubmission }
  | { type: "CONFIRM_PINCODE"; pincode: PincodeSubmission }
  | { type: "PINCODE_DONT_MATCH" }
  | { type: "COMPLETE"; extraFormInput: ExtraStepFormInput }
  | { type: "COMPLETE_TERM_OF_SERVICE" }
  | { type: "OTP_CONFIRM"; otp: string }
  | { type: "OTP_ERROR" }
  | { type: "OTP_CONFIRMED" }
  | { type: "GO_BACK" }
  | { type: "FORM_VALID" }
  | { type: "FORM_VALID_UBBLE_CONFIRMATION" }
  | { type: "FORM_NOT_VALID" };

const registerMachineBuilder = (initialState: RegisterState) =>
  Machine<RegisterMachineContext, RegisterEvent>({
    id: "register",
    initial: initialState,
    states: {
      [RegisterState.WaitingForPhoneNumber]: {
        on: {
          CHECK_PHONE_NUMBER: {
            target: RegisterState.StartRegistration,
            actions: assign({
              phoneNumber: (_, event) => event.phoneNumber,
            }),
          },
        },
      },
      [RegisterState.StartRegistration]: {
        invoke: {
          id: "startRegistration",
          src: (ctx) => registerManager.start(ctx.phoneNumber!),
          onDone: {
            actions: [
              assign({
                error: (_) => undefined,
              }),
              send({ type: "START_COMPLETING" }),
            ],
          },
          onError: {
            target: RegisterState.WaitingForPhoneNumber,
            actions: assign({
              error: (_, event) => event?.data,
            }),
          },
        },
        on: {
          START_COMPLETING: RegisterState.Completing,
        },
      },
      [RegisterState.Completing]: {
        on: {
          COMPLETE: {
            actions: [
              assign({
                extraFormInput: (_, event) => event.extraFormInput,
                pincodeRequired: (_, event) => !!event?.extraFormInput?.pincodeFormInput,
                termOfServiceUri: (_) => configurationManager.configuration.get().termsAndConditionsUrl ?? null,
              }),
              send((ctx) =>
                ctx?.pincodeRequired
                  ? { type: "PINCODE_REQUIRED" }
                  : ctx?.termOfServiceUri
                    ? { type: "TERM_OF_SERVICE_REQUIRED" }
                    : { type: "TERM_OF_SERVICE_NOT_REQUIRED" },
              ),
            ],
          },
          GO_BACK: RegisterState.WaitingForPhoneNumber,
          PINCODE_REQUIRED: RegisterState.PromptForPincodeChoice,
          TERM_OF_SERVICE_REQUIRED: RegisterState.ReadingTermOfService,
          TERM_OF_SERVICE_NOT_REQUIRED: RegisterState.ReadingTermOfService,
        },
      },
      [RegisterState.PromptForPincodeChoice]: {
        on: {
          COMPLETE: RegisterState.FetchingPincodeKeyboard,
          GO_BACK: RegisterState.Completing,
        },
      },
      [RegisterState.FetchingPincodeKeyboard]: {
        invoke: {
          id: "fetchKeyboard",
          src: (ctx) =>
            pincodeKeyboardService.fetchKeyboard(TOUCH_SIZE, ctx.phoneNumber?.number?.substring(1), FONT_SIZE),
          onDone: {
            target: RegisterState.PromptingPincodeKeyboard,
            actions: assign({
              keyboard: (_, event) => event.data,
            }),
          },
        },
      },
      [RegisterState.PromptingPincodeKeyboard]: {
        on: {
          SUBMIT_PINCODE: {
            target: RegisterState.PromptingConfirmPincodeKeyboard,
            actions: assign({ code: (_, event) => event.pincode }),
          },
          GO_BACK: RegisterState.Completing,
        },
      },
      [RegisterState.PromptingConfirmPincodeKeyboard]: {
        on: {
          CONFIRM_PINCODE: {
            actions: send((ctx, event) => {
              if (event.pincode.value.join() === ctx.code?.value.join()) {
                registerManager.fillPincode(event.pincode);
                return ctx?.termOfServiceUri
                  ? { type: "TERM_OF_SERVICE_REQUIRED" }
                  : { type: "TERM_OF_SERVICE_NOT_REQUIRED" };
              }
              return { type: "PINCODE_DONT_MATCH" };
            }),
          },
          PINCODE_DONT_MATCH: RegisterState.PincodePromptNotMatch,
          TERM_OF_SERVICE_REQUIRED: RegisterState.ReadingTermOfService,
          TERM_OF_SERVICE_NOT_REQUIRED: RegisterState.ValidateForm,
          GO_BACK: RegisterState.FetchingPincodeKeyboard,
        },
      },
      [RegisterState.PincodePromptNotMatch]: {
        on: {
          SUBMIT_PINCODE: {
            target: RegisterState.PromptingConfirmPincodeKeyboard,
            actions: assign({ code: (_, event) => event.pincode }),
          },
          GO_BACK: RegisterState.FetchingPincodeKeyboard,
        },
      },
      [RegisterState.ReadingTermOfService]: {
        on: {
          COMPLETE_TERM_OF_SERVICE: RegisterState.ValidateForm,
          GO_BACK: {
            actions: [
              assign({ error: (_) => undefined }),
              send((ctx) => (ctx?.pincodeRequired ? { type: "PINCODE_REQUIRED" } : { type: "PINCODE_NOT_REQUIRED" })),
            ],
          },
          PINCODE_REQUIRED: RegisterState.FetchingPincodeKeyboard,
          PINCODE_NOT_REQUIRED: RegisterState.Completing,
        },
      },
      [RegisterState.ValidateForm]: {
        invoke: {
          id: "validateForm",
          src: () => registerManager.validateForm(),
          onDone: {
            actions: [
              assign<
                RegisterMachineContext,
                {
                  type: string;
                  data: { data: RegisterPreAuthDto; done: true } | { data: RegisterPreAuthErrorDto; done: false };
                }
              >({
                error: (_, event) => (event.data.done ? undefined : event.data.data.error.message),
              }),
              send<
                RegisterMachineContext,
                {
                  type: string;
                  data: { data: RegisterPreAuthDto; done: true } | { data: RegisterPreAuthErrorDto; done: false };
                }
              >((_, event) => {
                if (event.data.done) {
                  if (event.data.data.metadata.confirmationMode?.type === RegisterTypeConfirmation.Url) {
                    return { type: "FORM_VALID_UBBLE_CONFIRMATION" };
                  }
                  return { type: "FORM_VALID" };
                }
                return { type: "FORM_NOT_VALID" };
              }),
            ],
          },
          onError: {
            target: RegisterState.ReadingTermOfService,
            actions: assign({ error: (_, event) => event.data }),
          },
        },
        on: {
          FORM_VALID: RegisterState.ConfirmRegister,
          FORM_NOT_VALID: RegisterState.ReadingTermOfService,
          FORM_VALID_UBBLE_CONFIRMATION: RegisterState.UbbleConfirmationRedirection,
        },
      },
      [RegisterState.UbbleConfirmationRedirection]: {
        invoke: {
          id: "ubbleConfirmation",
          src: async () => window.location.assign(await registerManager.getUbbleUrl()),
        },
      },
      [RegisterState.ConfirmRegisterAfterUbble]: {
        invoke: {
          id: "confirmRegisterAfterUbble",
          src: () => registerManager.confirmRegister(),
          onDone: {
            target: RegisterState.OTPSubmission,
            actions: assign({
              error: (_) => undefined,
            }),
          },
          onError: {
            actions: assign({
              error: (_, event) => event?.data,
            }),
          },
        },
      },
      [RegisterState.ConfirmRegister]: {
        invoke: {
          id: "confirmRegister",
          src: () => registerManager.confirmRegister(),
          onDone: {
            target: RegisterState.OTPSubmission,
            actions: assign({
              error: (_) => undefined,
            }),
          },
          onError: {
            target: RegisterState.ReadingTermOfService,
            actions: assign({
              error: (_, event) => event?.data,
            }),
          },
        },
      },
      [RegisterState.OTPSubmission]: {
        on: {
          OTP_CONFIRM: {
            target: RegisterState.OTPConfirming,
            actions: assign({
              otp: (_, event) => event.otp,
            }),
          },
        },
      },
      [RegisterState.OTPConfirming]: {
        invoke: {
          id: "validateRegisterOtp",
          src: (ctx) =>
            registerManager.loadPendingEnrollment().then((pendingEnrollment) => {
              if (isDefined(pendingEnrollment) && isDefined(pendingEnrollment.phoneNumber)) {
                return authExecutor.validateRegisterOtp(pendingEnrollment.phoneNumber, {
                  enrollment_id: pendingEnrollment.preAuth!.metadata.enrollmentId,
                  enrollment_otp: ctx.otp!,
                });
              }
            }),
          onDone: {
            actions: send({ type: "OTP_CONFIRMED" }),
          },
          onError: {
            actions: [
              assign({
                error: (_, event) => event.data,
              }),
              send({ type: "OTP_ERROR" }),
            ],
          },
        },
        on: {
          OTP_ERROR: RegisterState.OTPSubmission,
          OTP_CONFIRMED: RegisterState.RegistrationCompleted,
        },
      },
      [RegisterState.RegistrationCompleted]: {
        type: "final",
      },
    },
  });
