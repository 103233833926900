import React from "react";
import type { RouteProps } from "react-router-dom";
import { Redirect, Route } from "react-router-dom";

import { authenticationManager } from "../../../../shared/core/service/services";
import { useObservable } from "../../../../shared/utils/observable";
import { Path } from "../path";

export const HomeRoute = (props: RouteProps) => {
  const isConnected = useObservable(authenticationManager.isConnected);

  return isConnected ? <Redirect to={{ pathname: Path.AccountTransactions }} /> : <Route {...props} />;
};
