import React from "react";
import styled from "styled-components";

import { useIntl } from "../../../../shared/core/i18n/use-intl";
import {
  accountManager,
  authenticationManager,
  clientManager,
  configurationManager,
} from "../../../../shared/core/service/services";
import { useObservable } from "../../../../shared/utils/observable";
import { formatPhoneNumber } from "../../../../shared/utils/phone-number";
import { useAsyncEffect } from "../../../../shared/utils/utils";
import { Path } from "../../../core/routing/path";
import { PrimaryLink } from "../../common/buttons/primary-button";
import { PageHeader } from "../../common/page/page-header";
import { theme } from "../../styles/theme";

export const AccountBlockedScreen: React.FC<{
  isThreePincodeAttemptsError?: boolean;
}> = ({ isThreePincodeAttemptsError }) => {
  const { formatMessage } = useIntl();
  const configuration = useObservable(configurationManager.configuration);
  const isConnected = useObservable(authenticationManager.isConnected);

  useAsyncEffect(async () => {
    if (isConnected) {
      await clientManager.updateClient(true);
      await accountManager.refresh();
    }
  });

  const getContacts = () => {
    if (configuration.contactPhone && configuration.contactEmail) {
      return formatMessage("accountBlockedScreen.contactsPhoneAndEmail", {
        contactPhone: formatPhoneNumber(configuration.contactPhone),
        contactEmail: configuration.contactEmail,
      });
    }
    if (configuration.contactPhone) {
      return formatMessage("accountBlockedScreen.contactsPhone", {
        contactPhone: formatPhoneNumber(configuration.contactPhone),
      });
    }
    if (configuration.contactEmail) {
      return formatMessage("accountBlockedScreen.contactsEmail", { contactEmail: configuration.contactEmail });
    }
    return "";
  };

  const reason = isThreePincodeAttemptsError
    ? {
        subtitle: formatMessage("accountBlockedScreen.multipleAttemptBlockedReason.subtitle"),
        message: formatMessage("accountBlockedScreen.multipleAttemptBlockedReason.message", {
          contacts: getContacts(),
        }),
      }
    : {
        subtitle: undefined,
        message: formatMessage("accountBlockedScreen.defaultBlockedReason.message", { contacts: getContacts() }),
      };

  return (
    <BlockedSection>
      <PageHeader title={formatMessage("accountBlockedScreen.title")} />
      <Content>
        <Message>{reason.message}</Message>
        <PrimaryLink to={Path.AccountTransactions} size="M">
          {formatMessage("accountBlockedScreen.button")}
        </PrimaryLink>
      </Content>
    </BlockedSection>
  );
};

const BlockedSection = styled.section`
  align-self: stretch;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Message = styled.span`
  ${theme.text};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  > *:not(:first-child) {
    margin-top: 40px;
  }
`;
