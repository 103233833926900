import IBAN from "iban";
import React, { useState } from "react";
import styled, { css } from "styled-components";

import { useIntl } from "../../../../shared/core/i18n/use-intl";
import { logger } from "../../../../shared/core/logging/logger";
import { beneficiaryManager, externalAccountManager } from "../../../../shared/core/service/services";
import type { Recipient } from "../../../../shared/domains/recipients/recipient";
import { formatPhoneNumber } from "../../../../shared/utils/phone-number";
import { EDIT_RECIPIENT_MODAL_ID } from "../../../core/modal/modal-id";
import { PrimaryButton } from "../../common/buttons/primary-button";
import { ErrorMessage } from "../../common/error-message";
import { PrefixedTextInput } from "../../common/forms/prefixed-text-input";
import { TextInput } from "../../common/forms/text-input";
import { Modal } from "../../common/modal/modal";
import { RoundedModalContainer } from "../../common/modal/rounded-modal-container";
import { UserIcon } from "../../common/svg/user-icon";
import { fonts, theme } from "../../styles/theme";

export interface EditBeneficiaryModalProps {
  recipient: Recipient;
  forExternalAccount?: boolean;
}

export const EditBeneficiaryModal = (props: EditBeneficiaryModalProps) => {
  const { recipient, forExternalAccount } = props;
  const intl = useIntl();
  const { formatMessage } = intl;
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);

  const [name, setName] = useState<string>(recipient?.name || "");

  const handleSave = async () => {
    setLoading(true);
    try {
      if (forExternalAccount) {
        await externalAccountManager.updateRecipient(recipient.id, name);
      } else {
        await beneficiaryManager.updateRecipient(recipient.id, name);
      }
    } catch (e) {
      logger.debug("AddBeneficiaryDetail: Confirm error", e);
      setErrorMessage(formatMessage("editRecipient.errorMessage"));
    } finally {
      Modal.dismiss(EDIT_RECIPIENT_MODAL_ID);
      setLoading(false);
    }
  };

  return (
    <RoundedModalContainer closeButton id={EDIT_RECIPIENT_MODAL_ID}>
      <Container>
        <Title>{formatMessage("editRecipient.title")}</Title>
        <Subtitle>
          {formatMessage(forExternalAccount ? "editRecipient.externalAccountSubtitle" : "editRecipient.subtitle")}
        </Subtitle>
        {errorMessage && <StyledErrorMessage>{errorMessage}</StyledErrorMessage>}
        <NameInput
          prefixElement={<UserIcon height={20} width={20} color={"#000000"} />}
          value={name}
          onChange={(event) => setName(event.target.value)}
          placeholder={formatMessage("addRecipient.nameFieldPlaceholder")}
        />
        {recipient?.iban && (
          <IbanInput
            value={IBAN.printFormat(recipient.iban)}
            dir={"ltr"}
            bottomOffset={16}
            onChange={() => null}
            disabled
          />
        )}
        {recipient.accountReference && recipient.bic && (
          <BicInput value={recipient.bic} dir={"ltr"} onChange={() => null} bottomOffset={16} disabled />
        )}
        {recipient?.accountReference && (
          <AccountReferenceInput
            value={recipient.accountReference}
            dir={"ltr"}
            onChange={() => null}
            bottomOffset={16}
            disabled
          />
        )}
        {recipient?.phone && (
          <PhoneInput
            value={formatPhoneNumber(recipient.phone)}
            dir={"ltr"}
            onChange={() => null}
            bottomOffset={16}
            disabled
          />
        )}
        <ConfirmButton size="M" onClick={handleSave} disabled={loading} showSpinner={loading}>
          {formatMessage("editRecipient.saveButton")}
        </ConfirmButton>
      </Container>
    </RoundedModalContainer>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 56px;
`;

const Title = styled.span`
  ${fonts.bold}
  font-size: 1.5rem;
  color: ${theme.mainColor};
`;

const Subtitle = styled(Title)`
  color: black;
`;

const inputStyle = css`
  width: 100%;
  max-width: 360px;
  margin-bottom: 25px;
`;

const NameInput = styled(PrefixedTextInput)`
  margin-top: 48px;
  ${inputStyle};
`;

const IbanInput = styled(TextInput)`
  ${inputStyle};
`;

const BicInput = styled(TextInput)`
  ${inputStyle};
`;

const AccountReferenceInput = styled(TextInput)`
  ${inputStyle};
`;

const PhoneInput = styled(TextInput)`
  ${inputStyle};
`;

const ConfirmButton = styled(PrimaryButton)`
  margin-top: 30px;
  margin-bottom: 30px;
`;

const StyledErrorMessage = styled(ErrorMessage)`
  margin: 10px 0 0;
  text-align: center;
`;
