import type { SVGProps } from "react";
import React from "react";

export const ContactPhoneIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="26" height="26" viewBox="0 0 26 26" {...props}>
    <g fill="none" fillRule="evenodd">
      <path d="M0 0h26v26H0z" />
      <path
        d="M20.96 5.395a.542.542 0 0 1 .102.14l1.196 2.393a4.753 4.753 0 0 1-.89 5.486l-6.499 6.5a5.88 5.88 0 0 1-6.016 1.42l-2.616-.872a.542.542 0 0 1-.212-.897l2.872-2.873a2.167 2.167 0 0 1 2.501-.405l.27.135a2.167 2.167 0 0 0 2.502-.406l2.388-2.388c.66-.66.823-1.667.406-2.5l-.136-.271a2.167 2.167 0 0 1 .406-2.501l2.96-2.96a.542.542 0 0 1 .767 0zm-5.254-3.204-.2 2.157a9.726 9.726 0 0 0-7.803 2.814 9.726 9.726 0 0 0-2.814 7.79l-2.158.199a11.892 11.892 0 0 1 3.44-9.521 11.892 11.892 0 0 1 9.535-3.44zm-.379 4.317-.207 2.156a5.403 5.403 0 0 0-4.352 1.562 5.402 5.402 0 0 0-1.563 4.337l-2.158.201a7.569 7.569 0 0 1 2.189-6.07 7.569 7.569 0 0 1 6.091-2.186z"
        fill={props.color ?? "#000"}
      />
    </g>
  </svg>
);
