import { useObservable } from "../../../utils/observable";
import { useObservablePaginated } from "../../../utils/observable-paginated";
import { useAsyncEffect } from "../../../utils/utils";
import type { UpcomingTransfer } from "./upcoming-transfers";
import type { UpcomingTransfersManager } from "./upcoming-transfers-manager";

export default function useUpcomingTransfers(upcomingTransferManager: UpcomingTransfersManager) {
  const paginatedUpcomingTransfers = useObservablePaginated<UpcomingTransfer>(
    upcomingTransferManager.paginatedUpcomingTransfers,
  );

  const canLoadMore = useObservable<boolean>(upcomingTransferManager.canLoadMore);
  const isLoading = useObservable<boolean>(upcomingTransferManager.isLoading);

  useAsyncEffect(async () => {
    await upcomingTransferManager.getUpcomingTransfers();
  }, []);

  return {
    upcomingTransfers: paginatedUpcomingTransfers.items,
    canLoadMore,
    isLoading,
    refresh: async () => await upcomingTransferManager.getUpcomingTransfers(),
    getUpcomingTransfers: async () => await upcomingTransferManager.getUpcomingTransfers(),
    loadMore: async () => await upcomingTransferManager.loadMore(),
  };
}
