import { find } from "lodash";
import { useMemo } from "react";

import { Amount, amountValueForCurrency } from "../../core/amount/amount";
import type { CurrencyIdentifier } from "../../core/currency/currency";
import { currencyManager, featuresManager } from "../../core/service/services";
import { useObservable } from "../../utils/observable";
import { useAsyncEffect } from "../../utils/utils";

export default function useCurrencies(amount?: Amount | null, foreignCurrency?: CurrencyIdentifier | null) {
  const exchangeRates = useObservable(currencyManager.exchangeRates);
  const features = useObservable(featuresManager.features);
  const canShowExchangeRate = features.sdaExchangeRateView;

  const matchingExchangeRate = useMemo(() => {
    if (exchangeRates && amount && foreignCurrency) {
      return find(
        exchangeRates,
        (rate) => rate.baseCurrency === amount.currency && rate.foreignCurrency === foreignCurrency,
      );
    }
    return null;
  }, [exchangeRates, amount, foreignCurrency]);

  const reversedMatchingExchangeRate = useMemo(() => {
    if (exchangeRates && amount && foreignCurrency && !matchingExchangeRate) {
      return find(
        exchangeRates,
        (rate) => rate.baseCurrency === foreignCurrency && rate.foreignCurrency === amount.currency,
      );
    }
    return null;
  }, [exchangeRates, matchingExchangeRate, amount, foreignCurrency]);

  const convertedAmount = useMemo(() => {
    if (amount && foreignCurrency) {
      const baseAmount = amountValueForCurrency(amount.value, amount.currency);
      if (matchingExchangeRate) {
        return new Amount(baseAmount * matchingExchangeRate.exchangeRates.sellRate, foreignCurrency);
      } else if (reversedMatchingExchangeRate) {
        return new Amount(baseAmount / reversedMatchingExchangeRate.exchangeRates.sellRate, foreignCurrency);
      }
    }
    return null;
  }, [amount, foreignCurrency, reversedMatchingExchangeRate, matchingExchangeRate]);

  useAsyncEffect(async () => {
    if (canShowExchangeRate && !exchangeRates && foreignCurrency) {
      await currencyManager.getExchangeRates();
    }
  }, [canShowExchangeRate, exchangeRates, foreignCurrency]);

  return {
    canShowExchangeRate,
    convertedAmount,
    getExchangeRate: currencyManager.getExchangeRates,
  };
}
