import type { CountryCode } from "libphonenumber-js";
import { AsYouType } from "libphonenumber-js";
import React, { useCallback, useState } from "react";
import styled, { css } from "styled-components";

import { countries } from "../../../../shared/assets/countries";
import { useIntl } from "../../../../shared/core/i18n/use-intl";
import { configurationManager } from "../../../../shared/core/service/services";
import { useObservable } from "../../../../shared/utils/observable";
import type { PrefixedSelectInputType } from "./prefixed-select-input";
import { PrefixedSelectInput } from "./prefixed-select-input";
import { PrefixContainer, PrefixedTextInput } from "./prefixed-text-input";

export const PhoneInput: React.FC<{
  onChangePhoneNumber?: (phone: string) => void;
  onChangeCountryCode?: (code: CountryCode) => void;
  initialCountryCode?: CountryCode;
  initialPhone?: string;
  maxWidth?: string;
  disabled?: boolean;
  required?: boolean;
}> = ({
  required = true,
  disabled,
  maxWidth,
  initialPhone,
  initialCountryCode,
  onChangePhoneNumber,
  onChangeCountryCode,
}) => {
  const defaultCountry = useObservable(configurationManager.defaultCountry);
  const [countryCode, setCountryCode] = useState(initialCountryCode ?? defaultCountry);
  const [phoneNumber, setPhoneNumber] = useState(initialPhone ?? "");
  const configuration = useObservable(configurationManager.configuration);
  const { formatMessage } = useIntl();

  const updateCountryCode = useCallback(
    (code: CountryCode) => {
      setCountryCode(code);
      onChangeCountryCode?.(code);
    },
    [onChangeCountryCode],
  );

  const updatePhoneNumber = useCallback(
    (phone: string) => {
      const nationalNumber = "+" + countries[countryCode].phoneCode;
      const internationalPhoneNumber = phone.startsWith(nationalNumber) ? phone : nationalNumber + phone;
      const formatted = new AsYouType().input(internationalPhoneNumber).substr(nationalNumber.length);
      setPhoneNumber(formatted);
      onChangePhoneNumber?.(phone);
    },
    [countryCode, onChangePhoneNumber],
  );

  return (
    <PhoneInputContainer maxWidth={maxWidth}>
      <CountrySelectInput
        disabled={disabled}
        onChange={updateCountryCode}
        innerId="country-select-field"
        options={configuration.countries}
        itemRenderer={(code) => countries[code].englishName}
        value={countryCode}
        prefixElement={countries[countryCode] && <FlagIcon src={countries[countryCode].flag} />}
      />
      <TelPhoneInput
        required={required}
        disabled={disabled}
        placeholder={formatMessage("addRecipient.phoneFieldPlaceholder")}
        prefixElement={<PhoneCode>+{countries[countryCode].phoneCode}</PhoneCode>}
        type="tel"
        value={phoneNumber}
        onChange={(event) => {
          updatePhoneNumber(event.target.value);
        }}
      />
    </PhoneInputContainer>
  );
};

const PhoneInputContainer = styled.div<{ maxWidth?: string }>`
  max-width: ${(props) => (props.maxWidth === "0" ? "none" : props.maxWidth || "100%")};
`;

const inputStyle = css`
  width: 100%;
  margin-bottom: 20px;
`;

const CountrySelectInput = styled<PrefixedSelectInputType<CountryCode>>(PrefixedSelectInput)`
  ${inputStyle};
`;

const FlagIcon = styled.img`
  width: 14px;
  height: 14px;
  border-radius: 7px;
`;

const TelPhoneInput = styled(PrefixedTextInput)`
  ${inputStyle};

  ${PrefixContainer} {
    width: 80px;
  }
`;

const PhoneCode = styled.span`
  font-size: 0.875rem;
`;
