import type { SVGProps } from "react";
import React from "react";

export function PlayIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 26 26" {...props}>
      <path d="M21.5 13L9.5 18.6292V7.37083L21.5 13Z" fillRule="nonzero" />
    </svg>
  );
}
