import type { AxiosRequestConfig } from "axios";

import type { AuthenticationManager } from "../../../domains/authentication/authentication-manager";
import type { ServiceDomainManager } from "../../../domains/service-domain/service-domain-manager";
import type { SecuredCookiesService } from "../secured-cookies-service";
import type { Interceptor } from "./interceptor";

export const addAuthorizationInterceptor: (
  authenticationManager: AuthenticationManager | ServiceDomainManager,
  securedCookiesService?: SecuredCookiesService,
) => Interceptor<AxiosRequestConfig> = (authenticationManager, securedCookiesService) => ({
  onFulfilled: async (config) => {
    const accessToken = await authenticationManager.getAccessToken();
    const addAuthorization = !securedCookiesService?.getSecuredCookies();
    return {
      ...config,
      headers: {
        ...config.headers,
        ...(accessToken && addAuthorization && { Authorization: `${accessToken.type} ${accessToken.value}` }),
      },
    };
  },
});
