import type { SVGProps } from "react";
import React from "react";

export function SearchIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" {...props}>
      <g fill="none" fillRule="evenodd">
        <g transform="translate(-599 -180) translate(453 173) translate(139)">
          <path d="M0 0H26V26H0z" transform="translate(7 7)" />
          <path
            fill={props.fill || "#B1B1B1"}
            d="M15.745 11.373c0 2.415-1.957 4.372-4.372 4.372S7 13.788 7 11.373 8.958 7 11.373 7s4.372 1.958 4.372 4.373zm-.629 5.157c-1.05.764-2.345 1.215-3.743 1.215-3.52 0-6.373-2.853-6.373-6.372C5 7.853 7.853 5 11.373 5s6.372 2.853 6.372 6.373c0 1.398-.45 2.692-1.215 3.743l4.471 4.471-1.414 1.414-4.47-4.47z"
            transform="translate(7 7)"
          />
        </g>
      </g>
    </svg>
  );
}
