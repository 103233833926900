import type { ButtonHTMLAttributes } from "react";
import React from "react";
import styled from "styled-components";

import { useIntl } from "../../../../shared/core/i18n/use-intl";
import { PINCODE_MUST_BE_CHANGED_MODAL_ID } from "../../../core/modal/modal-id";
import { ChangePincodeState, useChangePincodeMachine } from "../../../machine/change-pincode-machine";
import { DefaultButton } from "../../common/buttons/default-button";
import { PincodeKeyboard } from "../../common/keyboard/pincode-keyboard";
import { Modal } from "../../common/modal/modal";
import { RoundedModalContainer } from "../../common/modal/rounded-modal-container";
import { ChevronIcon } from "../../common/svg/chevron";
import { theme } from "../../styles/theme";
import { PincodeChangeExplanation } from "./pincode-change-explanation";
import { PincodeChangeSuccess } from "./pincode-change-success";

interface PincodeChangeModalProps {
  type: "skip-explanation" | "operation-requirement" | "after-authentication";
}
export const PincodeChangeModal = (props: PincodeChangeModalProps) => {
  const { type } = props;
  const { state, context, submitCurrentPincode, submitNewPincode, confirmNewPincode, startPincodeChange, goBack } =
    useChangePincodeMachine(type === "skip-explanation");
  const { errorMessage } = context;
  const { formatMessage } = useIntl();
  const dismissModal = () => Modal.dismiss(PINCODE_MUST_BE_CHANGED_MODAL_ID);
  const renderPincodeMachineState = () => {
    const keyboard = context.keyboard;
    switch (state) {
      case ChangePincodeState.Explaining:
        return <PincodeChangeExplanation startPincodeChange={startPincodeChange} abort={dismissModal} type={type} />;
      case ChangePincodeState.FetchingCurrentPincodeKeyboard:
      case ChangePincodeState.FetchingCurrentPincodeKeyboardAfterError:
      case ChangePincodeState.PromptingCurrentPincodeKeyboard:
        return (
          <>
            <Title>{formatMessage("pincodeChangeScreen.title")}</Title>
            <PincodeKeyboard
              title={formatMessage("pincodeChangeFlow.currentCodeLabel")}
              errorMessage={errorMessage}
              keyboard={keyboard}
              loadingKeyboard={
                state === ChangePincodeState.FetchingCurrentPincodeKeyboard ||
                state === ChangePincodeState.FetchingCurrentPincodeKeyboardAfterError
              }
              onSubmit={submitCurrentPincode}
            />
          </>
        );
      case ChangePincodeState.FetchingChangePincodeKeyboard:
      case ChangePincodeState.PromptingChangePincodeKeyboard:
      case ChangePincodeState.PincodePromptNotMatch:
        return (
          <>
            <Title>{formatMessage("pincodeChangeScreen.title")}</Title>
            <PincodeKeyboard
              title={formatMessage("pincodeChangeFlow.newCodeLabel")}
              errorMessage={
                state === ChangePincodeState.PincodePromptNotMatch
                  ? formatMessage("pincodeChangeFlow.newCodesDontMatchError")
                  : errorMessage
              }
              keyboard={keyboard}
              loadingKeyboard={state === ChangePincodeState.FetchingChangePincodeKeyboard}
              onSubmit={submitNewPincode}
            />
            <AbsoluteGoBack onClick={goBack} />
          </>
        );
      case ChangePincodeState.PromptingConfirmPincodeKeyboard:
      case ChangePincodeState.RequestingPincodeChange:
        return (
          <>
            <Title>{formatMessage("pincodeChangeScreen.title")}</Title>
            <PincodeKeyboard
              title={formatMessage("pincodeChangeFlow.newCodeConfirmationLabel")}
              errorMessage={errorMessage}
              keyboard={keyboard}
              loadingKeyboard={state === ChangePincodeState.RequestingPincodeChange}
              onSubmit={confirmNewPincode}
            />
            <AbsoluteGoBack onClick={goBack} />
          </>
        );
      case ChangePincodeState.Done:
        return <PincodeChangeSuccess onComplete={dismissModal} />;
    }
  };
  return (
    <ModalContainer closeButton id={PINCODE_MUST_BE_CHANGED_MODAL_ID}>
      {renderPincodeMachineState()}
    </ModalContainer>
  );
};

const ModalContainer = styled(RoundedModalContainer)`
  padding-top: 50px;
`;

const Title = styled.div`
  ${theme.boldText};
  font-size: 1.375rem;
  margin-left: 30px;
  margin-bottom: 30px;
`;

const GoBack = (props: ButtonHTMLAttributes<HTMLButtonElement>) => {
  const { formatMessage } = useIntl();
  return (
    <DefaultButton {...props}>
      <LeftChevron width="12" height="12" fill="#b1b1b1" />
      <GoBackLabel>{formatMessage("common.goBack")}</GoBackLabel>
    </DefaultButton>
  );
};

const LeftChevron = styled(ChevronIcon)`
  transform: rotate(180deg);
  margin-right: 3px;
`;
const AbsoluteGoBack = styled(GoBack)`
  position: absolute;
  top: 20px;
  left: 20px;
`;

const GoBackLabel = styled.span`
  color: #b1b1b1;
  font-size: 0.875rem;
`;
