import React, { useState } from "react";
import styled from "styled-components";

import { useIntl } from "../../../../../shared/core/i18n/use-intl";
import { RECURRING_TRANSFER_FREQUENCY_OPTIONS } from "../../../../../shared/domains/transactions/recurring-transfer";
import type { UpcomingTransfer } from "../../../../../shared/domains/transactions/upcoming-transfers/upcoming-transfers";
import { UpcomingTransferStatus } from "../../../../../shared/domains/transactions/upcoming-transfers/upcoming-transfers";
import { AmountText } from "../../../common/amount-text";
import CardMoreMenu from "../../../common/card-more-menu";
import { theme } from "../../../styles/theme";
import { UpcomingTransferStatusPill } from "./upcoming-transfer-status-pill";

type UpcomingTransferItemProps = {
  transfer: UpcomingTransfer;
  hasStandingOrderUpdateActivated: boolean;
  hasStandingOrderDeleteActivated: boolean;
  handleUpcomingTransferDelete: (transfer: UpcomingTransfer) => void;
  handleUpcomingTransferStatus: (transfer: UpcomingTransfer) => void;
  handleUpcomingTransferEdit: (transfer: UpcomingTransfer) => void;
  handleUpcomingTransferDetail?: (transfer: UpcomingTransfer) => void;
};

export const UpcomingTransferItem = ({
  transfer,
  hasStandingOrderUpdateActivated,
  hasStandingOrderDeleteActivated,
  handleUpcomingTransferDelete,
  handleUpcomingTransferStatus,
  handleUpcomingTransferEdit,
  handleUpcomingTransferDetail,
}: UpcomingTransferItemProps) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { formatDate, formatMessage } = useIntl();

  const handleToggleMenu = () => setIsMenuOpen((bool) => !bool);

  const handleEdit = () => {
    handleUpcomingTransferEdit(transfer);
    handleToggleMenu();
  };

  const handlePause = () => {
    handleUpcomingTransferStatus(transfer);
    handleToggleMenu();
  };

  const handleDelete = () => {
    handleUpcomingTransferDelete(transfer);
    handleToggleMenu();
  };

  const matchingFrequency = RECURRING_TRANSFER_FREQUENCY_OPTIONS.find(
    (option) =>
      option.frequency.unit === transfer.frequency.unit && option.frequency.interval === transfer.frequency.interval,
  );

  const hasPauseEditEnabled =
    hasStandingOrderUpdateActivated &&
    [UpcomingTransferStatus.ACTIVATED, UpcomingTransferStatus.DEACTIVATED].includes(transfer.status);

  return (
    <StyledUpcomingTransfer onClick={() => handleUpcomingTransferDetail(transfer)}>
      <div>
        <BoldText>{transfer.paymentTransaction.paymentTransactionParties.creditor.name}</BoldText>
        <FrequencyText>
          {matchingFrequency && <span>{formatMessage(matchingFrequency.label)}</span>} -{" "}
          {formatMessage("upcomingTransfers.nextOn")}{" "}
          {formatDate(transfer.nextExecutionDate, { year: "numeric", month: "long", day: "numeric" })}
        </FrequencyText>
      </div>
      <StatusAndAmountWrapper>
        <UpcomingTransferStatusPill status={transfer.status} />
        <StyledAmountText disabled={isMenuOpen}>
          <AmountText amount={transfer.paymentTransaction.paymentTransactionAmountInformation.instructedAmount} />
        </StyledAmountText>
      </StatusAndAmountWrapper>
      <CardMoreMenu
        isRTL={false}
        isOpen={isMenuOpen}
        isPaused={transfer.status === UpcomingTransferStatus.DEACTIVATED}
        onToggle={handleToggleMenu}
        onPause={handlePause}
        onEdit={handleEdit}
        onDelete={handleDelete}
        enableEdit={hasPauseEditEnabled}
        enablePause={hasPauseEditEnabled}
        enableDelete={hasStandingOrderDeleteActivated}
      />
    </StyledUpcomingTransfer>
  );
};

const BoldText = styled.span`
  ${theme.bodyBlackBold}
  font-size: 16px;
`;

const FrequencyText = styled.div`
  ${theme.bodySmallGreyRegular}
`;

const StatusAndAmountWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const StyledAmountText = styled.div<{ disabled: boolean }>`
  display: flex;
  width: 120px;
  justify-content: flex-end;
  transition: opacity 0.3s;
  opacity: ${({ disabled }) => (disabled ? 0 : 1)};
  ${theme.bodyBlackBold}
`;
const StyledUpcomingTransfer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 100px 20px 30px;
  border-radius: 13px;
  background-color: #ffffff;
  align-self: stretch;
  margin-top: 15px;
  cursor: pointer;
  transition: box-shadow 0.3s;
  &:hover {
    box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.05);
  }
`;
