import type { SVGProps } from "react";
import React from "react";

export function SpendingIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="16" height="20" viewBox="0 0 16 20" {...props} xmlns="http://www.w3.org/2000/svg">
      <path d="M15.3906 8.625H9.375C8.82799 8.625 8.30339 8.4077 7.91659 8.02091C7.5298 7.63411 7.3125 7.10951 7.3125 6.5625V0.546875C7.3125 0.501291 7.29439 0.457574 7.26216 0.425341C7.22993 0.393108 7.18621 0.375 7.14062 0.375H3.1875C2.45815 0.375 1.75868 0.664731 1.24296 1.18046C0.727231 1.69618 0.4375 2.39565 0.4375 3.125V16.875C0.4375 17.6043 0.727231 18.3038 1.24296 18.8195C1.75868 19.3353 2.45815 19.625 3.1875 19.625H12.8125C13.5418 19.625 14.2413 19.3353 14.757 18.8195C15.2728 18.3038 15.5625 17.6043 15.5625 16.875V8.79688C15.5625 8.75129 15.5444 8.70757 15.5122 8.67534C15.4799 8.64311 15.4362 8.625 15.3906 8.625ZM11.4375 15.5H4.5625C4.38016 15.5 4.2053 15.4276 4.07636 15.2986C3.94743 15.1697 3.875 14.9948 3.875 14.8125C3.875 14.6302 3.94743 14.4553 4.07636 14.3264C4.2053 14.1974 4.38016 14.125 4.5625 14.125H11.4375C11.6198 14.125 11.7947 14.1974 11.9236 14.3264C12.0526 14.4553 12.125 14.6302 12.125 14.8125C12.125 14.9948 12.0526 15.1697 11.9236 15.2986C11.7947 15.4276 11.6198 15.5 11.4375 15.5ZM11.4375 12.0625H4.5625C4.38016 12.0625 4.2053 11.9901 4.07636 11.8611C3.94743 11.7322 3.875 11.5573 3.875 11.375C3.875 11.1927 3.94743 11.0178 4.07636 10.8889C4.2053 10.7599 4.38016 10.6875 4.5625 10.6875H11.4375C11.6198 10.6875 11.7947 10.7599 11.9236 10.8889C12.0526 11.0178 12.125 11.1927 12.125 11.375C12.125 11.5573 12.0526 11.7322 11.9236 11.8611C11.7947 11.9901 11.6198 12.0625 11.4375 12.0625Z" />
      <path d="M15.0134 7.10348L8.83402 0.924141C8.82201 0.912195 8.80673 0.904068 8.7901 0.900782C8.77348 0.897495 8.75626 0.899197 8.7406 0.905671C8.72494 0.912146 8.71154 0.923105 8.7021 0.937171C8.69265 0.951237 8.68757 0.967782 8.6875 0.984726V6.5625C8.6875 6.74484 8.75993 6.9197 8.88886 7.04864C9.01779 7.17757 9.19266 7.25 9.375 7.25H14.9528C14.9697 7.24993 14.9863 7.24485 15.0003 7.2354C15.0144 7.22595 15.0254 7.21256 15.0318 7.1969C15.0383 7.18124 15.04 7.16402 15.0367 7.1474C15.0334 7.13077 15.0253 7.11549 15.0134 7.10348Z" />
    </svg>
  );
}
