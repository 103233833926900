import { Buffer } from "buffer";

import { logger } from "../../../core/logging/logger";
import type { ConnectedApiService } from "../../../core/net/connected-api-service";
import type { Attachment } from "./attachment";

export interface AttachmentContent {
  contentType: string;
  base64Img: string;
  content: ArrayBuffer;
}

export class AttachmentsService {
  public constructor(private apiService: ConnectedApiService) {}

  public async addAttachment(
    transactionId: string,
    content: string,
    description?: string,
    url: string | undefined = undefined,
  ): Promise<Attachment> {
    try {
      const response = await this.apiService.instance.post<Attachment>(
        url ?? `/transactions/${transactionId}/attachments`,
        {
          content,
          description,
        },
      );
      return response.data;
    } catch (e) {
      logger.debug("AttachmentsService", "Add attachment failed", e?.response?.data?.error?.message);
      throw e;
    }
  }

  public async getAttachments(transactionId: string, url: string | undefined): Promise<Attachment[]> {
    try {
      const response = await this.apiService.instance.get<Attachment[]>(
        url ?? `/transactions/${transactionId}/attachments`,
      );
      return response.data;
    } catch (e) {
      logger.debug("AttachmentsService", "Get attachments failed", e);
      throw e;
    }
  }

  public async deleteAttachment(transactionId: string, attachmentId: string, url: string | undefined) {
    try {
      await this.apiService.instance.delete(url ?? `/transactions/${transactionId}/attachments/${attachmentId}`);
    } catch (e) {
      logger.debug("AttachmentsService", "Delete attachment failed", e);
      throw e;
    }
  }

  public async getContent(
    transactionId: string,
    attachmentId: string,
    url: string | undefined,
  ): Promise<AttachmentContent | null> {
    try {
      const response = await this.apiService.instance.get(
        url ?? `/transactions/${transactionId}/attachments/${attachmentId}/content`,
        {
          responseType: "arraybuffer",
        },
      );
      if (response) {
        const contentType = response.headers["content-type"];
        const base64 = Buffer.from(response.data, "binary").toString("base64");
        return { contentType, base64Img: base64, content: response.data };
      }
      return null;
    } catch (e) {
      logger.debug("AttachmentsService", "Get content failed", e);
      throw e;
    }
  }
}
