import React from "react";
import styled from "styled-components";

import { DeleteButton, EditButton, MoreButton, OpenButton, PauseButton } from "./card-more-menu-actions";

type CardMoreMenuProps = {
  isPaused?: boolean;
  isOpen: boolean;
  isRTL?: boolean;
  alwaysOpened?: boolean;
  enableEdit?: boolean;
  enablePause?: boolean;
  enableDelete?: boolean;
  onToggle: () => void;
  onOpen?: () => void;
  onPause?: () => void;
  onEdit?: () => void;
  onDelete?: () => void;
};

export default function CardMoreMenu(props: CardMoreMenuProps) {
  const {
    isPaused,
    isRTL = true,
    alwaysOpened = false,
    isOpen,
    enableEdit = true,
    enablePause = true,
    enableDelete = true,
    onToggle,
    onPause,
    onOpen,
    onEdit,
    onDelete,
  } = props;
  return (
    <MoreMenu $isRTL={isRTL}>
      {!alwaysOpened && (!!onDelete || !!onEdit || !!onOpen) && <MoreButton active={isOpen} onClick={onToggle} />}
      {!!onDelete && enableDelete && <DeleteButton show={alwaysOpened || isOpen} onClick={onDelete} />}
      {!!onPause && enablePause && <PauseButton isPaused={isPaused} show={alwaysOpened || isOpen} onClick={onPause} />}
      {!!onEdit && enableEdit && <EditButton show={alwaysOpened || isOpen} onClick={onEdit} />}
      {!!onOpen && <OpenButton show={alwaysOpened || isOpen} onClick={onOpen} />}
    </MoreMenu>
  );
}

const MoreMenu = styled.div<{ $isRTL: boolean }>`
  position: absolute;
  display: flex;
  flex-direction: ${(props) => (props.$isRTL ? "row" : "row-reverse")};
  align-items: center;
  right: ${(props) => (props.$isRTL ? "unset" : "25px")};
  left: ${(props) => (props.$isRTL ? "25px" : "unset")};
  top: 0px;
  bottom: 0px;
  z-index: 1;
`;
