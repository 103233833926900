import type { SVGProps } from "react";
import React from "react";

export function QuestionIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 2.538a8.462 8.462 0 1 0 0 16.923 8.462 8.462 0 0 0 0-16.923zm.846 13.116c0 .233-.19.423-.423.423h-.846a.423.423 0 0 1-.423-.423v-.846c0-.234.19-.424.423-.424h.846c.234 0 .423.19.423.424v.846zm.745-4.409a2.445 2.445 0 0 0 1.692-2.31v-.473a2.437 2.437 0 0 0-2.437-2.437h-1.692a2.437 2.437 0 0 0-2.437 2.437v.423c0 .233.19.423.423.423h.643c.234 0 .423-.19.423-.423v-.423c0-.524.424-.948.948-.948h1.692a.948.948 0 0 1 .982.948v.473a.956.956 0 0 1-.643.906l-.804.262a1.59 1.59 0 0 0-1.092 1.506v.66c0 .234.19.423.423.423h.643c.234 0 .423-.19.423-.423v-.66a.11.11 0 0 1 .068-.101l.745-.263z"
      />
    </svg>
  );
}
