import { logger } from "../../core/logging/logger";
import type { ConnectedApiService } from "../../core/net/connected-api-service";
import type { Client } from "./client";

export class ClientService {
  public constructor(private apiService: ConnectedApiService) {}

  public async fetchClient() {
    try {
      const response = await this.apiService.instance.get<Client>("/client");
      return response.data;
    } catch (e) {
      logger.debug("ClientService", "Fetch client failed", e);
      throw e;
    }
  }

  public async patchClient(values: Partial<Client>) {
    try {
      const response = await this.apiService.instance.patch<Client>("/client", values);
      return response.data;
    } catch (e) {
      logger.debug("ClientService", "Update client failed", e);
      throw e;
    }
  }
}
