import React from "react";
import styled from "styled-components";

import { useIntl } from "../../../../shared/core/i18n/use-intl";
import { TRANSACTION_CODE_ID } from "../../../core/modal/modal-id";
import { useRTL } from "../../../domain/language/use-rtl";
import { useTransactionCodes } from "../../../domain/transaction-codes/use-transaction-codes";
import { AccountListGrid } from "../../common/account/account-list-grid";
import { PrimaryButton } from "../../common/buttons/primary-button";
import { Modal } from "../../common/modal/modal";
import { PageSectionDescription, PageSectionTitle } from "../../common/page/page-section-title";
import { UIConstants } from "../../styles/uiConstants";
import { SkeletonAccountTile } from "../account-transactions/components/account-tile";
import { EmptyBlock } from "../transfer/components/empty-block";
import { TransactionCodeCard } from "./components/transaction-code-card";
import { TransactionCodeModal } from "./components/transaction-code-modal";

export function TransactionCodesScreen() {
  const { formatMessage } = useIntl();

  const { transactionCodes, loading } = useTransactionCodes();

  const newTransactionCode = () => {
    Modal.present(TRANSACTION_CODE_ID, () => <TransactionCodeModal />, {
      canBeDismissed: false,
    });
  };

  const { isRTL } = useRTL();

  return (
    <Section>
      <ScreenHeader>
        <ScreenTitleAndSubtitle>
          <PageSectionTitle>{formatMessage("transactionCode.title")}</PageSectionTitle>
          <PageSectionDescription>{formatMessage("transactionCode.description")}</PageSectionDescription>
        </ScreenTitleAndSubtitle>
        <NewCodeButton onClick={newTransactionCode} size="S">
          {formatMessage("transactionCode.newCodeButton")}
        </NewCodeButton>
      </ScreenHeader>
      {!loading && transactionCodes.length === 0 ? (
        <EmptyBlock
          title={formatMessage("transactionCode.noCodeAvailable")}
          message={formatMessage("transactionCode.noCodeAvailableExplanation")}
        />
      ) : (
        <AccountListGrid>
          {loading ? (
            <SkeletonAccountTile $isRTL={isRTL} />
          ) : (
            transactionCodes.map((code) => <TransactionCodeCard key={code.date} transactionCode={code} />)
          )}
        </AccountListGrid>
      )}
    </Section>
  );
}

const Section = styled.section`
  display: flex;
  align-self: stretch;
  flex: 1 0 auto;
  flex-direction: column;
  align-items: flex-start;
`;

const ScreenHeader = styled.div`
  display: flex;
  align-self: stretch;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  @media (max-width: ${UIConstants.TABLET_BREAKPOINT - 1}px) {
    flex-direction: column;
    align-items: center;
  }
`;

const ScreenTitleAndSubtitle = styled.div`
  display: flex;
  flex: 0 1 auto;
  flex-direction: column;
`;

const NewCodeButton = styled(PrimaryButton)`
  flex: 0 0 auto;
  @media (max-width: ${UIConstants.TABLET_BREAKPOINT - 1}px) {
    margin-top: 20px;
  }
`;
