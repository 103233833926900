export const TRANSFER_MODAL_ID = "transfer";
export const SIMPLE_TRANSFER_MODAL_ID = "simple_transfer";
export const BILL_ISSUER_MODAL_ID = "bill_issuer";
export const ADD_RECIPIENT_MODAL_ID = "add_recipient";
export const EDIT_RECIPIENT_MODAL_ID = "edit_recipient";
export const REMOVE_RECIPIENT_MODAL_ID = "remove_recipient";
export const ADD_OR_EDIT_SPENDING = "add_or_edit_spending";
export const REMOVE_ATTACHMENT_MODAL_ID = "remove_attachment";
export const REMOVE_SPENDING_MODAL_ID = "remove_spending";
export const ACCOUNT_TRANSACTION_DETAILS_MODAL_ID = "account_transaction-details";
export const AUTHORIZATION_HOLD_DETAILS_MODAL_ID = "account_transaction-details";
export const ACCOUNT_DETAIL_MODAL_ID = "account_detail";
export const CASH_TRANSFER_MODAL_ID = "cash_transfer";
export const CARD_OPPOSITION_MODAL_ID = "card_opposition";
export const CARD_DEACTIVATION_MODAL_ID = "card_deactivation";
export const CARD_ACTIVATION_MODAL_ID = "card_activation";
export const CARD_CREATION_MODAL_ID = "card_creation";
export const CARD_PINCODE_UPDATE_MODAL_ID = "card_pincode_update";
export const CARD_UPDATE_OUTSTANDING_MODAL_ID = "card_update_outstanding";
export const CARD_REFABRICATION_MODAL_ID = "card_refabrication";
export const CARD_SENSITIVE_DETAILS_MODAL_ID = "card_sensitive_details_modal_id";
export const PINCODE_MUST_BE_CHANGED_MODAL_ID = "pincode_must_be_changed";
export const CONTACT_SUPPORT_MODAL_ID = "contact_support";
export const RECHARGE_MODAL_ID = "recharge";
export const TRANSACTION_CODE_ID = "transaction_code";
export const TRUST_CHOICE_MODAL_ID = "trust_choice_modal";
export const ACCOUNT_DELETE_MODAL_ID = "account_delete";
export const REFUND_TRANSACTION_REASON_MODAL_ID = "refund_transaction_reason";
export const DELETE_UPCOMING_TRANSFER_MODAL_ID = "delete_upcoming_transfer";
export const EDIT_UPCOMING_TRANSFER_MODAL_ID = "edit_upcoming_transfer";
export const DETAIL_UPCOMING_TRANSFER_MODAL_ID = "detail_upcoming_transfer";
