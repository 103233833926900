import { debounce } from "lodash";
import { useCallback } from "react";

export function useLoadMoreDebounce(wait = 200) {
  return useCallback(
    debounce(
      (fetch, loading, hasMore) => {
        if (!loading && hasMore) {
          fetch();
        }
      },
      wait,
      { leading: false, trailing: true },
    ),
    [wait],
  );
}
