import type { AxiosRequestConfig } from "axios";

import type { AuthenticationManager } from "../../../domains/authentication/authentication-manager";
import { logger } from "../../logging/logger";
import type { Interceptor } from "./interceptor";

export const isConnectedCheckInterceptor: (
  authenticationManager: AuthenticationManager,
) => Interceptor<AxiosRequestConfig> = (authenticationManager) => ({
  onFulfilled: async (config) => {
    if (!authenticationManager.isConnected.get()) {
      logger.debug("Request cancelled, not connected");
      return Promise.reject("Request cancelled, not connected");
    }
    return config;
  },
});
