import { Signal } from "micro-signals";

import type { DefaultCacheLoader } from "../../core/cache/default-cache-loader";
import type { LanguageTag } from "../../core/i18n/translations";
import { logger } from "../../core/logging/logger";
import { isDefined } from "../../utils/assert";
import { Observable } from "../../utils/observable";
import type { FeaturesManager } from "../features/features-manager";
import type { Client } from "./client";
import { ClientStatus } from "./client";
import { ClientNotLoadedError } from "./client-error";
import type { ClientService } from "./client-service";

export class ClientManager {
  public client = new Observable<Client | null>(null);

  public readonly onLanguageChange = new Signal();

  public constructor(
    private clientService: ClientService,
    private featuresManager: FeaturesManager,
    private cacheLoader: DefaultCacheLoader<Client>,
  ) {}

  public async initialize(): Promise<void> {
    const client = await this.cacheLoader.read();
    this.client.set(client);
  }

  public async updateClient(forceRefresh?: boolean) {
    if (this.featuresManager.features.get().clientInformationView) {
      const client = await this.cacheLoader.load(() => this.clientService.fetchClient(), forceRefresh);
      this.client.set(client);

      if (!client) {
        throw new ClientNotLoadedError("Failed to update client");
      }

      return client;
    }
    if (forceRefresh) {
      logger.debug("Missing kyc_view scope to retrieve client");
    } else {
      throw new ClientNotLoadedError("Missing kyc_view scope to retrieve client");
    }
  }

  public async checkAccountIsOpen(): Promise<boolean> {
    const client = this.client?.get();
    if (!client) {
      return false;
    }

    const previousClientStatus = client.status;
    try {
      const updatedClient = await this.updateClient(true);
      return (
        updatedClient.status != previousClientStatus &&
        isDefined(previousClientStatus) &&
        updatedClient.status === ClientStatus.Opened
      );
    } catch (error) {
      logger.debug("checkAccountIsOpen failed to update client ", error);
      return false;
    }
  }

  public async setLanguage(language: LanguageTag) {
    const client = await this.clientService.patchClient({ lang: language });
    if (client) {
      this.cacheLoader.store(client);
    }
    this.client.set(client);
    this.onLanguageChange.dispatch(null);
  }

  public async clear() {
    await this.cacheLoader.clear();
    this.client.set(null);
  }
}
