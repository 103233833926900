import filesize from "filesize";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled from "styled-components";

import type { FileProps, FormImageInput } from "../../../../shared/core/data-forms/form-input-types";
import { useIntl } from "../../../../shared/core/i18n/use-intl";
import { logger } from "../../../../shared/core/logging/logger";
import { hasMultiplePages } from "../../../../shared/utils/form-input-image";
import type { UploadedImage } from "../../../../shared/utils/images-converter";
import { theme } from "../../styles/theme";
import { FileUploader, FileUploaderType, MATCH_FILE_UPLOADER_TYPES } from "../forms/file-uploader";

const MAX_NB_PAGES = 20;

interface ImageDimension {
  width: number;
  height: number;
}

export const SingleImageField: React.FC<{
  input: FormImageInput;
  fileProps?: FileProps;
  value?: string;
  index?: number;
  hasExistingFile?: boolean;
  disabled?: boolean;
  onChange: (image: UploadedImage | null) => void;
}> = ({ input, value, index, disabled, hasExistingFile, fileProps, onChange }) => {
  const { formatMessage } = useIntl();
  const [previewDisplayed, setPreviewDisplayed] = useState(false);
  const [image, setImage] = useState<string | null>(null);

  let acceptedTypes: FileUploaderType[];

  if (input.acceptedTypes) {
    const inputTypes = input.acceptedTypes;
    acceptedTypes = inputTypes.map((item) => MATCH_FILE_UPLOADER_TYPES[item] ?? item);
    acceptedTypes = acceptedTypes.filter((item) => ![FileUploaderType.PDF, FileUploaderType.DOCX].includes(item));
  } else {
    acceptedTypes = [FileUploaderType.PNG, FileUploaderType.JPG, FileUploaderType.JPEG];
  }

  const loadImgDimensions = async (imageUrl: string, setDimensions: (ImageDimension) => void) => {
    const img = new Image();
    img.src = imageUrl;

    img.onload = () => setDimensions({ width: img.width, height: img.height });
    img.onerror = (err) => {
      logger.debug("Image dimensions error : " + err);
    };
  };

  const onDelete = () => {
    setPreviewDisplayed(false);
    setImage(null);
    onChange(null);
  };

  return (
    <InputContainer>
      <FileUploader
        hasExistingFile={hasExistingFile}
        initialFile={
          value && hasExistingFile
            ? {
                name: formatMessage("registerScreen.pictureInputFilled"),
                type: FileUploaderType.PNG,
                size: filesize(value.length),
              }
            : undefined
        }
        typeAllowed={acceptedTypes}
        fileUploaded={async (file, props) => {
          if (!file) {
            onChange(null);
            return;
          }
          const reader = new FileReader();
          reader.addEventListener(
            "load",
            async () => {
              const base64Image = reader.result?.toString();
              if (base64Image) {
                const setImageDimension = (imageDimension: ImageDimension) => {
                  onChange({
                    base64: base64Image,
                    width: imageDimension.width,
                    height: imageDimension.height,
                    fileProps: props,
                  });
                };
                await loadImgDimensions(base64Image, setImageDimension);
                setImage(base64Image);
              }
            },
            false,
          );

          if (file) {
            reader.readAsDataURL(file);
          }
        }}
        onPreviewClicked={() => setPreviewDisplayed(!previewDisplayed)}
        onImageDeleted={onDelete}
        instructions={formatMessage("registerScreen.pictureInputEmpty")}
        disabled={disabled}
        maxSize={input.maxSize}
        required={input.required}
        badgeIndex={index}
        fileProps={fileProps}
      />
      {previewDisplayed && image ? <PreviewImage src={image} /> : null}
    </InputContainer>
  );
};

const InputContainer = styled.div`
  position: relative;
  flex: 1;
`;

const PreviewImage = styled.img`
  width: 100%;
  height: 100px;
  object-fit: contain;
`;

const EMPTY_IMAGE: UploadedImage = {
  base64: "",
  width: 0,
  height: 0,
};

export const ImageField: React.FC<{
  input: FormImageInput;
  onChange: (picture: string | null, fileProps?: FileProps) => void;
  onMultiImagesChange: (values: (UploadedImage | null)[] | null) => void;
  disabled?: boolean;
}> = ({ input, onChange, onMultiImagesChange, disabled }) => {
  const { formatMessage } = useIntl();

  const hasSeveralPages = useMemo(() => {
    // if (input.type === FormInputType.Image) {
    return hasMultiplePages(input as FormImageInput);
    // }
    return false;
  }, [input]);

  const [nbInputs, setNbInputs] = useState(input.minimumPageCount ?? 1);
  const [images, setImages] = useState<(UploadedImage | null)[]>(
    hasSeveralPages ? Array<UploadedImage>(input.maximumPageCount ?? nbInputs) : [],
  );

  const imageKeys = useMemo(() => {
    // if (input.type === FormInputType.Image) {
    if (nbInputs > 1) {
      return [...Array(nbInputs).keys()];
    }
    // }
    return [...Array(1).keys()];
  }, [input, nbInputs]);

  const showAddButton = useMemo(() => {
    // if (input.type === FormInputType.Image) {
    const maxPages = input.maximumPageCount ?? MAX_NB_PAGES;
    if (maxPages > nbInputs) {
      return true;
    }
    // }
    return false;
  }, [input, nbInputs]);

  const addImageInput = useCallback(() => {
    // if (input.type === FormInputType.Image) {
    if (input.maximumPageCount && nbInputs < input.maximumPageCount) {
      setNbInputs(nbInputs + 1);
    }
    // }
  }, [input, nbInputs]);

  const handleImageChange = (image: UploadedImage | null, index: number) => {
    if (nbInputs === 1) {
      onChange(image?.base64.split("base64,")?.[1] ?? null, image?.fileProps);
    } else {
      const updatedImages = images;
      updatedImages[index] = image;
      setImages(updatedImages);
      onMultiImagesChange(updatedImages);
    }
  };

  const hasExistingFile = (key) => (images[key] ? true : false);

  useEffect(() => {
    if (input.values) {
      setImages(input.values as (UploadedImage | null)[]);
    } else {
      setImages([]);
    }
  }, [input.values]);

  const isRequired = () => {
    const { minimumPageCount } = input;
    const imageCount = images.filter(Boolean).length;

    if (minimumPageCount) {
      return imageCount >= minimumPageCount ? false : true;
    } else {
      input.required;
    }
  };

  return (
    <>
      {imageKeys.map((key, index) => {
        const instanceOfUploadedImage = input.values?.[index];
        return (
          <div key={key} style={{ marginTop: 2, marginBottom: 8 }}>
            <SingleImageField
              onChange={(uploadedImage) => handleImageChange(uploadedImage, index)}
              disabled={disabled}
              input={{ ...input, required: isRequired() }}
              hasExistingFile={hasExistingFile(key)}
              value={hasSeveralPages || hasExistingFile(key) ? undefined : (input.value as string)}
              fileProps={
                hasSeveralPages
                  ? instanceOfUploadedImage
                    ? (instanceOfUploadedImage as UploadedImage).fileProps
                    : undefined
                  : input.fileProps
              }
              index={hasSeveralPages ? index + 1 : undefined}
            />
          </div>
        );
      })}
      {showAddButton ? (
        <AddPictureContainer onClick={addImageInput}>
          <AddPictureWrapper>
            <>
              <PlusTitle>{"＋"}</PlusTitle>
              <AddPictureTitle>{formatMessage("registerScreen.addPictureButton")}</AddPictureTitle>
            </>
          </AddPictureWrapper>
        </AddPictureContainer>
      ) : null}
    </>
  );
};

const AddPictureContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f4f4f4;
  margin-top: 16px;
  border-color: #bfbfbf;
  border-style: dashed;
  border-radius: 11px;
  border-width: 1.5px;
  justify-content: center;
  align-items: center;
  padding: 12px 0px;
  :hover {
    cursor: pointer;
  }
`;

const AddPictureWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const AddPictureTitle = styled.span`
  font-size: 0.85rem;
  ${theme.mediumText};
  margin: 0 4px;
`;

const PlusTitle = styled.span`
  margin: 0 4px;
  font-size: 0.875rem;
  ${theme.boldText};
`;
