import React from "react";
import styled from "styled-components";

import { useIntl } from "../../../../../../shared/core/i18n/use-intl";
import type { Account } from "../../../../../../shared/domains/account/account";
import type { Product } from "../../../../../../shared/domains/products/product";
import { isDefined } from "../../../../../../shared/utils/assert";
import { useAsyncOperation } from "../../../../../../shared/utils/utils";
import { PrimaryButton } from "../../../../common/buttons/primary-button";
import { theme } from "../../../../styles/theme";
import { CreateCardSelector } from "./create-card-selector";
import { VirtualCardDescrition } from "./virtual-card-description";

export interface CreateCardSelectionStepProps {
  isVirtual?: boolean;
  currentAccounts: Account[];
  selectedAccount?: Account;
  debitCardProducts: Product[];
  selectedProduct?: Product;
  selectAccount: (account: Account) => void;
  selectProduct: (product: Product) => void;
  confirmSelection: () => Promise<void>;
}

export const CreateCardSelectionStep = (props: CreateCardSelectionStepProps) => {
  const {
    currentAccounts,
    selectedAccount,
    debitCardProducts,
    selectedProduct,
    isVirtual,
    selectAccount,
    selectProduct,
    confirmSelection,
  } = props;
  const { formatMessage } = useIntl();

  const [goNext, loading] = useAsyncOperation(async () => {
    await confirmSelection();
  });
  const isPhysical = !isVirtual;
  const disabled = !(isDefined(selectedAccount) && isDefined(selectedProduct));

  return (
    <Container>
      <SelectTitle>{formatMessage("createCard.stepSelection.title")}</SelectTitle>
      <SelectInstructions>
        {formatMessage(
          isPhysical
            ? "createCard.stepSelection.selectAccountMessage"
            : "createCard.stepSelection.selectVirtualCardMessage",
        )}
      </SelectInstructions>
      <SelectorContainer>
        <CreateCardSelector
          values={currentAccounts}
          selectedValue={selectedAccount}
          getLabel={(account) => formatMessage("createCard.stepSelection.accountLabel", { id: account.id })}
          onChange={selectAccount}
        />
      </SelectorContainer>
      <SelectInstructions>
        {formatMessage(
          isPhysical
            ? "createCard.stepSelection.selecProductMessage"
            : "createCard.stepSelection.selectProductForVirtualCardMessage",
        )}
      </SelectInstructions>
      <SelectorContainer>
        <CreateCardSelector
          values={debitCardProducts}
          selectedValue={selectedProduct}
          getLabel={(product) => product.name}
          onChange={selectProduct}
        />
      </SelectorContainer>
      {isVirtual && <VirtualCardDescrition />}
      <ButtonContainer>
        <PrimaryButton size="S" disabled={disabled} onClick={goNext} showSpinner={loading}>
          {formatMessage(
            isVirtual ? "createCard.stepSelection.createVirtualCardButton" : "createCard.stepSelection.orderCardButton",
          )}
        </PrimaryButton>
      </ButtonContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const SelectTitle = styled.span`
  ${theme.boldText}
  margin-bottom: 20px;
  font-size: 1.125rem;
  color: #000000;
`;

const SelectInstructions = styled.span`
  ${theme.text}
  font-size: 0.875rem;
  color: #aaaaaa;
`;

const SelectorContainer = styled.div`
  flex: 0;
  margin-top: 16px;
  margin-bottom: 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  justify-content: center;
  align-items: flex-end;
  flex: 1;
`;
