import type { FormAnswers } from "../core/data-forms/form-answers";
import type { FormInput } from "../core/data-forms/form-input-types";
import { FormInputType } from "../core/data-forms/form-input-types";
import { isDefined } from "./assert";

export function collectValues(inputs: FormInput[]) {
  return inputs.reduce((acc, value) => {
    acc[value.id] = value.value;
    return acc;
  }, {} as FormAnswers);
}

export function areAllRequiredInputsFilled(inputs: FormInput[]) {
  return inputs.reduce<boolean>(
    (acc, input) =>
      acc &&
      (!input.required ||
        (input.type === FormInputType.Bool && input.value !== undefined) ||
        ([FormInputType.Select, FormInputType.SelectWithAmount].includes(input.type) &&
          input.value !== "" &&
          input.value !== undefined) ||
        !!input.value),
    true,
  );
}

export function areAllInputsValid(inputs: FormInput[]) {
  return inputs.reduce<boolean>((acc, input) => acc && !input.invalid, true);
}

// Recursively flattens inputs
// [{id: "a", type: "COLLECTION", inputs: [{id: "b", value: 1}, {id: "c", value: 2}]}]
// is flatten to [{id: "a.b", value: 1}, {id: "a.c", value: 2}]
const DELIMITER = ".";
export function flattenFormInputs(inputs: FormInput[]): FormInput[] {
  return inputs.reduce<FormInput[]>((acc, input) => {
    if (input.type === FormInputType.Collection) {
      return acc.concat(
        flattenFormInputs(input.inputs).map((it) => ({ ...it, id: `${input.id}${DELIMITER}${it.id}` })),
      );
    } else {
      return acc.concat(input);
    }
  }, []);
}

// Collect value from nested input
// [{id: "a.b.c", value: 27}, {id: "a.b.d", value: 13}]
// is transformed to {a: {b: {c: 27, d: 13}}}
export function collectFromFlatten(inputs: FormInput[]) {
  const collectedValues = {};
  for (const input of inputs) {
    const path = input.id.split(DELIMITER);
    const depth = path.length;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let leaf: { [id: string]: any } = collectedValues;
    for (let i = 0; i < depth - 1; i++) {
      if (!isDefined(leaf[path[i]])) {
        leaf[path[i]] = {};
      }
      leaf = leaf[path[i]];
    }
    leaf[path[depth - 1]] = input.value;
  }
  return collectedValues as FormAnswers;
}
