import type { HTMLAttributes } from "react";
import React from "react";
import styled from "styled-components";

import { addAmounts } from "../../../../../shared/core/amount/amount";
import { useIntl } from "../../../../../shared/core/i18n/use-intl";
import type { AccountingTransaction } from "../../../../../shared/domains/accounting-transaction/transaction/transaction";
import { useTransactionLinks } from "../../../../../shared/domains/accounting-transaction/use-transaction-links";
import { type AuthorizationHold } from "../../../../../shared/domains/authorization-hold/authorization-hold";
import { useRTL } from "../../../../domain/language/use-rtl";
import { AmountText } from "../../../common/amount-text";
import { ChevronDown } from "../../../common/svg/chevron-down";
import { WaitingIcon } from "../../../common/svg/waiting-icon";
import { shadows, theme } from "../../../styles/theme";
import { UIConstants } from "../../../styles/uiConstants";
import { AccountTransactionCard } from "./account-transaction-card";

export interface TransactionAccordionProps extends HTMLAttributes<HTMLDivElement> {
  data: AuthorizationHold[] | AccountingTransaction[];
  total?: number;
  transactionType?:
    | "authorizationHolds"
    | "unjustifiedTransactions"
    | "unjustifiedTransactions2"
    | "unqualifiedTransactions";
  onTransactionClick?: (transaction: AccountingTransaction) => void;
  onAuthorizationHoldClick?: (authorizationHold: AuthorizationHold) => void;
}

export function TransactionAccordion(props: TransactionAccordionProps) {
  const {
    data,
    total = data.length,
    transactionType = "authorizationHolds",
    onTransactionClick,
    onAuthorizationHoldClick,
    ...rest
  } = props;
  const { formatMessage } = useIntl();
  const { isRTL } = useRTL();
  const [expanded, setExpanded] = React.useState(false);

  const { canGetBothAttachmentsAndSpendings, canShowJustifyLabel } = useTransactionLinks();

  if (data?.length <= 0) {
    return null;
  }

  const getTitle = () =>
    formatMessage(
      total > 1
        ? total > 20
          ? `accountTransactions.${transactionType}.title.more`
          : `accountTransactions.${transactionType}.title.other`
        : `accountTransactions.${transactionType}.title.one`,
      { count: total },
    );

  const pendingOperationTotalAmount = addAmounts(...data.map((authorizationHold) => authorizationHold.amount));

  const toShowUnjustifiedTag = (transaction: AuthorizationHold | AccountingTransaction) => {
    if (transactionType === "authorizationHolds") {
      return canShowJustifyLabel(transaction) && !canGetBothAttachmentsAndSpendings(transaction);
    }
    return true;
  };

  return (
    <>
      <Card onClick={() => setExpanded(!expanded)} $isRTL={isRTL} {...rest}>
        <LeftColumn $isRTL={isRTL}>
          <IconContainer>
            <StyledWaitingIcon color={theme.mainColor()} />
          </IconContainer>
          <MainPart $isRTL={isRTL}>
            <Title>{getTitle()}</Title>
            <Description>{formatMessage(`accountTransactions.${transactionType}.description`)}</Description>
          </MainPart>
        </LeftColumn>
        <RightColumn>
          {pendingOperationTotalAmount && <StyledAmount amount={pendingOperationTotalAmount} $isRTL={isRTL} />}
          <ChevronDownIcon $expanded={expanded} />
        </RightColumn>
      </Card>
      <TransactionsContainer $expanded={expanded}>
        <TransactionsContainerInner>
          {data.length > 0 &&
            data.map((transaction) => (
              <TransactionCardContainer key={transaction.id} $expanded={expanded}>
                <StyledTransactionCard
                  transaction={transaction}
                  $expanded={expanded}
                  showUnjustifiedTag={toShowUnjustifiedTag(transaction)}
                  onClick={() => {
                    if (transactionType === "authorizationHolds") {
                      onAuthorizationHoldClick?.(transaction);
                    } else {
                      onTransactionClick?.(transaction);
                    }
                  }}
                />
              </TransactionCardContainer>
            ))}
        </TransactionsContainerInner>
      </TransactionsContainer>
    </>
  );
}

const Card = styled.div<{ $isRTL: boolean }>`
  display: flex;
  align-items: center;
  padding: ${(props) => (props.$isRTL ? "25px 17px 16px 27px" : "25px 27px 16px 17px")};
  border-radius: 17px;
  ${shadows.medium};
  background-color: #ffffff;
  @media (max-width: ${UIConstants.TABLET_BREAKPOINT - 1}px) {
    flex-direction: column;
    align-items: stretch;
  }
  :hover {
    cursor: pointer;
  }
  z-index: 2;
`;

const LeftColumn = styled.div<{ $isRTL: boolean }>`
  flex: 1 0 80%;
  display: flex;
  @media (max-width: ${UIConstants.TABLET_BREAKPOINT - 1}px) {
    flex: 0 1 auto;
  }
  align-items: center;
  padding: ${(props) => (props.$isRTL ? "0 0 0 8px" : "0 8px 0 0")};
`;

const IconContainer = styled.div`
  display: flex;
  width: 42px;
  height: 42px;
  align-items: center;
  justify-content: center;
  margin-bottom: 6px;
`;

const StyledWaitingIcon = styled(WaitingIcon)`
  flex: 0 0 auto;
  align-self: center;
`;

const Title = styled.span`
  font-size: 1.125rem;
  line-height: 1.2rem;
  ${theme.boldText};
  margin-bottom: 8px;
`;

const MainPart = styled.div<{ $isRTL: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: ${(props) => (props.$isRTL ? "auto 24px auto 0" : "auto 0 auto 24px")};
  padding: 0 0 8px 0;
  @media (max-width: ${UIConstants.TABLET_BREAKPOINT - 1}px) {
    margin: ${(props) => (props.$isRTL ? "auto 24px 10px 0" : "auto 0 10px 24px")};
  }
`;

const Description = styled.span`
  font-size: 0.9375rem;
  ${theme.text};
  text-align: left;
  color: black;
  opacity: 0.6;
`;

const StyledAmount = styled(AmountText)<{ $isRTL: boolean }>`
  font-size: 1.125rem;
  ${theme.boldText};
  justify-content: flex-end;
  text-align: right;
  flex: 1 1 40%;
  @media (max-width: ${UIConstants.TABLET_BREAKPOINT - 1}px) {
    margin: ${(props) => (props.$isRTL ? "0 67px 0 0" : "0 0 0 67px")};
    flex: 0 1 auto;
  }
`;

const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  @media (max-width: ${UIConstants.TABLET_BREAKPOINT - 1}px) {
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
  }
`;

const ChevronDownIcon = styled(ChevronDown)<{ $expanded: boolean }>`
  margin: 16px 6px 0;
  width: 15px;
  transform: ${(props) => (props.$expanded ? "rotate(180deg)" : "rotate(360deg)")};
  transition: transform 0.3s ease-in;
  @media (max-width: ${UIConstants.TABLET_BREAKPOINT - 1}px) {
    margin: 0 6px 0;
  }
`;

const TransactionsContainer = styled.div<{ $expanded: boolean }>`
  display: flex;
  position: relative;
  width: 100%;
  max-height: ${(props) => (props.$expanded ? 10000 : 0)};
  padding: ${(props) => (props.$expanded ? "34px 0 20px" : 0)};
  top: -40px;
  background-color: #f4f4f4;
  border-radius: 17px;
  z-index: 1;
  transition: max-height 0.3s ease-in;
  transition: padding 0.3s ease-in;
`;

const TransactionsContainerInner = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

const TransactionCardContainer = styled.div<{ $expanded: boolean }>`
  padding: ${(props) => (props.$expanded ? "12px 23px" : 0)};
  max-height: ${(props) => (props.$expanded ? 10000 : 0)};
  transition: max-height 0.3s ease-in;
`;

const StyledTransactionCard = styled(AccountTransactionCard)<{ $expanded: boolean }>`
  position: relative;
  top: ${(props) => (props.$expanded ? 0 : -40)};
  width: 100%;
  align-self: stretch;
  max-height: ${(props) => (props.$expanded ? 10000 : 0)};
  opacity: ${(props) => (props.$expanded ? 1 : 0)};
  transition: max-height 1s ease-in;
  transition: opacity 0.3s ease-in;
  transition: top 0.3s ease-in;
  :hover {
    cursor: pointer;
  }
`;
