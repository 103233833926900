import React, { useState } from "react";
import styled from "styled-components";

import { Currencies } from "../../../../shared/core/currency/currency";
import { useIntl } from "../../../../shared/core/i18n/use-intl";
import { cardManager, i18NManager } from "../../../../shared/core/service/services";
import { getUrlFromLink } from "../../../../shared/domains/BaseUrl";
import type { Card } from "../../../../shared/domains/cards/card";
import { CardLinks } from "../../../../shared/domains/cards/card-links";
import type { Outstanding } from "../../../../shared/domains/cards/outstanding";
import { DurationUnit, OutstandingsType } from "../../../../shared/domains/cards/outstanding";
import { useCards } from "../../../../shared/domains/cards/use-cards";
import { isDefined } from "../../../../shared/utils/assert";
import { useAsyncEffect } from "../../../../shared/utils/utils";
import { CARD_UPDATE_OUTSTANDING_MODAL_ID } from "../../../core/modal/modal-id";
import { useQueryParam } from "../../../domain/authentication/use-query-param";
import { useRTL } from "../../../domain/language/use-rtl";
import { Modal } from "../../common/modal/modal";
import { PageHeader } from "../../common/page/page-header";
import { PageSectionTitle } from "../../common/page/page-section-title";
import { Selector } from "../../common/selector";
import { MainColorSpinner } from "../../common/spinner";
import { UIConstants } from "../../styles/uiConstants";
import { AddCardItem } from "./components/card-creation/add-card-item";
import { CardUpdateOutstandingModal } from "./components/card-update-outstanding-modal";
import { OutstandingsGaugeView } from "./components/outstanding-gauge";

export function CardOutstandingsScreen() {
  const { cards, loading: cardsLoading } = useCards();
  const [card, setCard] = useState<Card>({} as Card);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const card_id = useQueryParam("card_id");
  const { formatMessage, formatDate } = useIntl();
  const screenLoading = cardsLoading;
  const [destination, setDestination] = useState<OutstandingsType>(OutstandingsType.Payments);
  const [paymentOutstandings, setPaymentOutstandings] = useState<Outstanding[]>([]);
  const [cashoutOutstandings, setCashoutOutstandings] = useState<Outstanding[]>([]);
  const [validationMsg, setValidationMsg] = useState<boolean>(false);

  const { isRTL } = useRTL();

  const outstandingToCard = (outstanding: Outstanding) => {
    const durationLabel = (nb: number, unit: DurationUnit) => {
      const durationUnit = "cardOptions.outstandings.durationUnit";
      switch (unit) {
        case DurationUnit.Hour:
          return durationUnit + (nb > 1 ? ".hours" : ".hour");
        case DurationUnit.Day:
          return durationUnit + (nb > 1 ? ".days" : ".day");
        case DurationUnit.Week:
          return durationUnit + (nb > 1 ? ".weeks" : ".week");
        case DurationUnit.Month:
          return durationUnit + (nb > 1 ? ".months" : ".month");
        case DurationUnit.Year:
          return durationUnit + (nb > 1 ? ".years" : ".year");
      }
    };
    const periodLabel = () => {
      if (outstanding.specificPeriod) {
        return formatMessage("cardOptions.outstandings.fixedPeriod", {
          startDate: formatDate(outstanding.specificPeriod.startDate),
          endDate: formatDate(outstanding.specificPeriod.endDate),
        });
      } else if (outstanding.period) {
        const unitLabel = formatMessage(durationLabel(outstanding.period.value, outstanding.period.unit));
        return formatMessage("cardOptions.outstandings.slidingPeriod", {
          num: outstanding.period.value,
          unit: unitLabel,
        });
      }
      return "";
    };

    const title = outstanding.title;
    const period = periodLabel();
    const rawPeriod = outstanding.period;
    const type = outstanding.type;
    const maxValue = outstanding.maxValue;
    const maxAmount = outstanding.maxAmount;
    const usedValue = outstanding.usedValue;
    const availableValue = outstanding.availableValue >= 0 ? outstanding.availableValue : 0;
    const percentage = usedValue / maxValue || 0;

    const operationType = formatMessage(
      isDefined(outstanding.maxAmount)
        ? "cardOptions.outstandings.maxAmount"
        : "cardOptions.outstandings.maxOperations",
    );
    let maxAmountLabel = `${maxValue}`;
    if (isDefined(outstanding.maxAmount)) {
      maxAmountLabel = i18NManager.formatAmount(outstanding.maxAmount);
    }
    let usedAmountLabel = `${usedValue}`;
    if (isDefined(outstanding.usedAmount)) {
      usedAmountLabel = i18NManager.formatAmount(outstanding.usedAmount);
    }
    let availableAmountLabel = `${availableValue}`;
    if (isDefined(outstanding.availableAmount)) {
      availableAmountLabel = i18NManager.formatAmount(outstanding.availableAmount);
    }

    return {
      id: outstanding.id,
      title,
      period,
      rawPeriod,
      percentage,
      type,
      operationType,
      maxAmountLabel,
      maxAmount,
      maxValue,
      usedAmountLabel,
      availableAmountLabel,
    };
  };

  const cardLimitToCard = (cardLimit: [string, number], currency: string) => {
    const title = cardLimit[0];
    const period = "";
    const maxValue = cardLimit[1];
    const percentage = 0;
    const operationType = formatMessage("cardOptions.outstandings.maxAmount");
    const maxAmountLabel = `${(maxValue / 100).toFixed(2)} ${Currencies[currency].symbol}`;

    return {
      id: cardLimit[0],
      title,
      period,
      type: cardLimit[0],
      percentage,
      operationType,
      maxAmountLabel,
      maxValue,
    };
  };

  useAsyncEffect(async () => {
    if (card_id) {
      try {
        const response = await cardManager.retrieveCard(card_id);
        setCard(response);
      } catch (e) {
        console.error(e);
      }
    }
  }, [card_id]);

  const getOutstandings = () => {
    try {
      const url = getUrlFromLink(card.links, CardLinks.GetOutstandings);
      cardManager
        .getOutstandingsCard(card.id, url)
        .then((response) => {
          // LOGIC : If no ongoing outstandings, the API returns []. So we must at this point rely on the card's limit to display the gauges.
          if (response && response.length > 0) {
            setupOutstandingsFromOutstandings(response);
          } else {
            setupOutstandingsFromCardLimits();
          }
        })
        .catch((err) => console.error(err));
    } catch (e) {
      console.error(e);
    }
  };

  const setupOutstandingsFromCardLimits = () => {
    if (card.limits) {
      const paymentsLimitsRaw = Object.entries(card.limits).filter((e) => e[0].startsWith("LIMIT_PAYMENT"));
      const cashoutsLimitsRaw = Object.entries(card.limits).filter((e) => e[0].startsWith("LIMIT_CASHOUT"));
      const c =
        paymentsLimitsRaw.length > 0
          ? paymentsLimitsRaw.map((cardLimit) => cardLimitToCard(cardLimit, card.currency))
          : [];
      const d =
        cashoutsLimitsRaw.length > 0
          ? cashoutsLimitsRaw.map((cardLimit) => cardLimitToCard(cardLimit, card.currency))
          : [];

      setPaymentOutstandings(c);
      setCashoutOutstandings(d);
    } else {
      setPaymentOutstandings([]);
      setCashoutOutstandings([]);
    }
    setIsLoading(false);
  };
  const setupOutstandingsFromOutstandings = (outstandings) => {
    const paymentsRaw = outstandings.filter((e) => e.type.startsWith("LIMIT_PAYMENT")).filter((e) => e.maxValue > 0);
    const cashoutsRaw = outstandings.filter((e) => e.type.startsWith("LIMIT_CASHOUT")).filter((e) => e.maxValue > 0);
    const a = paymentsRaw.length > 0 ? paymentsRaw.map((outstanding) => outstandingToCard(outstanding)) : [];
    const b = cashoutsRaw.length > 0 ? cashoutsRaw.map((outstanding) => outstandingToCard(outstanding)) : [];

    const paymentsOutstandingsOrder = [
      "LIMIT_PAYMENT_DAY",
      "LIMIT_PAYMENT_WEEK",
      "LIMIT_PAYMENT_MONTH",
      "LIMIT_PAYMENT_YEAR",
      "LIMIT_PAYMENT_ALL",
    ];
    const cashoutsOutstandingsOrder = [
      "LIMIT_CASHOUT_DAY",
      "LIMIT_CASHOUT_WEEK",
      "LIMIT_CASHOUT_MONTH",
      "LIMIT_CASHOUT_YEAR",
      "LIMIT_CASHOUT_ALL",
    ];
    a.sort((c, d) => paymentsOutstandingsOrder.indexOf(c.type) - paymentsOutstandingsOrder.indexOf(d.type));
    b.sort((c, d) => cashoutsOutstandingsOrder.indexOf(c.type) - cashoutsOutstandingsOrder.indexOf(d.type));

    setPaymentOutstandings(a);
    setCashoutOutstandings(b);
    setIsLoading(false);
  };

  useAsyncEffect(async () => {
    if (card.id) {
      getOutstandings();
    }
  }, [card]);

  const handleRefresh = () => {
    setValidationMsg(true);
    setIsLoading(true);
    getOutstandings();
  };

  const handleSetDestination = (value: OutstandingsType) => {
    setDestination(value);
  };

  const updateOutstandingLimit = (outstanding) => {
    Modal.present(
      CARD_UPDATE_OUTSTANDING_MODAL_ID,
      () => <CardUpdateOutstandingModal card={card} outstanding={outstanding} onSuccess={() => handleRefresh()} />,
      {
        canBeDismissed: false,
      },
    );
  };

  const canUpdateOutstandings = () => {
    const url = getUrlFromLink(card.links, CardLinks.UpdateLimits);
    return url !== undefined;
  };

  return (
    <CardsSection>
      <StyledPageHeader>
        <CardsSectionTitle $isRTL={isRTL}>{formatMessage("cardOptions.outstandings.title")}</CardsSectionTitle>
      </StyledPageHeader>
      {isLoading ? (
        <StyledBlueSpinner key="spinner" />
      ) : (
        <>
          {!screenLoading && cards.length === 0 ? (
            <AddCardItem />
          ) : (
            <>
              <SelectorContainer>
                <Selector
                  size={"L"}
                  options={[
                    {
                      value: OutstandingsType.Payments,
                      text: formatMessage("cardOptions.outstandings.selector.payments"),
                      key: "payments",
                    },
                    {
                      value: OutstandingsType.Cashouts,
                      text: formatMessage("cardOptions.outstandings.selector.cashouts"),
                      key: "cashouts",
                    },
                  ]}
                  value={destination}
                  onChange={handleSetDestination}
                />
              </SelectorContainer>
              {isLoading ? (
                <StyledBlueSpinner key="spinner" />
              ) : (
                <>
                  {destination === OutstandingsType.Payments && paymentOutstandings && (
                    <StyledGaugeContainer>
                      {paymentOutstandings.length === 0 && (
                        <NoOutstandingsLabel>
                          {formatMessage("cardOptions.outstandings.noOutstandingLabel")}
                        </NoOutstandingsLabel>
                      )}
                      {paymentOutstandings.map((e) => (
                        <OutstandingsGaugeView
                          outstanding={e}
                          key={e.id}
                          onUpdateClick={(o) => updateOutstandingLimit(o)}
                          canUpdate={canUpdateOutstandings()}
                        />
                      ))}
                    </StyledGaugeContainer>
                  )}
                  {destination === OutstandingsType.Cashouts && (
                    <StyledGaugeContainer>
                      {cashoutOutstandings.length === 0 && (
                        <NoOutstandingsLabel>
                          {formatMessage("cardOptions.outstandings.noOutstandingLabel")}
                        </NoOutstandingsLabel>
                      )}
                      {cashoutOutstandings.map((e) => (
                        <OutstandingsGaugeView
                          outstanding={e}
                          key={e.id}
                          onUpdateClick={(o) => updateOutstandingLimit(o)}
                          canUpdate={canUpdateOutstandings()}
                        />
                      ))}
                    </StyledGaugeContainer>
                  )}
                </>
              )}
            </>
          )}
        </>
      )}
    </CardsSection>
  );
}

const NoOutstandingsLabel = styled.p``;

const CardsSection = styled.section`
  align-self: stretch;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const StyledPageHeader = styled(PageHeader)`
  margin-bottom: 10px;
`;

const CardsSectionTitle = styled(PageSectionTitle)<{ $isRTL: boolean }>`
  margin-right: ${(props) => (props.$isRTL ? 0 : 10)}px;
  margin-left: ${(props) => (props.$isRTL ? 10 : 0)}px;
`;

const SelectorContainer = styled.div`
  margin-top: 8px;
  margin-bottom: 32px;
  @media (max-width: ${UIConstants.TABLET_BREAKPOINT}px) {
    margin-bottom: 32px;
  }
`;

const StyledGaugeContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
`;

const StyledBlueSpinner = styled(MainColorSpinner)`
  margin: 40px auto 0 auto;
`;
