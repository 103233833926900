import { useCallback, useMemo } from "react";

import { authorizationHoldManager } from "../../core/service/services";
import { useObservable } from "../../utils/observable";
import { useAsyncEffect } from "../../utils/utils";
import type { Account } from "../account/account";
import type { AuthorizationHold } from "./authorization-hold";
import type { AuthorizationHoldLoader } from "./authorization-hold-loader";

export interface AuthorizationHolds {
  data: AuthorizationHold[];
  loading: boolean;
  error: Error | null;
  canLoadMore: boolean;
  loadMore: () => void;
  loadingMore: boolean;
  loadingMoreError: Error | null;
  refresh: () => void;
  refreshing: boolean;
}

export function useAuthorizationHolds(account: Account | null): AuthorizationHolds {
  const accountId = useMemo(() => (account !== null ? account.id : null), [account]);

  const loader: AuthorizationHoldLoader = useMemo(() => {
    if (!accountId) {
      return authorizationHoldManager.getDefaultLoader();
    }
    return authorizationHoldManager.getTransactionsLoader(accountId);
  }, [accountId]);

  const data = useObservable(loader.data);
  const initializing = useObservable(loader.initializing);
  const loading = useObservable(loader.loading);
  const refreshing = useObservable(loader.refreshing);
  const error = useObservable(loader.error);
  const loadingMore = useObservable(loader.loadingMore);
  const loadingMoreError = useObservable(loader.loadingMoreError);

  useAsyncEffect(async () => {
    await loader.load();
  }, [loader]);

  const refresh = useCallback(async () => {
    await loader.refresh();
  }, [loader]);

  const canLoadMore = useMemo(() => data.offset + data.limit < data.total, [data.offset, data.limit, data.total]);

  const loadMore = useCallback(async () => {
    await loader.loadMore();
  }, [loader]);

  return {
    data: data.items,
    loading: initializing || loading,
    error,
    canLoadMore,
    loadMore,
    loadingMore,
    loadingMoreError,
    refresh,
    refreshing,
  };
}
