import sodium from "libsodium-wrappers";
import { Signal } from "micro-signals";

import { logger } from "../../../shared/core/logging/logger";
import type { Card } from "../../../shared/domains/cards/card";
import type { CardStatus } from "../../../shared/domains/cards/card-filter";
import type { CardService, OverrideCardData } from "../../../shared/domains/cards/card-service";
import type { Outstanding } from "../../../shared/domains/cards/outstanding";
import { Observable } from "../../../shared/utils/observable";
import { ObservableArray } from "../../../shared/utils/observable-array";

const MOCKED_CARD_DETAILS = {
  panDisplay: "1234 5678 9012 3456",
  cvv: "123",
  expirationDate: new Date(2023, 11).toISOString(),
};

export class CardDetailsManager {
  public cards = new ObservableArray<Card>([]);
  public outstandings = new ObservableArray<Outstanding>([]);
  public publicKey = new Observable<Uint8Array | null>(null);
  public privateKey = new Observable<Uint8Array | null>(null);

  public readonly onCardStatusWillChange = new Signal<CardStatus | undefined>();

  public constructor(private cardService: CardService) {
    sodium.ready;
  }

  public async getSensitiveCardDetails(cardId: string, scaToken?: string): Promise<OverrideCardData> {
    if (!this.publicKey.get() || !this.privateKey.get()) {
      const keyPair = sodium.crypto_box_keypair();
      this.publicKey.set(keyPair.publicKey);
      this.privateKey.set(keyPair.privateKey);
    }
    const publicKey = this.publicKey.get();
    const privateKey = this.privateKey.get();
    if (publicKey && privateKey) {
      try {
        const response = await this.cardService.getSensitiveCardDetails(
          cardId,
          sodium.to_hex(publicKey),
          scaToken || "",
        );

        let content: Uint8Array | undefined;

        const decodedPan = response.encryptedPan
          ? sodium.crypto_box_seal_open(sodium.from_hex(response.encryptedPan), publicKey, privateKey)
          : null;
        const decodedCvv = response.encryptedCvv
          ? sodium.crypto_box_seal_open(sodium.from_hex(response.encryptedCvv), publicKey, privateKey)
          : null;

        try {
          content = response.content
            ? await sodium.crypto_box_seal_open(sodium.from_base64(response.content), publicKey, privateKey)
            : undefined;
        } catch (e) {
          logger.debug("CardManager", "Failed to decrypt card image", e);
        }

        return {
          panDisplay: decodedPan ? sodium.to_string(decodedPan) : undefined,
          cvv: decodedCvv ? sodium.to_string(decodedCvv) : undefined,
          base64Image: content ? sodium.to_base64(content) : response.content,
          expirationDate:
            response.expirationYear && response.expirationMonth
              ? new Date(response.expirationYear, Number(response.expirationMonth)).toISOString()
              : undefined,
        };
      } catch (e) {
        if (e.response?.data?.error?.code === 500) {
          return MOCKED_CARD_DETAILS;
        } else {
          logger.debug("CardManager", "Failed to get sensitive card details", e);
          throw e;
        }
      }
    } else {
      throw new Error("Keys not generated");
    }
  }
}
