import type { SVGProps } from "react";
import React from "react";

export function FileDonwloadIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg {...props} width="37" height="41" viewBox="0 0 37 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.125001 4.08331L0.125 36.9166C0.125 39.1258 1.91586 40.9166 4.125 40.9166H18.8848C18.9268 40.9166 18.9686 40.916 19.0104 40.9147V32.75C19.0104 27.394 23.3523 23.0521 28.7083 23.0521H36.873C36.8743 23.0103 36.875 22.9684 36.875 22.9265V4.08331C36.875 1.87417 35.0841 0.0833144 32.875 0.0833142L4.125 0.083313C1.91586 0.083313 0.125002 1.87417 0.125001 4.08331ZM28.7083 26.1146H35.3438L22.0729 39.3854V32.75C22.0729 29.0853 25.0437 26.1146 28.7083 26.1146ZM20.3046 16.53C20.2179 16.6167 20.1265 16.6958 20.0312 16.7675L20.0313 8.2501C20.0313 7.40441 19.3457 6.71885 18.5 6.71885C17.6543 6.71885 16.9688 7.40441 16.9688 8.2501V16.7675C16.8735 16.6958 16.7821 16.6167 16.6954 16.53L13.4578 13.2923C12.8598 12.6944 11.8902 12.6944 11.2922 13.2923C10.6943 13.8903 10.6943 14.8599 11.2922 15.4579L14.5299 18.6955C16.7225 20.8881 20.2775 20.8881 22.4701 18.6955L25.7078 15.4579C26.3057 14.8599 26.3057 13.8903 25.7078 13.2923C25.1098 12.6944 24.1402 12.6944 23.5422 13.2923L20.3046 16.53Z"
        fill="#B1B1B1"
      />
    </svg>
  );
}
