import React, { useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import styled from "styled-components";

import { useIntl } from "../../../../shared/core/i18n/use-intl";
import { configurationManager, featuresManager } from "../../../../shared/core/service/services";
import { useObservable } from "../../../../shared/utils/observable";
import { anonymizePhoneNumber } from "../../../../shared/utils/phone-number";
import { Path } from "../../../core/routing/path";
import { useQueryParam } from "../../../domain/authentication/use-query-param";
import { useRTL } from "../../../domain/language/use-rtl";
import { ConnectState, useConnectMachine } from "../../../machine/connect-machine";
import { PincodeState } from "../../../machine/keyboard-machine-type";
import { DefaultButton } from "../../common/buttons/default-button";
import { PrimaryButton } from "../../common/buttons/primary-button";
import { BankContact } from "../../common/contact/bank-contact-view";
import { ExternalSites } from "../../common/external-sites-view";
import { MarkdownText } from "../../common/forms/markdown-text";
import { PincodeKeyboard } from "../../common/keyboard/pincode-keyboard";
import { ResponsiveContainer } from "../../common/responsive-container";
import { StoreButtons } from "../../common/store-buttons-view";
import { StaticAssets } from "../../styles/custom-assets";
import { shadows, theme } from "../../styles/theme";
import { UIConstants } from "../../styles/uiConstants";
import { VersionBox } from "../login/components/version-box";
import { OtpConfirm } from "../recipient/otp-confirm";
import { ConnectContactSupport } from "./components/connect-contact-support";
import { ConnectInputNumber } from "./components/connect-input-number";

const centeredStates = [
  PincodeState.PromptingKeyboard,
  PincodeState.FetchKeyboardAfterError,
  ConnectState.ValidatePincode,
  ConnectState.OTPSubmission,
  ConnectState.OTPConfirming,
];

export const IDX_QUERY_PARAM = "idx";

export const ConnectScreen = () => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const features = useObservable(featuresManager.features);
  const phoneParam = useQueryParam("phone");
  const defaultCountry = useObservable(configurationManager.defaultCountry);
  const [countryCode, setCountryCode] = useState(defaultCountry);
  const [phoneNumber, setPhoneNumber] = useState(phoneParam || "");

  const { state, context, showContactSupport, phoneLoading, phoneError, checkPhoneNumber, confirmOtp, submitPinCode } =
    useConnectMachine();

  const goToRoot = () => {
    history.replace(Path.Root);
  };

  const errorMessage = context.errorMessage;
  const { isRTL } = useRTL();

  const goToRegisterPage = () => {
    const path = features.sdaCustomerOnboardingCreate ? Path.Onboarding : Path.Register;
    history.push(path);
  };

  const renderInnerStep = () => {
    switch (state) {
      case ConnectState.WaitingForPhoneNumber:
      case PincodeState.PincodeConfirmation:
        return (
          <>
            <ConnectInputNumber
              onLostPhoneClick={showContactSupport}
              error={phoneError}
              onUpdatePhone={setPhoneNumber}
              onUpdateCountryCode={setCountryCode}
              currentCountryCode={countryCode}
              initialValue={phoneNumber}
            />

            <BottomContainer>
              <SubmitButton
                size="M"
                onClick={() => checkPhoneNumber(phoneNumber, countryCode)}
                showSpinner={phoneLoading}
              >
                {formatMessage("connectScreen.connectButton")}
              </SubmitButton>
              <RowContainer withLargeTopOffset={true}>
                <RowQuestion>{formatMessage("connectScreen.registerQuestion")}</RowQuestion>
                <RowLink onClick={goToRegisterPage}>{formatMessage("connectScreen.registerButton")}</RowLink>
              </RowContainer>
            </BottomContainer>
          </>
        );

      case ConnectState.ContactSupport:
        return (
          <>
            <ConnectContactSupport />
            <BottomContainer>
              <SubmitButton size="M" onClick={() => goToRoot()}>
                {formatMessage("connectScreen.contactSupport.done")}
              </SubmitButton>
            </BottomContainer>
          </>
        );

      case PincodeState.PromptingKeyboard:
      case PincodeState.FetchKeyboardAfterError:
      case ConnectState.ValidatePincode: {
        const keyboard = context.keyboard;
        return (
          <>
            <CenteredTitle>{formatMessage("connectScreen.pincodeTitle")}</CenteredTitle>
            <PincodeKeyboard
              keyboard={keyboard}
              onSubmit={(pincode) => submitPinCode(pincode)}
              title={formatMessage("pincodeConfirmation.label")}
              loadingKeyboard={state === PincodeState.FetchKeyboardAfterError}
              errorMessage={errorMessage}
              keyColor={"white"}
            />
          </>
        );
      }

      case ConnectState.OTPSubmission:
      case ConnectState.OTPConfirming: {
        return (
          <>
            <CenteredTitle>{formatMessage("connectScreen.otpTitle")}</CenteredTitle>
            <OtpConfirm
              submitOtp={confirmOtp}
              phoneNumber={context.phone ? anonymizePhoneNumber(context.phone, 2) : undefined}
              errorMessage={errorMessage}
              explanationColor={"#b1b1b1"}
            />
            <BottomContainer>
              <RowContainer withLargeTopOffset={false}>
                <RowQuestion>{formatMessage("connectScreen.noCodeQuestion")}</RowQuestion>
                <RowButton onClick={showContactSupport}>{formatMessage("connectScreen.contactSupportLink")}</RowButton>
              </RowContainer>
            </BottomContainer>
          </>
        );
      }

      case ConnectState.Connected:
        return <Redirect to={{ pathname: Path.AccountTransactions }} />;
    }
  };

  return (
    <Page>
      <MainRow $isRTL={isRTL}>
        <LeftPanel>
          <LeftImage src={StaticAssets.registerImage} />
        </LeftPanel>
        <CenterPanel isCentered={centeredStates.includes(state)} $isRTL={isRTL}>
          <Logo src={StaticAssets.logo} onClick={goToRoot} />
          {renderInnerStep()}
          <Spacer />
        </CenterPanel>
        <RightPanel $isRTL={isRTL}>
          {state == ConnectState.WaitingForPhoneNumber && (
            <>
              <TopRightContainer>
                <ExternalSites />
                <StoreButtons showIsAvailable smallVersion={true} />
              </TopRightContainer>
              <Spacer />
              <HelpContainer>
                <BankContact />
              </HelpContainer>
            </>
          )}
        </RightPanel>
      </MainRow>
      <BottomRow $isRTL={isRTL}>
        <VersionBox />
      </BottomRow>
    </Page>
  );
};

const Page = styled(ResponsiveContainer)`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  padding: 0;
  margin: 0;
`;

const MainRow = styled.div<{ $isRTL: boolean }>`
  position: relative;
  display: flex;
  flex-direction: row;
  flex: 1 0 auto;
  overflow: hidden;
  width: auto;
  margin-left: ${(props) => (props.$isRTL ? 0 : 20)}px;
  margin-right: ${(props) => (props.$isRTL ? 20 : 0)}px;
  padding: 0 20px;
  justify-content: stretch;

  @media (max-width: ${UIConstants.TABLET_BREAKPOINT}px) {
    flex-direction: column;
    align-items: stretch;

    margin-left: 10px;
    margin-right: 10px;
    padding-top: 0px;
    margin-top: 0px;
  }
`;

const BottomRow = styled.div<{ $isRTL: boolean }>`
  position: relative;
  display: flex;
  flex-direction: row;
  flex: 0 0 auto;
  margin: 0;
  padding: 0 42px 10px 42px;
  @media (max-width: ${UIConstants.TABLET_BREAKPOINT}px) {
    padding: 0 30px 10px 30px;
  }
`;

const LeftPanel = styled.div`
  justify-content: center;
  flex-direction: column;
  padding: 0;
  margin: 0;
  width: 40%;
  width: 600px;
  max-height: 754px;
  padding: 30px 0 10px 0;
  @media (max-width: ${UIConstants.TABLET_BREAKPOINT}px) {
    max-width: 0;
  }
`;

const CenterPanel = styled.div<{ isCentered?: boolean; $isRTL: boolean }>`
  display: flex;
  flex-direction: column;
  max-width: 660px;
  width: 660px;
  padding: ${(props) =>
    props.isCentered ? "100px 40px 10px 40px" : props.$isRTL ? "100px 100px 10px 50px" : "100px 50px 10px 100px"};
  margin: 10px 0;
  align-items: ${(props) => (props.isCentered ? "center" : "flex-start")};

  @media (max-width: ${UIConstants.TABLET_BREAKPOINT}px) {
    padding: 0 0 20px 0;
    margin: -20px 0 0 0;
    max-width: 414px;
    width: 100%;
    justify-content: flex-start;
  }
`;

const RightPanel = styled.div<{ $isRTL: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 10px 0;
  align-items: flex-end;
  padding: 30px 10px 0 10px;
  @media (max-width: ${UIConstants.TABLET_BREAKPOINT}px) {
    align-items: flex-start;
    max-width: 414px;
    width: auto;
    padding: 30px 0 20px 0;
  }
`;

const Spacer = styled.div`
  flex-grow: 1;
`;

const LeftImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 21px;
  ${shadows.medium};
  @media (max-width: ${UIConstants.TABLET_BREAKPOINT}px) {
    width: 0;
    opacity: 0;
  }
`;

const Logo = styled.img`
  width: 112px;
  height: 112px;
  object-fit: contain;
  margin-top: 0;
  margin-bottom: 8px;
  :hover {
    cursor: pointer;
  }

  @media (max-width: ${UIConstants.TABLET_BREAKPOINT}px) {
    margin-bottom: 0;
  }
`;

const BottomContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const SubmitButton = styled(PrimaryButton)``;

const RowContainer = styled.div<{ withLargeTopOffset: boolean }>`
  display: flex;
  flex-direction: row;
  margin-top: ${(props) => (props.withLargeTopOffset ? 48 : 12)}px;
  white-space: pre;
  align-items: baseline;
`;

const RowQuestion = styled.span`
  ${theme.mediumText}
  font-size: 0.8125rem;
  color: #b1b1b1;
`;

const RowLink = styled(DefaultButton)`
  ${theme.mediumText}
  font-size: 0.8125rem;
  color: black;
  text-decoration: underline;
  margin-right: 4px;
  margin-left: 4px;
`;

const RowButton = styled(DefaultButton)`
  ${theme.mediumText}
  font-size: 0.8125rem;
  color: black;
  text-decoration: underline;
`;

const CenteredTitle = styled(MarkdownText)`
  ${theme.boldText}
  font-size: 1.875rem;
  margin-bottom: 24px;
  text-align: center;
`;

const TopRightContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 0.875rem;
`;

const HelpContainer = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-direction: row;
  align-items: center;
  gap: 24px;
  @media (max-width: ${UIConstants.TABLET_BREAKPOINT}px) {
    gap: 12px;
    margin-top: 40px;
  }
`;
