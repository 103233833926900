import React from "react";

import type { FormTextInput } from "../../../../shared/core/data-forms/form-input-types";
import { useIntl } from "../../../../shared/core/i18n/use-intl";
import { escapeHtmlChars } from "../../../../shared/utils/string";
import { TextInput } from "../forms/text-input";
import { DisableValueText } from "./disable-value-text";

export interface TextFieldProps {
  input: FormTextInput;
  hasError?: boolean;
  errorMessage?: string | null;
  onChange?: (value: string) => void;
  disabled?: boolean;
  placeholder?: string;
  innerStyle?: React.CSSProperties;
  multiline?: boolean;
  onKeyDown?;
  onBlur?;
}

export const TextField: React.FC<TextFieldProps> = ({
  input,
  disabled,
  hasError = false,
  errorMessage,
  onChange,
  placeholder,
  innerStyle,
  multiline,
  onKeyDown,
  onBlur,
}) => {
  const { formatMessage } = useIntl();

  const handleOnChange = (value: string) => {
    const text = escapeHtmlChars(value);
    onChange?.(text);
  };

  return input.disabled || disabled ? (
    <DisableValueText>{input.value}</DisableValueText>
  ) : (
    <TextInput
      required={input.required}
      value={input.value ?? ""}
      onChange={(e) => handleOnChange(e.target.value)}
      placeholder={placeholder ? placeholder : formatMessage("billFormScreen.textInputPlaceholder")}
      hasError={!!hasError}
      errorMessage={errorMessage}
      style={innerStyle}
      multiline={multiline}
      onKeyDown={(e) => onKeyDown?.(e)}
      onBlur={onBlur}
    />
  );
};
