"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var extended_signal_1 = require("./extended-signal");
var Signal = /** @class */ (function (_super) {
    __extends(Signal, _super);
    function Signal() {
        var _this = _super.call(this, {
            add: function (listener) {
                _this._listeners.add(listener);
            },
            remove: function (listener) {
                _this._listeners.delete(listener);
            },
        }) || this;
        _this._listeners = new Set();
        _this._instanceDefaultListener = function (payload) { return Signal._staticDefaultListener(payload); };
        return _this;
    }
    Signal.setDefaultListener = function (listener) {
        Signal._staticDefaultListener = listener;
    };
    Signal.prototype.dispatch = function (payload) {
        if (this._listeners.size === 0) {
            var instanceDefaultListener = this._instanceDefaultListener;
            instanceDefaultListener(payload);
            return;
        }
        this._listeners.forEach(function (callback) { return callback.call(undefined, payload); });
    };
    Signal.prototype.setDefaultListener = function (listener) {
        this._instanceDefaultListener = listener;
    };
    Signal._staticDefaultListener = function () {
        // default static listener is a noop
    };
    return Signal;
}(extended_signal_1.ExtendedSignal));
exports.Signal = Signal;
