import type { SVGProps } from "react";
import React from "react";

export const WaitingIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="42" height="42" viewBox="0 0 42 42" {...props}>
    <g fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.75 21.752c-.043.089-.085.179-.13.266-.277.53-.781.754-1.198.71-.715-.077-1.02-.492-1.178-.97-.013-.038-.013-.081-.014-.122-.005-.391-.026-.784-.008-1.174a1.233 1.233 0 0 1 1.07-1.173 1.273 1.273 0 0 1 1.355.788c.033.084.069.167.103.25v1.425zM29.776 7.93c.297-.43.587-.854.881-1.276.07-.1.143-.203.23-.288.192-.183.406-.15.534.08.044.079.074.169.098.257.376 1.356.738 2.715 1.13 4.066.13.447-.114.967-.721 1.009-.622.043-1.242.117-1.864.171-.57.05-1.142.092-1.713.14-.091.007-.182.025-.274.032-.173.011-.348.032-.52.024-.3-.014-.424-.211-.31-.487.046-.113.12-.215.19-.317.286-.423.576-.845.877-1.283-.318-.196-.616-.395-.93-.567a13.017 13.017 0 0 0-3.842-1.407 13.22 13.22 0 0 0-2.008-.229c-.534-.02-1.066-.01-1.595.026-.6.041-1.195.142-1.785.272a13.05 13.05 0 0 0-4.64 2.022 13.177 13.177 0 0 0-3.14 3.066 13.118 13.118 0 0 0-2.556 7.994c.012.755.067 1.503.202 2.243.423 2.314 1.362 4.392 2.868 6.198a13.215 13.215 0 0 0 4.028 3.244c1.168.617 2.402 1.027 3.691 1.286.553.11.922.43 1.065.985.192.743-.389 1.515-1.132 1.555-.365.02-.71-.082-1.056-.16a15.265 15.265 0 0 1-5.435-2.415 15.935 15.935 0 0 1-3.531-3.373 15.704 15.704 0 0 1-2.64-5.26 17.29 17.29 0 0 1-.51-2.503 15.326 15.326 0 0 1-.106-2.561c.025-.699.104-1.394.223-2.086.151-.873.371-1.728.667-2.56.354-1 .81-1.954 1.35-2.87.6-1.017 1.326-1.93 2.126-2.791a15.306 15.306 0 0 1 3.721-2.91 15.376 15.376 0 0 1 3.277-1.364c.865-.253 1.745-.43 2.638-.54a14.582 14.582 0 0 1 2.823-.063c2.229.158 4.34.76 6.31 1.83.458.25.901.528 1.38.81z"
        fill={props.color ?? "#81C241"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.247 16.243c0 1.456.004 2.911-.005 4.367 0 .192.058.288.227.376 1.72.887 3.435 1.784 5.152 2.678.917.478 1.834.957 2.752 1.433.314.163.546.39.635.75.13.523-.232 1.115-.758 1.232-.307.068-.583-.008-.853-.15-.807-.422-1.616-.842-2.424-1.263l-5.368-2.794c-.409-.213-.817-.428-1.228-.635-.124-.062-.202-.134-.178-.284.008-.05.002-.103.002-.154v-9.663c0-.103-.002-.207.004-.31.035-.582.493-1.033 1.03-1.015.575.019 1.01.472 1.011 1.066.003 1.455.001 2.91.001 4.366zM36.03 16.564a1.313 1.313 0 0 1-.852 1.185c-.581.213-1.142-.045-1.432-.416-.208-.266-.55-1.304-.568-1.644-.023-.437.347-1.04.895-1.198.608-.175 1.16.101 1.408.485.055.086.112.174.148.269.113.297.222.596.32.899.043.135.055.28.082.42zM34.537 27.581c-.897.014-1.466-.902-1.23-1.598.12-.35.226-.704.369-1.044.223-.53.725-.781 1.2-.747.655.046 1.016.434 1.169.935.072.237.064.49-.013.732-.094.293-.188.587-.296.875-.206.552-.722.874-1.199.847z"
        fill={props.color ?? "#81C241"}
      />
      <mask id="f1zog4fmla" maskUnits="userSpaceOnUse" x="22" y="34" width="4" height="3">
        <path d="M22.35 34.013h3.415v2.737H22.35v-2.737z" fill="#fff" />
      </mask>
      <g mask="url(#f1zog4fmla)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M25.765 35.267c.001.522-.306 1.084-1.023 1.268-.287.073-.576.137-.866.192-.675.128-1.312-.294-1.488-.978-.162-.627.22-1.31.868-1.496.39-.111.793-.204 1.196-.236.581-.047 1.053.306 1.236.86.042.124.052.26.077.39z"
          fill={props.color ?? "#81C241"}
        />
      </g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.753 30.597c0-.27.065-.518.224-.729.229-.303.462-.604.714-.887.363-.405.95-.452 1.387-.274.464.188.785.692.776 1.194a1.176 1.176 0 0 1-.222.694c-.221.296-.441.596-.695.863-.668.703-1.792.448-2.108-.47-.043-.125-.051-.26-.076-.391zM28.272 35.046c-.45.01-.98-.3-1.183-.772-.213-.49-.13-1.015.237-1.398.056-.06.114-.12.183-.164.302-.192.6-.392.913-.563.652-.355 1.473-.034 1.753.662.217.537.035 1.133-.347 1.427a8.028 8.028 0 0 1-1.01.64c-.163.09-.362.114-.546.168z"
        fill={props.color ?? "#81C241"}
      />
    </g>
  </svg>
);
