import React from "react";

import { clientManager } from "../../../../shared/core/service/services";
import { PINCODE_MUST_BE_CHANGED_MODAL_ID } from "../../../core/modal/modal-id";
import { Modal } from "../../common/modal/modal";
import { PincodeChangeModal } from "./pincode-change-modal";

export type Task<T> = () => T | (() => Promise<T>);
export function performTaskIfPossible<T>(task: Task<T>) {
  return () => {
    const client = clientManager.client.get();
    if (client?.mustChangePincode) {
      Modal.present(PINCODE_MUST_BE_CHANGED_MODAL_ID, () => <PincodeChangeModal type="operation-requirement" />, {
        canBeDismissed: false,
      });
    } else {
      task();
    }
  };
}
