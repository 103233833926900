import type { SVGProps } from "react";
import React from "react";

export function ResetIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 19 24" {...props}>
      <path
        fillRule="nonzero"
        d="M0 9.5v1.188h2.375V9.5c0-3.93 3.196-7.125 7.125-7.125 3.93 0 7.125 3.196 7.125 7.125 0 3.93-3.196 7.125-7.125 7.125H8.312v-4.75l-5.937 5.938 5.938 5.937V19H9.5c5.238 0 9.5-4.262 9.5-9.5S14.738 0 9.5 0 0 4.262 0 9.5z"
      />
    </svg>
  );
}
