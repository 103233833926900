import type { GeoPosition } from "react-native-geolocation-service";

import type { TransactionGeolocationManager } from "../../../shared/core/geolocalisation/transaction-geolocalisation-manager";

export class WebTransactionGeolocalisationManager implements TransactionGeolocationManager {
  initialize(): Promise<void> {
    return Promise.resolve();
  }
  requestActivation(): Promise<void> {
    return Promise.resolve();
  }
  updatePosition(): Promise<GeoPosition | null | undefined> {
    return Promise.resolve(undefined);
  }
  getPosition(): GeoPosition | null | undefined {
    return undefined;
  }
}
