import type { InputHTMLAttributes } from "react";
import React from "react";
import styled from "styled-components";

import { useRTL } from "../../../domain/language/use-rtl";
import { theme } from "../../styles/theme";
import { TextInput } from "./text-input";

interface TextInputProps extends InputHTMLAttributes<HTMLInputElement> {
  prefixElement?: React.ReactNode;
  hasError?: boolean;
  errorMessage?: string;
  bottomOffset?: number;
}

export const PrefixedTextInput = (props: TextInputProps) => {
  const { prefixElement, className, bottomOffset, ...rest } = props;
  const { isRTL } = useRTL();

  return (
    <Row className={className} dir={"ltr"}>
      <PrefixContainer $isRTL={isRTL}>{prefixElement}</PrefixContainer>
      <StyledTextInput {...rest} bottomOffset={bottomOffset} />
    </Row>
  );
};
const Row = styled.div`
  display: flex;
  flex-direction: row;
`;
const StyledTextInput = styled(TextInput)`
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  width: 100%;
`;

export const PrefixContainer = styled.div<{ $isRTL: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  background-color: #ffffff;
  width: 60px;
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    bottom: 0;
    right: ${(props) => (props.$isRTL ? "auto" : 0)};
    left: ${(props) => (props.$isRTL ? 0 : "auto")};
    width: 1px;
    height: calc(100% - 10px);
    background-color: ${theme.colors.gray[500]};
    transform: translateY(-50%);
  }
`;
