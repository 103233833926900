import type { SVGProps } from "react";
import React from "react";

export function ErrorIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="189" height="189" viewBox="0 0 189 189" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="94.5" cy="94.5" r="72.6075" stroke="#FF9A03" strokeWidth="6" />
      <circle cx="94.5" cy="94.5" r="92.5" stroke="#FF9A03" strokeWidth="3" />
      <path
        d="M89.3661 110.533L88.0553 54.0741H100.508L99.197 110.533H89.3661ZM94.6092 134.926C92.3518 134.926 90.4948 134.201 89.0384 132.75C87.6548 131.223 86.963 129.429 86.963 127.368C86.963 125.23 87.6548 123.436 89.0384 121.985C90.4948 120.458 92.3518 119.695 94.6092 119.695C96.7211 119.695 98.4688 120.458 99.8524 121.985C101.309 123.436 102.037 125.23 102.037 127.368C102.037 129.429 101.309 131.223 99.8524 132.75C98.4688 134.201 96.7211 134.926 94.6092 134.926Z"
        fill="#FF9A03"
      />
    </svg>
  );
}
