import type { SVGProps } from "react";
import React from "react";

export function PenIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 26 26" {...props}>
      <path
        fillRule="nonzero"
        d="M17.82 10.986c.19.194.19.504 0 .699l-8.042 8.073c-.193.195-.424.348-.679.45l-4.33 1.756c-.185.074-.397.031-.538-.11-.207-.132-.287-.394-.19-.619l1.716-4.331c.102-.255.254-.486.45-.679l8.05-8.053c.094-.095.222-.148.355-.148.133 0 .26.053.354.148l2.853 2.814zm3.74-5.888l-.658-.659c-.28-.28-.66-.439-1.057-.439h-.758c-.404-.005-.793.153-1.078.44l-1.806 1.825c-.19.194-.19.505 0 .699l2.804 2.844c.093.095.22.148.354.148.133 0 .26-.053.354-.148l1.846-1.816c.286-.285.444-.674.439-1.078v-.758c0-.397-.158-.778-.44-1.058z"
      />
    </svg>
  );
}
