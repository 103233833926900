import { logger } from "../../../core/logging/logger";
import type { ConnectedApiService } from "../../../core/net/connected-api-service";
import type { BaseSpendingCategory, SpendingAdditionalData } from "./categorization";

export class CategorizationsService {
  public constructor(private apiService: ConnectedApiService) {}

  public async getCategories(): Promise<BaseSpendingCategory[]> {
    try {
      const response = await this.apiService.instance.get<BaseSpendingCategory[]>(
        "/spendings-categorization/categories",
      );
      return response.data;
    } catch (e) {
      logger.debug("SpendingsService", "Get categories failed", e);
      throw e;
    }
  }

  public async getAdditionalData(): Promise<SpendingAdditionalData[]> {
    try {
      const response = await this.apiService.instance.get<SpendingAdditionalData[]>(
        "/spendings-categorization/available-additional-data",
      );
      return response.data;
    } catch (e) {
      logger.debug("SpendingsService", "Get additional data failed", e);
      throw e;
    }
  }
}
