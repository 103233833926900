import moment from "moment";

export enum RecurringTransferUnit {
  DAY = "day",
  WEEK = "week",
  MONTH = "month",
}

export interface RecurringTransferFrequency {
  unit: RecurringTransferUnit;
  interval: number;
}

export interface RecurringTransferOption {
  label: string;
  frequency: RecurringTransferFrequency;
}

export const RECURRING_TRANSFER_FREQUENCY_OPTIONS: RecurringTransferOption[] = [
  {
    label: "recurringTransfer.optionLabel.daily",
    frequency: {
      unit: RecurringTransferUnit.DAY,
      interval: 1,
    },
  },
  {
    label: "recurringTransfer.optionLabel.weekly",
    frequency: {
      unit: RecurringTransferUnit.WEEK,
      interval: 1,
    },
  },
  {
    label: "recurringTransfer.optionLabel.twiceAMonth",
    frequency: {
      unit: RecurringTransferUnit.WEEK,
      interval: 2,
    },
  },
  {
    label: "recurringTransfer.optionLabel.monthly",
    frequency: {
      unit: RecurringTransferUnit.MONTH,
      interval: 1,
    },
  },
];

export type RecurringTransferParams = {
  enabled: boolean;
  firstExecutionDate: string;
  frequency: RecurringTransferFrequency;
  endDate?: string;
};

export const defaultRecurringTransferParams: RecurringTransferParams = {
  enabled: false,
  firstExecutionDate: moment().add(1, "day").format("YYYY-MM-DD"),
  frequency: RECURRING_TRANSFER_FREQUENCY_OPTIONS[0].frequency,
  endDate: "",
};
