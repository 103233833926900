import React, { useEffect, useMemo, useRef } from "react";
import type { OptionTypeBase } from "react-select";
import Select, { components } from "react-select";
import styled from "styled-components";

import { useRTL } from "../../../domain/language/use-rtl";
import { theme } from "../../styles/theme";
import { ChevronDown } from "../svg/chevron-down";

const selectColor = {
  error: theme.colors.error[500],
  optionText: "#000",
  singleText: "#000",
  disabledText: theme.colors.gray[700],
  bg: {
    normal: "#fff",
    selected: theme.colors.info[500],
    focus: theme.colors.gray[50],
    hover: theme.colors.gray[300],
    disabled: theme.colors.gray[300],
  },
};

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    fontFamily: "inherit",
    fontSize: "0.875rem",
    color: state.selectProps.$hasError ? selectColor.error : selectColor.optionText,
    cursor: state.selectProps.withPointer ? "pointer" : "default",
    borderRadius: "8px",
    padding: state.selectProps.disabledArrow ? "0 10px" : "9px 25px 9px 5px",
    outline: "none",
    width: "100%",
    minHeight: "48px",
    border: "0",
    boxShadow: "0",
    backgroundColor: state.selectProps.disabled ? selectColor.bg.disabled : selectColor.bg.normal,
    pointerEvents: state.selectProps.disabled ? "none" : "auto",
    "&:hover": {
      border: "0",
    },
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: "0.875rem",
    whiteSpace: "normal",
    wordWrap: "break-word",
    paddingLeft: "20px",
    backgroundColor: state.isSelected
      ? selectColor.bg.selected
      : state.isFocused
        ? selectColor.bg.focus
        : selectColor.bg.normal,
    "&:hover": {
      backgroundColor: selectColor.bg.hover,
      color: selectColor.optionText,
    },
  }),
  singleValue: (provided, state) => ({
    ...provided,
    whiteSpace: "normal",
    wordWrap: "break-word",
    position: "static",
    transform: "none",
    marginLeft: state.selectProps.disabledArrow ? "0" : "5px",
    color: state.selectProps.$hasError
      ? selectColor.error
      : state.selectProps.disabled
        ? selectColor.disabledText
        : selectColor.singleText,
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: "2px 0 2px 8px",
    height: "1.75rem",
  }),
  placeholder: (provided) => ({
    ...provided,
    position: "static",
    transform: "none",
  }),
  input: (provided) => ({
    ...provided,
    position: "absolute",
    top: "0",
  }),
  menuPortal: (base) => ({ ...base, zIndex: 2000000001 }),
  menu: (provided) => ({
    ...provided,
    maxHeight: "300px",
    overflowY: "auto",
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    width: 0,
  }),
};

const mergeStyles = (customStyle, selectStyle = {}) => {
  const mergedStyles = { ...customStyle };

  Object.keys(selectStyle).forEach((key) => {
    if (typeof customStyle[key] === "object" && typeof selectStyle[key] === "object") {
      mergedStyles[key] = { ...customStyle[key], ...selectStyle[key] };
    } else {
      mergedStyles[key] = selectStyle[key];
    }
  });

  return mergedStyles;
};

export interface SelectInputProps<T> {
  autoFocus?: boolean;
  className?: string;
  disabled?: boolean;
  disabledArrow?: boolean;
  disabledOptions?: readonly T[];
  hasError?: boolean;
  innerId: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  selectStyle?: React.CSSProperties;
  style?: React.CSSProperties;
  value: T | null;
  withPointer?: boolean;
  options?: readonly T[];
  itemRenderer?: (item: T) => string;
  onChange: (v: T) => void;
}

export type SelectInputType<K> = React.FC<SelectInputProps<K>>;

const DropdownIndicator = (props) => {
  if (props.selectProps.disabledArrow) {
    return null; // Do not render the dropdown indicator if disabledArrow is true
  }

  return (
    <components.DropdownIndicator {...props}>
      <ChevronDownIcon $isRTL={!!props.selectProps.$isRTL} />
    </components.DropdownIndicator>
  );
};

export function SelectInput<T>({
  autoFocus = false,
  className,
  disabled,
  disabledArrow,
  disabledOptions,
  hasError = false,
  innerId: id,
  label,
  options,
  placeholder,
  selectStyle,
  style,
  value,
  withPointer,
  itemRenderer = (v) => `${v}`,
  onChange,
  ...rest
}: SelectInputProps<T>): JSX.Element {
  const { isRTL } = useRTL();
  const selectRef = useRef(null);

  useEffect(() => {
    if (autoFocus && selectRef.current) {
      selectRef.current.focus();
    }
  }, [autoFocus]);

  const handleChange = (selectedOption: OptionTypeBase) => {
    if (options && selectedOption) {
      onChange(options[selectedOption.value - 1]);
    }
  };

  const selectOptions = useMemo(
    () => [
      ...(placeholder && !value && value !== 0 ? [{ value: "", label: placeholder, isDisabled: true }] : []),
      ...(options?.map((v, index) => ({
        value: index + 1,
        label: itemRenderer(v),
        isDisabled: disabledOptions?.includes(v),
      })) || []),
    ],
    [options, disabledOptions, placeholder, value, itemRenderer],
  );

  const mergedStyles = useMemo(() => mergeStyles(customStyles, selectStyle), [selectStyle]);

  const selectedValue = selectOptions.find((option) => option.label === itemRenderer(value));
  return (
    <SelectContainer className={className} hasLabel={!!label} style={style}>
      {label && (
        <Label id={`${id}-label`} htmlFor={id}>
          {label}
        </Label>
      )}
      <SelectWrapper>
        <Select
          ref={selectRef}
          {...rest}
          id={id}
          className="react-select-container"
          classNamePrefix="react-select"
          aria-labelledby={label ? `${id}-label` : undefined}
          aria-invalid={hasError}
          styles={mergedStyles}
          placeholder={placeholder}
          value={selectedValue}
          onChange={handleChange}
          options={selectOptions}
          menuPortalTarget={document.body}
          $isRTL={!!isRTL}
          $hasError={hasError}
          withPointer={withPointer}
          disabled={disabled}
          disabledArrow={disabled || disabledArrow}
          components={{ DropdownIndicator }}
          menuPlacement="auto"
        />
      </SelectWrapper>
    </SelectContainer>
  );
}

const SelectContainer = styled.div<{ hasLabel: boolean }>`
  display: inline-flex;
  flex-direction: ${(props) => (props.hasLabel ? "column" : "row")};
  flex-shrink: 1;
  flex-grow: 1;
  background: #fff;
  border-radius: 10px;
`;

const Label = styled.label`
  font-weight: 600;
  color: #000;
  margin-bottom: 10px;
  font-size: 1rem;
`;

const SelectWrapper = styled.div`
  position: relative;
  flex-shrink: 1;
  flex-grow: 1;
`;

const ChevronDownIcon = styled(ChevronDown)<{ $isRTL: boolean }>`
  pointer-events: none;
  position: absolute;
  top: 50%;
  right: ${(props) => (props.$isRTL ? "unset" : "15px")};
  left: ${(props) => (props.$isRTL ? "10px" : "unset")};
  transform: translateY(-50%);
  width: 15px;
`;
