import { parsePhoneNumber } from "libphonenumber-js";
import React, { useEffect } from "react";
import styled from "styled-components";

import type { FormEmailInput } from "../../../../shared/core/data-forms/form-input-types";
import { FormInputType } from "../../../../shared/core/data-forms/form-input-types";
import { useIntl } from "../../../../shared/core/i18n/use-intl";
import {
  authenticationManager,
  customerActionService,
  featuresManager,
  identityManager,
} from "../../../../shared/core/service/services";
import { Scope } from "../../../../shared/domains/authentication/scope";
import { sessionHasScope } from "../../../../shared/domains/authentication/session";
import { formatAddress } from "../../../../shared/domains/client/address";
import { useClientDisplayName } from "../../../../shared/domains/client/client";
import { useClientLinks } from "../../../../shared/domains/client/use-client-links";
import { isDefined } from "../../../../shared/utils/assert";
import { getLocaleDateFromUtcDateString } from "../../../../shared/utils/date-to-local";
import { useObservable } from "../../../../shared/utils/observable";
import { filterObjectKeys, getGenderByInt, isObjectEmpty, useAsyncEffect } from "../../../../shared/utils/utils";
import { useClient } from "../../../domain/authentication/use-client";
import { useRTL } from "../../../domain/language/use-rtl";
import { Avatar } from "../../common/avatar/avatar";
import { GrayPrimaryButton, PrimaryButton, WhitePrimaryButton } from "../../common/buttons/primary-button";
import { EmailField } from "../../common/data-forms/email-field";
import { ErrorMessage } from "../../common/error-message";
import { PageHeader } from "../../common/page/page-header";
import { Spinner } from "../../common/spinner";
import { shadows, theme } from "../../styles/theme";
import { UIConstants } from "../../styles/uiConstants";
import { DownloadDocument } from "./components/download-document";
import { InformationSection } from "./components/information-section";

const CheckIcon = require("../../../assets/images/svg/check-circle.svg");
const CloseIcon = require("../../../assets/images/svg/close-square.svg");

export const MyProfileScreen = () => {
  const { formatMessage, formatDate } = useIntl();
  const { client } = useClient();
  const displayName = useClientDisplayName(client);
  const emailRef = React.useRef<HTMLInputElement>(null);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [editingEmail, setEditingEmail] = React.useState(false);
  const features = useObservable(featuresManager.features);
  const { updateEmailAddressLink, canUpdateEmailAddress } = useClientLinks();
  const [successMsg, setSuccessMsg] = React.useState<string | null>();
  const [userEmail, setUserEmail] = React.useState<FormEmailInput>({
    type: FormInputType.Email,
    id: "email",
    label: "Email",
    value: client?.email || "",
  });

  const clientRole = useObservable(identityManager.clientRole);
  const kycDocuments = useObservable(identityManager.kycDocuments);
  const clientRoleLoading = useObservable(identityManager.clientRoleLoading);
  const kycDocumentsLoading = useObservable(identityManager.kycDocumentsLoading);
  const clientRoleError = useObservable(identityManager.clientRoleError);
  const kycDocumentsError = useObservable(identityManager.kycDocumentsError);
  const downloadKycError = useObservable(identityManager.downloadKycError);

  const [clientKyc, setClientKyc] = React.useState<object>();
  const [viewKycDetails, setViewKycDetails] = React.useState(false);

  const { isRTL } = useRTL();

  const session = authenticationManager.session.get();

  useAsyncEffect(async () => {
    if (sessionHasScope(session, Scope.IdentityView)) {
      await identityManager.fetchClientRole(session.clientId!);
    }
  }, []);

  useAsyncEffect(async () => {
    await identityManager.fetchKycDocuments(session.clientId!);
  }, []);

  useEffect(() => {
    const kycExcludes = ["address", "contactphone", "country", "email", "firstName", "lastName"];

    clientRole && setClientKyc(filterObjectKeys(clientRole.kyc, kycExcludes));
  }, [clientRole]);

  const handleEditEmail = () => {
    setEditingEmail(!editingEmail);
    setUserEmail({
      ...userEmail,
      value: client?.email || "",
    });
    setTimeout(() => {
      if (emailRef.current) {
        emailRef.current.focus();
      }
    }, 100);
  };

  const handleSetUserEmail = (value: string) => {
    setUserEmail({
      ...userEmail,
      value,
    });
  };

  const handleSubmitNewEmail = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (client && features?.sdaCustomerOnboardingActionsInitiate && userEmail.value !== client.email) {
      const url = updateEmailAddressLink(client);
      if (url) {
        setIsSubmitting(true);
        emailRef.current?.blur();
        try {
          await customerActionService.makeCustomerAction(url, { email: userEmail.value });
          setSuccessMsg(formatMessage("clientInformation.emailSuccess"));
        } catch (e) {
          console.log("catch", e);
        } finally {
          handleEditEmail();
          setIsSubmitting(false);
        }
      }
    } else {
      handleEditEmail();
    }
  };

  const toggleKycDetails = () => {
    setViewKycDetails((prev) => !prev);
  };

  return (
    <>
      {!!successMsg && (
        <SuccessMsg>
          <img src={CheckIcon} alt="" />
          {successMsg}
          <CloseButton onClick={() => setSuccessMsg(null)}>
            <img src={CloseIcon} alt="" />
          </CloseButton>
        </SuccessMsg>
      )}
      <Page>
        <Profile $isRTL={isRTL}>
          <PageHeader title={formatMessage("clientInformation.title")} />
          {isDefined(client) && (
            <ProfileCard>
              <NameContainer>
                <ProfileAvatar size={40} name={displayName} $isRTL={isRTL} />
                <Name>{displayName}</Name>
              </NameContainer>
              {client.birthDate ? (
                <StyledInformationSection
                  label={formatMessage("clientInformation.birthDateTitle")}
                  value={formatDate(getLocaleDateFromUtcDateString(client.birthDate), {
                    year: "numeric",
                    month: "long",
                    day: "2-digit",
                  })}
                />
              ) : null}
              <StyledInformationSection
                label={formatMessage("clientInformation.birthPlaceTitle")}
                value={client.birthPlace}
              />
              <StyledInformationSection
                label={formatMessage("clientInformation.addressTitle")}
                value={formatAddress(client.address)}
              />
              <StyledInformationSection
                label={formatMessage("clientInformation.phoneTitle")}
                value={
                  client.contactphone ? parsePhoneNumber(`+${client.contactphone}`).formatInternational() : undefined
                }
                isPhone={true}
              />
              <StyledInformationSection
                label={formatMessage("clientInformation.emailTitle")}
                value={client.email}
                onEdit={
                  canUpdateEmailAddress(client) && features?.sdaCustomerOnboardingActionsInitiate
                    ? () => handleEditEmail()
                    : undefined
                }
              >
                {editingEmail ? (
                  <form
                    style={{
                      pointerEvents: isSubmitting ? "none" : "auto",
                    }}
                    onSubmit={(e) => (canUpdateEmailAddress(client) ? handleSubmitNewEmail(e) : null)}
                  >
                    <>
                      <EmailField ref={emailRef} input={userEmail} onChange={handleSetUserEmail} />
                      <StyledActionWrapper>
                        <WhitePrimaryButton
                          disabled={isSubmitting}
                          type="submit"
                          style={{ padding: "5px 20px" }}
                          size="S"
                        >
                          {formatMessage("common.validate")}
                        </WhitePrimaryButton>
                        <PrimaryButton type="button" style={{ padding: "5px 20px" }} size="S" onClick={handleEditEmail}>
                          {formatMessage("common.cancel")}
                        </PrimaryButton>
                      </StyledActionWrapper>
                    </>
                  </form>
                ) : null}
              </StyledInformationSection>
              {clientKyc &&
                viewKycDetails &&
                Object.keys(clientKyc).map((key) => {
                  let value = clientKyc[key];
                  if (["gender", "personal_gender"].includes(key)) {
                    value = formatMessage(`gender.${getGenderByInt(value)}`);
                  } else if (typeof value === "boolean") {
                    value = String(value);
                  } else if (typeof value === "object") {
                    value = value.label;
                  }

                  return <StyledInformationSection key={key} label={key} value={value} />;
                })}

              {clientRoleLoading && <Spinner />}
              {clientKyc && !isObjectEmpty(clientKyc) && (
                <MiniButton
                  size="XS"
                  onClick={(e) => {
                    e.currentTarget.blur();
                    toggleKycDetails();
                  }}
                >
                  {viewKycDetails
                    ? formatMessage("clientInformation.viewLess")
                    : formatMessage("clientInformation.viewMore")}
                </MiniButton>
              )}
            </ProfileCard>
          )}
        </Profile>

        {kycDocuments.length === 0 && kycDocumentsLoading && <Spinner />}
        {kycDocuments.length > 0 && (
          <KycDocuments $isRTL={isRTL}>
            <PageHeader title={formatMessage("documents.title")} />
            <KycDocumentsCard>
              {kycDocumentsLoading && <Spinner style={{ marginBottom: 10 }} />}
              {kycDocuments.map((document: any) => (
                <DownloadDocument key={document.id} document={document} />
              ))}
            </KycDocumentsCard>
          </KycDocuments>
        )}
      </Page>

      {clientRoleError?.message && <ErrorMessage>{clientRoleError.message}</ErrorMessage>}
      {kycDocumentsError?.message && <ErrorMessage>{kycDocumentsError.message}</ErrorMessage>}
      {downloadKycError?.message && <ErrorMessage>{downloadKycError.message}</ErrorMessage>}
    </>
  );
};

const ProfileCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 25px;
  ${shadows.medium};
  border-radius: 18px;
  background-color: #ffffff;
  margin-bottom: 20px;
`;

const Profile = styled.div<{ $isRTL: boolean }>`
  margin-right: ${(props) => (props.$isRTL ? 0 : 20)}px;

  width: ${UIConstants.CONTACT_TILE_WIDTH_NOT_MOBILE}px;
  @media (max-width: ${UIConstants.TABLET_BREAKPOINT - 1}px) {
    width: ${UIConstants.CONTACT_TILE_WIDTH_MOBILE}px;
  }
`;

const ProfileAvatar = styled(Avatar)<{ $isRTL: boolean }>`
  margin-right: ${(props) => (props.$isRTL ? 0 : 10)}px;
  margin-left: ${(props) => (props.$isRTL ? 10 : 0)}px;
`;

const NameContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
`;
const Name = styled.span`
  ${theme.mediumText};
  color: #000000;
  font-size: 0.9375rem;
`;

const StyledInformationSection = styled(InformationSection)`
  &:not(:last-of-type) {
    margin-bottom: 20px;
  }
`;

const KycDocuments = styled.div<{ $isRTL: boolean }>`
  margin-left: ${(props) => (props.$isRTL ? 20 : 0)}px;

  width: ${UIConstants.CONTACT_TILE_WIDTH_NOT_MOBILE}px;
  @media (max-width: ${UIConstants.TABLET_BREAKPOINT - 1}px) {
    width: ${UIConstants.CONTACT_TILE_WIDTH_MOBILE}px;
  }
`;

const KycDocumentsCard = styled.div`
  padding: 25px;
  ${shadows.medium};
  border-radius: 18px;
  background-color: #ffffff;
  margin-bottom: 20px;
`;

const Page = styled.div`
  display: grid;
  width: 100%;
  grid-row-gap: 45px;
  grid-column-gap: 45px;
  grid-template-columns: repeat(auto-fill, ${UIConstants.CONTACT_TILE_WIDTH_NOT_MOBILE}px);
  @media (max-width: ${UIConstants.TABLET_BREAKPOINT - 1}px) {
    grid-template-columns: repeat(auto-fill, ${UIConstants.CONTACT_TILE_WIDTH_MOBILE}px);
  }
`;

const StyledActionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 20px;
  gap: 10px;
`;

const SuccessMsg = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  background-color: ${theme.colors.info[300]};
  color: ${theme.colors.info[500]};
  font-size: 0.875rem;
  font-weight: 500;
  padding: 15px 20px;
  border-radius: 10px;
  margin-bottom: 20px;
  ${shadows.medium}
`;

const CloseButton = styled.button`
  appearance: none;
  border: none;
  cursor: pointer;
  background-color: transparent;
  justify-self: flex-end;
  margin-left: 10px;
`;

const MiniButton = styled(GrayPrimaryButton)`
  font-size: 0.75rem;
  padding: 6px 18px;
  color: rgb(98, 98, 98);
  background-color: rgb(236, 236, 236);
  max-width: fit-content;
  box-shadow: none;
  margin-top: 20px;
  :hover {
    background-color: rgb(220, 220, 220);
  }
  :active {
    background-color: rgb(211, 211, 211);
  }
`;
