import { map } from "lodash";

import { Currencies } from "../../core/currency/currency";
import { fetchTargetCurrencies } from "../../utils/forex-config";
import type { Account } from "../account/account";
import type { PaymentNetworkProduct } from "./customer-instruction";

/**
 * Returns authorized currencies based on ForEx configuration.
 * @param paymentNetwork Payment network product
 * @param sourceAccount Source account (from)
 * @returns List of relevant currencies
 */
export const getCurrencySelectOptions = (paymentNetwork: PaymentNetworkProduct, sourceAccount: Account) => {
  const { forexConfiguration: { currencyCouples } = {} } = paymentNetwork.processing;

  const targetCurrencies = fetchTargetCurrencies(sourceAccount, currencyCouples!);

  const currencySelectOptions = map(Currencies, (v) => v).filter((curr) =>
    targetCurrencies?.includes(curr.iso_numeric),
  );

  return currencySelectOptions;
};

/**
 * Returns destination accounts based on type of Payment Network which is configured for ForEx.
 * @param paymentNetwork Payment network product
 * @param sourceAccount Source account (from)
 * @param accounts List of available accounts
 * @returns List of relevant destination accounts (to)
 */
export const getDestinationAccounts = (
  paymentNetwork: PaymentNetworkProduct,
  sourceAccount: Account,
  accounts: Account[],
) => {
  const { forexConfiguration } = paymentNetwork.processing;

  if (forexConfiguration?.enabled === true) {
    const targetCurrencies = fetchTargetCurrencies(sourceAccount, forexConfiguration.currencyCouples);

    const destinationAccounts = accounts.filter(
      (account) => account.id !== sourceAccount.id && targetCurrencies.includes(account.currency),
    );

    return destinationAccounts;
  } else {
    const destinationAccounts = accounts.filter(
      (account) => account.id !== sourceAccount.id && account.currency === sourceAccount.currency,
    );

    return destinationAccounts;
  }
};
