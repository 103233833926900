import type { SVGProps } from "react";
import React from "react";

export function RightArrow(props: SVGProps<SVGSVGElement>) {
  const fillColor = props.fill || "#ffffff";
  return (
    <svg width="12" height="12" viewBox="0 0 12 10" {...props} fillRule="nonzero">
      <g fill={fillColor}>
        <path d="M6.754 1.088a.583.583 0 0 1 .825 0l3.5 3.5A.586.586 0 0 1 11.25 5l-.004-.065.003.03.001.035a.592.592 0 0 1-.082.298.58.58 0 0 1-.089.114l.062-.072a.584.584 0 0 1-.004.005l-.058.067-3.5 3.5a.583.583 0 0 1-.825-.824l2.504-2.505H1.333a.583.583 0 0 1-.575-.488L.75 5c0-.322.261-.583.583-.583h7.925L6.754 1.912a.584.584 0 0 1-.067-.744z" />
      </g>
    </svg>
  );
}
