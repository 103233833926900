import React from "react";
import styled from "styled-components";

import { useIntl } from "../../../../shared/core/i18n/use-intl";
import { configurationManager, i18NManager } from "../../../../shared/core/service/services";
import { useObservable } from "../../../../shared/utils/observable";
import { ACCOUNT_DELETE_MODAL_ID, PINCODE_MUST_BE_CHANGED_MODAL_ID } from "../../../core/modal/modal-id";
import { changeLanguage } from "../../../domain/language/language";
import { useRTL } from "../../../domain/language/use-rtl";
import { getLanguageName } from "../../../utils/translations";
import { Modal } from "../../common/modal/modal";
import { PageHeader } from "../../common/page/page-header";
import { shadows, theme } from "../../styles/theme";
import { UIConstants } from "../../styles/uiConstants";
import { PincodeChangeModal } from "../pincode/pincode-change-modal";
import { AccountDeleteModal } from "./components/account-delete-modal";
import { SettingsActionItem, SettingsSelectItem } from "./components/settings-item";

export const MySettingsScreen = () => {
  const { isRTL } = useRTL();
  const { formatMessage } = useIntl();
  const userLanguage = useObservable(i18NManager.localeTag);
  const configurationLanguages = useObservable(configurationManager.configuration).langs;

  return (
    <Page>
      <Settings $isRTL={isRTL}>
        <PageHeader title={formatMessage("settings.title")} />
        <SettingsCards>
          <ActionItem
            title={formatMessage("settings.changePincodeTitle")}
            subtitle={formatMessage("settings.changePincodeDescription")}
            onClick={() =>
              Modal.present(PINCODE_MUST_BE_CHANGED_MODAL_ID, () => <PincodeChangeModal type="skip-explanation" />, {
                canBeDismissed: false,
              })
            }
          />
          <SettingsSelectItem
            title={formatMessage("settings.changeLanguageTitle")}
            subtitle={formatMessage("settings.changeLanguageDescription")}
            selectInputProps={{
              value: userLanguage,
              innerId: "language",
              options: configurationLanguages,
              style: { marginTop: "10px" },
              selectStyle: {
                control: (provided) => ({
                  ...provided,
                  backgroundColor: theme.colors.gray[50],
                }),
              },
              itemRenderer: getLanguageName,
              onChange: (language) => changeLanguage(language),
            }}
          />
          <ActionItem
            title={formatMessage("settings.accountDeleteTitle")}
            subtitle={formatMessage("settings.accountDeleteDescription")}
            onClick={() => {
              Modal.present(ACCOUNT_DELETE_MODAL_ID, () => <AccountDeleteModal />, {
                canBeDismissed: false,
              });
            }}
          />
        </SettingsCards>
      </Settings>
    </Page>
  );
};

const Page = styled.div`
  display: grid;
  width: 100%;
  grid-row-gap: 45px;
  grid-column-gap: 45px;
  grid-template-columns: repeat(auto-fill, ${UIConstants.CONTACT_TILE_WIDTH_NOT_MOBILE}px);
  @media (max-width: ${UIConstants.TABLET_BREAKPOINT - 1}px) {
    grid-template-columns: repeat(auto-fill, ${UIConstants.CONTACT_TILE_WIDTH_MOBILE}px);
  }
`;

const Settings = styled.div<{ $isRTL: boolean }>`
  margin-right: ${(props) => (props.$isRTL ? 0 : 20)}px;

  width: ${UIConstants.CONTACT_TILE_WIDTH_NOT_MOBILE}px;
  @media (max-width: ${UIConstants.TABLET_BREAKPOINT - 1}px) {
    width: ${UIConstants.CONTACT_TILE_WIDTH_MOBILE}px;
  }
`;

const SettingsCards = styled.div`
  padding: 25px;
  ${shadows.medium};
  border-radius: 18px;
  background-color: #ffffff;
`;

const ActionItem = styled(SettingsActionItem)`
  margin-bottom: 20px;
  margin-top: 24px;
`;
