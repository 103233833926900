import type { SVGProps } from "react";
import React from "react";

export function IconCommunication(props: SVGProps<SVGSVGElement>) {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m15.26 17.248 2.571-2.571c.71-.71.887-1.795.437-2.694l-.145-.291a2.333 2.333 0 0 1 .437-2.694l3.188-3.188a.583.583 0 0 1 .934.152l1.288 2.576c.986 1.97.6 4.35-.959 5.908l-6.998 6.999a6.331 6.331 0 0 1-6.48 1.53l-2.816-.94a.583.583 0 0 1-.228-.965l3.093-3.093a2.333 2.333 0 0 1 2.693-.438l.292.146c.898.45 1.983.273 2.693-.437z"
        fill={props.color ?? "#81C241"}
      />
      <path
        opacity=".3"
        fillRule="evenodd"
        clipRule="evenodd"
        d="m16.506 7.008-.223 2.323a5.818 5.818 0 0 0-4.687 1.681 5.818 5.818 0 0 0-1.683 4.671l-2.324.217a8.15 8.15 0 0 1 2.357-6.537 8.151 8.151 0 0 1 6.56-2.355zm.408-4.649-.216 2.324a10.474 10.474 0 0 0-8.402 3.03 10.474 10.474 0 0 0-3.031 8.39l-2.324.213A12.807 12.807 0 0 1 6.646 6.063a12.807 12.807 0 0 1 10.268-3.704z"
        fill={props.color ?? "#81C241"}
      />
    </svg>
  );
}
