import React from "react";
import styled from "styled-components";

import type { FormFieldValueType } from "../../../../../../mobile/core/data-forms/form-field";
import type {
  FileProps,
  FormCollectionInput,
  FormCollectionInputWithHierarchy,
  FormImageInput,
  FormInputWithHierarchy,
} from "../../../../../../shared/core/data-forms/form-input-types";
import { FormInputType } from "../../../../../../shared/core/data-forms/form-input-types";
import { useIntl } from "../../../../../../shared/core/i18n/use-intl";
import { isDefined } from "../../../../../../shared/utils/assert";
import { hasMultiplePages } from "../../../../../../shared/utils/form-input-image";
import type { UploadedImage } from "../../../../../../shared/utils/images-converter";
import { FormField } from "../../../../common/data-forms/form-field";
import FormLabel from "../../../../common/forms/form-label";
import { MarkdownText } from "../../../../common/forms/markdown-text";
import { theme } from "../../../../styles/theme";
import { FormWithButtons } from "../../shared/components/form-with-buttons";

const EnrollmentStepForm: React.FC<{
  disabled?: boolean;
  erroredFields?: string[];
  overrideActions?: React.ReactNode;
  step: FormCollectionInputWithHierarchy | FormCollectionInput;
  errorMessage?: string | null;
  withoutActions?: boolean;
  onInputValueChange: (input: FormInputWithHierarchy, value: FormFieldValueType, fileProps?: FileProps) => void;
  onInputMultiImagesChange: (input: FormInputWithHierarchy, values: UploadedImage[]) => void;
  onSubmit?: () => void;
  onBackward?: () => void;
}> = ({
  disabled,
  overrideActions,
  step,
  errorMessage,
  erroredFields,
  onInputValueChange,
  onInputMultiImagesChange,
  onSubmit,
  onBackward,
}) => {
  const { formatMessage } = useIntl();

  const nbImages = (input: FormInputWithHierarchy) => {
    const nbPicturesForDocumentText = (count: number) => {
      if (count === 1) {
        return "(" + formatMessage("registerScreen.nbPicturesForDocument.one") + ")";
      }
      return `(${formatMessage("registerScreen.nbPicturesForDocument.others", { count })})`;
    };

    if (input.type === FormInputType.Image) {
      const inputImage = input as FormImageInput;
      if (isDefined(inputImage.minimumPageCount)) {
        return hasMultiplePages(inputImage) ? nbPicturesForDocumentText(inputImage.minimumPageCount) : undefined;
      }
    }
    return undefined;
  };

  const inputHasError = (inputName) => (erroredFields ? erroredFields?.includes(inputName) : false);

  return (
    <FormWithButtons
      disabled={disabled}
      overrideActions={overrideActions}
      onSubmit={() => onSubmit?.()}
      errorMessage={errorMessage}
      onBackward={() => onBackward?.()}
    >
      {step.label && <StepTitle>{step.label}</StepTitle>}
      <InputsContainer>
        {step?.inputs?.map((input) => (
          <div key={input.id}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "baseline",
              }}
            >
              {input.label && <FormLabel disableFormatting label={input.label} required={input.required} />}
              <Comment>{nbImages(input)}</Comment>
            </div>
            <FormField
              key={input.id}
              input={input}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onChange={(value, fileProps) => onInputValueChange(input, value as any, fileProps)}
              onMultiImagesChange={(values) => onInputMultiImagesChange(input, values)}
              hasError={inputHasError(input.id)}
            />
            {!!input.description && (
              <Description style={inputHasError(input.id) ? { color: theme.colors.error[500] } : {}}>
                {input.description}
              </Description>
            )}
          </div>
        ))}
      </InputsContainer>
    </FormWithButtons>
  );
};

export default EnrollmentStepForm;

const StepTitle = styled(MarkdownText)`
  ${theme.boldText};
  display: block;
  font-size: 1.8rem;
  line-height: 2.875rem;
  align-self: flex-start;
  margin-bottom: 24px;
`;

const Description = styled.div`
  ${theme.text}
  font-size: 0.688rem;
  color: ${theme.colors.gray[700]};
  margin-top: 5px;
`;

const Comment = styled.span`
  ${theme.text}
  font-size: 0.688rem;
  font-style: italic;
  color: #969696;
`;

const InputsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;
