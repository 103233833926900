import { featuresManager } from "../../core/service/services";
import { isDefined } from "../../utils/assert";
import type { Account } from "../account/account";
import { AccountStatus, AccountType } from "../account/account";

export const isRechargeable = (account: Account) => {
  if (account.type !== AccountType.Current) {
    return false;
  }
  if ([AccountStatus.Blocked, AccountStatus.Closed, AccountStatus.CreditBlocked].includes(account.status)) {
    return false;
  }
  const features = featuresManager.features.get();
  if (features.walletRechargeByCard) {
    return true;
  }
  if (features.bankToWallet) {
    return isDefined(account.externalAccount);
  }
  return false;
};
