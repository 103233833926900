import React, { useCallback, useState } from "react";
import styled from "styled-components";

import { useIntl } from "../../../../../shared/core/i18n/use-intl";
import { authenticationsSessionsService } from "../../../../../shared/core/service/services";
import type { Card } from "../../../../../shared/domains/cards/card";
import type { OverrideCardData } from "../../../../../shared/domains/cards/card-service";
import {
  CardSensitiveDetailsStep,
  useCardSensitiveDetails,
} from "../../../../../shared/domains/cards/use-card-sensitive-details";
import { anonymizePhoneNumber, formatPhoneNumber } from "../../../../../shared/utils/phone-number";
import { useAsyncEffect } from "../../../../../shared/utils/utils";
import { CARD_SENSITIVE_DETAILS_MODAL_ID } from "../../../../core/modal/modal-id";
import { useClient } from "../../../../domain/authentication/use-client";
import { Modal } from "../../../common/modal/modal";
import { RoundedModalContainer } from "../../../common/modal/rounded-modal-container";
import { MainColorSpinner } from "../../../common/spinner";
import { OtpConfirm } from "../../recipient/otp-confirm";
import { ResultView } from "../../result/result-view";

export interface ICardSensitiveDetailsProps {
  card?: Card | null;
  onCompleted: (a: OverrideCardData) => void;
}

export const CardSensitiveDetails = (props: ICardSensitiveDetailsProps) => {
  const { card, onCompleted } = props;
  const [errorMessage, setErrorMessage] = useState<string>("");
  const { client } = useClient();

  const { loading, error, scaToken, step, getSensitiveCardDetails } = useCardSensitiveDetails();

  const handleOTPValidation = async (otp, scaToken) => {
    if (scaToken) {
      try {
        const response = await authenticationsSessionsService.verifyAuthentication(scaToken, otp);
        if (response.status === "DONE" && card) {
          await handleGetSensitiveCardDetails(card, scaToken);
        }
      } catch (e) {
        setErrorMessage(e.response?.data?.error?.message || e.toString());
      }
    } else {
      throw new Error("Missing SCA Token");
    }
  };

  const handleGetSensitiveCardDetails = useCallback(
    async (cardToUse: Card, scaSessionToken?: string) => {
      try {
        const response = await getSensitiveCardDetails(cardToUse, scaSessionToken);
        if (response) {
          onCompleted(response);
        }
      } catch (e) {
        setErrorMessage(e.response?.data?.error?.message || e.toString());
      }
    },
    [getSensitiveCardDetails],
  );

  useAsyncEffect(async () => {
    if (step === CardSensitiveDetailsStep.Init && card) {
      await handleGetSensitiveCardDetails(card);
    } else if (step === CardSensitiveDetailsStep.Success) {
      closeModal();
    }
  }, [step]);

  const { formatMessage } = useIntl();
  const closeModal = useCallback(() => Modal.dismiss(CARD_SENSITIVE_DETAILS_MODAL_ID), []);

  return (
    <RoundedModalContainer closeButton={true} id={CARD_SENSITIVE_DETAILS_MODAL_ID}>
      <StyledModalContainer>
        {step === CardSensitiveDetailsStep.Init && <StyledBlueSpinner />}
        {step === CardSensitiveDetailsStep.SCACheck && (
          <OtpConfirm
            submitOtp={(otp: string) => handleOTPValidation(otp, scaToken)}
            errorMessage={errorMessage}
            phoneNumber={client ? anonymizePhoneNumber(formatPhoneNumber(client.mobile), 2) : undefined}
            loading={loading}
          />
        )}
        {step === CardSensitiveDetailsStep.Error && (
          <StyledResultView
            type={"error"}
            title={formatMessage("resultScreen.sensitiveDetails.failureTitle")}
            subtitle={formatMessage("resultScreen.sensitiveDetails.failureSubtitle")}
            description={formatMessage("resultScreen.sensitiveDetails.failureDescription")}
            error={error}
            onConfirm={() => {
              closeModal();
            }}
          />
        )}
      </StyledModalContainer>
    </RoundedModalContainer>
  );
};

const StyledResultView = styled(ResultView)`
  margin-left: 20px;
  margin-right: 20px;
`;

const StyledModalContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
`;

const StyledBlueSpinner = styled(MainColorSpinner)``;
