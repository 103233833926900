import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { useIntl } from "../../../../shared/core/i18n/use-intl";
import type { Client } from "../../../../shared/domains/client/client";
import { useClientDisplayName } from "../../../../shared/domains/client/client";
import { Path } from "../../../core/routing/path";
import { useClickOutsideHandler } from "../../../utils/use-outside-click";
import { theme } from "../../styles/theme";
import { UIConstants } from "../../styles/uiConstants";
import { Circle } from "../shape/circle";
import { LogoutIcon } from "../svg/logout-icon";
import { UserIcon } from "../svg/user-icon";
import { sideBarColors } from "./component/colors";

interface BottomNavProps {
  client?: Client | null;
  onDisconnectClick: () => void;
  onProfileClick: () => void;
}

export function BottomNav(props: BottomNavProps) {
  const { onDisconnectClick, onProfileClick, client } = props;
  const { formatMessage } = useIntl();

  const displayName = useClientDisplayName(client, true);

  const [settingsMenuVisibility, setSettingsMenuVisibility] = useState(false);
  const settingsMenuRef = useRef<HTMLDivElement>(null);

  /** Settings menu handlers */

  const showSettingsMenu = () => {
    setSettingsMenuVisibility(true);
  };

  const hideSettingsMenu = () => {
    setSettingsMenuVisibility(false);
  };

  /**
   * Closes the settings menu on clicking outside.
   */
  useClickOutsideHandler(settingsMenuRef, hideSettingsMenu);

  return (
    <BottomContainer>
      <Separator />
      <BottomWrapper>
        <ProfileContainer>
          {!!client && (
            <>
              <UserButton to={Path.Profile} onClick={onProfileClick}>
                <UserCircle size={36}>
                  <UserIcon />
                </UserCircle>
              </UserButton>
              <ProfileInfos>
                {displayName && <Name>{displayName}</Name>}
                <ProfileButton onClick={showSettingsMenu}>{formatMessage("navigationMenu.settingsLink")}</ProfileButton>
              </ProfileInfos>
            </>
          )}
        </ProfileContainer>
        <DisconnectCircle size={35} onClick={onDisconnectClick}>
          <DisconnectButton size={25}>
            <LogoutIcon />
          </DisconnectButton>
        </DisconnectCircle>
      </BottomWrapper>

      <SettingsMenu ref={settingsMenuRef} visible={settingsMenuVisibility}>
        <SettingsMenuItem to={Path.Settings} onClick={hideSettingsMenu}>
          {formatMessage("navigationMenu.settings")}
        </SettingsMenuItem>
        <SettingsMenuItem to={Path.Profile} onClick={hideSettingsMenu}>
          {formatMessage("navigationMenu.userProfile")}
        </SettingsMenuItem>
      </SettingsMenu>
    </BottomContainer>
  );
}

const BottomContainer = styled.div`
  position: relative;
  bottom: 0;
  background-color: ${sideBarColors.bottomBackground};
  border-radius: 0 0 11px 11px;
  display: flex;
  flex-direction: column;
  flex: 0;
  height: auto;
  align-items: stretch;
`;

const Separator = styled.div`
  margin: 0 16px;
  width: auto;
  height: 1px;
  self-align: center;
  background-color: ${sideBarColors.bottomSeparator};
`;

const BottomWrapper = styled.div`
  background-color: ${sideBarColors.bottomBackground};
  border-radius: 0 0 11px 11px;
  display: flex;
  flex-direction: row;
  flex: 1;
  height: auto;
  padding: 12px;
  align-items: center;
  @media (max-width: ${UIConstants.DESKTOP_SMALL_BREAKPOINT - 1}px) {
    flex-direction: column;
    gap: 8px;
  }
`;

const ProfileContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;

const UserButton = styled(Link)`
  :hover {
    cursor: pointer;
  }
`;

const UserCircle = styled(Circle)`
  background-color: ${sideBarColors.userCircle};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
`;

const DisconnectCircle = styled(Circle)`
  border: solid 1px ${sideBarColors.disconnectCircle};
  margin: 10px;
  :hover {
    cursor: pointer;
    border: solid 2px ${sideBarColors.disconnectCircle};
  }
`;

const DisconnectButton = styled(Circle)`
  background-color: ${theme.mainColor};
  :hover {
    opacity: 0.8;
  }
`;

const ProfileInfos = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 8px;
  @media (max-width: ${UIConstants.DESKTOP_SMALL_BREAKPOINT - 1}px) {
    display: none;
  }
  max-width: 140px;
`;

const Name = styled.span`
  ${theme.mediumText}
  font-size: 0.9375rem;
  color: ${sideBarColors.name};
  text-overflow: ellipsis;
  overflow: hidden;
`;

const ProfileButton = styled.p`
  ${theme.text}
  font-size: 0.8125rem;
  color: ${sideBarColors.profile};
  margin: 0;
  :hover {
    cursor: pointer;
    text-decoration: underline;
  }
`;

const SettingsMenu = styled.div<{ visible: boolean }>`
  position: absolute;
  background-color: #fff;
  width: 90%;
  border-radius: 12px;
  padding: 8px 0;
  top: -50%;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  transform: ${({ visible }) => (visible ? "translateY(0) scale(1)" : "translateY(10px) scale(0.95)")};
  pointer-events: ${({ visible }) => (visible ? "auto" : "none")};
  transition:
    opacity 0.2s ease,
    transform 0.2s ease;
  box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.08);
`;

const SettingsMenuItem = styled(Link)`
  color: rgba(0, 0, 0, 0.87);
  font-size: 1rem;
  display: block;
  padding: 6px 16px;
  :hover {
    cursor: pointer;
    background-color: rgb(244, 244, 244);
  }
`;
