import styled from "styled-components";

import { theme } from "../../styles/theme";

export const PageSectionTitle = styled.h2`
  ${theme.boldText};
  font-size: 1.125rem;
  margin: 5px 0;
`;

export const PageSectionDescription = styled.span`
  ${theme.bodyGreyRegular}
`;
