import type { AxiosRequestConfig } from "axios";

import { logger } from "../../logging/logger";
import type { Interceptor } from "./interceptor";

export const addRequestLogInterceptor: () => Interceptor<AxiosRequestConfig> = () => ({
  onFulfilled: async (config) => {
    logger.info(config.method, config.url);

    return config;
  },
});
