import type { PincodeSubmission } from "../../shared/domains/pincode/pincode";

export type KeyboardEvent =
  | { type: "PINCODE_CONFIRM" }
  | { type: "PROMPT_KEYBOARD" }
  | { type: "SUBMIT_PINCODE"; pincodeSubmission: PincodeSubmission };

export enum PincodeState {
  PincodeConfirmation = "PincodeConfirmation",
  PromptingKeyboard = "PromptingKeyboard",
  FetchKeyboardAfterError = "FetchKeyboardAfterError",
}

export const TOUCH_SIZE = 135;
export const FONT_SIZE = 64;
