"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var TagMap = /** @class */ (function () {
    function TagMap() {
        this._tagToListeners = new WeakMap();
        this._listenerToTags = new WeakMap();
    }
    TagMap.prototype.setListeners = function (listener) {
        var _this = this;
        var tags = [];
        for (var _i = 1; _i < arguments.length; _i++) {
            tags[_i - 1] = arguments[_i];
        }
        var tagSet = this._listenerToTags.get(listener) || new Set();
        tags.forEach(function (tag) {
            var listenerSet = _this._tagToListeners.get(tag) || new Set();
            listenerSet.add(listener);
            tagSet.add(tag);
            _this._tagToListeners.set(tag, listenerSet);
        });
        this._listenerToTags.set(listener, tagSet);
    };
    TagMap.prototype.getListeners = function (tag) {
        return this._tagToListeners.get(tag) || new Set();
    };
    TagMap.prototype.getTags = function (listener) {
        return this._listenerToTags.get(listener) || new Set();
    };
    TagMap.prototype.clearListener = function (listener) {
        var _this = this;
        var tags = this.getTags(listener);
        this._listenerToTags.delete(listener);
        tags.forEach(function (tag) {
            var listenerSet = _this.getListeners(tag);
            listenerSet.delete(listener);
            if (listenerSet.size === 0) {
                _this._tagToListeners.delete(tag);
            }
        });
    };
    return TagMap;
}());
exports.TagMap = TagMap;
