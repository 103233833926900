import { AccountType } from "./account";

export const accountTypeLabels = {
  [AccountType.Current]: "accountType.current",
  [AccountType.Joint]: "accountType.joint",
  [AccountType.Savings]: "accountType.savings",
  [AccountType.Dedicated]: "accountType.dedicated",
  [AccountType.MoneyPot]: "accountType.moneyPot",
  [AccountType.Technical]: "accountType.technical",
};

export const accountTypeLabelsWeb = {
  [AccountType.Current]: "account.accountType.current",
  [AccountType.Joint]: "account.accountType.joint",
  [AccountType.Savings]: "account.accountType.savings",
  [AccountType.Dedicated]: "account.accountType.dedicated",
  [AccountType.MoneyPot]: "account.accountType.moneyPot",
  [AccountType.Technical]: "account.accountType.technical",
};
