import type { Amount } from "../../../core/amount/amount";
import type { UrlLink } from "../../BaseUrl";
import type { AccountingEntry } from "../accounting-entry";
import type { AccountingTransactionType } from "../accounting-transaction-type";

export interface AccountingTransaction extends Transaction {
  objectType: AccountingTransactionType.Transaction;
}

export interface Transaction {
  additional?: string;
  amount?: Amount;
  balance?: Amount;
  bookingDate?: Date;
  businessProcessId?: string;
  date: string;
  description?: string;
  detailedAccounting: AccountingEntry[];
  externalData?: { [key: string]: string };
  externalId?: string;
  geolocation?: Geolocation;
  id: string;
  justified?: boolean;
  label?: string;
  links?: UrlLink[];
  locked?: boolean;
  qualified?: boolean;
  shortDescription?: string;
  type: {
    value: TransactionType;
    label: string;
  };
}

export interface Geolocation {
  latitude: number;
  longitude: number;
}

export type TransactionType =
  | "ACCOUNT_TO_ACCOUNT"
  | "ACCOUNT_TO_EXTERNAL_TRF"
  | "ACCOUNT_TO_EXTERNAL"
  | "ACCOUNT_TO_TRANSIT"
  | "ADJUSTMENT"
  | "BANK_TO_WALLET"
  | "BLOCK_ACCOUNT"
  | "BUY_DEPOSIT"
  | "CARD"
  | "CASH_TO_EXTERNAL_TRF"
  | "CASHIN"
  | "CASHIN_TO_TRANSIT"
  | "CASHOUT"
  | "CASHOUT_FROM_EXTERNAL_TRF"
  | "CASHOUT_FROM_TRANSIT"
  | "CLOSE_ACCOUNT"
  | "DEPOSIT"
  | "DISTRIBUTOR_POSTING"
  | "EXTERNAL"
  | "EXTERNAL_TO_MONEYPOT"
  | "EXTERNAL_TRF_TO_ACCOUNT"
  | "EXTERNAL_TRF_WITHDRAW"
  | "FEES_PAYMENT"
  | "INVOICE_GENERATION"
  | "MAINTENANCE_PAYMENT"
  | "ONLINE_PAYMENT"
  | "OPEN_ACCOUNT"
  | "OVERBALANCE"
  | "PAYBILL_ACCOUNT"
  | "PAYBILL_CASH"
  | "PAYMENT"
  | "POSTING"
  | "RECHARGE_BALANCE"
  | "RECHARGE_CREDIT_CARD"
  | "REVERSAL"
  | "SALARY_DISBURSEMENT"
  | "SALARY_TO_ACCOUNT"
  | "SELL_DEPOSIT"
  | "SETTLEMENT_TO_ACCOUNT"
  | "SETTLEMENT_TO_CASH"
  | "TRANSIT_TO_ACCOUNT"
  | "UNBLOCK_ACCOUNT"
  | "WALLET_TO_BANK"
  | "WALLET_TO_BANK_ARCHIVE"
  | "VISA_PAYMENT_TO_EXTERNAL_SYSTEM";

export enum RefundTransactionReason {
  AC03 = "AC03",
  AM09 = "AM09",
  CUST = "CUST",
}
