import useComponentSize from "@rehooks/component-size";
import type { HTMLAttributes } from "react";
import React, { useRef } from "react";
import styled from "styled-components";

import type { CarouselType } from "../../../common/carousel/carousel";
import { Carousel, Row } from "../../../common/carousel/carousel";
import { shadows } from "../../../styles/theme";

interface ImagesCarouselProps extends HTMLAttributes<HTMLDivElement> {
  imagesSource: string[];
  onUpdateCurrentImage?: (index: number) => void;
}

export function ImagesCarousel(props: Readonly<ImagesCarouselProps>) {
  const { imagesSource, onUpdateCurrentImage, ...rest } = props;
  const containerRef = useRef<HTMLDivElement>(null);
  const { width: containerWidth } = useComponentSize(containerRef);

  return (
    <StyledCarousel
      dir="ltr"
      innerRef={containerRef}
      items={imagesSource}
      itemWidth={containerWidth}
      renderItem={(src, index) => <Image key={index} src={src} />}
      horizontalPadding={0}
      theme="BottomIndicator"
      onUpdateCurrentImage={onUpdateCurrentImage}
      {...rest}
    />
  );
}

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const StyledCarousel = styled<CarouselType<string>>(Carousel)`
  ${Row} {
    border-radius: 21px;
    ${shadows.medium};
    padding-top: 0;
    padding-bottom: 0;
    scroll-snap-type: x mandatory;

    > * > * {
      scroll-snap-align: start;
    }
  }
`;
