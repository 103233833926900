import React from "react";
import styled from "styled-components";

import { useRTL } from "../../../domain/language/use-rtl";
import { UIConstants } from "../../styles/uiConstants";
import { PageSectionDescription, PageSectionTitle } from "./page-section-title";

export const PageHeader = ({
  title,
  description,
  children,
}: {
  title: string;
  description?: string;
  children?: React.ReactNode;
}) => {
  const { isRTL } = useRTL();

  return (
    <StyledPageHeader>
      <StyledTitlesContainer isRTL={isRTL}>
        <PageSectionTitle>{title}</PageSectionTitle>
        {description && <PageSectionDescription>{description}</PageSectionDescription>}
      </StyledTitlesContainer>
      {children && children}
    </StyledPageHeader>
  );
};

const StyledTitlesContainer = styled.div<{ isRTL: boolean }>`
  margin-right: ${({ isRTL }) => (isRTL ? 0 : 10)}px;
  margin-left: ${({ isRTL }) => (isRTL ? 10 : 0)}px;
`;

const StyledPageHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;

  @media (max-width: ${UIConstants.TABLET_BREAKPOINT}px) {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
  }
`;
