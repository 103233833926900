import { logger } from "../../core/logging/logger";
import type { ServiceDomainApiService } from "../../core/net/service-domain-api-service";
import type { TokenResultDto } from "../authentication/oauth-service";
import { CREDIT_SCOPES, SAVINGS_SCOPES, SERVICE_DOMAIN_SCOPES } from "./service-domain-scopes";

export class ServiceDomainService {
  public constructor(private serviceDomainApiService: ServiceDomainApiService) {}

  public async getToken() {
    try {
      const response = await this.serviceDomainApiService.instance.post<TokenResultDto>("/oauth2/token", {
        grant_type: "client_credentials",
        scope: [...SAVINGS_SCOPES, ...CREDIT_SCOPES, ...SERVICE_DOMAIN_SCOPES].join(" "),
      });
      return response.data;
    } catch (e) {
      logger.debug("ServiceDomainService", "Fetch token failed", e);
      throw e;
    }
  }
}
