import { Config } from "../../../shared/core/config/config";
import type { I18NStore } from "../../../shared/core/i18n/i18n-store";
import type { UserAgentService } from "../../../shared/core/net/user-agent-service";
import type { UUIDManager } from "../../core/uuid/uuid-manager";
import { getApplicationScopes } from "./auth-utils";
import type { RegisteringParameters } from "./authentication-service";
import { getAuthorizeRedirectUrl } from "./authorize-redirect-url";
import type { AuthConfiguration } from "./oauth-service";
import { ApplicationScopes } from "./scope";

export class AuthenticationConfigurationService {
  private configuration: AuthConfiguration = {
    serviceConfiguration: {
      authorizationEndpoint: `${Config.API_URL}/oauth2/authorize`,
      tokenEndpoint: `${Config.API_URL}/oauth2/token`,
    },
    clientId: Config.API_CLIENT_ID,
    clientSecret: Config.API_CLIENT_SECRET,
    redirectUrl: getAuthorizeRedirectUrl(),
    scopes: getApplicationScopes(ApplicationScopes, Config.SAVINGS_ACCOUNT_CREATION_ENABLED),
  };

  constructor(
    private uuidManager: UUIDManager | null,
    private customUserAgentService: UserAgentService | null,
    private i18NStore: I18NStore,
  ) {}

  async getConfiguration(
    phoneNumber?: string,
    registeringParameters?: RegisteringParameters,
  ): Promise<AuthConfiguration> {
    const deviceUUID = await this.uuidManager?.getUUID();
    const optionalDeviceUUID: { device_id: string } | Record<string, unknown> = deviceUUID
      ? { device_id: deviceUUID }
      : {};
    const userAgent = this.customUserAgentService ? await this.customUserAgentService.getUserAgent() : null;
    const localeTag = await this.i18NStore.localeTag.get();
    const customHeaders = userAgent
      ? {
          token: {
            "User-Agent": userAgent,
          },
        }
      : undefined;
    if (registeringParameters) {
      return {
        ...this.configuration,
        additionalParameters: {
          ...registeringParameters,
          ...optionalDeviceUUID,
          signUp: "1",
          phone: phoneNumber ?? "",
          lang: localeTag,
        },
        // only used by Android
        customHeaders,
      };
    } else {
      return {
        ...this.configuration,
        additionalParameters: {
          ...optionalDeviceUUID,
          signUp: phoneNumber ? "1" : "0",
          phone: phoneNumber ?? "",
          lang: localeTag,
        },
        // only used by Android
        customHeaders,
      };
    }
  }
}
