import queryString from "query-string";
import { useLocation } from "react-router-dom";

import { authExecutor } from "../../../shared/core/service/services";
import { useAsyncEffect } from "../../../shared/utils/utils";

export const useConnectFromRedirect = () => {
  const location = useLocation().search;
  const parsed = queryString.parse(location);
  const { code, state } = parsed;
  useAsyncEffect(async () => {
    if (typeof code === "string" && typeof state === "string") {
      await authExecutor.authenticateFromRedirect(code, state);
    }
  }, [code, state]);
  return parsed;
};
