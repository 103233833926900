import React from "react";
import styled from "styled-components";

import { useRTL } from "../../../../domain/language/use-rtl";
import { SmallChevronIcon } from "../../../common/svg/small-chevron-icon";
import { theme } from "../../../styles/theme";
import { UIConstants } from "../../../styles/uiConstants";

export interface ActionMenuProps {
  className?: string;
  title: string;
  disabled?: boolean;
  onClick: () => void;
}

export const ActionMenu = (props: ActionMenuProps) => {
  const { title, className, disabled, onClick } = props;
  const { isRTL } = useRTL();

  return (
    <ButtonContainer disabled={disabled} className={className} onClick={onClick}>
      <TextButton>{title}</TextButton>
      <SmallChevronContainer $isRTL={isRTL}>
        <SmallChevronIcon />
      </SmallChevronContainer>
    </ButtonContainer>
  );
};

const ButtonContainer = styled.button<{ disabled?: boolean }>`
  display: flex;
  position: relative;
  flex: 1;
  border: none;
  flex-direction: row;
  justify-content: space-between;
  background-color: white;

  :hover {
    cursor: pointer;
  }

  opacity: ${(props) => (props.disabled ? 0.3 : null)};
  pointer-events: ${(props) => (props.disabled ? "none" : null)};
`;

const SmallChevronContainer = styled.div<{ $isRTL: boolean }>`
  margin-right: 12px;
  transform: ${(props) => (props.$isRTL ? "rotate(180deg)" : "rotate(0deg)")};
  @media (max-width: ${UIConstants.DESKTOP_SMALL_BREAKPOINT}px) {
    margin-right: 2px;
    margin-left: 2px;
  }
`;

const TextButton = styled.span`
  ${theme.mediumText}
  color: black;
  font-size: 0.9375rem;
`;
