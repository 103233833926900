import type { Amount } from "../../core/amount/amount";
import type { I18NManager } from "../../core/i18n/i18n-manager";
import { logger } from "../../core/logging/logger";
import type { ConnectedApiService } from "../../core/net/connected-api-service";
import type { Paginated } from "../../utils/pagination";
import type { PincodeSubmission } from "../pincode/pincode";
import type { TransactionCode } from "./transaction-code";

enum TransactionCodeError {
  TransactionWithdrawCodeGenerationError = "TRANSACTION_WITHDRAW_CODE_GENERATION_ERROR",
}

export class TransactionCodeService {
  public constructor(
    private apiService: ConnectedApiService,
    private i18NManager: I18NManager,
  ) {}

  public async fetchTransactionCodes() {
    try {
      const response = await this.apiService.instance.get<Paginated<TransactionCode>>("/transaction-codes");
      return response.data.items;
    } catch (e) {
      logger.debug("TransactionCodeService", "Failed to fetch transaction codes", e);
      throw e?.response?.data?.error?.message || e.toString();
    }
  }

  public async createTransactionCode(amount: Amount, acceptorShortCode: string, pincode?: PincodeSubmission) {
    try {
      const response = await this.apiService.instance.post<TransactionCode>("/transaction-codes", {
        amount,
        acceptorShortCode,
        pincode,
      });
      return response.data;
    } catch (e) {
      logger.debug("TransactionCodeService", "Failed to create transaction codes", e);
      let message = e?.response?.data?.error?.message || e.toString();
      if (message === TransactionCodeError.TransactionWithdrawCodeGenerationError) {
        message = this.i18NManager.formatMessage(TransactionCodeError.TransactionWithdrawCodeGenerationError);
      }
      throw message;
    }
  }
}
