import type { SVGProps } from "react";
import React from "react";

export const CopyToClipboardIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width="24" height="24" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g fill="#B1B1B1" fillRule="nonzero">
      <path d="M8.91 17.578h5.418c1.283 0 2.323-1.09 2.323-2.433V8.039c1.005.051 1.549.669 1.549 1.765v7.627c0 1.147-.594 1.769-1.69 1.769h-5.913c-1.048 0-1.637-.57-1.687-1.622z" />
      <path d="M7.59 4.9h5.913c1.096 0 1.69.622 1.69 1.77v7.626c0 1.147-.594 1.77-1.69 1.77H7.59c-1.096 0-1.69-.623-1.69-1.77V6.669c0-1.147.594-1.769 1.69-1.769z" />
    </g>
  </svg>
);
