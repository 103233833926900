import React from "react";
import styled from "styled-components";

import { useIntl } from "../../../../shared/core/i18n/use-intl";
import type { Recipient } from "../../../../shared/domains/recipients/recipient";
import { REMOVE_RECIPIENT_MODAL_ID } from "../../../core/modal/modal-id";
import { PrimaryButton } from "../../common/buttons/primary-button.ts";
import { Modal } from "../../common/modal/modal";
import { RoundedModalContainer } from "../../common/modal/rounded-modal-container";
import { fonts, theme } from "../../styles/theme";

export interface RemoveRecipientModalProps {
  recipient: Recipient;
  onConfirm: () => void;
}

export const RemoveRecipientModal = (props: RemoveRecipientModalProps) => {
  const { recipient, onConfirm } = props;

  const { formatMessage } = useIntl();

  const closeModal = () => {
    Modal.dismiss(REMOVE_RECIPIENT_MODAL_ID);
  };

  const handleConfirm = () => {
    onConfirm();
    closeModal();
  };

  return (
    <ModalContainer closeButton id={REMOVE_RECIPIENT_MODAL_ID}>
      <Container>
        <Title>{formatMessage("recipient.deletePrompt.title")}</Title>
        <Subtitle>
          {recipient.counterparty
            ? formatMessage("recipient.deletePrompt.externalAccountTitle")
            : formatMessage("recipient.deletePrompt.recipient")}
        </Subtitle>
        <Message>
          {recipient.counterparty
            ? formatMessage("recipient.deletePrompt.externalAccountMessage")
            : formatMessage("recipient.deletePrompt.message", { name: recipient.name })}
        </Message>
        <ConfirmButton size="M" onClick={handleConfirm}>
          {formatMessage("recipient.deletePrompt.confirmButton")}
        </ConfirmButton>
      </Container>
    </ModalContainer>
  );
};

const ModalContainer = styled(RoundedModalContainer)``;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 56px;
`;

const Title = styled.span`
  ${fonts.bold}
  font-size: 1.5rem;
  color: ${theme.mainColor};
`;

const Subtitle = styled(Title)`
  color: black;
`;

const Message = styled.div`
  ${theme.text};
  color: black;
  font-size: 0.9375rem;
  line-height: 1.25rem;
  text-align: center;
  margin: 48px 10px 15px;
`;

const ConfirmButton = styled(PrimaryButton)`
  margin-top: 30px;
  margin-bottom: 30px;
`;
