import type { ConnectedApiService } from "../../../shared/core/net/connected-api-service";
import type { AccountStatementsService } from "../../../shared/domains/account-statements/account-statements-service";
import { BaseAccountStatementsService } from "../../../shared/domains/account-statements/account-statements-service";
import { downloadFile } from "../../utils/download";

export class WebAccountStatementsService extends BaseAccountStatementsService implements AccountStatementsService {
  public constructor(apiService: ConnectedApiService) {
    super(apiService);
  }

  public async downloadStatement(accountId: string, statementId: string, variant?: "normal" | "fee") {
    const path = variant === "normal" ? "statements" : "fees-statements";
    const result = await this.apiService.instance.get(`/accounts/${accountId}/${path}/${statementId}/download`, {
      responseType: "arraybuffer",
    });
    await downloadFile(`${statementId}.pdf`, result.data, "application/pdf");
    return `${statementId}.pdf`;
  }
}
