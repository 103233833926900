import React from "react";
import styled from "styled-components";

import { useIntl } from "../../../../../shared/core/i18n/use-intl";
import type { DefaultButtonProps } from "../../../common/buttons/default-button";
import { DefaultButton } from "../../../common/buttons/default-button";
import { ChevronIcon } from "../../../common/svg/chevron";
import { theme } from "../../../styles/theme";

export interface ModalBackButtonProps extends DefaultButtonProps {
  title?: string;
}

export const ModalBackButton = (props: ModalBackButtonProps) => {
  const { title } = props;
  const { formatMessage } = useIntl();

  return (
    <CloseButton {...props}>
      <Container>
        <ArrowBack />
        <Label>{title ?? formatMessage("transactionCode.modalBackButton")}</Label>
      </Container>
    </CloseButton>
  );
};

const CloseButton = styled(DefaultButton)`
  padding: 0px;
`;

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const ArrowBack = styled(ChevronIcon)`
  margin-right: 10px;
  width: 18px;
  height: 18px;
  transform: rotate(180deg);
`;

const Label = styled.span`
  ${theme.boldText};
  font-size: 0.9375rem;
  color: #000000;
`;
