import moment from "moment";

export const getLocaleDateFromUtcDateString = (utcIsoDate: string): Date => moment(utcIsoDate).toDate();

export const getMidnightLocaleDateFromUtc = (utcIsoDate: Date): Date => {
  const localeDate = new Date(utcIsoDate);
  localeDate.setFullYear(utcIsoDate.getUTCFullYear(), utcIsoDate.getUTCMonth(), utcIsoDate.getUTCDate());
  localeDate.setHours(0, 0, 0, 0);

  return localeDate;
};

export const getMidnightUtcDateFromLocale = (localeDate: Date): Date => {
  const utcIsoDate = new Date(localeDate);
  utcIsoDate.setUTCFullYear(localeDate.getFullYear(), localeDate.getMonth(), localeDate.getDate());
  utcIsoDate.setUTCHours(0, 0, 0, 0);

  return localeDate;
};

/**
 * Makes actual UTC Date object with same values from Locale/any Date object.
 * Warning: This function doesn't do time conversion from Locale to UTC zone.
 * @param date Date object
 * @returns UTC transformed date object
 */
export const makeUtcDate = (date: Date) => {
  const transformedUtcDate = new Date(
    Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
      date.getMilliseconds(),
    ),
  );

  return transformedUtcDate;
};

/**
 * Swaps the Dates if End date is prior to Start date.
 * @param startDate Start date (from)
 * @param endDate End date (to)
 * @param preserveDayTimings On enabled preserves the time of the Date objects, else sets the Start date and End date timings to 00:00:00 and 23:59:59 respectively. (Recommended) Disable this for transactional statements.
 * @returns Dates where Start date is always prior to End date.
 */
export const arrangeDates = (startDate: Date, endDate: Date, preserveDayTimings?: boolean) => {
  let selectedStartDate = preserveDayTimings ? startDate : moment(startDate).startOf("day").toDate();
  let selectedEndDate = preserveDayTimings ? endDate : moment(endDate).endOf("day").toDate();

  if (startDate > endDate) {
    selectedStartDate = preserveDayTimings ? endDate : moment(endDate).startOf("day").toDate();
    selectedEndDate = preserveDayTimings ? startDate : moment(startDate).endOf("day").toDate();
  }

  return { selectedStartDate, selectedEndDate };
};
