import type { CacheStatus } from "../../core/cache/cache-status";
import type { DefaultCacheLoader } from "../../core/cache/default-cache-loader";
import { logger } from "../../core/logging/logger";
import { Observable } from "../../utils/observable";
import { ObservablePaginated } from "../../utils/observable-paginated";
import type { Paginated } from "../../utils/pagination";
import { createEmptyPaginatedData } from "../../utils/pagination";
import type { AuthorizationHold } from "./authorization-hold";
import type { AuthorizationHoldService } from "./authorization-hold-service";

export class AuthorizationHoldLoader {
  public data = new ObservablePaginated<AuthorizationHold>(createEmptyPaginatedData<AuthorizationHold>());
  public cacheStatus = new Observable<CacheStatus | null>(null);

  public initializing = new Observable<boolean>(true);
  public loading = new Observable<boolean>(false);
  public refreshing = new Observable<boolean>(false);
  public error = new Observable<Error | null>(null);

  public loadingMore = new Observable<boolean>(false);
  public loadingMoreError = new Observable<Error | null>(null);

  public constructor(
    private accountId: string | null,
    private authorizationHoldService: AuthorizationHoldService,
    private cacheLoader: DefaultCacheLoader<Paginated<AuthorizationHold>>,
  ) {}

  public async load(): Promise<void> {
    if (!this.accountId || this.loading.get() || this.refreshing.get()) {
      return;
    }
    this.loading.set(true);
    this.initializing.set(false);
    await this.loadOrRefresh();
    this.loading.set(false);
  }

  public async refresh(): Promise<void> {
    if (!this.accountId || this.refreshing.get() || this.loading.get()) {
      return;
    }
    this.refreshing.set(true);
    await this.loadOrRefresh(true);
    this.refreshing.set(false);
  }

  private async loadOrRefresh(forceRefresh?: boolean) {
    if (this.accountId) {
      const accountId: string = this.accountId;
      try {
        this.error.set(null);

        const transactions = await this.cacheLoader.load(
          () => this.authorizationHoldService.fetchAuthorizationHolds(accountId, { sort: "-date", offset: 0 }),
          forceRefresh,
          this.accountId,
        );

        if (!transactions) {
          throw Error("Failed to fetch transactions");
        }
        await this.updateCacheStatus();
        this.data.set(transactions);
      } catch (e) {
        logger.debug("AuthorizationHoldLoader", "Failed to load or refresh authorizations hold", e);
        this.error.set(e);
        this.data.set(createEmptyPaginatedData<AuthorizationHold>());
      }
    }
  }

  public async loadMore(): Promise<void> {
    if (this.accountId) {
      const accountId: string = this.accountId;
      if (!this.accountId || this.loading.get() || this.loadingMore.get()) {
        return;
      }
      try {
        this.loadingMore.set(true);
        this.loadingMoreError.set(null);

        const additional = await this.authorizationHoldService.fetchAuthorizationHolds(accountId, {
          offset: this.data.get().offset + this.data.get().limit,
          limit: this.data.get().limit,
          sort: this.data.get().sort,
        });

        this.data.add(additional);

        await this.cacheLoader.store(this.data.get(), this.cacheStatus.get()?.creation, accountId);
      } catch (e) {
        logger.debug("AuthorizationHoldLoader", "Failed to load more authorizations hold", e);
        this.loadingMoreError.set(e);
      } finally {
        this.loadingMore.set(false);
      }
    }
  }

  private async updateCacheStatus() {
    if (this.accountId) {
      const cacheStatus = await this.cacheLoader.readStatus(this.accountId);
      this.cacheStatus.set(cacheStatus);
    }
  }

  public async clear() {
    if (this.accountId) {
      await this.cacheLoader.clear(this.accountId);
      this.data.set(createEmptyPaginatedData<AuthorizationHold>());
      this.cacheStatus.set(null);
    }
  }
}
