import type { AxiosInstance } from "axios";
import axios from "axios";

import type { ErrorStore } from "../../error-store";
import type { DebugInterface } from "../debug/debug-interface";
import { addBaseUrlInterceptor } from "./interceptors/add-base-url-interceptor";
import { addSecuredCookieInterceptor } from "./interceptors/add-secured-cookie-interceptor";
import { addUserAgentInterceptor } from "./interceptors/add-user-agent-interceptor";
import { debugResponseInterceptor } from "./interceptors/debug-response-interceptor";
import { addRequestInterceptor, addResponseInterceptor } from "./interceptors/interceptor";
import { logResponseInterceptor } from "./interceptors/log-response-interceptor";
import type { SecuredCookiesService } from "./secured-cookies-service";
import type { UserAgentService } from "./user-agent-service";

export class OauthHttpService {
  public instance: AxiosInstance;

  public constructor(
    private errorStore: ErrorStore,
    private customUserAgentService: UserAgentService | null,
    private securedCookiesService?: SecuredCookiesService,
    private debugIntercept?: DebugInterface | undefined,
  ) {
    this.instance = axios.create();
    addRequestInterceptor(this.instance, addBaseUrlInterceptor());
    if (this.securedCookiesService) {
      addRequestInterceptor(this.instance, addSecuredCookieInterceptor(this.securedCookiesService));
    }
    if (this.customUserAgentService) {
      addRequestInterceptor(this.instance, addUserAgentInterceptor(this.customUserAgentService));
    }
    if (this.debugIntercept) {
      addResponseInterceptor(this.instance, debugResponseInterceptor(debugIntercept));
    }
    addResponseInterceptor(this.instance, logResponseInterceptor);

    this.instance.interceptors.response.use(
      (response) => response,
      (error) => {
        if ((error.response && error.response.status === 429) || error.code == "ERR_NETWORK") {
          this.errorStore.error.set(error);
          return Promise.resolve();
        }
        return Promise.reject(error);
      },
    );
  }
}
