import moment from "moment";
import React, { useMemo } from "react";
import styled from "styled-components";

import { useIntl } from "../../../../shared/core/i18n/use-intl";
import type {
  RecurringTransferFrequency,
  RecurringTransferOption,
  RecurringTransferParams,
} from "../../../../shared/domains/transactions/recurring-transfer";
import { RECURRING_TRANSFER_FREQUENCY_OPTIONS } from "../../../../shared/domains/transactions/recurring-transfer";
import { SwitchContainer } from "../buttons/switch-button";
import FormLabel from "../forms/form-label";
import { SelectInput } from "../forms/select-input";
import { TextInput } from "../forms/text-input";

interface RecurringTransferFormProps {
  value: RecurringTransferParams;
  onChange: (value: RecurringTransferParams) => void;
}

const RecurringTransferForm: React.FC<RecurringTransferFormProps> = (props) => {
  const { value, onChange } = props;
  const { formatMessage } = useIntl();

  const handleChange = (val: string | boolean | RecurringTransferFrequency, key: keyof RecurringTransferParams) => {
    onChange({ ...value, [key]: val });
  };

  const matchingFrequencyOption = useMemo(
    () =>
      RECURRING_TRANSFER_FREQUENCY_OPTIONS.find(
        (option) =>
          option.frequency.unit === value.frequency.unit && option.frequency.interval === value.frequency.interval,
      ) || null,
    [value.frequency.unit, value.frequency.interval],
  );

  const minimumDates = useMemo(
    () => ({
      firstExecutionDate: moment().add(1, "day").format("YYYY-MM-DD"),
      endDate: moment(value.firstExecutionDate).add(1, "day").format("YYYY-MM-DD"),
    }),
    [value.firstExecutionDate],
  );

  return (
    <Container>
      <SwitchContainer
        value={value.enabled}
        labelPosition="left"
        onSwitch={(checked) => handleChange(checked, "enabled")}
        getSwitchLabel={() => formatMessage("recurringTransfer.switchLabel")}
      />
      {value.enabled && (
        <>
          <FormLabel
            label="recurringTransfer.firstExecutionDateLabel"
            size="normal"
            margin="dense"
            required
            htmlFor="firstExecutionDate"
          />
          <TextInput
            id="firstExecutionDate"
            value={value.firstExecutionDate}
            type="date"
            required
            min={minimumDates.firstExecutionDate}
            onChange={(date) => handleChange(date.target.value, "firstExecutionDate")}
          />
          <FormLabel
            label="recurringTransfer.frequencyLabel"
            size="normal"
            margin="dense"
            required
            htmlFor="frequency"
          />
          <SelectInput<RecurringTransferOption>
            innerId="frequency"
            value={matchingFrequencyOption}
            options={RECURRING_TRANSFER_FREQUENCY_OPTIONS}
            itemRenderer={(option) => formatMessage(option.label)}
            required
            style={{
              width: "100%",
            }}
            onChange={(option) => handleChange(option.frequency, "frequency")}
          />
          <FormLabel label="recurringTransfer.endDateLabel" size="normal" margin="dense" htmlFor="endDate" />
          <TextInput
            id="endDate"
            value={value.endDate}
            type="date"
            min={minimumDates.endDate}
            onChange={(date) => handleChange(date.target.value, "endDate")}
          />
        </>
      )}
    </Container>
  );
};

export default RecurringTransferForm;

const Container = styled.div`
  margin-top: 10px;
  padding: 10px 0;
`;
