import { createContext } from "react";

import type { Transaction } from "../../shared/domains/accounting-transaction/transaction/transaction";
import type { BillIssuer } from "../../shared/domains/bills/bill";
import type { AdditionalData } from "../../shared/domains/bills/bill-service";
import type { PincodeSubmission } from "../../shared/domains/pincode/pincode";
import type { ConfirmationMode } from "../../shared/domains/transactions/transaction-request";
import type { PincodeState } from "./keyboard-machine-type";
import type { PaybillMachineContext, PaybillState } from "./paybill-machine";

interface PaybillMachineProps {
  state: PaybillState | PincodeState;
  context: PaybillMachineContext;
  startPaybillConfirmation: (
    billIssuer: BillIssuer,
    sessionId: string,
    confirmationMode: ConfirmationMode,
    transaction: Transaction,
    additionalData: AdditionalData | undefined,
  ) => void;
  submitPincode: (submission: PincodeSubmission) => void;
}

export const PaybillContext = createContext<PaybillMachineProps>(null as never);
