import type { Transaction } from "./transaction";

export function accountsAffectedByTransaction(transaction: Transaction): string[] {
  const accountIds: string[] = [];
  if (transaction.detailedAccounting) {
    transaction.detailedAccounting.map((accountingEntry) => {
      if (!accountIds.includes(accountingEntry.accountId)) {
        accountIds.push(accountingEntry.accountId);
      }
    });
  }
  return accountIds;
}
