import { useCallback, useState } from "react";

import { authenticationsSessionsService, cardManager } from "../../core/service/services";
import type { Card } from "./card";
import type { OverrideCardData } from "./card-service";

export enum CardSensitiveDetailsStep {
  Init = "INIT",
  Confirm = "CONFIRM",
  Success = "SUCCESS",
  Error = "ERROR",
  SCACheck = "SCA_CHECK",
}

export const useCardSensitiveDetails = () => {
  const [step, setStep] = useState(CardSensitiveDetailsStep.Init);
  const [scaToken, setScaToken] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [sensitiveData, setSensitiveData] = useState<OverrideCardData>();

  const [card, setCard] = useState<Card | undefined>(undefined);

  const [textError, setTextError] = useState<string | undefined>(undefined);

  const resetStep = () => {
    setStep(CardSensitiveDetailsStep.Init);
  };

  const resetStepToConfirm = () => {
    setStep(CardSensitiveDetailsStep.Confirm);
  };

  const getSensitiveCardDetailsFlow = useCallback(
    async (cardToUse: Card, scaSessionToken: string) => {
      try {
        setLoading(true);
        const data = await cardManager.getSensitiveCardDetails(cardToUse.id, scaSessionToken);
        setSensitiveData(data);
        setStep(CardSensitiveDetailsStep.Success);
        setLoading(false);
        return data;
      } catch (e) {
        const result = e as any;
        //SCA use case, must send and verify the given token
        if (result.response?.data?.error === "Unauthorized" && result.response?.data?.new_token) {
          setScaToken(result.response.data.new_token);
          setStep(CardSensitiveDetailsStep.SCACheck);
          await authenticationsSessionsService.sendAuthentication(result.response.data.new_token);
          setLoading(false);
        } else {
          setLoading(false);
          setTextError(result.response?.data?.error?.message || result.toString());
          setStep(CardSensitiveDetailsStep.Error);
          throw e;
        }
      }
    },
    [setStep, setTextError],
  );

  const getSensitiveCardDetails = useCallback(
    async (card: Card, scaSessionToken?: string) => await getSensitiveCardDetailsFlow(card, scaSessionToken || ""),
    [getSensitiveCardDetailsFlow],
  );

  return {
    step,
    loading,
    card,
    sensitiveData,
    scaToken,
    error: textError,
    setCard,
    getSensitiveCardDetails,
    resetStep,
    resetStepToConfirm,
  };
};
