import React, { useEffect } from "react";
import styled from "styled-components";

import type { FormInput } from "../../../../../shared/core/data-forms/form-input-types";
import { FormInputType } from "../../../../../shared/core/data-forms/form-input-types";
import type { BillIssuer } from "../../../../../shared/domains/bills/bill";
import { FormField } from "../../../common/data-forms/form-field";
import type { TextFieldProps } from "../../../common/data-forms/text-field";
import { TextField } from "../../../common/data-forms/text-field";
import { theme } from "../../../styles/theme";

export function BillFormItem<T>({
  input,
  issuer,
  onChange,
  showLeftDecoration,
  disabled,
}: {
  input: FormInput;
  issuer: BillIssuer;
  onChange?: (value: T) => void;
  showLeftDecoration?: boolean;
  disabled?: boolean;
}) {
  return (
    <>
      <Section>
        <Bullet />
        <SectionText>
          {input.label} {input.required ? "*" : ""}
        </SectionText>
      </Section>
      <FormPart showLeftDecoration={showLeftDecoration}>
        {input.description && <Description>{input.description}</Description>}
        {input.type === FormInputType.Text ? (
          <TextFieldWithAutofillReference
            input={input}
            disabled={disabled}
            issuer={issuer}
            onChange={onChange as any}
          />
        ) : (
          <FormField input={input} disabled={disabled} selectFieldType="combobox" onChange={onChange} />
        )}
      </FormPart>
    </>
  );
}

const TextFieldWithAutofillReference: React.FC<TextFieldProps & { issuer?: BillIssuer }> = ({ issuer, ...props }) => {
  useAutofillReference(props.input, issuer, props.onChange);
  return <TextField {...props} />;
};

function useAutofillReference(input: FormInput, issuer?: BillIssuer, onChange?: (str: string) => void) {
  useEffect(() => {
    if (!input.value && input.id === "billId" && issuer?.references && onChange) {
      onChange(issuer.references[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

const Bullet = () => (
  <BulletContainer>
    <OutterBullet>
      <InnerBullet />
    </OutterBullet>
  </BulletContainer>
);

const FormPart: React.FC<{ showLeftDecoration?: boolean; children: React.ReactNode }> = ({
  showLeftDecoration,
  children,
}) => (
  <FormPartContainer>
    <div
      style={{
        marginRight: 23,
        width: 4,
        backgroundColor: "black",
        borderRadius: 4,
        opacity: showLeftDecoration ? 1 : 0,
      }}
    />
    <FormPartChildrenContainer>{children}</FormPartChildrenContainer>
  </FormPartContainer>
);

const FormPartContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  padding-left: 22px;
`;

const FormPartChildrenContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 32px 10px 0;
  flex-grow: 1;
`;

const Section = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px;
`;

const SectionText = styled.span`
  ${theme.boldText};
  font-size: 15px;
  color: black;
  margin-left: 10px;
`;

const Description = styled.span`
  ${theme.text}
  margin-bottom: 15px;
`;

const BulletContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const OutterBullet = styled.div`
  display: flex;
  width: 28px;
  height: 28px;
  border-radius: 28px;
  background-color: #ffffff;
  justify-content: center;
  align-items: center;
  box-shadow: 0 8px 20px 0 rgba(0, 0, 0, 0.1);
`;
const InnerBullet = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 8px;
  background-color: #000000;
`;
