import filesize from "filesize";
import * as React from "react";
import { useState } from "react";
import styled from "styled-components";

import type { FileProps } from "../../../../shared/core/data-forms/form-input-types";
import { useIntl } from "../../../../shared/core/i18n/use-intl";
import { isDefined } from "../../../../shared/utils/assert";
import BinImage from "../../../assets/images/bills/bin.png";
import DownloadImage from "../../../assets/images/bills/download.png";
import GreenCheckImage from "../../../assets/images/bills/green-check.png";
import PreviewImage from "../../../assets/images/bills/preview.png";
import { useRTL } from "../../../domain/language/use-rtl";
import { shadows, theme } from "../../styles/theme";
import { BadgeCount } from "../data-forms/components/badge-count";
import { ErrorMessage } from "../error-message";
import { DownloadIcon } from "../svg/download-icon";

const MAX_SIZE_ALLOWED = 7340032; // 7MB
const MAX_SIZE_FILENAME = 15;

export enum FileUploaderType {
  PDF = "application/pdf",
  JPG = "image/jpg",
  JPEG = "image/jpeg",
  PNG = "image/png",
  BMP = "image/bmp",
  GIF = "image/gif",
  DOCX = "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
}

export enum LegacyFileUploaderType {
  PDF = "pdf",
  JPG = "jpg",
  JPEG = "jpeg",
  PNG = "png",
  BMP = "bmp",
  GIF = "gif",
  DOCX = "docx",
}

export const DEFAULT_FILE_UPLOADER_TYPES = [
  FileUploaderType.PDF,
  FileUploaderType.JPG,
  FileUploaderType.JPEG,
  FileUploaderType.PNG,
  FileUploaderType.BMP,
  FileUploaderType.GIF,
  FileUploaderType.DOCX,
];

export const DEFAULT_LEGACY_FILE_UPLOADER_TYPES = [
  LegacyFileUploaderType.PDF,
  LegacyFileUploaderType.JPG,
  LegacyFileUploaderType.JPEG,
  LegacyFileUploaderType.PNG,
  LegacyFileUploaderType.BMP,
  LegacyFileUploaderType.GIF,
  LegacyFileUploaderType.DOCX,
];

const FILE_UPLOADER_TYPE_EXTENSIONS: Map<FileUploaderType, string> = new Map([
  [FileUploaderType.PDF, "PDF"],
  [FileUploaderType.JPG, "JPG"],
  [FileUploaderType.JPEG, "JPEG"],
  [FileUploaderType.PNG, "PNG"],
  [FileUploaderType.PNG, "BMP"],
  [FileUploaderType.PNG, "GIF"],
  [FileUploaderType.PNG, "DOCX"],
]);

export const MATCH_FILE_UPLOADER_TYPES: Record<LegacyFileUploaderType, FileUploaderType> = {
  [LegacyFileUploaderType.PDF]: FileUploaderType.PDF,
  [LegacyFileUploaderType.JPG]: FileUploaderType.JPG,
  [LegacyFileUploaderType.JPEG]: FileUploaderType.JPEG,
  [LegacyFileUploaderType.PNG]: FileUploaderType.PNG,
  [LegacyFileUploaderType.BMP]: FileUploaderType.BMP,
  [LegacyFileUploaderType.GIF]: FileUploaderType.GIF,
  [LegacyFileUploaderType.DOCX]: FileUploaderType.DOCX,
};

const matchingLegacyUploadType = (type: LegacyFileUploaderType): FileUploaderType | null => {
  switch (type) {
    case LegacyFileUploaderType.PDF:
      return FileUploaderType.PDF;
    case LegacyFileUploaderType.JPG:
      return FileUploaderType.JPG;
    case LegacyFileUploaderType.JPEG:
      return FileUploaderType.JPEG;
    case LegacyFileUploaderType.PNG:
      return FileUploaderType.PNG;
    case LegacyFileUploaderType.BMP:
      return FileUploaderType.BMP;
    case LegacyFileUploaderType.GIF:
      return FileUploaderType.GIF;
    case LegacyFileUploaderType.DOCX:
      return FileUploaderType.DOCX;
    default:
      return null;
  }
};

interface FileUploaderProps {
  id?: string;
  initialFile?: FileProps;
  hasExistingFile?: boolean;
  fileUploaded: (fileUploaded: File | null, fileProps?: FileProps) => void;
  onPreviewClicked?: () => void;
  onImageDeleted?: () => void;
  typeAllowed?: (FileUploaderType | LegacyFileUploaderType)[];
  maxSize?: number;
  className?: string;
  instructions?: string;
  disabled?: boolean;
  required?: boolean;
  showDownloadIcon?: boolean;
  badgeIndex?: number;
  fileProps?: FileProps;
}

export const FileUploader: React.FC<FileUploaderProps> = ({
  id,
  hasExistingFile,
  initialFile,
  fileUploaded,
  onPreviewClicked,
  onImageDeleted,
  typeAllowed,
  className,
  instructions,
  disabled,
  required,
  maxSize,
  showDownloadIcon,
  badgeIndex,
  fileProps,
}) => {
  const { formatMessage } = useIntl();
  const maxSizeAllowed = maxSize ?? MAX_SIZE_ALLOWED;
  const [fileAccepted, setFileAccepted] = useState(!!initialFile);
  const [fileProperties, setFileProperties] = useState<FileProps | null>(null);
  const [error, setError] = useState("");
  const uploadWording = instructions ?? "";
  const { isRTL } = useRTL();

  const updateFile = (file: File | null, props?: FileProps) => {
    setFileAccepted(!!file);
    fileUploaded(file, props);
  };

  const formatAllowedString = typeAllowed
    ? typeAllowed.join(",")
    : Array.from(FILE_UPLOADER_TYPE_EXTENSIONS.keys()).join(", ");

  const onFileAdded = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileList = event.target.files;
    if (fileList && fileList.length > 0) {
      const fileName = truncateFilename(fileList[0].name, MAX_SIZE_FILENAME);
      const fileType = fileList[0].type;
      const biggerThanAllowed = fileList[0].size > maxSizeAllowed;
      const wrongFormat = typeAllowed
        ? !typeAllowed.find((type) => type === fileType) &&
          !typeAllowed.find((type) => fileType === matchingLegacyUploadType(type as LegacyFileUploaderType))
        : !DEFAULT_FILE_UPLOADER_TYPES.includes(fileType as FileUploaderType);

      if (biggerThanAllowed) {
        setError(formatMessage("formError.invalidFileSize", { size: filesize(maxSizeAllowed) }));
        return;
      } else if (wrongFormat) {
        setError(formatMessage("formError.invalidFileFormat", { formats: formatAllowedString }));
      } else {
        setError("");
        const fileSizeLabel = filesize(fileList[0].size);
        const filePropsData = { name: fileName, size: fileSizeLabel, type: fileType };
        updateFile(fileList[0], filePropsData);
        setFileProperties(filePropsData);
      }
    }
  };

  function truncateFilename(originalFilename: string, len: number): string {
    const extension = originalFilename
      .substring(originalFilename.lastIndexOf(".") + 1, originalFilename.length)
      .toLowerCase();
    let filename = originalFilename.replace("." + extension, "");
    if (filename.length <= len) {
      return originalFilename;
    } else {
      const maxSizeTruncateFilename = Math.floor(len / 2);
      const firstPartStart = 0;
      const firstPartEnd = firstPartStart + maxSizeTruncateFilename;
      const secondPartStart = filename.length - maxSizeTruncateFilename;
      const secondPartEnd = secondPartStart + maxSizeTruncateFilename;
      filename =
        filename.substring(firstPartStart, firstPartEnd) + "..." + filename.substring(secondPartStart, secondPartEnd);
      return filename + "." + extension;
    }
  }

  React.useEffect(() => {
    if (fileProps) {
      setFileProperties(fileProps);
      setFileAccepted(true);
    }
  }, [fileProps]);

  return (
    <FileUploadContainer className={className}>
      <DropArea $isRTL={isRTL}>
        {fileAccepted || hasExistingFile ? (
          <>
            <GreenCheck $isRTL={isRTL} src={GreenCheckImage} />
            <FileLabel $isRTL={isRTL}>
              {fileProperties && (
                <>
                  <span>{fileProperties.name}</span>
                  <FileSizeStyle>({fileProperties.size})</FileSizeStyle>
                </>
              )}
            </FileLabel>

            {hasExistingFile && !!onPreviewClicked ? (
              <PreviewIcon $isRTL={isRTL} onClick={() => onPreviewClicked()} src={PreviewImage} />
            ) : null}
            <RemoveFileIcon
              $isRTL={isRTL}
              src={BinImage}
              onClick={() => {
                updateFile(null);
                onImageDeleted?.();
              }}
            />
          </>
        ) : (
          <>
            {isDefined(badgeIndex) && <BadgeCount num={`${badgeIndex}`} margin={10} />}
            <Placeholder>{uploadWording}</Placeholder>
            {showDownloadIcon ? <DownloadFileIcon $isRTL={isRTL} /> : <FileIcon $isRTL={isRTL} src={DownloadImage} />}
          </>
        )}
        {!fileAccepted && !hasExistingFile && (
          <input
            id={id}
            type="file"
            accept={formatAllowedString}
            required={required}
            onChange={onFileAdded}
            disabled={disabled}
            autoFocus
          />
        )}
      </DropArea>
      {error ? <StyledErrorMessage>{error}</StyledErrorMessage> : null}
    </FileUploadContainer>
  );
};

const StyledErrorMessage = styled(ErrorMessage)`
  margin-top: 10px;
`;

const FileSizeStyle = styled.span`
  color: #828282;
  padding-left: 5px;
`;

const FileUploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 0.875rem;
  ${theme.mediumText};
`;

const DropArea = styled.div<{ dragging?: boolean; $isRTL: boolean }>`
  position: relative;
  padding: ${(props) => (props.$isRTL ? "15px 17px 14px 66px" : "15px 66px 14px 17px")};
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  background-color: #ffffff;
  border-radius: 10px;
  ${shadows.medium};
  border: ${(props) => (props.dragging ? "1px dashed #2b2b2b" : "1px solid #ffffff")};

  input[type="file"],
  input[type="file"]::-webkit-file-upload-button {
    appearance: none;
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    width: 100%;
  }

  input[type="file"]:not(:disabled) {
    cursor: pointer;
  }
`;

const FileLabel = styled.div<{ $isRTL: boolean }>`
  min-height: 20px;
  display: flex;
  flex-direction: column;
  margin-left: ${(props) => (props.$isRTL ? 0 : 35)}px;
  margin-right: ${(props) => (props.$isRTL ? 35 : 0)}px;
`;

const SideIcon = styled.img`
  position: absolute;
  cursor: pointer;
  top: 50%;
  transform: translateY(-50%);
  width: 25px;
  height: 25px;
  border-radius: 7px;
`;

const FileIcon = styled(SideIcon)<{ $isRTL: boolean }>`
  right: ${(props) => (props.$isRTL ? "unset" : "10px")};
  left: ${(props) => (props.$isRTL ? "10px" : "unset")};
`;

const DownloadFileIcon = styled(DownloadIcon)<{ $isRTL: boolean }>`
  position: absolute;
  cursor: pointer;
  top: 50%;
  transform: translateY(-50%);
  right: ${(props) => (props.$isRTL ? "unset" : "10px")};
  left: ${(props) => (props.$isRTL ? "10px" : "unset")};
  width: 25px;
  height: 25px;
  border-radius: 7px;
`;

const RemoveFileIcon = styled(SideIcon)<{ $isRTL: boolean }>`
  width: 20px;
  height: 20px;
  right: ${(props) => (props.$isRTL ? "unset" : "10px")};
  left: ${(props) => (props.$isRTL ? "10px" : "unset")};
  z-index: 2;
`;

const GreenCheck = styled(SideIcon)<{ $isRTL: boolean }>`
  width: 35px;
  height: 35px;
  right: ${(props) => (props.$isRTL ? "10px" : "unset")};
  left: ${(props) => (props.$isRTL ? "unset" : "10px")};
  z-index: 2;
  padding: 10px;
  border-radius: 35px;
  background-color: #2dd794;
`;

const Placeholder = styled.span`
  color: #b1b1b1;
  font-weight: bold;
`;

const PreviewIcon = styled(SideIcon)<{ $isRTL: boolean }>`
  right: ${(props) => (props.$isRTL ? "unset" : "40px")};
  left: ${(props) => (props.$isRTL ? "40px" : "unset")};
`;
