import { useIntl } from "../../../../../shared/core/i18n/use-intl";
import type { AuthorizationHoldType } from "../../../../../shared/domains/authorization-hold/authorization-hold";

export const useAuthorizationHoldLabel = () => {
  const { formatMessage } = useIntl();

  const authorizationHoldLabels = {
    ["CASHOUT"]: "authorizationHolds.cashoutLabel",
    ["PAYMENT"]: "authorizationHolds.paymentLabel",
    ["EXTERNAL_TO_ACCOUNT"]: "authorizationHolds.externalToAccountLabel",
    ["ACCOUNT_TO_EXTERNAL"]: "authorizationHolds.accountToExternalLabel",
    ["EXTERNAL_FROM_ACCOUNT"]: "authorizationHolds.externalFromAccountLabel",
    ["ACCOUNT_RECHARGE"]: "authorizationHolds.accountRechargeLabel",
    ["ON_US_CREDIT_TRANSFER"]: "authorizationHolds.onUsCreditTransferLabel",
    ["MANAGEMENT_FEES"]: "authorizationHolds.managementFeesLabel",
  };

  const labelForType = (type: AuthorizationHoldType) =>
    authorizationHoldLabels[type] ? formatMessage(authorizationHoldLabels[type]) : type;

  return {
    labelForType,
  };
};
