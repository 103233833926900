import { logger } from "../../core/logging/logger";
import type { ConnectedServiceDomainApiService } from "../../core/net/connected-service-domain-api-service";
import type { ExchangeRate } from "./currency";

export class CurrencyService {
  public constructor(private connectedServiceDomainApiService: ConnectedServiceDomainApiService) {}

  public async getExchangeRates(): Promise<ExchangeRate[]> {
    try {
      const response = await this.connectedServiceDomainApiService.instance.get<ExchangeRate[]>("/exchange-rates");
      return response.data;
    } catch (e) {
      logger.debug("CurrencyService", "Fetch exchange rates failed", e);
      throw e;
    }
  }
}
