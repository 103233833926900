import { logger } from "../../core/logging/logger";
import type { ConnectedServiceDomainApiService } from "../../core/net/connected-service-domain-api-service";
import type { Paginated } from "../../utils/pagination";
import type { Account } from "./account";
import { AccountStatus } from "./account";

export class AccountService {
  public constructor(private apiService: ConnectedServiceDomainApiService) {}

  public async fetchAccounts() {
    try {
      const response = await this.apiService.instance.get<Paginated<Account>>("/accounts", {
        params: {
          status: [
            AccountStatus.Opened,
            AccountStatus.Blocked,
            AccountStatus.DebitBlocked,
            AccountStatus.CreditBlocked,
          ],
          limit: 200,
        },
      });
      //TODO keep until next part of ticket (vc-1086) is done
      // const fake = response.data.items.filter((e) => +e.id === +"17000660633")[0];
      // console.log(fake);
      // return { ...response.data, items: [fake], count: 1, total: 1 };
      return response.data;
    } catch (e) {
      logger.debug("AccountService", "Failed to fetch accounts", e);
      throw e;
    }
  }

  public async updateAccountName(accountId, name) {
    try {
      const response = await this.apiService.instance.patch(`/accounts/${accountId}/name`, {
        name: name,
      });
      return response.data;
    } catch (e) {
      logger.debug("AccountService", "Failed to update account name", e);
      throw e;
    }
  }
}
