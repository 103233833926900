import { useEffect, useState } from "react";

import { Observable } from "./observable";
import type { Paginated } from "./pagination";

export class ObservablePaginated<T> extends Observable<Paginated<T>> {
  public add(page: Paginated<T>) {
    const data = {
      items: [...this.get().items, ...page.items],
      count: page.count,
      total: page.total,
      offset: page.offset,
      limit: page.limit,
      sort: page.sort,
    };
    this.set(data);
  }
}

export function useObservablePaginated<T>(observable: ObservablePaginated<T>) {
  const [value, setValue] = useState(observable.get());
  useEffect(() => {
    observable.onChange.add(setValue);
    if (observable.get() !== value) {
      setValue(observable.get());
    }
    return () => observable.onChange.remove(setValue);
  }, [observable, value]);
  return value;
}
