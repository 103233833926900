import React from "react";
import styled from "styled-components";

import { useIntl } from "../../../../../../shared/core/i18n/use-intl";
import type { Keyboard } from "../../../../../../shared/domains/pincode/keyboard";
import type { PincodeSubmission } from "../../../../../../shared/domains/pincode/pincode";
import { MarkdownText } from "../../../../common/forms/markdown-text";
import { PincodeKeyboard } from "../../../../common/keyboard/pincode-keyboard";
import { PadlockLocked } from "../../../../common/svg/padlock";
import { shadows, theme } from "../../../../styles/theme";
import { FormWithButtons } from "../../shared/components/form-with-buttons";

export const RegisterPincodeStep: React.FC<{
  onSubmit: () => void;
  onBackward: () => void;
  errorMessage?: string | null;
}> = ({ onSubmit, onBackward, errorMessage }) => {
  const { formatMessage } = useIntl();

  return (
    <FormWithButtons onSubmit={onSubmit} errorMessage={errorMessage} onBackward={onBackward}>
      <Container>
        <Title>{formatMessage("registerScreen.pincodeStepTitle")}</Title>
        <Label>{formatMessage("registerScreen.message")}</Label>
        <Centering>
          <RoundContainer>
            <LockImage />
          </RoundContainer>
        </Centering>
        <WarningText>{formatMessage("registerScreen.doNotForgetWarning")}</WarningText>
      </Container>
    </FormWithButtons>
  );
};

export const RegisterNewPincodeStep: React.FC<{
  keyboard?: Keyboard;
  onSubmit: (submission: PincodeSubmission) => void;
  onBackward: () => void;
  errorMessage?: string | null;
  loading?: boolean;
}> = ({ onSubmit, onBackward, errorMessage, keyboard, loading }) => {
  const { formatMessage } = useIntl();

  return (
    <FormWithButtons onBackward={onBackward}>
      <Container>
        <Title>{formatMessage("registerScreen.pincodeStepTitle")}</Title>
        <StyledPincodeKeyboard
          title={formatMessage("registerScreen.newCodeLabel")}
          errorMessage={errorMessage ?? undefined}
          keyboard={keyboard}
          loadingKeyboard={loading}
          onSubmit={onSubmit}
        />
      </Container>
    </FormWithButtons>
  );
};

export const RegisterConfirmPincodeStep: React.FC<{
  keyboard?: Keyboard;
  onSubmit: (submission: PincodeSubmission) => void;
  onBackward: () => void;
  errorMessage?: string | null;
}> = ({ onSubmit, onBackward, errorMessage, keyboard }) => {
  const { formatMessage } = useIntl();

  return (
    <FormWithButtons onBackward={onBackward}>
      <Container>
        <Title>{formatMessage("registerScreen.pincodeStepTitle")}</Title>
        <StyledPincodeKeyboard
          title={formatMessage("registerScreen.newCodeConfirmationLabel")}
          errorMessage={errorMessage ?? undefined}
          keyboard={keyboard}
          onSubmit={onSubmit}
        />
      </Container>
    </FormWithButtons>
  );
};

const Container = styled.div`
  flex: 1;
  max-width: 500px;
  display: flex;
  flex-direction: column;
`;

const Title = styled(MarkdownText)`
  ${theme.boldText};
  text-align: center;
  font-size: 1.875rem;
`;

const Label = styled.div`
  margin-top: 20px;
  color: #aaa;
  font-size: 0.875rem;
  ${theme.text};
  text-align: center;
  margin-bottom: 40px;
`;

const Centering = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const RoundContainer = styled.div`
  display: flex;
  width: 160px;
  height: 160px;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 90px;
  ${shadows.medium};
`;

const LockImage = styled(PadlockLocked)`
  height: 70px;
  width: 70px;
  padding: 20px;
  border-radius: 50px;
  background-color: #000;
  fill: #fff;
`;

const WarningText = styled.div`
  ${theme.text};
  text-align: center;
  font-style: italic;
  font-size: 0.875rem;
  margin-bottom: 40px;
  margin-top: 40px;
`;

const StyledPincodeKeyboard = styled(PincodeKeyboard)`
  margin-top: 40px;
`;
