import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import { useIntl } from "../../../../shared/core/i18n/use-intl";
import { accountManager } from "../../../../shared/core/service/services";
import { accountTypeLabelsWeb } from "../../../../shared/domains/account/account-types-labels";
import { groupByAccountType } from "../../../../shared/domains/account/account-utils";
import { useAccounts } from "../../../../shared/domains/account/use-accounts";
import { useObservable } from "../../../../shared/utils/observable";
import { repeat } from "../../../../shared/utils/utils";
import { Path } from "../../../core/routing/path";
import { useClient } from "../../../domain/authentication/use-client";
import { useRTL } from "../../../domain/language/use-rtl";
import { AccountListGrid } from "../../common/account/account-list-grid";
import { TertiaryLink } from "../../common/buttons/tertiary-button";
import { PageHeader } from "../../common/page/page-header";
import { theme } from "../../styles/theme";
import { AccountTile, SkeletonAccountTile } from "./components/account-tile";
import { ACCOUNT_QUERY_PARAM, useSelectedAccount } from "./utils/use-selected-account";

const SKELETON_ACCOUNT_NUMBER = 4;

export default function AllAccountsScreen() {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const { client } = useClient();
  const accounts = useAccounts();
  const accountsLoading = useObservable<boolean>(accountManager.loading);
  const [focusedAccount] = useSelectedAccount(accounts);

  const { isRTL } = useRTL();

  const groupedAccounts = groupByAccountType(accounts);

  return (
    <AccountsSection>
      <PageHeader title={formatMessage("accountsList.title")}>
        <GoBackToClassicViewLink
          to={
            focusedAccount
              ? `${Path.AccountTransactions}?${ACCOUNT_QUERY_PARAM}=${focusedAccount.id}`
              : Path.AccountTransactions
          }
        >
          {formatMessage("allAccountsScreen.goBackClassicViewButton")}
        </GoBackToClassicViewLink>
      </PageHeader>
      {!accountsLoading && accounts.length === 0 ? (
        <NoAccountAvailable>{formatMessage("allAccountsScreen.noAccountAvailable")}</NoAccountAvailable>
      ) : accountsLoading ? (
        <AccountListGrid>
          {repeat(SKELETON_ACCOUNT_NUMBER, (index) => (
            <SkeletonAccountTile key={index} $isRTL={isRTL} />
          ))}
        </AccountListGrid>
      ) : (
        groupedAccounts.map((groupedTypeAccounts) => (
          <GroupAccountContainer key={groupedTypeAccounts.type}>
            <AccountTypeText>{formatMessage(accountTypeLabelsWeb[groupedTypeAccounts.type])}</AccountTypeText>
            <AccountListGrid>
              {groupedTypeAccounts.data.map((groupedAccount, index) => (
                <StyledAccountTile
                  onAccountClick={(account) =>
                    history.push(`${Path.AccountTransactions}?${ACCOUNT_QUERY_PARAM}=${account.id}`)
                  }
                  key={groupedAccount.id + index}
                  isActive={groupedAccount.id === focusedAccount?.id}
                  account={groupedAccount}
                  owner={client}
                />
              ))}
            </AccountListGrid>
          </GroupAccountContainer>
        ))
      )}
    </AccountsSection>
  );
}

const AccountsSection = styled.section`
  align-self: stretch;
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const StyledAccountTile = styled(AccountTile)`
  text-align: left;
`;

const GoBackToClassicViewLink = styled(TertiaryLink)`
  text-align: center;
`;

const NoAccountAvailable = styled.span`
  ${theme.text};
  margin-top: 20px;
`;

const GroupAccountContainer = styled.div`
  width: 100%;
  margin-bottom: 10px;
`;

const AccountTypeText = styled.div`
  ${theme.boldText};
  font-size: 1rem;
  color: #b1b1b1;
  margin-top: 16px;
  margin-bottom: 16px;
`;
