import React, { useEffect } from "react";
import styled from "styled-components";

import type { FormSelectInput } from "../../../../shared/core/data-forms/form-input-types";
import { useIntl } from "../../../../shared/core/i18n/use-intl";
import { logger } from "../../../../shared/core/logging/logger.ts";
import type { RadioSelectorType } from "../forms/radio-selector";
import { RadioSelector } from "../forms/radio-selector";
import { SelectInput } from "../forms/select-input";
import { DisableValueText } from "./disable-value-text";

export const SelectField: React.FC<{
  input: FormSelectInput;
  onChange: (value: string | number) => void;
  disabled?: boolean;
  type: "radio" | "combobox";
  hasError?: boolean;
}> = ({ input, onChange, type, disabled, hasError = false }) => {
  const { formatMessage } = useIntl();

  useEffect(() => {
    if (!input.values) {
      logger.debug("SelectField: input.values is empty", input.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (value: string | number | undefined) => {
    onChange?.(value || value === 0 ? value : "");
  };

  return input.disabled || disabled ? (
    <DisableValueText>{getSelectedOptionLabel(input)}</DisableValueText>
  ) : type === "radio" ? (
    <StyledRadioSelector
      innerId="select"
      values={new Map<string | number, string>(input.values?.map(({ id, label }) => [id, label]))}
      value={input.value}
      onChange={handleChange}
    />
  ) : (
    // type === "combobox"
    <SelectInput
      style={{ width: "100%" }}
      innerId={input.id}
      key={input.id}
      placeholder={
        input.label
          ? `${formatMessage("selectField.select")} ${input.label.toLowerCase()}`
          : formatMessage("selectField.select_an_option")
      }
      required={input.required}
      options={input.values?.map((x) => x.id)}
      value={findValue(input)}
      onChange={handleChange}
      itemRenderer={(v) => {
        const foundValue = input.values?.find((x) => v != null && x.id != null && v.toString() === x.id.toString());
        return foundValue?.label ?? "";
      }}
      hasError={hasError}
    />
  );
};

function findValue(input: FormSelectInput) {
  if (!input.value && input.value !== 0) {
    logger.debug("input.value is empty for KYC:", input.id, "input:", input);
  }
  const matchingValue = input.values?.find((v) => v.id.toString() === input.value?.toString());
  return matchingValue ? matchingValue.id : null;
}

function getSelectedOptionLabel(input: FormSelectInput) {
  const option = input.values?.find((v) => v.id === input.value);
  if (option) {
    return option.label;
  }
  return null;
}

const StyledRadioSelector = styled<RadioSelectorType<string | number | undefined>>(RadioSelector)`
  flex-direction: column;

  > *:not(:last-child) {
    margin-bottom: 10px;
  }
`;
