import copy from "copy-to-clipboard";
import type { HTMLAttributes } from "react";
import React, { useState } from "react";
import ReactTooltip from "react-tooltip";
import styled from "styled-components";

import type { FormInput } from "../../../../../shared/core/data-forms/form-input-types";
import { useIntl } from "../../../../../shared/core/i18n/use-intl";
import { getRandomID } from "../../../../../shared/utils/random";
import { useTooltip } from "../../../../utils/use-tooltip";
import { FormField } from "../../../common/data-forms/form-field";
import { RoundedSquareButton } from "../../../common/shape/rounded-square";
import { ClipboardIcon } from "../../../common/svg/clipboard-icon";
import { PenIcon } from "../../../common/svg/pen-icon";
import { theme } from "../../../styles/theme";

interface ClipboardInformationRowProps extends HTMLAttributes<HTMLDivElement> {
  value: string;
  title: string;
  noCopy?: boolean;
  editAction?: () => void;
  input?: FormInput;
  onEditChange?: (name: string) => void;
  onEnterPressed?: (name: string) => void;
  successLabel?: string;
  errorLabel?: string;
}

export const ClipboardInformationRow = (props: ClipboardInformationRowProps) => {
  const { title, value, noCopy, editAction, input, onEditChange, onEnterPressed, successLabel, errorLabel, ...rest } =
    props;
  const { elementRef, showTooltip } = useTooltip<HTMLButtonElement>();
  const { formatMessage } = useIntl();
  const [uid] = useState(getRandomID());
  const copyToClipboard = () => {
    copy(value);
    showTooltip();
  };
  const [isEditing, setIsEditing] = useState<boolean>(false);

  return (
    <Row {...rest}>
      <Column>
        <Title>{title}</Title>
        {isEditing && input ? (
          <div>
            <FormField
              input={input}
              onChange={onEditChange}
              onBlur={(e) => (onEnterPressed ? onEnterPressed(e.target.value) : null)}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  onEnterPressed(e.target.value);
                }
              }}
            />
            {successLabel?.length > 0 && (
              <Subtitle style={{ color: theme.colors.success[500], marginTop: 8 }}>{successLabel}</Subtitle>
            )}
            {errorLabel?.length > 0 && <Subtitle style={{ color: theme.colors.error[500] }}>{errorLabel}</Subtitle>}
          </div>
        ) : (
          <Value>{value}</Value>
        )}
      </Column>
      <ActionButtonsContainer>
        {editAction && (
          <GreyRoundedSquare size={34} onClick={() => setIsEditing(!isEditing)} style={{ marginRight: 4 }}>
            <PenIcon width={18} fill="#000000" />
          </GreyRoundedSquare>
        )}
        {!noCopy && (
          <GreyRoundedSquare
            data-for={uid}
            ref={elementRef}
            size={34}
            data-tip={formatMessage("accountDetails.clipboard", { name: title })}
            onClick={copyToClipboard}
          >
            <ClipboardIcon />
          </GreyRoundedSquare>
        )}
      </ActionButtonsContainer>
      <ReactTooltip id={uid} place="top" type="dark" effect="solid" event={"none"} />
    </Row>
  );
};

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-self: stretch;
  justify-content: space-between;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 80%;
`;

const Title = styled.span`
  ${theme.text};
  font-size: 0.9375rem;
  color: #b1b1b1;
`;

const Value = styled.span`
  ${theme.boldText};
  font-size: 0.9375rem;
`;

const GreyRoundedSquare = styled(RoundedSquareButton)`
  background-color: #f4f4f4;
`;

const ActionButtonsContainer = styled.div`
  display: flex;
  align-items: center;
`;

const Subtitle = styled.div`
  ${theme.text};
  font-size: 1rem;
  color: #b1b1b1;
  text-align: center;
  margin-left: 16px;
`;
