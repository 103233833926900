import React from "react";
import type { RouteProps } from "react-router-dom";
import { Redirect, Route } from "react-router-dom";

import { authenticationManager } from "../../../../shared/core/service/services";
import { useObservable } from "../../../../shared/utils/observable";
import { PageWithNav } from "../../../ui/common/page/page-with-nav";
import { Path, SAVING_BASE_PATH, TRANSFER_BASE_PATH } from "../path";

export const AuthNavRoute = (props: RouteProps) => {
  const isConnected = useObservable(authenticationManager.isConnected);
  const { path } = props;

  const isPathIncluded = (path: string) => {
    const basePaths = [TRANSFER_BASE_PATH, SAVING_BASE_PATH];

    return basePaths.some((basePath) => path.includes(basePath));
  };

  return isConnected ? (
    <PageWithNav withTitle={isPathIncluded(path as string) ? false : path !== Path.JustifyTransaction}>
      <Route {...props} />
    </PageWithNav>
  ) : (
    <Redirect to={{ pathname: Path.Root }} />
  );
};
