import moment from "moment";

import { Currencies } from "../../../core/currency/currency";
import { logger } from "../../../core/logging/logger";
import type { ConnectedApiService } from "../../../core/net/connected-api-service";
import type { Paginated, PaginationOptions } from "../../../utils/pagination";
import { AccountingTransactionType } from "../accounting-transaction-type";
import type { AccountingTransaction } from "./transaction";
import type { TransactionFilters } from "./transaction-filter";
import { TransactionDirection } from "./transaction-filter";

export class TransactionService {
  public constructor(private apiService: ConnectedApiService) {}

  public async fetchTransaction(accountId: string, transactionId: string): Promise<AccountingTransaction> {
    try {
      const response = await this.apiService.instance.get<Paginated<AccountingTransaction>>("/transactions", {
        params: {
          accountId,
          id: transactionId,
        },
      });
      return response.data.items && response.data.items.length ? response.data.items[0] : {};
    } catch (e) {
      logger.debug("TransactionService", "Fetch transactions failed", JSON.stringify(e));
      throw e;
    }
  }

  public async fetchTransactions(
    accountId: string,
    pagination?: PaginationOptions,
    direction?: TransactionDirection,
    filter?: TransactionFilters,
  ): Promise<Paginated<AccountingTransaction>> {
    try {
      const response = await this.apiService.instance.get<Paginated<AccountingTransaction>>("/transactions", {
        params: {
          accountId,
          ...pagination,
          ...this.directionParams(direction),
          ...this.filterParam(filter),
        },
      });
      return {
        ...response.data,
        items: response.data.items.map((transaction) => ({
          ...transaction,
          objectType: AccountingTransactionType.Transaction,
        })),
      };
    } catch (e) {
      logger.debug("TransactionService", "Fetch transactions failed", JSON.stringify(e));
      throw e;
    }
  }

  private directionParams(filter: TransactionDirection | undefined) {
    switch (filter) {
      case TransactionDirection.ALL:
        return undefined;
      case TransactionDirection.INCOME:
        return { direction: "credit" };
      case TransactionDirection.OUTCOME:
        return { direction: "debit" };
      default:
        return undefined;
    }
  }

  private filterParam(filter?: TransactionFilters) {
    if (!filter) {
      return {};
    }
    let params = {};
    const [startDate, endDate] = filter.timerange.bounds;
    const todayRef = moment();
    if (startDate) {
      params = {
        ...params,
        startDate: startDate.set({ h: todayRef.hour(), m: todayRef.minutes(), s: todayRef.seconds() }).format(),
      };
    }
    if (endDate) {
      params = {
        ...params,
        endDate: endDate.set({ h: todayRef.hour(), m: todayRef.minutes(), s: todayRef.seconds() }).format(),
      };
    }
    const [minAmount, maxAmount] = filter.amountRange;
    const decimalNumber = filter.currencyId ? Currencies[filter.currencyId].decimal_number : 0;
    if (minAmount !== null) {
      params = { ...params, minAmount: minAmount * Math.pow(10, decimalNumber) };
    }
    if (maxAmount !== null) {
      params = { ...params, maxAmount: maxAmount * Math.pow(10, decimalNumber) };
    }

    if (filter.hasOwnProperty("isQualified")) {
      params = { ...params, isQualified: filter.isQualified };
    }
    if (filter.hasOwnProperty("isJustified")) {
      params = { ...params, isJustified: filter.isJustified };
    }
    if (filter.hasOwnProperty("isLocked")) {
      params = { ...params, isLocked: filter.isLocked };
    }
    if (filter.hasOwnProperty("direction")) {
      params = { ...params, direction: filter.direction };
    }
    return params;
  }
}
