import { useState } from "react";

import { logger } from "../../core/logging/logger";
import { productManager } from "../../core/service/services";
import { useObservable } from "../../utils/observable";
import { useAsyncEffect } from "../../utils/utils";

export const useProducts = () => {
  const products = useObservable(productManager.debitCardProducts);
  const [loading, setLoading] = useState(true);

  useAsyncEffect(async () => {
    try {
      setLoading(true);
      await productManager.loadDebitCardProducts();
    } catch (e) {
      logger.debug("error fetching products", e);
    } finally {
      setLoading(false);
    }
  }, []);

  return { products, loading };
};
