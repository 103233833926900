import { logger } from "../../core/logging/logger";
import type { ConnectedApiService } from "../../core/net/connected-api-service";
import type { Paginated, PaginationOptions } from "../../utils/pagination";
import { AccountingTransactionType } from "../accounting-transaction/accounting-transaction-type";
import type { FeaturesManager } from "../features/features-manager";
import type { AuthorizationHold } from "./authorization-hold";

export class AuthorizationHoldService {
  public constructor(
    private apiService: ConnectedApiService,
    private featuresManager: FeaturesManager,
  ) {}

  public async fetchAuthorizationHolds(
    accountId: string,
    pagination?: PaginationOptions,
  ): Promise<Paginated<AuthorizationHold>> {
    if (!this.featuresManager.features.get().consultAuthorizationHolds) {
      throw new Error("Feature is not enabled");
    }
    try {
      const response = await this.apiService.instance.get<Paginated<AuthorizationHold>>("/authorization-holds", {
        params: { accountId, ...pagination },
      });

      return {
        ...response.data,
        items: response.data.items.map((accountingEntry) => ({
          ...accountingEntry,
          objectType: AccountingTransactionType.AuthorizationHold,
        })),
      };
    } catch (e) {
      logger.debug("AuthorizationHoldService", "Fetch authorization hold failed", e);
      throw e;
    }
  }
}
