import type { SVGProps } from "react";
import React from "react";

export const EmptyRowsPlaceholder = (props: SVGProps<SVGSVGElement>) => (
  <svg width="176" height="176" viewBox="0 0 176 176" fill="none" {...props}>
    <path
      opacity=".64"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M32.398 120.536a65.73 65.73 0 0 0 4.685 7.032c17.036 22.272 43.413 22.84 48.228 23.051 13.48.589 28.077-2.892 40.369-9.987l.009-.006c11.706-6.759 21.321-16.796 25.895-29.716 6.197-17.51.775-33.65-2.03-42-.773-2.298-2.81-7.999-6.707-14.753l-.033-.057c-5.691-9.846-15.327-21.912-30.726-28.92-2.213-1.006-5.013-2.28-8.736-3.185-13.305-3.234-30.243.232-45.125 8.808l-.114.066c-16.63 9.62-30.675 25.63-34.168 45.8-2.636 15.224 1.103 31.122 8.416 43.802l.037.065z"
      fill="#EEE"
    />
    <rect x="40.435" y="70.981" width="123.189" height="25.635" rx="9.357" fill="#fff" />
    <rect x="47.705" y="78.3" width="10.587" height="10.678" rx="4.392" fill="#F4F4F4" />
    <ellipse cx="52.999" cy="83.638" rx="1.906" ry="1.922" fill="#D5D5D5" />
    <rect opacity=".637" x="66.365" y="77.715" width="34.879" height="4.439" rx="2.219" fill="#D5D5D5" />
    <rect x="66.365" y="85.298" width="20.245" height="4.439" rx="2.219" fill="#F4F4F4" />
    <rect x="26.38" y="40.385" width="123.189" height="25.635" rx="9.357" fill="#E8E8E8" />
    <rect opacity=".174" x="33.65" y="47.703" width="10.587" height="10.678" rx="4.392" fill="#B1B1B1" />
    <ellipse cx="38.944" cy="53.042" rx="1.906" ry="1.922" fill="#000" fillOpacity=".077" />
    <rect x="52.309" y="47.118" width="34.879" height="4.439" rx="2.219" fill="#000" fillOpacity=".077" />
    <rect opacity=".174" x="52.309" y="54.701" width="20.245" height="4.439" rx="2.219" fill="#B1B1B1" />
    <rect x="26.38" y="101.578" width="123.189" height="25.635" rx="9.357" fill="#E8E8E8" />
    <rect opacity=".174" x="33.65" y="108.896" width="10.587" height="10.678" rx="4.392" fill="#B1B1B1" />
    <ellipse cx="38.944" cy="114.235" rx="1.906" ry="1.922" fill="#000" fillOpacity=".077" />
    <rect x="52.309" y="108.311" width="34.879" height="4.439" rx="2.219" fill="#000" fillOpacity=".077" />
    <rect opacity=".174" x="52.309" y="115.894" width="20.245" height="4.439" rx="2.219" fill="#B1B1B1" />
  </svg>
);
