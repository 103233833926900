import type { HTMLAttributes } from "react";
import React from "react";
import styled from "styled-components";

import { useIntl } from "../../../../../shared/core/i18n/use-intl";
import { AccountingEntryType } from "../../../../../shared/domains/accounting-transaction/accounting-entry";
import { AccountingTransactionType } from "../../../../../shared/domains/accounting-transaction/accounting-transaction-type";
import type {
  AccountingTransaction,
  Transaction,
} from "../../../../../shared/domains/accounting-transaction/transaction/transaction";
import { useTransactionLinks } from "../../../../../shared/domains/accounting-transaction/use-transaction-links";
import type { AuthorizationHold } from "../../../../../shared/domains/authorization-hold/authorization-hold";
import type { CashTransfer } from "../../../../../shared/domains/transactions/cash-transfer/cash-transfer";
import { useRTL } from "../../../../domain/language/use-rtl";
import { AmountText } from "../../../common/amount-text";
import { Circle } from "../../../common/shape/circle";
import { shadows, theme } from "../../../styles/theme";
import { UIConstants } from "../../../styles/uiConstants";
import { useAuthorizationHoldLabel } from "../hooks/use-authorization-hold-label";

interface AccountTransactionCardProps extends HTMLAttributes<HTMLDivElement> {
  transaction: AccountingTransaction | AuthorizationHold | CashTransfer;
  received?: boolean;
  showUnjustifiedTag?: boolean;
}

export function AccountTransactionCard(props: AccountTransactionCardProps) {
  const { transaction, received, showUnjustifiedTag = false, ...rest } = props;
  const { formatDate, formatMessage } = useIntl();
  const { labelForType } = useAuthorizationHoldLabel();
  const { canShowJustifyLabel } = useTransactionLinks();
  const { isRTL } = useRTL();

  const detailedAccountingTransfer = (transaction as AccountingTransaction)?.detailedAccounting?.find(
    (accounting) => accounting.type === AccountingEntryType.Transfer,
  );
  const transactionDescription = hasObjectType(transaction)
    ? transaction.objectType === AccountingTransactionType.Transaction
      ? transaction.shortDescription || detailedAccountingTransfer?.description || transaction.type.label
      : labelForType((transaction as AuthorizationHold).type)
    : transaction.label;

  return (
    <Card $isRTL={isRTL} {...rest}>
      <IconLabelContainer>
        <Label $isRTL={isRTL}>{transactionDescription}</Label>
      </IconLabelContainer>
      <Date $isRTL={isRTL}>
        {formatDate(transaction.date, {
          month: "short",
          day: "numeric",
          year: "numeric",
        })}
      </Date>
      <UnjustifiedLabelContainer>
        {canShowJustifyLabel(transaction as Transaction) && showUnjustifiedTag ? (
          <StyledUnjustifiedLabel>
            <Dot size={6} />
            <UnjustifiedMention>{formatMessage("accountTransactions.expenseUnjustified")}</UnjustifiedMention>
          </StyledUnjustifiedLabel>
        ) : null}
      </UnjustifiedLabelContainer>
      {transaction.amount && <Amount amount={transaction.amount} $isRTL={isRTL} />}
    </Card>
  );
}

export function hasObjectType(
  transaction: AccountingTransaction | AuthorizationHold | CashTransfer,
): transaction is AccountingTransaction | AuthorizationHold {
  return (transaction as AccountingTransaction | AuthorizationHold)?.objectType !== undefined;
}

const Card = styled.div<{ $isRTL: boolean }>`
  display: flex;
  align-items: center;
  padding: ${(props) => (props.$isRTL ? "18px 15px 18px 27px" : "18px 27px 18px 15px")};
  border-radius: 13px;
  ${shadows.medium};

  background-color: #ffffff;
  @media (max-width: ${UIConstants.TABLET_BREAKPOINT - 1}px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const IconLabelContainer = styled.div`
  flex: 1 0 45%;
  display: flex;
  @media (max-width: ${UIConstants.TABLET_BREAKPOINT - 1}px) {
    flex: 0 1 auto;
  }
`;

const Label = styled.span<{ $isRTL: boolean }>`
  font-size: 1.125rem;
  line-height: 1.2rem;
  ${theme.boldText};
  margin: ${(props) => (props.$isRTL ? "auto 27px auto 0" : "auto 0 auto 27px")};
  @media (max-width: ${UIConstants.TABLET_BREAKPOINT - 1}px) {
    margin: ${(props) => (props.$isRTL ? "auto 27px 10px 0" : "auto 0 10px 27px")};
  }
`;

const Date = styled.span<{ $isRTL: boolean }>`
  ${theme.text};
  font-size: 0.9375rem;
  padding: 0 30px;
  text-align: left;
  width: 25%;
  @media (max-width: ${UIConstants.TABLET_BREAKPOINT - 1}px) {
    padding: ${(props) => (props.$isRTL ? "0 67px 8px 0" : "0 0 8px 67px")};
    width: auto;
  }
`;

const Amount = styled(AmountText)<{ $isRTL: boolean }>`
  font-size: 1.125rem;
  ${theme.boldText};
  justify-content: flex-end;
  text-align: ${(props) => (props.$isRTL ? "start" : "end")};
  flex: 1 1 25%;
  @media (max-width: ${UIConstants.TABLET_BREAKPOINT - 1}px) {
    margin: ${(props) => (props.$isRTL ? "0 67px 0 0" : "0 0 0 67px")};
    flex: 0 1 auto;
  }
`;

const Dot = styled(Circle)`
  background-color: #b1b1b1;
  margin: 0 8px;
  float: left;
`;

const UnjustifiedMention = styled.div`
  ${theme.boldText};
  font-size: 12px;
  color: ${theme.colors.warning[500]};
`;
const UnjustifiedLabelContainer = styled.div`
  width: 30%;
`;

const StyledUnjustifiedLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
