import type { SVGProps } from "react";
import React from "react";

export function PhoneIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 26 26" {...props}>
      <path
        fillRule="nonzero"
        d="M20.96 5.395c.042.041.076.089.102.14l1.196 2.393c.915 1.83.556 4.04-.89 5.486l-6.499 6.5c-1.575 1.574-3.904 2.124-6.016 1.42l-2.616-.872c-.284-.095-.437-.402-.343-.686.027-.08.072-.152.131-.211l2.872-2.873c.66-.659 1.667-.823 2.501-.405l.27.135c.835.417 1.843.253 2.502-.406l2.388-2.388c.66-.66.823-1.667.406-2.5l-.136-.271c-.417-.834-.253-1.842.406-2.501l2.96-2.96c.212-.212.555-.212.767 0zm-5.254-3.204l-.2 2.157c-2.872-.267-5.723.735-7.803 2.814-2.076 2.076-3.077 4.922-2.814 7.79l-2.158.199c-.321-3.503.904-6.985 3.44-9.521 2.54-2.54 6.028-3.765 9.535-3.44zm-.379 4.317l-.207 2.156c-1.602-.154-3.193.402-4.352 1.562-1.156 1.156-1.712 2.74-1.563 4.337l-2.158.201c-.208-2.232.572-4.453 2.189-6.07 1.622-1.622 3.852-2.401 6.091-2.186z"
      />
    </svg>
  );
}
