import IBAN from "iban";
import type { CountryCode } from "libphonenumber-js";
import { AsYouType } from "libphonenumber-js";
import React, { useEffect, useMemo, useState } from "react";
import styled, { css } from "styled-components";

import { countries } from "../../../../shared/assets/countries";
import { useIntl } from "../../../../shared/core/i18n/use-intl";
import { logger } from "../../../../shared/core/logging/logger";
import { configurationManager, featuresManager } from "../../../../shared/core/service/services";
import { isDefined } from "../../../../shared/utils/assert";
import { useObservable } from "../../../../shared/utils/observable";
import { validatePhoneNumber } from "../../../../shared/utils/phone-number";
import { PrimaryButton } from "../../common/buttons/primary-button";
import { ErrorMessage } from "../../common/error-message";
import type { PrefixedSelectInputType } from "../../common/forms/prefixed-select-input";
import { PrefixedSelectInput } from "../../common/forms/prefixed-select-input";
import { PrefixContainer, PrefixedTextInput } from "../../common/forms/prefixed-text-input";
import { TextInput } from "../../common/forms/text-input";
import type { SelectorOptions } from "../../common/selector";
import { Selector } from "../../common/selector";
import { UserIcon } from "../../common/svg/user-icon";
import { fonts, theme } from "../../styles/theme";

export interface AddBeneficiaryInfoProps {
  createPhoneRecipient: (name: string, phone: string) => void;
  createIbanRecipient: (name: string, iban: string, externalAccount: boolean) => void;
  createAccountRecipient: (name: string, account: string, bic: string) => void;
  searchBank: () => void;
  errorMessage?: string;
  loading?: boolean;
  initialName?: string;
  forExternalAccount?: boolean;
  bankName?: string;
  bicCode?: string;
  formState: { name: string; account: string };
  setFormState: React.Dispatch<React.SetStateAction<{ name: string; account: string }>>;
}
enum RecipientType {
  IBAN = "IBAN",
  PHONE = "PHONE",
  ACCOUNT = "ACCOUNT",
}

export const AddBeneficiaryDetail = (props: AddBeneficiaryInfoProps) => {
  const {
    createPhoneRecipient,
    createIbanRecipient,
    createAccountRecipient,
    searchBank,
    loading,
    errorMessage,
    initialName,
    forExternalAccount = false,
    bankName,
    bicCode,
    formState,
    setFormState,
  } = props;
  const intl = useIntl();
  const { formatMessage } = intl;

  const features = useObservable(featuresManager.features);

  const ibanOption = {
    value: RecipientType.IBAN,
    key: RecipientType.IBAN.toString(),
    text: formatMessage("addRecipient.selector.iban"),
  };

  const phoneOption = {
    value: RecipientType.PHONE,
    key: RecipientType.PHONE.toString(),
    text: formatMessage("addRecipient.selector.phone"),
  };

  const accountOption = {
    value: RecipientType.ACCOUNT,
    key: RecipientType.ACCOUNT.toString(),
    text: formatMessage("addRecipient.selector.account"),
  };

  const recipientTypeOption: SelectorOptions<RecipientType>[] = forExternalAccount
    ? [ibanOption]
    : [ibanOption, phoneOption, accountOption];
  const showRecipientsSelector = !forExternalAccount;

  const configuration = useObservable(configurationManager.configuration);
  const defaultCountry = useObservable(configurationManager.defaultCountry);

  const [recipientType, setRecipientType] = useState<RecipientType>(
    bankName ? RecipientType.ACCOUNT : RecipientType.IBAN,
  );

  const [countryCode, setCountryCode] = useState(defaultCountry);
  const [phone, setPhone] = useState("");
  const [iban, setIban] = useState("");
  const [bic, setBic] = useState(bicCode ?? "");
  const [dataError, setDataError] = useState<undefined | string>(undefined);
  const [isValid, setIsValid] = useState(false);

  const hasError = isDefined(dataError);

  const editPhone = (phoneNumber: string) => {
    const nationalNumber = "+" + countries[countryCode].phoneCode;
    const internationalPhoneNumber = phoneNumber.startsWith(nationalNumber)
      ? phoneNumber
      : nationalNumber + phoneNumber;
    const formatted = new AsYouType().input(internationalPhoneNumber).substr(nationalNumber.length);
    setPhone(formatted);
  };

  useEffect(() => {
    setDataError(undefined);
  }, [recipientType]);

  useEffect(() => {
    let canValidate = false;
    if (formState.name) {
      if (recipientType === RecipientType.PHONE) {
        canValidate = !!phone;
      } else if (recipientType === RecipientType.IBAN) {
        canValidate = !!iban;
      } else {
        canValidate = !!bic && !!formState.account;
      }
    }
    setIsValid(canValidate);
  }, [recipientType, phone, iban, formState.account, formState.name, bic]);

  const bicInput = useMemo(() => (bankName ? `${bankName} (${bic})` : bic), [bankName, bic]);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  return (
    <Container>
      <Title>{formatMessage("addRecipient.title")}</Title>
      <Subtitle>
        {formatMessage(forExternalAccount ? "addRecipient.externalAccountSubtitle" : "addRecipient.subtitle")}
      </Subtitle>
      {errorMessage && <StyledErrorMessage>{errorMessage}</StyledErrorMessage>}
      {showRecipientsSelector ? (
        <RecipientTypeSelector
          options={recipientTypeOption}
          value={recipientType}
          onChange={(type) => setRecipientType(type as RecipientType)}
          size="M"
        />
      ) : null}
      <NameInput
        prefixElement={<UserIcon height={20} width={20} color={"#000000"} />}
        value={formState.name}
        name="name"
        onChange={handleInputChange}
        placeholder={formatMessage("addRecipient.nameFieldPlaceholder")}
      />
      {recipientType === RecipientType.IBAN ? (
        <IbanInput
          placeholder={formatMessage("addRecipient.ibanFieldPlaceholder")}
          onChange={(event) => {
            if (hasError) {
              setDataError(undefined);
            }
            setIban(IBAN.printFormat(event.target.value));
          }}
          value={iban}
          hasError={hasError}
          errorMessage={formatMessage("formError.invalidIBAN")}
          dir={"ltr"}
          bottomOffset={16}
        />
      ) : recipientType === RecipientType.ACCOUNT ? (
        <>
          <AccountInput
            placeholder={formatMessage("addRecipient.accountFieldPlaceholder")}
            onChange={handleInputChange}
            value={formState.account}
            name="account"
            dir={"ltr"}
          />
          <BicInput
            placeholder={formatMessage(
              features.bicReferential ? "addRecipient.bankFieldPlaceholder" : "addRecipient.bicFieldPlaceholder",
            )}
            value={bicInput}
            onClick={features.bicReferential ? () => searchBank() : undefined}
            onChange={(event) => {
              if (hasError) {
                setDataError(undefined);
              }
              setBic(event.target.value);
            }}
            dir={"ltr"}
          />
        </>
      ) : (
        <>
          <CountrySelectInput
            onChange={(selectedCountryCode) => setCountryCode(selectedCountryCode)}
            innerId="country-select-field"
            options={configuration.countries}
            itemRenderer={(code) => countries[code].englishName}
            value={countryCode}
            prefixElement={countries[countryCode] && <FlagIcon src={countries[countryCode].flag} />}
          />
          <PhoneInput
            placeholder={formatMessage("addRecipient.phoneFieldPlaceholder")}
            prefixElement={<PhoneCode>+{countries[countryCode].phoneCode}</PhoneCode>}
            type="tel"
            value={phone}
            onChange={(event) => {
              if (hasError) {
                setDataError(undefined);
              }
              editPhone(event.target.value);
            }}
            hasError={hasError}
            errorMessage={formatMessage("common.invalidPhoneNumber")}
            bottomOffset={16}
          />
        </>
      )}
      <ConfirmButton
        size="M"
        onClick={() => {
          try {
            if (recipientType === RecipientType.PHONE) {
              const phoneNumber = validatePhoneNumber(intl, phone, countryCode).substring(1);
              createPhoneRecipient(formState.name, phoneNumber);
            } else if (recipientType === RecipientType.IBAN) {
              createIbanRecipient(formState.name, iban, forExternalAccount);
            } else if (recipientType === RecipientType.ACCOUNT) {
              createAccountRecipient(formState.name, formState.account, bic);
            }
          } catch (e) {
            logger.debug("AddBeneficiaryDetail: Confirm error", e);
          }
        }}
        disabled={!isValid}
        showSpinner={loading}
      >
        {formatMessage("addRecipient.createButton")}
      </ConfirmButton>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 56px;
`;

const Title = styled.span`
  ${fonts.bold}
  font-size: 1.5rem;
  color: ${theme.mainColor};
`;

const Subtitle = styled(Title)`
  color: black;
`;

const RecipientTypeSelector = styled(Selector)`
  margin-top: 40px;
`;

const inputStyle = css`
  width: 100%;
  max-width: 360px;
  margin-bottom: 25px;
`;

const NameInput = styled(PrefixedTextInput)`
  margin-top: 48px;
  ${inputStyle};
`;

const CountrySelectInput = styled<PrefixedSelectInputType<CountryCode>>(PrefixedSelectInput)`
  ${inputStyle};
`;

const FlagIcon = styled.img`
  width: 14px;
  height: 14px;
  border-radius: 7px;
`;

const PhoneInput = styled(PrefixedTextInput)`
  ${inputStyle};

  ${PrefixContainer} {
    width: 80px;
  }
`;

const IbanInput = styled(TextInput)`
  ${inputStyle};
`;

const AccountInput = styled(TextInput)`
  ${inputStyle};
`;

const BicInput = styled(TextInput)`
  ${inputStyle};
`;

const PhoneCode = styled.span`
  font-size: 0.875rem;
`;

const ConfirmButton = styled(PrimaryButton)`
  margin-top: 30px;
  margin-bottom: 30px;
`;

const StyledErrorMessage = styled(ErrorMessage)`
  margin: 10px 0 0 0;
  text-align: center;
`;
