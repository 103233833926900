import React from "react";
import styled from "styled-components";

import { useIntl } from "../../../../../shared/core/i18n/use-intl";
import { CONTACT_SUPPORT_MODAL_ID } from "../../../../core/modal/modal-id";
import { PrimaryButton } from "../../../common/buttons/primary-button";
import { Modal } from "../../../common/modal/modal";
import { theme } from "../../../styles/theme";
import { UIConstants } from "../../../styles/uiConstants";
import { ContactSupportModal } from "../../contact-support/contact-support-modal";
import { AccountPlaceholder } from "./account-placeholder";

export const NoAccountAvailableBlock = () => {
  const { formatMessage } = useIntl();

  return (
    <GreyCard>
      <IllustrationContainer>
        <AccountPlaceholder />
      </IllustrationContainer>
      <ContentContainer>
        <Title>{formatMessage("recharge.noAccountAvailable")}</Title>
        <Description>{formatMessage("recharge.noAccountExplanation")}</Description>
      </ContentContainer>
      <ButtonContainer>
        <ContactSupportButton
          onClick={() => {
            Modal.present(
              CONTACT_SUPPORT_MODAL_ID,
              () => (
                <ContactSupportModal
                  title={formatMessage("contactSupport.rechargeNotAvailableTitle")}
                  subtitle={formatMessage("contactSupport.rechargeNotAvailableMessage")}
                />
              ),
              { canBeDismissed: false },
            );
          }}
          size="S"
        >
          {formatMessage("recharge.contactSupport")}
        </ContactSupportButton>
      </ButtonContainer>
    </GreyCard>
  );
};

const GreyCard = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #f4f4f4;
  border-radius: 18px;
  padding: 40px;
  @media (max-width: ${UIConstants.DESKTOP_SMALL_BREAKPOINT}px) {
    flex-direction: column;
  }
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 26px;
  @media (max-width: ${UIConstants.DESKTOP_SMALL_BREAKPOINT}px) {
    margin-left: 0px;
    margin-top: 26px;
    margin-bottom: 26px;
  }
`;
const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 26px;
  @media (max-width: ${UIConstants.DESKTOP_SMALL_BREAKPOINT}px) {
    margin-left: 0px;
  }
`;
const IllustrationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media (max-width: ${UIConstants.DESKTOP_SMALL_BREAKPOINT}px) {
    align-items: center;
  }
`;
const ContactSupportButton = styled(PrimaryButton)`
  min-width: 250px;
`;
const Title = styled.div`
  font-size: 0.9375rem;
  margin-bottom: 8px;
  ${theme.boldText};
  color: #000000;
  @media (max-width: ${UIConstants.DESKTOP_SMALL_BREAKPOINT}px) {
    text-align: center;
  }
`;

const Description = styled.div`
  font-size: 0.9375rem;
  ${theme.text};
  color: #b1b1b1;
  @media (max-width: ${UIConstants.DESKTOP_SMALL_BREAKPOINT}px) {
    text-align: center;
  }
`;
