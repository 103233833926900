import type { Account, AccountType } from "./account";

type AccountSectionArray = { type: AccountType; data: Account[] }[];

export const isJointAccount = (account: Account) => account.type === "CURRENT" && account.coOwners.length > 0;

export const retrieveCoOwner = (account: Account) => account?.coOwners.filter((coOwner) => coOwner.userId !== account?.userId)[0];

export function groupByAccountType(accounts: Account[]): AccountSectionArray {
  const accountsWithJoint = accounts.map((account) => {
    if (isJointAccount(account)) {
      return { ...account, type: "JOINT" };
    }
    return account;
  });

  const accountsGroupByType = accountsWithJoint.reduce(
    (acc, curr) => {
      (acc[curr.type] = acc[curr.type] || []).push(curr);
      return acc;
    },
    {} as { [key in AccountType]: Account[] },
  );

  return Object.entries(accountsGroupByType).map((entry) => ({ type: entry[0] as AccountType, data: entry[1] }));
}
