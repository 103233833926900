import type { CountryCode } from "libphonenumber-js";
import parsePhoneNumberFromString from "libphonenumber-js";
import React, { useCallback, useState } from "react";

import type { FormPhoneInput } from "../../../../shared/core/data-forms/form-input-types";
import { configurationManager } from "../../../../shared/core/service/services";
import { getCountryByNumeric } from "../../../../shared/utils/countries";
import { useObservable } from "../../../../shared/utils/observable";
import { PhoneInput } from "../forms/phone-input";
import { DisableValueText } from "./disable-value-text";

export interface PhoneFieldProps {
  input: FormPhoneInput;
  onChange?: (value: string) => void;
  disabled?: boolean;
}

export const PhoneField: React.FC<PhoneFieldProps> = ({ input, disabled, onChange }) => {
  const defaultCountry = useObservable(configurationManager.defaultCountry);

  const [countryCode, setCountryCode] = useState<CountryCode>(
    getCountryByNumeric(input.value?.country) || defaultCountry,
  );

  const onPhoneNumberChange = useCallback(
    (phone: string) => {
      const phoneNumberParsed = parsePhoneNumberFromString(phone, countryCode);
      if (phoneNumberParsed) {
        onChange?.(phoneNumberParsed.number.substring(1));
      }
    },
    [countryCode, onChange],
  );

  return input.disabled || disabled ? (
    <DisableValueText>{input.value}</DisableValueText>
  ) : (
    <>
      <PhoneInput
        initialPhone={input.value?.number}
        required={input.required}
        onChangeCountryCode={setCountryCode}
        onChangePhoneNumber={onPhoneNumberChange}
        initialCountryCode={countryCode}
      />
    </>
  );
};
