import type { PhoneNumber } from "libphonenumber-js";

import { logger } from "../../../shared/core/logging/logger";
import type { AuthenticationManager } from "../../../shared/domains/authentication/authentication-manager";
import type { RegisteringParameters, TokenResult } from "../../../shared/domains/authentication/authentication-service";
import { AuthenticationMode } from "../../../shared/domains/configuration/configuration";
import type { ConfigurationManager } from "../../../shared/domains/configuration/configuration-manager";
import { Observable } from "../../../shared/utils/observable";

export class WebAuthExecutor {
  public isConnecting = new Observable<boolean>(false);

  constructor(
    private authenticationManager: AuthenticationManager,
    private configurationManager: ConfigurationManager,
  ) {}

  public async authenticateFromRedirect(code: string, state: string) {
    try {
      this.isConnecting.set(true);
      await this.authenticationManager.connectWithCodeGrant(code, state);
      this.authenticationManager.setIsAuthenticated(true);
    } catch (e) {
      logger.error(e);
      await this.authenticationManager.logout();
    } finally {
      this.isConnecting.set(false);
    }
  }

  public async validateRegisterOtp(
    phoneNumber: PhoneNumber,
    registeringParameters: RegisteringParameters,
  ): Promise<TokenResult | void> {
    if (this.configurationManager.configuration.get().authentication_mode === AuthenticationMode.PhonePincode) {
      return await this.authenticationManager.validateRegisterOtp(registeringParameters);
    } else {
      await this.connect(phoneNumber, registeringParameters);
    }
  }

  public async connect(phoneNumber?: PhoneNumber, registeringParameters?: RegisteringParameters) {
    if (this.configurationManager.configuration.get().authentication_mode === AuthenticationMode.LoginPassword) {
      return await this.connectWithUsernameAndPassword(phoneNumber, registeringParameters);
    }
  }

  public withPhoneNumberAndPinCode(): boolean {
    return this.configurationManager.configuration.get().authentication_mode === AuthenticationMode.PhonePincode;
  }

  private async connectWithUsernameAndPassword(
    phoneNumber?: PhoneNumber,
    registeringParameters?: RegisteringParameters,
  ) {
    await this.authenticationManager.connect(phoneNumber?.number.substring(1), registeringParameters);
  }
}
