import { Observable } from "../../utils/observable";
import { ObservableArray } from "../../utils/observable-array";
import type { IdentityRole, KycDocument } from "./identity";
import type { IdentityService } from "./identity-service";

export class IdentityManager {
  /** loading */
  public clientRoleLoading = new Observable<boolean>(false);
  public kycDocumentsLoading = new Observable<boolean>(false);

  /** data */
  public clientRole = new Observable<IdentityRole | null>(null);
  public kycDocuments = new ObservableArray<KycDocument>([]);

  /** error */
  public clientRoleError = new Observable<Error | null>(null);
  public kycDocumentsError = new Observable<Error | null>(null);
  public downloadKycError = new Observable<Error | null>(null);

  public constructor(readonly identityService: IdentityService) {}

  public async fetchClientRole(clientId: string) {
    try {
      this.clientRoleLoading.set(true);
      this.clientRoleError.set(null);

      const res = await this.identityService.fetchClientRole();
      const clientRole = res.items.find((identity: IdentityRole) => identity.id === clientId);

      this.clientRole.set(clientRole);
    } catch (e) {
      this.clientRoleError.set(e);
      throw e;
    } finally {
      this.clientRoleLoading.set(false);
    }
  }

  public async fetchKycDocuments(clientId: string) {
    try {
      this.kycDocumentsLoading.set(true);
      this.kycDocumentsError.set(null);

      const res = await this.identityService.fetchKycDocuments(clientId);

      this.kycDocuments.set(res.items);
    } catch (e) {
      this.kycDocumentsError.set(e);
      throw e;
    } finally {
      this.kycDocumentsLoading.set(false);
    }
  }

  public async downloadKycDocument(id: string) {
    try {
      this.downloadKycError.set(null);

      const res = await this.identityService.downloadKycDocument(id);

      return res;
    } catch (e) {
      this.downloadKycError.set(e);
      throw e;
    }
  }
}
