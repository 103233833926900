import type { CreditScope, Scope, SharedServiceDomainScope } from "./scope";
import { SavingsScope } from "./scope";

type AllScopes = (Scope | SharedServiceDomainScope | CreditScope | SavingsScope)[];

export const filterScopes = (scopes: AllScopes, excludedScopes: AllScopes) =>
  scopes.filter((scope) => !excludedScopes.includes(scope));

export const getApplicationScopes = (allScopes: AllScopes, isSavingsAccountCreationEnabled: boolean) => {
  const excludedScopes = isSavingsAccountCreationEnabled
    ? []
    : [SavingsScope.SavProductRead, SavingsScope.SavContractWrite];

  return filterScopes(allScopes, excludedScopes);
};
