export function isDefined<T>(data: T | null | undefined): data is T {
  if (data === null || data === undefined) {
    return false;
  }
  return true;
}

export function atLeastOneIsDefined<T>(list: (T | null | undefined)[]) {
  return list.filter((it) => isDefined(it)).length > 0;
}

/**
 * Object has-property-deep check
 * The method would iterate (recursively) on all "object" properties of the object
 * you pass in and return true as soon as it finds one that contains the property you pass in.
 * If no object contains such property, it returns false.
 *
 * @param obj The object to check
 * @param prop The property to check
 * @returns
 */
export function hasOwnDeepProperty(obj: unknown, prop: string) {
  // only performs property checks on objects (taking care of the corner case for null as well)
  if (typeof obj === "object" && obj !== null) {
    // if this object already contains the property, we are done
    if (obj.hasOwnProperty(prop)) {
      return true;
    }
    // otherwise iterate on all the properties of this object.
    for (const p in obj) {
      // and as soon as you find the property you are looking for, return true
      if (obj.hasOwnProperty(p) && hasOwnDeepProperty(obj[p], prop)) {
        return true;
      }
    }
  }
  return false;
}
