import type { GeoPosition } from "react-native-geolocation-service";

import type { Amount } from "../../../core/amount/amount";
import { logger } from "../../../core/logging/logger";
import type { ConnectedApiService } from "../../../core/net/connected-api-service";
import type { ConnectedServiceDomainApiService } from "../../../core/net/connected-service-domain-api-service";
import type { Account } from "../../account/account";
import type { ClientManager } from "../../client/client-manager";
import type { CheckPincodeService } from "../../pincode/check-pincode-service";
import type { PincodeSubmission } from "../../pincode/pincode";
import {
  AccountBlockedErrorFromErrorResponse,
  isAccountBlockedErrorResponse,
  isRecipientNotFoundByInvalidPhoneError,
} from "../../pincode/pincode-error";
import type {
  AccountOrRecipient,
  CustomerInstructionInformation,
  CustomerInstructionResult,
  PaymentAddress,
  PaymentContracts,
  PaymentNetwork,
  PaymentTransaction,
} from "../customer-instruction";
import { makeFormattedCustomerInstruction } from "../customer-instruction";
import type { RecurringTransferParams } from "../recurring-transfer";
import type { ConfirmationMode, TransactionRequest } from "../transaction-request";
import { TransactionCallType } from "../transaction-request";

export class TransferService {
  public constructor(
    private apiService: ConnectedApiService,
    private serviceDomainApiService: ConnectedServiceDomainApiService,
    private clientManager: ClientManager,
    private checkPincodeService: CheckPincodeService,
  ) {}

  public async startSimpleTransfer(amount: Amount, phoneNumber: string, label?: string, location?: GeoPosition | null) {
    try {
      const response = await this.apiService.instance.post<TransactionRequest>("/transactions/p2p-simple-transfer", {
        metadata: {
          mode: TransactionCallType.PreAuth,
          location: location ? { latitude: location.coords.latitude, longitude: location.coords.longitude } : undefined,
        },
        data: { amount, label, phoneNumber },
      });
      return response.data;
    } catch (e) {
      logger.debug("TransferService", "Failed to start transfer", e);
      if (isAccountBlockedErrorResponse(e) || isRecipientNotFoundByInvalidPhoneError(e)) {
        throw AccountBlockedErrorFromErrorResponse(e);
      }
      throw e?.response?.data?.error?.message || e.toString();
    }
  }

  public async confirmSimpleTransfer(
    confirmationMode: ConfirmationMode,
    amount: Amount,
    phoneNumber: string,
    label?: string,
    pincode?: PincodeSubmission,
    location?: GeoPosition | null,
  ) {
    try {
      const response = await this.apiService.instance.post<TransactionRequest>("/transactions/p2p-simple-transfer", {
        metadata: {
          mode: TransactionCallType.Transaction,
          confirmationMode,
          pincode,
          location: location ? { latitude: location.coords.latitude, longitude: location.coords.longitude } : undefined,
        },
        data: {
          amount,
          label,
          phoneNumber,
        },
      });
      return response.data;
    } catch (e) {
      logger.debug("TransferService", "Failed to confirm transfer", e);
      if (isAccountBlockedErrorResponse(e) || isRecipientNotFoundByInvalidPhoneError(e)) {
        throw AccountBlockedErrorFromErrorResponse(e);
      }
      throw e?.response?.data?.error?.message || e.toString();
    }
  }

  public async startTransfer(recipientId: string, amount: Amount, label?: string, location?: GeoPosition | null) {
    try {
      const response = await this.apiService.instance.post<TransactionRequest>("/transactions/p2p-transfer", {
        metadata: {
          mode: TransactionCallType.PreAuth,
          location: location ? { latitude: location.coords.latitude, longitude: location.coords.longitude } : undefined,
        },
        data: { recipientId, amount, label },
      });
      return response.data;
    } catch (e) {
      logger.debug("TransferService", "Failed to start transfer", e);
      if (isAccountBlockedErrorResponse(e)) {
        throw AccountBlockedErrorFromErrorResponse(e);
      }
      throw e?.response?.data?.error?.message || e.toString();
    }
  }

  public async confirmTransfer(
    confirmationMode: ConfirmationMode,
    recipientId: string,
    amount: Amount,
    label?: string,
    pincode?: PincodeSubmission,
    location?: GeoPosition | null,
  ) {
    try {
      const response = await this.apiService.instance.post<TransactionRequest>("/transactions/p2p-transfer", {
        metadata: {
          mode: TransactionCallType.Transaction,
          confirmationMode,
          pincode,
          location: location ? { latitude: location.coords.latitude, longitude: location.coords.longitude } : undefined,
        },
        data: {
          recipientId,
          amount,
          label,
        },
      });
      return response.data;
    } catch (e) {
      if (isAccountBlockedErrorResponse(e)) {
        throw AccountBlockedErrorFromErrorResponse(e);
      }
      throw e?.response?.data?.error?.message || e.toString();
    }
  }

  public async startPayout(
    recipientId: string,
    amount: Amount,
    srcAccountId: string,
    purpose: string | undefined = undefined,
    location?: GeoPosition | null,
  ) {
    try {
      const response = await this.apiService.instance.post<TransactionRequest>("/transactions/payout", {
        metadata: {
          mode: TransactionCallType.PreAuth,
          location: location ? { latitude: location.coords.latitude, longitude: location.coords.longitude } : undefined,
        },
        data: { recipientId, amount, purpose, srcAccountId },
      });
      return response.data;
    } catch (e) {
      logger.debug("TransferService", "Failed to start payout", e);
      if (isAccountBlockedErrorResponse(e)) {
        throw AccountBlockedErrorFromErrorResponse(e);
      }
      throw e?.response?.data?.error?.message || e.toString();
    }
  }

  public async confirmPayout(
    confirmationMode: ConfirmationMode,
    recipientId: string,
    amount: Amount,
    srcAccountId: string,
    purpose: string | undefined = undefined,
    pincode?: PincodeSubmission,
    location?: GeoPosition | null,
  ) {
    try {
      const response = await this.apiService.instance.post<TransactionRequest>("/transactions/payout", {
        metadata: {
          mode: TransactionCallType.Transaction,
          confirmationMode,
          pincode,
          location: location ? { latitude: location.coords.latitude, longitude: location.coords.longitude } : undefined,
        },
        data: {
          recipientId,
          amount,
          srcAccountId,
          purpose,
        },
      });
      return response.data;
    } catch (e) {
      logger.debug("TransferService", "Failed to confirm payout", e);
      if (isAccountBlockedErrorResponse(e)) {
        throw AccountBlockedErrorFromErrorResponse(e);
      }
      throw e?.response?.data?.error?.message || e.toString();
    }
  }

  public async startCashTransfer(
    recipientId: string,
    amount: Amount,
    label: string | undefined = undefined,
    location?: GeoPosition | null,
  ) {
    try {
      const response = await this.apiService.instance.post<TransactionRequest>("/transactions/p2p-cash-transfer", {
        metadata: {
          mode: TransactionCallType.PreAuth,
          location: location ? { latitude: location.coords.latitude, longitude: location.coords.longitude } : undefined,
        },
        data: { recipientId, amount, label },
      });
      return response.data;
    } catch (e) {
      logger.debug("TransferService", "Failed to start cash transfer", e);
      if (isAccountBlockedErrorResponse(e)) {
        throw AccountBlockedErrorFromErrorResponse(e);
      }
      throw e?.response?.data?.error?.message || e.toString();
    }
  }

  public async confirmCashTransfer(
    confirmationMode: ConfirmationMode,
    recipientId: string,
    amount: Amount,
    label?: string,
    pincode?: PincodeSubmission,
    location?: GeoPosition | null,
  ) {
    try {
      const response = await this.apiService.instance.post<TransactionRequest>("/transactions/p2p-cash-transfer", {
        metadata: {
          mode: TransactionCallType.Transaction,
          confirmationMode,
          pincode,
          location: location ? { latitude: location.coords.latitude, longitude: location.coords.longitude } : undefined,
        },
        data: {
          recipientId,
          amount,
          label,
        },
      });
      return response.data;
    } catch (e) {
      logger.debug("TransferService", "Failed to confirm cash transfer", e);
      if (isAccountBlockedErrorResponse(e)) {
        throw AccountBlockedErrorFromErrorResponse(e);
      }
      throw e?.response?.data?.error?.message || e.toString();
    }
  }

  // CUSTOMER INSTRUCTION

  public async getPaymentNetworks() {
    try {
      const response = await Promise.all([
        this.serviceDomainApiService.instance.get<{ items: PaymentNetwork[] }>("/payment-networks/configuration"),
        this.serviceDomainApiService.instance.get<PaymentContracts>("/payment-service-contract"),
      ]);
      const networks = response[0].data;
      const contracts = response[1].data.creditTransferEmission;
      const paymentNetworks = contracts
        .filter((contract) => contract.serviceLevel !== "ON_US")
        .map((contract) => {
          const network = networks.items.find((n) => n.serviceLevel === contract.serviceLevel);
          return {
            ...network,
            ...contract,
          };
        });
      return paymentNetworks;
    } catch (e) {
      logger.debug("TransferService", "Failed to get payment networks", e);
      throw e?.response?.data?.error?.message || e.toString();
    }
  }

  public async getPaymentNetworkProduct(productId: string) {
    try {
      const response = await this.serviceDomainApiService.instance.get(`/products/${productId}`);

      return response.data;
    } catch (e) {
      logger.debug("TransferService", "Failed to get payment network product", e);
      throw e?.response?.data?.error?.message || e.toString();
    }
  }

  public async startCustomerInstruction(
    paymentNetwork: PaymentNetwork,
    amount: Amount,
    sourceAccount: Account,
    destinationAccountOrRecipient: AccountOrRecipient,
    pincode?: PincodeSubmission,
    label?: string,
    creditorAddress?: PaymentAddress,
    foreignAmount?: Amount | null,
    recurringTransferParams?: RecurringTransferParams,
  ) {
    const client = this.clientManager.client.get();
    const customerInstruction = makeFormattedCustomerInstruction({
      client,
      amount,
      creditorAddress,
      destinationAccountOrRecipient,
      foreignAmount,
      label,
      paymentNetwork,
      sourceAccount,
      recurringTransferParams,
    });
    try {
      if (pincode) {
        await this.checkPincodeService.checkPincode(pincode);
      }
      const endpoint =
        recurringTransferParams?.enabled && !foreignAmount
          ? "/standing-orders/credit-transfers"
          : "/customer-instructions/credit-transfers/submit";

      const response = await this.serviceDomainApiService.instance.post(endpoint, customerInstruction);
      return response.data as CustomerInstructionResult;
    } catch (e) {
      logger.debug("TransferService", "Failed to start transfer", e);
      if (isAccountBlockedErrorResponse(e)) {
        throw AccountBlockedErrorFromErrorResponse(e);
      }
      throw e?.response?.data?.error?.message || e?.response?.data?.message || e.toString();
    }
  }

  public async submitCustomerInstruction(id, token): Promise<CustomerInstructionResult> {
    try {
      const response = await this.serviceDomainApiService.instance.post(
        `/customer-instructions/credit-transfers/${id}/submit`,
        null,
        {
          headers: {
            "SCAP-token": token,
          },
        },
      );
      return response.data;
    } catch (e) {
      logger.debug("TransferService", "Failed to submit customer instruction", e);
      throw e?.response?.data?.error?.message || e?.response?.data?.message || e.toString();
    }
  }

  public async getAuthenticationSession(customerInstructionReference: string) {
    try {
      const response = await this.serviceDomainApiService.instance.get(
        `/authentications/sessions/${customerInstructionReference}`,
      );
      return response.data;
    } catch (e) {
      logger.debug("TransferService", "Failed to get authentication session", e);
      throw e?.response?.data?.error?.message || e?.response?.data?.message || e.toString();
    }
  }

  public async getCustomerInstruction(id: string | number) {
    try {
      const response = await this.serviceDomainApiService.instance.get(`/customer-instructions/${id}`);
      return response.data;
    } catch (e) {
      logger.debug("TransferService", "Failed to get customer instruction", e);
      throw e?.response?.data?.error?.message || e?.response?.data?.message || e.toString();
    }
  }
  public async submitCreditTransferSinglePayment(
    customerInstructionInformation?: CustomerInstructionInformation,
    paymentRTransaction?: PaymentTransaction,
  ): Promise<any> {
    try {
      const response = await this.serviceDomainApiService.instance.post(
        "/customer-instructions/r-credit-transfers/submit",
        {
          customerInstructionInformation: {
            ...customerInstructionInformation,
            paymentInstrument: "CreditTransferRecall",
          },
          paymentRTransaction: {
            ...paymentRTransaction,
          },
        },
      );
      return response.data;
    } catch (e) {
      logger.debug("TransferService", "Failed to submit Credit Transfer Single Payment", e);
      throw e?.response?.data?.error?.message || e?.response?.data?.message || e.toString();
    }
  }

  public async getPaymentTransaction(id: string | number): Promise<PaymentTransaction> {
    try {
      const response = await this.serviceDomainApiService.instance.get(`/payment-transactions/${id}`);
      return response.data;
    } catch (e) {
      logger.debug("TransferService", "Failed to get payment transaction", e);
      throw e?.response?.data?.error?.message || e?.response?.data?.message || e.toString();
    }
  }
}
