import { useMachine } from "@xstate/react";
import { assign, Machine, send } from "xstate";

import {
  authenticationsSessionsManager,
  beneficiaryManager,
  externalAccountManager,
  pincodeKeyboardService,
} from "../../shared/core/service/services";
import { AuthenticationsMethod } from "../../shared/domains/authentication/authentications-sessions";
import type { Keyboard } from "../../shared/domains/pincode/keyboard";
import type { PincodeSubmission } from "../../shared/domains/pincode/pincode";
import type { RecipientData } from "../../shared/domains/recipients/beneficiary-service";
import type { Recipient } from "../../shared/domains/recipients/recipient";
import { RecipientStrongAuthenticationModeEnum } from "../../shared/domains/recipients/recipient";
import { FONT_SIZE, PincodeState, TOUCH_SIZE } from "./keyboard-machine-type";

const REFRESHED_OTP_DELAY = 3000; // 3s
export const useRecipientMachine = () => {
  const [state, sendEvent] = useMachine(recipientMachine);

  const createIbanRecipient = (name: string, iban: string, externalAccount: boolean) => {
    sendEvent("CREATE_IBAN_RECIPIENT", { name, iban, externalAccount });
  };
  const createPhoneRecipient = (name: string, phone: string) => {
    sendEvent("CREATE_PHONE_RECIPIENT", { name, phone });
  };
  const createAccountRecipient = (name: string, account: string, bic: string) => {
    sendEvent("CREATE_ACCOUNT_RECIPIENT", { name, account, bic });
  };
  const searchBank = () => {
    sendEvent("SEARCH_BANK");
  };
  const updateBankName = (bankName: string, bic: string) => {
    sendEvent("UPDATE_BANK_NAME", { bankName, bic });
  };
  const confirmOtp = (otp: string) => {
    sendEvent("OTP_CONFIRM", { otp });
  };
  const sendOtpAgain = () => {
    sendEvent("SEND_OTP_AGAIN");
  };
  const cancelStrongAuthentication = () => {
    sendEvent("CANCEL_STRONG_AUTH");
  };
  const submitPincode = (submission: PincodeSubmission) => {
    sendEvent("SUBMIT_PINCODE", { pincodeSubmission: submission });
  };

  return {
    state: state.value as RecipientState | PincodeState,
    context: state.context,
    createIbanRecipient,
    createPhoneRecipient,
    createAccountRecipient,
    searchBank,
    updateBankName,
    confirmOtp,
    cancelStrongAuthentication,
    sendOtpAgain,
    submitPincode,
  };
};

interface RecipientMachineContext {
  name: string;
  recipientData: RecipientData;
  externalAccount?: boolean;
  recipientToAdd?: Recipient;
  otp?: string;
  keyboard?: Keyboard;
  otpSmsSent?: boolean;
  errorMessage?: string | undefined;
  refreshingErrorMessage?: string | undefined;
  bicCode?: string;
  bankName?: string;
  pincodeSubmission?: PincodeSubmission;
}

export enum RecipientState {
  CreatingRecipient = "CreatingRecipient",
  RequestingRecipient = "RequestingRecipient",
  SearchBank = "SearchBank",
  OTPSubmission = "OTPSubmission",
  OTPConfirming = "OTPConfirming",
  OTPRefreshing = "OTPRefreshing",
  StrongAuthCancelling = "StrongAuthCancelling",
  PincodeConfirming = "PincodeConfirming",
  Done = "Done",
}
type RecipientEvent =
  | { type: "CREATE_PHONE_RECIPIENT"; name: string; phone: string }
  | { type: "CREATE_IBAN_RECIPIENT"; name: string; iban: string; externalAccount: boolean }
  | { type: "CREATE_ACCOUNT_RECIPIENT"; name: string; account: string; bic: string }
  | { type: "SEARCH_BANK" }
  | { type: "UPDATE_BANK_NAME"; bankName: string; bic: string }
  | { type: "REQUEST_NEW_RECIPIENT" }
  | { type: "OTP_SUBMISSION" }
  | { type: "OTP_CONFIRM"; otp: string }
  | { type: "CANCEL_STRONG_AUTH" }
  | { type: "SEND_OTP_AGAIN" }
  | { type: "OTP_REFRESHED" }
  | { type: "PINCODE_SUBMISSION" }
  | { type: "DONE" }
  | KeyboardEvent;

export const recipientMachine = Machine<RecipientMachineContext, RecipientEvent>({
  id: "Recipient",
  initial: RecipientState.CreatingRecipient,
  states: {
    [RecipientState.CreatingRecipient]: {
      on: {
        CREATE_PHONE_RECIPIENT: {
          target: RecipientState.RequestingRecipient,
          actions: [
            assign({
              name: (_, event) => event.name,
              recipientData: (_, event) => ({ phone: event.phone }),
            }),
            send({ type: "REQUEST_NEW_RECIPIENT" }),
          ],
        },
        CREATE_IBAN_RECIPIENT: {
          target: RecipientState.RequestingRecipient,
          actions: [
            assign({
              name: (_, event) => event.name,
              recipientData: (_, event) => ({ iban: event.iban }),
              externalAccount: (_, event) => event.externalAccount,
            }),
            send({ type: "REQUEST_NEW_RECIPIENT" }),
          ],
        },
        CREATE_ACCOUNT_RECIPIENT: {
          target: RecipientState.RequestingRecipient,
          actions: [
            assign({
              name: (_, event) => event.name,
              recipientData: (_, event) => ({ accountReference: event.account, bic: event.bic }),
            }),
            send({ type: "REQUEST_NEW_RECIPIENT" }),
          ],
        },
        SEARCH_BANK: {
          target: RecipientState.SearchBank,
        },
      },
    },
    [RecipientState.RequestingRecipient]: {
      invoke: {
        id: "requestRecipient",
        src: (context) => {
          if (context.externalAccount) {
            return externalAccountManager.requestNewRecipient(context.name, context.recipientData);
          } else {
            return beneficiaryManager.requestNewRecipient(context.name, context.recipientData);
          }
        },
        onDone: {
          actions: [
            assign<RecipientMachineContext, { type: string; data: Recipient }>({
              recipientToAdd: (_, event) => event.data,
              errorMessage: undefined,
            }),
            send((context: RecipientMachineContext) => {
              const recipient = context.recipientToAdd;
              if (
                context.externalAccount ||
                (recipient?.strongAuthentication &&
                  recipient?.strongAuthentication.authenticationMode.includes(
                    RecipientStrongAuthenticationModeEnum.OTP_SMS,
                  ))
              ) {
                return { type: "OTP_SUBMISSION" };
              } else if (
                recipient?.strongAuthentication &&
                recipient?.strongAuthentication.authenticationMode.includes(
                  RecipientStrongAuthenticationModeEnum.PINCODE,
                )
              ) {
                return { type: "PINCODE_SUBMISSION" };
              } else {
                return { type: "DONE" };
              }
            }),
          ],
        },
        onError: {
          target: RecipientState.CreatingRecipient,
          actions: assign({
            errorMessage: (_, event) => event.data,
          }),
        },
      },
      on: {
        OTP_SUBMISSION: RecipientState.OTPSubmission,
        PINCODE_SUBMISSION: PincodeState.PincodeConfirmation,
        DONE: RecipientState.Done,
      },
    },
    [RecipientState.SearchBank]: {
      on: {
        UPDATE_BANK_NAME: {
          target: RecipientState.CreatingRecipient,
          actions: [
            assign({
              bankName: (_, event) => event.bankName,
              bicCode: (_, event) => event.bic,
            }),
          ],
        },
      },
    },
    [RecipientState.OTPRefreshing]: {
      invoke: {
        id: "otpRefresh",
        src: (context) => {
          if (context.externalAccount) {
            return externalAccountManager.requestNewRecipient(context.name, context.recipientData);
          } else {
            const recipient = context.recipientToAdd;
            if (recipient?.strongAuthentication?.strongAuthenticationToken) {
              const token = recipient.strongAuthentication.strongAuthenticationToken;
              return authenticationsSessionsManager.getAuthenticationSession(token);
            } else {
              throw new Error("Strong authentication token is missing");
            }
          }
        },
        onDone: {
          actions: [
            assign<RecipientMachineContext, { type: string; data: Recipient }>({
              recipientToAdd: (_, event) => event.data,
              refreshingErrorMessage: undefined,
            }),
            send("OTP_REFRESHED", { delay: REFRESHED_OTP_DELAY }),
          ],
        },
        onError: {
          target: RecipientState.OTPSubmission,
          actions: assign({
            refreshingErrorMessage: (_, event) => event.data,
          }),
        },
      },
      on: {
        OTP_REFRESHED: RecipientState.OTPSubmission,
      },
    },
    [RecipientState.OTPSubmission]: {
      invoke: {
        id: "sendSmsOtp",
        src: (context) => {
          const recipient = context.recipientToAdd;
          if (!context.otpSmsSent) {
            if (recipient?.strongAuthentication?.strongAuthenticationToken) {
              const token = recipient.strongAuthentication.strongAuthenticationToken;
              return authenticationsSessionsManager.getAuthenticationSession(token);
            } else {
              throw new Error("Strong authentication token is missing");
            }
          }
          return Promise.resolve();
        },
      },
      on: {
        OTP_CONFIRM: {
          target: RecipientState.OTPConfirming,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          actions: assign<RecipientMachineContext, any>({
            otp: (_, event) => event.otp,
            errorMessage: undefined,
            refreshingErrorMessage: undefined,
            otpSmsSent: true,
          }),
        },
        SEND_OTP_AGAIN: {
          target: RecipientState.OTPRefreshing,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          actions: assign<RecipientMachineContext, any>({
            errorMessage: undefined,
          }),
        },
        CANCEL_STRONG_AUTH: {
          target: RecipientState.StrongAuthCancelling,
        },
      },
    },
    [RecipientState.OTPConfirming]: {
      invoke: {
        id: "confirmNewRecipient",
        src: (context) => {
          if (context.externalAccount) {
            return externalAccountManager.confirmNewRecipient(
              context.name,
              context.recipientData,

              context.otp!,
            );
          } else {
            const otp = context.otp;
            const token = context.recipientToAdd?.strongAuthentication?.strongAuthenticationToken;
            if (token && otp) {
              return authenticationsSessionsManager.verifyAuthentication(token, otp);
            }
            throw new Error("Strong authentication token or OTP is missing");
          }
        },
        onDone: {
          target: RecipientState.Done,
          actions: [
            assign<RecipientMachineContext, { type: string; data: Recipient }>({
              errorMessage: undefined,
            }),
          ],
        },
        onError: {
          target: RecipientState.OTPSubmission,
          actions: assign({
            errorMessage: (_, event) => event.data,
          }),
        },
      },
    },
    [RecipientState.StrongAuthCancelling]: {
      invoke: {
        id: "cancelStrongAuth",
        src: (context) => {
          const recipient = context.recipientToAdd;
          if (recipient?.strongAuthentication?.strongAuthenticationToken) {
            const token = recipient.strongAuthentication.strongAuthenticationToken;
            return authenticationsSessionsManager.cancelAuthentication(token);
          }
          throw new Error("Strong authentication token is missing");
        },
        onDone: {
          target: RecipientState.Done,
          actions: assign<RecipientMachineContext, { type: string; data: Recipient }>({
            errorMessage: undefined,
          }),
        },
        onError: {
          target: RecipientState.Done,
          actions: assign({
            errorMessage: (_, event) => event.data,
          }),
        },
      },
    },
    [PincodeState.PincodeConfirmation]: {
      invoke: {
        id: "fetchKeyboard",
        src: () => pincodeKeyboardService.fetchKeyboard(TOUCH_SIZE, undefined, FONT_SIZE),
        onDone: {
          actions: [
            assign({
              keyboard: (_, event) => event.data,
            }),
            send({ type: "PROMPT_KEYBOARD" }),
          ],
        },
      },
      on: {
        PROMPT_KEYBOARD: PincodeState.PromptingKeyboard,
      },
    },
    [PincodeState.PromptingKeyboard]: {
      on: {
        SUBMIT_PINCODE: {
          target: RecipientState.PincodeConfirming,
          actions: assign({ pincodeSubmission: (_, event) => event.pincodeSubmission }),
        },
        CANCEL_STRONG_AUTH: {
          target: RecipientState.StrongAuthCancelling,
        },
      },
    },
    [PincodeState.FetchKeyboardAfterError]: {
      invoke: {
        id: "fetch-keyboard",
        src: () => pincodeKeyboardService.fetchKeyboard(TOUCH_SIZE, undefined, FONT_SIZE),
        onDone: {
          actions: [
            assign({
              keyboard: (_, event) => event.data,
            }),
            send({ type: "PROMPT_KEYBOARD" }),
          ],
        },
      },
      on: {
        PROMPT_KEYBOARD: PincodeState.PromptingKeyboard,
      },
    },
    [RecipientState.PincodeConfirming]: {
      invoke: {
        id: "confirmPincode",
        src: (context) => {
          const pincode = context.pincodeSubmission;
          const token = context.recipientToAdd?.strongAuthentication?.strongAuthenticationToken;
          if (token && pincode) {
            return authenticationsSessionsManager.verifyAuthentication(token, pincode, AuthenticationsMethod.PINCODE);
          }
          throw new Error("Strong authentication token or pincode is missing");
        },
        onDone: {
          target: RecipientState.Done,
          actions: [
            assign<RecipientMachineContext, { type: string; data: Recipient }>({
              errorMessage: undefined,
            }),
          ],
        },
        onError: {
          target: PincodeState.PincodeConfirmation,
          actions: assign({
            errorMessage: (_, event) => event.data,
          }),
        },
      },
    },
    [RecipientState.Done]: {
      type: "final",
      invoke: {
        src: () => beneficiaryManager.load(true), // reload beneficiaries when a new one is added
      },
    },
  },
});
