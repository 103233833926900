import type { Transaction } from "../../accounting-transaction/transaction/transaction";
import type { ConfirmationMode, TransactionCallType } from "../transaction-request";

export enum PspOperationResult {
  Success = "SUCCESS",
  Pending = "PENDING",
  Failure = "FAILURE",
}

export interface PspTransactionRequest {
  metadata: {
    mode: TransactionCallType;
    confirmationMode: ConfirmationMode;
    transaction: Transaction;
  };
  data: {
    pspResult: PspOperationResult;
    webviewUrl: string;
  };
}
