import type { ButtonHTMLAttributes } from "react";
import React from "react";
import styled from "styled-components";

import { PrimaryButton } from "./primary-button";

interface RoundActionButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  label?: string;
  size?: number;
  children?: React.ReactNode | JSX.Element;
}
export const RoundActionButton = (props: RoundActionButtonProps) => {
  const { label, children, size = 46, ...rest } = props;
  return (
    <RoundButton {...rest} size="S" diameter={size}>
      {children ? children : label || ">"}
    </RoundButton>
  );
};

const RoundButton = styled(PrimaryButton)<{ diameter: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${(props) => props.diameter};
  height: ${(props) => props.diameter};
  padding: 0px 0px;
`;
