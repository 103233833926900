import React from "react";
import styled from "styled-components";

import { useIntl } from "../../../../../shared/core/i18n/use-intl";
import type { UpcomingTransferStatus } from "../../../../../shared/domains/transactions/upcoming-transfers/upcoming-transfers";
import {
  UPCOMING_TRANSFER_STATUSES,
  UpcomingTransferStatusColor,
} from "../../../../../shared/domains/transactions/upcoming-transfers/upcoming-transfers";
import { theme } from "../../../styles/theme";

const UPCOMING_TRANSFER_STATUSES_STYLES = {
  [UpcomingTransferStatusColor.SUCCESS]: {
    color: theme.colors.success[500],
    backgroundColor: theme.colors.success[100],
  },
  [UpcomingTransferStatusColor.WARNING]: {
    color: theme.colors.warning[500],
    backgroundColor: theme.colors.warning[100],
  },
  [UpcomingTransferStatusColor.ERROR]: {
    color: theme.colors.gray[500],
    backgroundColor: theme.colors.gray[100],
  },
  [UpcomingTransferStatusColor.INFO]: {
    color: theme.colors.info[500],
    backgroundColor: theme.colors.info[100],
  },
};

export const UpcomingTransferStatusPill = ({ status }: { status: UpcomingTransferStatus }) => {
  const { formatMessage } = useIntl();
  const matchingStatus = UPCOMING_TRANSFER_STATUSES[status];
  const style =
    (matchingStatus?.color && UPCOMING_TRANSFER_STATUSES_STYLES[matchingStatus?.color]) ||
    UPCOMING_TRANSFER_STATUSES_STYLES[UpcomingTransferStatusColor.ERROR];
  return (
    <StyledUpcomingTransferStatusPill style={style}>
      {formatMessage(matchingStatus.label)}
    </StyledUpcomingTransferStatusPill>
  );
};

const StyledUpcomingTransferStatusPill = styled.div`
  ${theme.mediumText}
  font-size: 12px;
  display: flex;
  align-items: center;
  padding: 4px 10px 4px 7px;
  border-radius: 20px;
  &::before {
    content: "";
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    margin-right: 5px;
    background-color: currentColor;
  }
`;
