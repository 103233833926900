import { ConsoleFormatter, DefaultLoggerManagerBuilder, LogLevel } from "@betomorrow/logging-core";

import { Config } from "../config/config";

const loggingEnabled = Config.LOGGING_ENABLED;

export const loggerManager = loggingEnabled
  ? new DefaultLoggerManagerBuilder().withLevel(LogLevel.DEBUG).withConsoleAppender(new ConsoleFormatter()).build()
  : new DefaultLoggerManagerBuilder().withLevel(LogLevel.ERROR).build();

export const logger = loggerManager.getLogger();
