import React from "react";
import styled from "styled-components";

import { WaitingIcon } from "../../../common/svg/waiting-icon";
import { theme } from "../../../styles/theme";

export interface PendingColoredTagProps {
  label: string;
}
export const PendingColoredTag = (props: PendingColoredTagProps) => {
  const { label } = props;
  return (
    <TagContainer>
      <StyledWaitingIcon color={theme.mainColor()} />
      <TagText>{label}</TagText>
    </TagContainer>
  );
};

const TagContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 22px;
  border-radius: 13px;
  background-color: ${theme.mainColorLightBackground};
  align-items: center;
  justify-content: center;
`;

const TagText = styled.span`
  ${theme.boldText};
  font-size: 0.625rem;
  color: ${theme.mainColor};
  margin-left: 2px;
  margin-right: 10px;
`;

const StyledWaitingIcon = styled(WaitingIcon)`
  width: 14px;
  height: 14px;
  margin-left: 6px;
  margin-right: 2px;
`;
