import { useCallback, useEffect, useRef, useState } from "react";

import type { Direction } from "./direction";
import { directionFactor } from "./direction";

export function useCarouselAnimations(
  itemWidth: number,
  slideNumber: number,
  allItemsDisplayed: boolean,
  isRTL: boolean,
) {
  const [start, setStart] = useState(isRTL ? !allItemsDisplayed : true);
  const [end, setEnd] = useState(isRTL ? false : allItemsDisplayed);
  const [firstElementVisible, setFirstElementVisible] = useState(0);

  useEffect(() => {
    setStart(isRTL ? !allItemsDisplayed : true);
    setEnd(isRTL ? false : allItemsDisplayed);
  }, [allItemsDisplayed, isRTL]);

  const scrollingDiv = useRef<HTMLDivElement>(null);
  const lastScrollLeft = useRef(0);

  const computeElementVisible = useCallback(
    (direction: Direction, floatElementIndex: number) =>
      direction === (isRTL ? "right" : "left") ? Math.floor(floatElementIndex) : Math.ceil(floatElementIndex),
    [],
  );

  const handleScroll = () => {
    if (!scrollingDiv.current) {
      return;
    }
    const scrollLeft = scrollingDiv.current.scrollLeft;
    const scrollDirection =
      (!isRTL && lastScrollLeft.current > scrollLeft) || (isRTL && scrollLeft > lastScrollLeft.current)
        ? "left"
        : "right";
    const scrollRight = isRTL
      ? scrollingDiv.current.scrollWidth - scrollingDiv.current.clientWidth + scrollLeft
      : scrollingDiv.current.scrollWidth - (scrollLeft + scrollingDiv.current.clientWidth);

    setStart(isRTL ? scrollLeft >= 0 : scrollLeft <= 0);
    setEnd(scrollRight <= 0);
    setFirstElementVisible(computeElementVisible(scrollDirection, scrollLeft / itemWidth));
    lastScrollLeft.current = scrollLeft;
  };

  const snap = (to: Direction) => {
    if (!scrollingDiv.current) {
      return;
    }

    scrollingDiv.current.scrollTo({
      left: scrollingDiv.current.scrollLeft + itemWidth * slideNumber * directionFactor(to, isRTL),
      behavior: "smooth",
    });
  };

  const slidingAnimation = useRef(0);
  const animationStartDelay = useRef(0);

  const animateScroll = useCallback((to: Direction) => {
    const slide = () => {
      if (!scrollingDiv.current) {
        return;
      }
      scrollingDiv.current.scrollTo({
        left: scrollingDiv.current.scrollLeft + 5 * directionFactor(to, isRTL),
      });
      slidingAnimation.current = requestAnimationFrame(() => slide());
    };
    animationStartDelay.current = window.setTimeout(() => slide(), 300);
  }, []);

  useEffect(() => {
    if (start || end || allItemsDisplayed) {
      cancelAnimationFrame(slidingAnimation.current);
      slidingAnimation.current = 0;
    }
  }, [start, end, allItemsDisplayed]);

  const snapOrStopScrolling = (direction: Direction, canceled?: boolean) => {
    clearTimeout(animationStartDelay.current);
    if (slidingAnimation.current) {
      cancelAnimationFrame(slidingAnimation.current);
      slidingAnimation.current = 0;
    } else {
      !canceled && snap(direction);
    }
  };

  const scrollToElementIndex = (index: number) => {
    if (!scrollingDiv.current || (index + 1) * itemWidth > scrollingDiv.current.scrollWidth) {
      return;
    }

    scrollingDiv.current.scrollTo({
      left: itemWidth * index,
      behavior: "smooth",
    });
  };

  const isDisabled = (dir: Direction) => allItemsDisplayed || (dir === (isRTL ? "right" : "left") ? start : end);

  return {
    scrollingDiv,
    firstElementVisible,
    handleScroll,
    animateScroll,
    snapOrStopScrolling,
    isDisabled,
    scrollToElementIndex,
  };
}
