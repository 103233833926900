import type { Amount } from "../../core/amount/amount";
import type { CurrencyIdentifier } from "../../core/currency/currency";
import type { Account } from "../account/account";
import type { Client } from "../client/client";
import type { Recipient } from "../recipients/recipient";
import type { RecurringTransferParams } from "./recurring-transfer";

export type AccountOrRecipient = Account & Recipient;

export enum ServiceLevel {
  XCT = "XCT",
  SEPA = "SEPA",
  BACI = "BACI",
  KNGA = "KNGA",
  ON_US = "ON_US",
  SCT_INST = "SCT_INST",
}

export enum PaymentType {
  PHONE = "phone",
  IBAN = "IBAN",
  OTHER = "other",
}

export enum PaymentIdentificationMode {
  IBAN = "IBAN",
  PHONE = "PHONE",
  ACCOUNT_NUMBER = "ACCOUNT_NUMBER",
  IDENTITY_ID = "IDENTITY_ID",
}

export enum PaymentAcceptedAddress {
  STRUCTURED = "STRUCTURED",
  UNSTRUCTURED = "UNSTRUCTURED",
  UNSTRUCTURED_OR_NULL = "UNSTRUCTURED_OR_NULL",
  BOTH = "BOTH",
  NO_ADDRESS = "NO_ADDRESS",
}

export const NETWORK_ACCEPTED_ADDRESSES_NEEDED: PaymentAcceptedAddress[] = [
  PaymentAcceptedAddress.BOTH,
  PaymentAcceptedAddress.STRUCTURED,
  PaymentAcceptedAddress.UNSTRUCTURED,
  PaymentAcceptedAddress.UNSTRUCTURED_OR_NULL,
];

type StructuredAddress = {
  streetName?: string;
  buildingNumber?: string;
  postCode?: string;
  townName?: string;
};

export type PaymentAddress = {
  structuredAddress?: StructuredAddress;
  country: string;
  addressLines?: string;
};

export type CurrencyCouple = {
  origin: string;
  target: string;
};

export interface PaymentNetwork {
  name: string;
  serviceLevel: ServiceLevel;
  activated: boolean;
  identificationMode: PaymentIdentificationMode[];
  acceptedAddresses: PaymentAcceptedAddress;
  productId: string;
}

export interface PaymentNetworkProduct {
  id: string;
  name: string;
  paymentNetworkServiceLevel: string;
  processing: {
    forexConfiguration?: {
      currencyCouples: CurrencyCouple[];
      enabled: boolean;
    };
  };
}

export interface PaymentContracts {
  creditTransferEmission: PaymentNetwork[];
  creditTransferReception: PaymentNetwork[];
}

export type CustomerInstructionInformation = {
  paymentInstrument: string;
  paymentService: string;
  batchBooking: boolean;
  customerInstructionReference: string;
  requestedExecutionDate?: string;
  customerInstructionTypeInformation: {
    categoryPurpose: "NULL";
    serviceLevel?: ServiceLevel;
  };
  requestedTotalAmount?: Amount;
  foreignAmount?: {
    currency: CurrencyIdentifier;
  };
};

export type PaymentRTransaction = {
  paymentRTransactionAmountInformation: {
    returnedAmount?: Amount;
    chargeBearer?: string;
    chargesAmount?: Amount;
  };
  paymentRTransactionInformation: {
    paymentRTransactionIdentification: {
      originalTransactionId: string;
      relatedTransactionInternalId: string;
      originalMessageNameId?: string;
      originalMessageId?: string;
      originalEndToEndId?: string;
      originalInterbankingSettlementDate?: Date;
    };
    paymentRTransactionReturnInformation: {
      originator: object;
      reason?: string;
      decisionStatus?: string;
      externalProcessingDate?: Date;
      inquiryInformation?: string;
    };
  };
  externalData?: object;
};

export type SubmitCreditTransferPaymentRTransactionBody = {
  customerInstructionInformation: CustomerInstructionInformation;
  externalData?: object;
  paymentRTransaction: PaymentRTransaction;
};

type CustomerInstructionOrderingParties = {
  initiatingParty: {
    name?: string;
    // postalAddress: {
    // 	country: number;
    // };
    id: {
      value?: string;
      type?: PaymentType;
    };
  };
  debtor: {
    name?: string;
    // postalAddress: {
    // 	country: number;
    // };
    accountId: {
      value?: string;
      type?: PaymentType;
      bankId?: {
        value?: string;
        type?: PaymentType;
      };
    };
  };
};

export type PaymentTransaction = {
  paymentTransactionDedicatedInformations?: {
    remittanceInformation: {
      value: string;
      type: "STRUCTURED" | "UNSTRUCTURED";
    };
  };
  paymentTransactionAmountInformation: {
    instructedAmount?: Amount;
    chargeBearer?: string;
    chargesAmount?: Amount;
    foreignAmount?: {
      currency: CurrencyIdentifier;
    };
  };
  paymentTransactionParties: {
    initiatingParty?: {
      name: string;
      id: {
        value: number;
        type: string;
      };
      postalAddress: {
        addressLines: string;
        structuredAddress: {
          streetName: string;
          buildingNumber: string;
          postCode: string;
          townName: string;
        };
        country: string;
      };
    };
    debtor?: {
      name: string;
      id: {
        value: string;
        type: string;
      };
      accountId: {
        value: string;
        type: string;
        bankId: {
          value: string;
          type: string;
        };
      };
      postalAddress: {
        addressLines: string;
        structuredAddress: {
          streetName: string;
          buildingNumber: string;
          postCode: string;
          townName: string;
        };
        country: string;
      };
    };
    creditor: {
      name?: string;
      id?: {
        value: string;
        type: string;
      };
      accountId: {
        value?: string;
        type?: string;
        bankId?: {
          value: string;
          type: string;
        };
      };
      postalAddress?: PaymentAddress;
    };
    ultimateCreditor?: {
      name: string;
      id: {
        value: string;
        type: string;
      };
      postalAddress: {
        addressLines: string;
        structuredAddress: {
          streetName: string;
          buildingNumber: string;
          postCode: string;
          townName: string;
        };
        country: string;
      };
    };
  };
  paymentTransactionInformation?: {
    paymentInstrument: string;
    paymentTransactionIdentification: {
      messageId: string;
      instructionId: string;
      endToEndId: string;
      externalTransactionId: string;
      customerInstructionId: string;
      id: string;
      clearingChannel: string;
    };
    paymentTransactionTypeInformation: {
      serviceLevel: string;
    };
    direction: string;
    transactionType: string;
    mandateRelatedInformation: {
      mandateIdentification: string;
      dateOfSignature: Date;
    };
    sequenceType: string;
    creditorSchemeId: string;
    creditorSchemeName: string;
    requestedCollectionDate: Date;
    localInstrument: string;
  };
  paymentTransactionInternalInformation?: {
    id: string;
    status: {
      value: string;
      label: string;
    };
    history: any[];
    statusReasonInformation: {
      originator: string;
      reason: {
        code: string;
        message: string;
      };
    };
    contractId: number;
  };
  paymentTransactionDateInformation?: {
    creationDate: string;
    instructedDate: Date;
    settlementDate: Date;
  };
  customerInstructionId?: string;
  paymentInstructionId?: string;
  externalData?: {
    externalDataKey: string;
    externalDataBool: boolean;
    externalDataArray: number[];
  };
  links?: {
    rel: string;
    href: string;
  }[];
};

export interface CustomerInstruction {
  customerInstructionInformation: CustomerInstructionInformation;
  customerInstructionOrderingParties: CustomerInstructionOrderingParties;
  paymentTransaction: PaymentTransaction;
}

export type StandingOrder = CustomerInstruction & RecurringTransferParams;

export interface CustomerInstructionResult {
  id: number;
  status: {
    value: string;
    label: string;
  };
  customerInstructionInformation?: CustomerInstructionInformation;
  calculatedNbPaymentTransactions?: number;
  calculatedAmount?: Amount;
  customerInstructionOrderingParties?: CustomerInstructionOrderingParties;
  paymentTransaction?: PaymentTransaction;
  strongAuthenticationReference?: string;
}

export function getBlankCustomerInstruction({
  amount,
  label,
  serviceLevel,
  foreignAmount,
}: {
  amount?: Amount;
  label?: string;
  serviceLevel?: ServiceLevel;
  foreignAmount?: Amount | null;
}): CustomerInstruction {
  const canSetForeignAmount = foreignAmount && foreignAmount.currency !== amount?.currency;
  return {
    customerInstructionInformation: {
      paymentInstrument: serviceLevel === ServiceLevel.SCT_INST ? "CreditTransferInst" : "CreditTransfer",
      paymentService: "PAYMENTS",
      batchBooking: true,
      customerInstructionReference: `CINSTR${new Date().toISOString()}`,
      requestedExecutionDate: new Date().toISOString().substring(0, 10),
      requestedTotalAmount: amount,
      customerInstructionTypeInformation: {
        categoryPurpose: "NULL",
        serviceLevel,
      },
      ...(canSetForeignAmount && { foreignAmount: { currency: foreignAmount.currency } }),
    },
    customerInstructionOrderingParties: {
      initiatingParty: {
        name: undefined,
        id: {
          value: undefined,
          type: undefined,
        },
      },
      debtor: {
        name: undefined,
        accountId: {
          value: undefined,
          type: undefined,
          bankId: {
            value: undefined,
            type: undefined,
          },
        },
      },
    },
    paymentTransaction: {
      paymentTransactionAmountInformation: {
        instructedAmount: amount,
        ...(canSetForeignAmount && { foreignAmount: { currency: foreignAmount.currency } }),
      },
      paymentTransactionParties: {
        creditor: {
          name: undefined,
          accountId: {
            value: undefined,
            type: undefined,
          },
        },
      },
      ...(label && {
        paymentTransactionDedicatedInformations: {
          remittanceInformation: {
            value: label,
            type: "UNSTRUCTURED",
          },
        },
      }),
    },
  };
}

export function makeFormattedCustomerInstruction({
  client,
  amount,
  creditorAddress,
  destinationAccountOrRecipient,
  foreignAmount,
  label,
  paymentNetwork,
  sourceAccount,
  recurringTransferParams,
}: {
  amount: Amount;
  client?: Client | null;
  creditorAddress?: PaymentAddress;
  destinationAccountOrRecipient: AccountOrRecipient;
  foreignAmount?: Amount | null;
  paymentNetwork: PaymentNetwork;
  sourceAccount: Account;
  label?: string;
  recurringTransferParams?: RecurringTransferParams;
}): CustomerInstruction | StandingOrder {
  const customerInstruction = getBlankCustomerInstruction({
    amount,
    serviceLevel: paymentNetwork.serviceLevel,
    foreignAmount,
    label,
  });

  // Set the debtor information
  const identificationModes = paymentNetwork.identificationMode;
  const needPhoneNumberIdentificationMode =
    identificationModes.includes(PaymentIdentificationMode.PHONE) && identificationModes.length === 1;
  const needAccountIdentificationMode =
    identificationModes.includes(PaymentIdentificationMode.ACCOUNT_NUMBER) && identificationModes.length === 1;

  customerInstruction.customerInstructionOrderingParties.debtor = {
    ...customerInstruction.customerInstructionOrderingParties.debtor,
    name: client ? `${client.firstName} ${client.lastName}` : sourceAccount.name,
    accountId: {
      value: needAccountIdentificationMode ? sourceAccount.id : sourceAccount.iban?.replaceAll(" ", ""),
      type: needAccountIdentificationMode ? PaymentType.OTHER : PaymentType.IBAN,
    },
    ...(needPhoneNumberIdentificationMode && {
      id: {
        value: client?.contactphone,
        type: PaymentType.PHONE,
      },
      type: null,
    }),
  };

  // Set the initiating party information
  customerInstruction.customerInstructionOrderingParties.initiatingParty = {
    ...customerInstruction.customerInstructionOrderingParties.initiatingParty,
    name: `${client?.firstName} ${client?.lastName}`,
    id: {
      value: client?.contactphone,
      type: PaymentType.PHONE,
    },
  };

  // Set the creditor information
  customerInstruction.paymentTransaction.paymentTransactionParties.creditor = {
    ...customerInstruction.paymentTransaction.paymentTransactionParties.creditor,
    name: destinationAccountOrRecipient.name,
    ...(creditorAddress && {
      postalAddress: creditorAddress,
    }),
    ...(destinationAccountOrRecipient.iban && {
      accountId: {
        value: destinationAccountOrRecipient.iban?.replaceAll(" ", ""),
        type: PaymentType.IBAN,
      },
    }),
    ...(destinationAccountOrRecipient.accountReference && {
      accountId: {
        value: destinationAccountOrRecipient.accountReference,
        type: PaymentType.OTHER,
      },
    }),
    ...(destinationAccountOrRecipient.phone && {
      id: {
        value: destinationAccountOrRecipient.phone,
        type: PaymentType.PHONE,
      },
    }),
  };

  // Set standing order information
  if (recurringTransferParams?.enabled) {
    return {
      ...customerInstruction,
      firstExecutionDate: recurringTransferParams.firstExecutionDate,
      frequency: recurringTransferParams.frequency,
      ...(recurringTransferParams.endDate && { endDate: recurringTransferParams.endDate }),
    };
  }
  return customerInstruction;
}
