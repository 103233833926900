import styled from "styled-components";

import { UIConstants } from "../../styles/uiConstants";

export const AccountListGrid = styled.div`
  display: grid;
  width: 100%;
  grid-row-gap: 20px;
  grid-column-gap: 20px;

  grid-template-columns: repeat(auto-fill, ${UIConstants.ACCOUNT_TILE_WIDTH_NOT_MOBILE}px);
  @media (max-width: ${UIConstants.TABLET_BREAKPOINT - 1}px) {
    grid-template-columns: repeat(auto-fill, ${UIConstants.ACCOUNT_TILE_WIDTH_MOBILE}px);
  }
`;
