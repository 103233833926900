import type { HTMLAttributes } from "react";
import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

import type { Amount } from "../../../../../shared/core/amount/amount";
import { useIntl } from "../../../../../shared/core/i18n/use-intl";
import { featuresManager } from "../../../../../shared/core/service/services";
import type { Account } from "../../../../../shared/domains/account/account";
import { useSpendings } from "../../../../../shared/domains/accounting-transaction/transaction/use-spendings";
import { useTransactionLinks } from "../../../../../shared/domains/accounting-transaction/use-transaction-links";
import type { AuthorizationHold } from "../../../../../shared/domains/authorization-hold/authorization-hold";
import { useObservable } from "../../../../../shared/utils/observable";
import { AUTHORIZATION_HOLD_DETAILS_MODAL_ID } from "../../../../core/modal/modal-id";
import { Path } from "../../../../core/routing/path";
import { AmountText } from "../../../common/amount-text";
import { PrimaryButton } from "../../../common/buttons/primary-button";
import { Modal } from "../../../common/modal/modal";
import { RoundedModalContainer } from "../../../common/modal/rounded-modal-container";
import { theme } from "../../../styles/theme";
import { useAuthorizationHoldLabel } from "../hooks/use-authorization-hold-label";
import { PendingColoredTag } from "./pending-colored-tag";

interface AuthorizationHoldDetailsProps {
  authorizationHold: AuthorizationHold;
  account: Account;
}

export const AuthorizationHoldDetailsModal = (props: AuthorizationHoldDetailsProps) => (
  <RoundedModalContainer closeButton id={AUTHORIZATION_HOLD_DETAILS_MODAL_ID}>
    <AuthorizationHoldDetails authorizationHold={props.authorizationHold} account={props.account} />
  </RoundedModalContainer>
);

const AuthorizationHoldDetails: React.FC<AuthorizationHoldDetailsProps> = ({ account, authorizationHold }) => {
  const { push } = useHistory();

  const features = useObservable(featuresManager.features);
  const { formatMessage, formatDate } = useIntl();
  const { labelForType } = useAuthorizationHoldLabel();
  const { amountJustified, spendings } = useSpendings(authorizationHold);
  const { canEditJustification, canJustify, canViewJustification } = useTransactionLinks();

  if (!authorizationHold.amount) {
    return null;
  }

  const handleJustifyTransaction = () => {
    push(Path.JustifyTransaction, { transaction: authorizationHold, account });
    Modal.dismiss(AUTHORIZATION_HOLD_DETAILS_MODAL_ID);
  };

  const buttonTitle = canEditJustification(authorizationHold)
    ? formatMessage("accountingTransactionDetailsModal.verifyJustificationsButtonLabel")
    : formatMessage("accountingTransactionDetailsModal.justifyButtonLabel");

  return (
    <Container>
      <Header>
        <Title>{labelForType(authorizationHold.type)}</Title>
      </Header>
      <DateAndTagContainer>
        <DateLabel>
          {formatDate(authorizationHold.date, {
            month: "long",
            day: "2-digit",
            year: "numeric",
            hour: "2-digit",
            minute: "2-digit",
          })}
        </DateLabel>
        <PendingColoredTag label={formatMessage("authorizationHoldsDetailsModal.pending")} />
      </DateAndTagContainer>
      {!!authorizationHold.label && <Description style={{ marginTop: 10 }}>{authorizationHold.label}</Description>}
      {!!authorizationHold.id && (
        <Description style={{ marginTop: 10 }}>
          {formatMessage("authorizationHoldsDetailsModal.reference", { id: authorizationHold.id })}
        </Description>
      )}
      <DetailsTitle>{formatMessage("authorizationHoldsDetailsModal.detailsTitle")}</DetailsTitle>
      <Items>
        <TransactionEntryView
          label={formatMessage("authorizationHoldsDetailsModal.transferLabel")}
          amount={authorizationHold.amount}
          hasLink
        />
        <TransactionEntryView
          label={formatMessage("authorizationHoldsDetailsModal.amountLabel")}
          amount={authorizationHold.amount}
          bold
        />
      </Items>

      {features.manageCategorizationSpendings && spendings.length > 0 ? (
        <>
          <DetailsTitle>{formatMessage("accountingTransactionDetailsModal.justificationFollowUpTitle")}</DetailsTitle>
          <Items>
            <TransactionEntryView
              label={formatMessage("accountingTransactionDetailsModal.documentsSubmitted")}
              amount={amountJustified()}
              hasLink={canEditJustification(authorizationHold)}
              bold
            />

            {canEditJustification(authorizationHold) ? (
              <TransactionEntryView
                label={formatMessage("accountingTransactionDetailsModal.validationInProgress")}
                amount={null}
                grey
              />
            ) : null}
          </Items>
        </>
      ) : null}

      {(canViewJustification(authorizationHold) || canJustify(authorizationHold)) && (
        <CanJustifyButton onClick={handleJustifyTransaction} size="S">
          {buttonTitle}
        </CanJustifyButton>
      )}
    </Container>
  );
};

interface TransactionEntryViewProps extends HTMLAttributes<HTMLDivElement> {
  label: string;
  amount: Amount | undefined | null;
  green?: boolean;
  grey?: boolean;
  hasLink?: boolean;
  bold?: boolean;
}

export const TransactionEntryView: React.FC<TransactionEntryViewProps> = (props) => {
  const { label, amount, green, grey, hasLink, bold, ...viewProps } = props;

  return (
    <Item {...viewProps}>
      <Bullet green={green} grey={grey} />
      <ItemLabel bold={bold} grey={grey}>
        {label}
      </ItemLabel>
      {amount !== null ? (
        <ItemValue green={green} bold={bold}>
          <AmountText amount={amount} />
        </ItemValue>
      ) : null}
      {hasLink && <Link />}
    </Item>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
`;

const Title = styled.h2`
  ${theme.boldText};
  font-size: 1.375rem;
  margin: 0;
  flex-shrink: 1;
  flex-grow: 1;
`;

const DetailsTitle = styled.h3`
  ${theme.boldText};
  font-size: 1.125rem;
  margin: 10px 0;
`;

const DateAndTagContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 20px 0 8px;
`;

const DateLabel = styled.span`
  ${theme.text};
  font-size: 0.9375rem;
  margin: 0 8px 0 0;
`;

const Description = styled.span`
  ${theme.text};
  color: #b1b1b1;
  font-size: 0.9375rem;
  margin: 10px 0;
`;

const Item = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
`;

const ItemLabel = styled.span<{ bold?: boolean; green?: boolean; grey?: boolean }>`
  ${(props) => (props.bold ? theme.boldText : theme.text)};
  color: ${(props) => (props.green ? "#2ed794" : props.grey ? "#b1b1b1" : "#000000")};
  flex-shrink: 1;
  flex-grow: 1;
`;

const ItemValue = styled.span<{ bold?: boolean; green?: boolean }>`
  ${(props) => (props.bold ? theme.boldText : theme.text)};
  color: ${(props) => (props.green ? "#2ed794" : "#000000")};
  margin-left: 20px;
`;

const Bullet = styled.span<{ green?: boolean; grey?: boolean }>`
  width: 10px;
  height: 10px;
  background-color: ${(props) => (props.green ? "#2ed794" : props.grey ? "#b1b1b1" : "#000000")};
  margin-right: 15px;
  border-radius: 15px;
`;

const Items = styled.div`
  margin-top: 10px;
  margin-bottom: 20px;

  > *:not(:last-child) {
    margin-bottom: 20px;
  }
`;

const Link = styled.div`
  position: absolute;
  top: 100%;
  left: 3.5px;
  width: 2px;
  height: 20px;
  border-radius: 1.5px;
  background-color: #000000;
`;

const CanJustifyButton = styled(PrimaryButton)`
  margin-top: 20px;
`;
