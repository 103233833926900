import React, { useState } from "react";
import styled from "styled-components";

import { useIntl } from "../../../../../../shared/core/i18n/use-intl";
import { useAsyncOperation } from "../../../../../../shared/utils/utils";
import { PrimaryButton } from "../../../../common/buttons/primary-button";
import { SwitchContainer } from "../../../../common/buttons/switch-button";
import { ScrollableDiv } from "../../../../common/modal/scrollable-div";
import { theme } from "../../../../styles/theme";

export interface CreateCardCguStepProps {
  cgu: string;
  acceptCgu: () => Promise<void>;
}

export const CreateCardCguStep = (props: CreateCardCguStepProps) => {
  const { cgu, acceptCgu } = props;
  const { formatMessage } = useIntl();
  const [isCguAccepted, setCGUAccepted] = useState(false);

  const [goToNextStep, loading] = useAsyncOperation(async () => {
    await acceptCgu();
  });

  return (
    <Container>
      <CguTitle>{formatMessage("createCard.stepCgu.title")}</CguTitle>
      <ScrollableDiv>
        <CguContainer>
          <CguInfo>{cgu}</CguInfo>
        </CguContainer>
      </ScrollableDiv>
      <SwitchContainer value={isCguAccepted} onSwitch={setCGUAccepted} />
      <AcceptCguLabel>{formatMessage("createCard.stepCgu.acceptMessage")}</AcceptCguLabel>
      <ContinueButton size={"S"} showSpinner={loading} disabled={!isCguAccepted} onClick={goToNextStep}>
        {formatMessage("createCard.stepCgu.next")}
      </ContinueButton>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 530px;
`;

const CguTitle = styled.span`
  ${theme.boldText}
  padding-bottom: 20px;
  font-size: 1.125rem;
  color: #000000;
`;

const CguContainer = styled.div`
  display: flex;
`;

const CguInfo = styled.span`
  ${theme.mediumText}
  padding-top: 30px;
  padding-bottom: 10px;
  font-size: 0.9375rem;
  white-space: pre-wrap;
  color: #000000;
`;

const AcceptCguLabel = styled.span`
  ${theme.text}
  font-size: 0.75rem;
  color: #aaaaaa;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 16px;
`;

const ContinueButton = styled(PrimaryButton)`
  align-self: center;
  margin-bottom: 20px;
`;
