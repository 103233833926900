import React from "react";
import styled from "styled-components";

import { useIntl } from "../../../../shared/core/i18n/use-intl";
import { theme } from "../../styles/theme";

export type FormLabelProps = {
  label: string;
  required?: boolean;
  htmlFor?: string;
  disableFormatting?: boolean;
  margin?: "dense" | "normal" | "none";
  size?: "small" | "normal";
};

export default function FormLabel(props: FormLabelProps) {
  const { htmlFor, margin = "none", size = "small", label, required, disableFormatting } = props;
  const { formatMessage } = useIntl();
  return (
    <Label margin={margin} htmlFor={htmlFor} size={size}>
      {disableFormatting ? label : formatMessage(label)}
      {required ? " *" : ""}
    </Label>
  );
}

const Label = styled.label<{
  size?: "small" | "normal";
  margin: "dense" | "normal" | "none";
}>`
  ${theme.mediumText};
  display: block;
  font-size: ${({ size }) => (size === "small" ? ".75rem" : "15px")};
  margin-top: ${({ margin }) => (margin === "dense" ? "20px" : margin === "normal" ? "30px" : "0")};
  margin-bottom: 8px;
`;
