import { useCallback, useMemo, useState } from "react";

import { externalAccountManager } from "../../core/service/services";
import { useObservable } from "../../utils/observable";
import { useAsyncEffect } from "../../utils/utils";
import type { Recipient } from "./recipient";

export interface Externals {
  data: Recipient[];
  loading: boolean;
  error: Error | null;
  canLoadMore: boolean;
  loadMore: () => void;
  loadingMore: boolean;
  loadingMoreError: Error | null;
  refresh: () => void;
  refreshing: boolean;
}

export function useExternalAccounts(): Externals {
  const [refreshing, setRefreshing] = useState(false);

  const rawData = useObservable(externalAccountManager.externalAccounts);
  const loading = useObservable(externalAccountManager.loading);
  const error = useObservable(externalAccountManager.error);
  const loadingMore = useObservable(externalAccountManager.loadingMore);
  const loadingMoreError = useObservable(externalAccountManager.loadingMoreError);

  useAsyncEffect(async () => {
    await externalAccountManager.load();
  }, []);

  const refresh = useCallback(async () => {
    setRefreshing(true);
    await externalAccountManager.load(true);
    setRefreshing(false);
  }, []);

  const canLoadMore = useMemo(
    () => rawData.offset + rawData.limit < rawData.total,
    [rawData.offset, rawData.limit, rawData.total],
  );

  const data = useMemo(() => rawData.items, [rawData.items]);

  const loadMore = useCallback(async () => {
    await externalAccountManager.loadMore();
  }, []);

  return {
    data,
    loading,
    error,
    canLoadMore,
    loadingMore,
    loadingMoreError,
    refreshing,
    loadMore,
    refresh,
  };
}
