import React, { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import styled from "styled-components";

import { isDefined } from "../../../../../../shared/utils/assert";
import { getRandomID } from "../../../../../../shared/utils/random";
import { repeat } from "../../../../../../shared/utils/utils";
import { theme } from "../../../../styles/theme";

export interface DigitsInputRef {
  reset: () => void;
}

interface DigitsInputProps {
  length: number;
  onIdentifierChange?: (identifier: string, complete: boolean) => void;
  validator?: (identifier: string) => boolean;
  className?: string;
}
interface Input {
  id: string;
  name: string;
  value: string;
}
// keyCode constant
const BACKSPACE = 8;
const DELETE = 46;
const ARROW_LEFT = 37;
const ARROW_RIGHT = 39;

export const DigitsInput = forwardRef<DigitsInputRef, DigitsInputProps>((props, ref) => {
  const { length, onIdentifierChange, validator, className } = props;
  const [inputs, setInputs] = useState<Input[]>(
    repeat(length, (index) => ({
      id: `cardIdInput_${index}_${getRandomID()}`,
      name: `cardIdInput_${index}`,
      value: "",
    })),
  );

  const [focusInputId, setFocusInputId] = useState(inputs[0].id);
  const [isError, setError] = useState(false);

  const resetInput = () => {
    setInputs((prevInputs) => prevInputs.map((input) => ({ ...input, value: "" })));
    document.getElementById(inputs[0].id)?.focus({ preventScroll: true });
    onIdentifierChange?.("", false);
  };

  useImperativeHandle(ref, () => ({
    reset: resetInput,
  }));

  useEffect(() => {
    document.getElementById(focusInputId)?.focus({ preventScroll: true });
  }, [focusInputId]);

  const notifyIdentifierChange = () => {
    if (onIdentifierChange || validator) {
      const identifier = inputs.map((input) => input.value).join("");
      const isComplete = identifier.length === length;

      onIdentifierChange?.(identifier, isComplete);
      setError(isComplete && isDefined(validator) && !validator(identifier));
    }
  };

  const handleRemoveKeys = (e: React.KeyboardEvent<HTMLInputElement>, inputIndex: number) => {
    const shiftLeftTo = (index: number) => {
      while (index < length - 1) {
        inputs[index].value = inputs[index + 1].value;
        index++;
      }
      inputs[length - 1].value = "";
    };

    if (e.keyCode === BACKSPACE || e.key === "Backspace" || e.keyCode === DELETE || e.key === "Delete") {
      e.preventDefault();

      if (inputs[inputIndex].value.length > 0) {
        shiftLeftTo(inputIndex);
        setInputs([...inputs]);

        notifyIdentifierChange();
      } else {
        shiftLeftTo(inputIndex);
        if (inputIndex > 0) {
          setFocusInputId(inputs[inputIndex - 1].id);
        }
      }
    } else if ((e.keyCode === ARROW_LEFT || e.key === "ArrowLeft") && inputIndex > 0) {
      setFocusInputId(inputs[inputIndex - 1].id);
    } else if ((e.keyCode === ARROW_RIGHT || e.key === "ArrowRight") && inputIndex < length - 1) {
      setFocusInputId(inputs[inputIndex + 1].id);
    }
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>, inputIndex: number) => {
    const { value } = e.target;
    const sanitizedValue = value.replace(/[^0-9]/g, "");
    let nextFocusId = inputs[inputIndex].id;
    if (value.length === 0 && sanitizedValue.length === 0) {
      const prevInputIndex = inputIndex - 1;
      if (prevInputIndex >= 0) {
        nextFocusId = inputs[prevInputIndex].id;
      }
      inputs[inputIndex].value = sanitizedValue;
    } else if (sanitizedValue.length === 1) {
      const nextInputIndex = inputIndex + 1;
      if (nextInputIndex < inputs.length) {
        nextFocusId = inputs[nextInputIndex].id;
      }
      inputs[inputIndex].value = sanitizedValue;
    } else if (sanitizedValue.length > 1) {
      let inputIndexInLoop = inputIndex;
      for (; inputIndexInLoop < Math.min(length, sanitizedValue.length + inputIndex); inputIndexInLoop++) {
        inputs[inputIndexInLoop].value = sanitizedValue[inputIndexInLoop - inputIndex];
      }
      nextFocusId = inputs[Math.min(length - 1, inputIndexInLoop)].id;
    }
    setInputs([...inputs]);
    setFocusInputId(nextFocusId);
    if (focusInputId === nextFocusId) {
      document.getElementById(focusInputId)?.focus({ preventScroll: true });
    }
    notifyIdentifierChange();
  };

  return (
    <Container className={className}>
      {inputs.map((input, index) => (
        <DigitBlock
          input={input}
          index={index}
          key={index}
          isError={isError}
          onChangeAtIndex={handleOnChange}
          onKeyDownAtIndex={handleRemoveKeys}
        />
      ))}
    </Container>
  );
});

const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

interface DigitBlockProps {
  input: Input;
  index: number;
  isError?: boolean;
  onChangeAtIndex: (e: React.ChangeEvent<HTMLInputElement>, inputIndex: number) => void;
  onKeyDownAtIndex: (e: React.KeyboardEvent<HTMLInputElement>, inputIndex: number) => void;
}

export const DigitBlock = (props: DigitBlockProps) => {
  const { input, index, isError, onChangeAtIndex, onKeyDownAtIndex } = props;
  const isFilled = input.value.length > 0;

  const underlineColor = () => {
    if (isFilled) {
      return isError ? "#ff4242" : theme.mainColor();
    }
    return "#bababa";
  };

  return (
    <DigitContainer>
      <NumberInput
        value={input.value}
        id={input.id}
        key={index}
        onFocus={(event) => event.target.select()}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => onChangeAtIndex(event, index)}
        onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => onKeyDownAtIndex(event, index)}
      />
      <Line style={{ backgroundColor: underlineColor() }} />
    </DigitContainer>
  );
};

const Line = styled.div`
  height: 2px;
`;

const DigitContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 30px;

  margin-right: 5px;
  margin-left: 5px;
`;

const NumberInput = styled.input`
  flex: 1 0 auto;
  outline: none;
  border: none;
  ${theme.boldText};
  font-size: 15px;
  color: #000000;
  background-color: transparent;
  text-align: center;
  height: 40px;
`;
