import type { AxiosResponse } from "axios";

import type { AuthenticationManager } from "../../../domains/authentication/authentication-manager";
import type { ServiceDomainManager } from "../../../domains/service-domain/service-domain-manager";
import type { HttpService } from "../http-service";
import type { SecuredCookiesService } from "../secured-cookies-service";
import type { Interceptor } from "./interceptor";

export const renewTokenInterceptor: (
  httpService: HttpService,
  authenticationManager: AuthenticationManager | ServiceDomainManager,
  securedCookiesService?: SecuredCookiesService,
) => Interceptor<AxiosResponse> = (httpService, authenticationManager, securedCookiesService) => ({
  onRejected: async (error) => {
    if (!error?.response || error?.response?.status != 401) {
      return Promise.reject(error);
    }
    try {
      const accessToken = await authenticationManager.getAccessToken(true);
      const addAuthorization = !securedCookiesService?.getSecuredCookies();
      if (accessToken && addAuthorization) {
        error.response.config.headers.Authorization = `${accessToken.type} ${accessToken.value}`;
      }
      return httpService.instance(error.response.config);
    } catch (e) {
      return Promise.reject(error);
    }
  },
});
