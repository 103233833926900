import { jsPDF } from "jspdf";

import { logger } from "../../shared/core/logging/logger";
import type { ImagesConverter, UploadedImage } from "../../shared/utils/images-converter";

const A4_PAPER_DIMENSIONS = {
  width: 210,
  height: 297,
};

const A4_PAPER_RATIO = A4_PAPER_DIMENSIONS.width / A4_PAPER_DIMENSIONS.height;

// Calculates the best possible position of an image on the A4 paper format,
// so that the maximal area of A4 is used and the image ratio is preserved.
const imageDimensionsOnA4 = (dimensions: { width: number; height: number }) => {
  const isLandscapeImage = dimensions.width >= dimensions.height;

  // If the image is in landscape, the full width of A4 is used.
  if (isLandscapeImage) {
    return {
      width: A4_PAPER_DIMENSIONS.width * 0.9,
      height: (A4_PAPER_DIMENSIONS.width * 0.9) / (dimensions.width / dimensions.height),
    };
  }

  // If the image is in portrait and the full height of A4 would skew
  // the image ratio, we scale the image dimensions.
  const imageRatio = dimensions.width / dimensions.height;
  if (imageRatio > A4_PAPER_RATIO) {
    const imageScaleFactor = ((A4_PAPER_RATIO * dimensions.height) / dimensions.width) * 0.9;
    const scaledImageHeight = A4_PAPER_DIMENSIONS.height * imageScaleFactor;

    return {
      height: scaledImageHeight,
      width: scaledImageHeight * imageRatio,
    };
  }

  // The full height of A4 can be used without skewing the image ratio.
  return {
    width: A4_PAPER_DIMENSIONS.height / (dimensions.height / dimensions.width),
    height: A4_PAPER_DIMENSIONS.height,
  };
};

export class WebImagesConverter implements ImagesConverter {
  toPdf = async (images: (string | UploadedImage)[]): Promise<string | undefined> => {
    try {
      const doc = new jsPDF();

      // We let the images add all pages,
      // therefore the first default page can be removed.
      doc.deletePage(1);

      images.forEach((image) => {
        const webImage = image as UploadedImage;
        const imageDimensions = imageDimensionsOnA4({
          width: webImage.width,
          height: webImage.height,
        });
        doc.addPage();
        doc.addImage(
          webImage.base64,
          "JPEG",
          // Images are vertically and horizontally centered on the page.
          (A4_PAPER_DIMENSIONS.width - imageDimensions.width) / 2,
          (A4_PAPER_DIMENSIONS.height - imageDimensions.height) / 2,
          imageDimensions.width,
          imageDimensions.height,
          undefined,
          "FAST",
        );
      });

      // Creates a PDF
      const pdf = doc.output();
      return pdf.toString();
    } catch (e) {
      logger.debug("toPDF " + e);
    }
  };

  toBase64 = async (binaryData: string): Promise<string> => {
    const base64 = btoa(binaryData);
    return Promise.resolve(base64);
  };

  fileIntoBase64 = async (file: File): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
}
