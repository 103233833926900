import React, { useMemo } from "react";

import { useIntl } from "../../../../../../shared/core/i18n/use-intl";
import type { Card } from "../../../../../../shared/domains/cards/card";
import { CardStatus } from "../../../../../../shared/domains/cards/card";
import { CARD_PINCODE_UPDATE_MODAL_ID } from "../../../../../core/modal/modal-id";
import { Modal } from "../../../../common/modal/modal";
import { ActionMenu } from "../action-menu";
import { CardUpdatePincodeModal } from "./update-card-pincode-modal";

interface UpdateCardPincodeProps {
  card: Card;
  disabled?: boolean;
  className?: string;
}

export const UpdateCardPinCodeButton: React.FC<UpdateCardPincodeProps> = ({ card, disabled, className }) => {
  const { formatMessage } = useIntl();
  const canUpdate = useMemo(
    () => card.status === CardStatus.Active || card.status === CardStatus.Blocked,
    [card.status],
  );

  if (!canUpdate) {
    return null;
  }

  return (
    <ActionMenu
      title={formatMessage("cardOptions.updateCardPincode.updateButton")}
      disabled={disabled}
      className={className}
      onClick={() => {
        Modal.present(CARD_PINCODE_UPDATE_MODAL_ID, () => <CardUpdatePincodeModal card={card} />, {
          canBeDismissed: false,
        });
      }}
    />
  );
};
