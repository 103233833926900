import type { SVGProps } from "react";
import React from "react";

export function PauseIcon(props: SVGProps<SVGSVGElement>) {
  return (
    <svg viewBox="0 0 26 26" {...props}>
      <rect x="9" y="7" width="3" height="12" rx="1" fillRule="nonzero" />
      <rect x="14" y="7" width="3" height="12" rx="1" fillRule="nonzero" />
    </svg>
  );
}
