import React, { forwardRef } from "react";
import styled from "styled-components";

import type { FormEmailInput } from "../../../../shared/core/data-forms/form-input-types";
import { useIntl } from "../../../../shared/core/i18n/use-intl";
import { TextInput } from "../forms/text-input";
import { DisableValueText } from "./disable-value-text";

export interface EmailFieldProps {
  id?: string;
  input: FormEmailInput;
  onChange?: (value: string) => void;
  disabled?: boolean;
}

export const EmailField = forwardRef<HTMLInputElement, EmailFieldProps>(({ id, input, disabled, onChange }, ref) => {
  const { formatMessage } = useIntl();

  return input.disabled || disabled ? (
    <DisableValueText>{input.value}</DisableValueText>
  ) : (
    <StyledTextInput
      id={id}
      data-testid={id}
      ref={ref}
      required={input.required}
      value={input.value ?? ""}
      type="email"
      onChange={(e) => onChange?.(e.target.value)}
      placeholder={formatMessage("billFormScreen.textInputPlaceholder")}
    />
  );
});

const StyledTextInput = styled(TextInput)`
  input::placeholder {
    color: #b1b1b1;
  }
`;
